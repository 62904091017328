import React, { useEffect, useRef, useState } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import {
  getUsers,
  loginAsSelectedUser,
  selectSuperAdminState,
  sendReportToUser,
  setDeleteUser,
  setUnDeleteUser,
  updateUser,
  deleteUser,
} from '../../store/superAdmin';
import { selectAppState } from '../../store/app';
import 'react-calendar/dist/Calendar.css';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.css';
import { CheckBox } from '../../components/ui/CheckBox';
import { TextInput } from '../../components/ui/TextInput';
import { storage, StorageKeys } from '../../utils/localStorage';
import {
  COMPANY_COL_INDEX,
  DELETED_AT_COL_INDEX,
  IS_TRIAL_COL_INDEX,
  LAST_BILL_DATE_COL_INDEX,
  NEXT_BILL_DATE_COL_INDEX,
  SUBSCRIPTION_END_DATE_COL_INDEX,
  USER_ID_COL_INDEX,
  renderRow,
  SUBSCRIPTION_ID_COL_INDEX,
  validateTableFields,
  EMAIL_COL_INDEX,
  FIRST_NAME_COL_INDEX,
  LAST_NAME_COL_INDEX,
  ROLE_COL_INDEX,
  SUBSCRIPTION_REQUIRES_FOLLOWUP_COL_INDEX,
  DELETE_USER_COL_INDEX,
} from './usersTableConfig';
import { Loader } from '../../components/ui/Loader';

const cx = classNames.bind(styles);

export const Users = () => {
  const dispatch = useDispatch();
  const [withDeletedUsers, showDeletedUsers] = useState(false);
  const { parameters } = useSelector(selectAppState);
  const { users } = useSelector(selectSuperAdminState);
  const [searchValue, setValue] = useState('');
  const { data, sendingReportToUser, deletingUser, isLoading } = users;

  const savedWidths = storage.get(StorageKeys.colWidths);
  const colWidths = savedWidths
    ? JSON.parse(savedWidths)
    : [
        90, 200, 80, 80, 110, 200, 200, 90, 140, 80, 100, 120, 120, 120, 120,
        130, 120, 100, 140, 140, 140, 150, 140,
      ];

  const table = useRef<HotTable | null>(null);

  useEffect(() => {
    dispatch(getUsers.request());
  }, [dispatch]);

  const subscriptionNames: { [key: string]: string } = { '-1': 'Expired' };
  parameters?.subscriptions.forEach(
    (item) => (subscriptionNames[item.id] = item.systemName)
  );

  const getCellData = (row: number, col: number): string => {
    if (!table || !table.current) return '';
    return table.current.hotInstance.getDataAtCell(row, col);
  };

  const mapTableData = () => {
    if (!data || !parameters) return [];
    return data
      .filter((item) => {
        if (!searchValue) return true;
        return JSON.stringify(item)
          .toLowerCase()
          .includes(searchValue.toLowerCase());
      })
      .filter((item) => {
        if (withDeletedUsers) return item.deletedAt;
        return !item.deletedAt;
      })
      .map((item) => {
        return renderRow({
          item,
          getCellData,
          subscriptionNames,
        }).map((td) => td.value);
      });
  };

  const row = renderRow({
    setUnDeleteUser: (userId) => dispatch(setUnDeleteUser.request({ userId })),
    loginAsSelectedUser: (userId) =>
      dispatch(loginAsSelectedUser.request({ userId })),
    setDeleteUser: (userId) => dispatch(setDeleteUser.request({ userId })),
    sendingReportToUser,
    getCellData,
    showDeleted: withDeletedUsers,
    sendReportToUser: (userId) => dispatch(sendReportToUser.request(userId)),
    subscriptionNames,
    deleteUser: (userId) => dispatch(deleteUser.request(userId)),
    deletingUser,
  });

  const columns = row.map((item) => item.column);

  validateTableFields(row);

  const renderTable = () => {
    if (isLoading) return <Loader className={cx('loader')} />;
    return (
      <HotTable
        afterChange={(changes, source) => {
          if (!changes || !changes[0]) return null;
          const [row, , prevValue, nextValue] = changes[0];
          if (source === 'edit' && prevValue !== nextValue) {
            const subscriptions = Object.assign(
              {},
              ...Object.entries(subscriptionNames).map(([a, b]) => ({ [b]: a }))
            );
            const subName = getCellData(row, SUBSCRIPTION_ID_COL_INDEX);
            const dataToUpdate = {
              subscriptionId: subscriptions[subName],
              lastBillDate: getCellData(row, LAST_BILL_DATE_COL_INDEX),
              nextBillDate: getCellData(row, NEXT_BILL_DATE_COL_INDEX),
              subscriptionEndDate: getCellData(
                row,
                SUBSCRIPTION_END_DATE_COL_INDEX
              ),
              isTrial: !!getCellData(row, IS_TRIAL_COL_INDEX),
              userId: getCellData(row, USER_ID_COL_INDEX),
              email: getCellData(row, EMAIL_COL_INDEX),
              firstName: getCellData(row, FIRST_NAME_COL_INDEX),
              lastName: getCellData(row, LAST_NAME_COL_INDEX),
              companyName: getCellData(row, COMPANY_COL_INDEX),
              role: getCellData(row, ROLE_COL_INDEX),
              subscriptionRequiresFollowUp: !!getCellData(
                row,
                SUBSCRIPTION_REQUIRES_FOLLOWUP_COL_INDEX
              ),
            };
            dispatch(updateUser.request({ dataToUpdate: dataToUpdate }));
          }
        }}
        ref={table}
        id="hot-table"
        columns={columns}
        dropdownMenu={['filter_by_condition', 'filter_action_bar']}
        filters={true}
        hiddenColumns={{
          columns: withDeletedUsers
            ? [USER_ID_COL_INDEX]
            : [USER_ID_COL_INDEX, DELETED_AT_COL_INDEX, DELETE_USER_COL_INDEX],
        }}
        fixedColumnsLeft={2}
        columnSorting={true}
        licenseKey="non-commercial-and-evaluation"
        data={mapTableData()}
        colHeaders={true}
        manualColumnResize
        manualRowResize
        afterColumnResize={(newSize, column) => {
          const widths = [...colWidths];
          widths[column] = newSize;
          storage.save(StorageKeys.colWidths, JSON.stringify(widths));
        }}
        colWidths={colWidths}
        rowHeaders={true}
        width="100%"
        height="500"
      />
    );
  };

  return (
    <div className={cx('users')}>
      <div className={cx('row')}>
        <CheckBox
          className={cx('checkbox')}
          onChange={() => showDeletedUsers(!withDeletedUsers)}
          checked={withDeletedUsers}
          name=""
        />
        <div className={cx('text')}>Show archived users</div>
        {users.success && (
          <div className={cx('notification', 'notification--success')}>
            Success
          </div>
        )}
        {users.error && (
          <div className={cx('notification', 'notification--failed')}>
            Error
          </div>
        )}
      </div>
      <div className={cx('row')}>
        <TextInput
          className={cx('search-input')}
          autocomplete="off"
          onChange={(_, value) => setValue(value)}
          label="super-admin.search-users.title"
          value={searchValue}
          name="search"
        />
      </div>
      <div className={cx('table')}>{renderTable()}</div>
    </div>
  );
};
