import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {homePageState, showSecondarySuccessModal} from "../../../store/home";
import {useDispatch, useSelector} from "react-redux";
import {ModalWithLogo} from "../../../components/ModalWithLogo";

const cx = classNames.bind(styles);

export const SecondarySuccessModal = () => {
  const {secondarySuccessModal} = useSelector(homePageState);
  const dispatch = useDispatch()
  return (
    <ModalWithLogo withCloseButton close={() => dispatch(showSecondarySuccessModal(false))} visible={secondarySuccessModal} className={cx('modal')}>
      <h3 className={cx('heading')}>Lieber Nutzer,</h3>
      <p className={cx('text')}>
        Danke für Ihre Angaben und Ihr Interesse, unsere Software-Lösungen auszuprobieren. Das gewünschte Resultat, also den Vergleich Ihrer URL mit anderen, relevanten Seiten, erhalten Sie von uns innerhalb von 3 Werktagen per E-Mail.
      </p>
      <div className={cx('team')}>Ihr drkpi-Team</div>
    </ModalWithLogo>
  );
};
