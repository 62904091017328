import {combineReducers, createStore, compose, applyMiddleware} from 'redux';
import {connectRouter, routerMiddleware, RouterState} from 'connected-react-router';
import {createBrowserHistory, History} from 'history';
import createSagaMiddleware from 'redux-saga';
// Reducers
import {AuthState, authReducer} from './auth';
import {appReducer, AppState} from './app/app.reducer';
import {ProfileState, profileReducer} from './profile';
import {reportReducer, ReportState} from './report/report.reducer';
import {compareReducer, CompareState} from './compare/compare.reducer';
import {archiveReducer, ArchiveState} from './archive/archive.reducer';
import {benchmarkingReducer, BenchmarkingState} from './benchmarking/benchmarking.reducer';
import {trendReducer, TrendState} from './trend/trend.reducer';
import {brandingReducer, BrandingState} from './branding/branding.reducer';
import {superAdminReducer, SuperAdminState} from './superAdmin/superAdmin.reducer';
import {subscriptionReducer, SubscriptionState } from './subscription';
import {seoReducer, SeoState} from './seo';
import {EngagementState, engagementReducer} from './engagement';
import {visualsReducer, VisualsState} from './visuals';
import {HomePageState, homePageReducer } from './home';

import {rootSaga} from './sagas';
import { editPdfReportReducer, EditPdfReportState } from './editPdfReport';
import {editSectionTextReducer, EditSectionTextState} from './editSectionText';

export const history = createBrowserHistory();

interface Env {
  NODE_PATH: string;
  REACT_APP_MAP_KEY: string;
  REACT_APP_SERVER_URL: string;
}

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    _env_: Env;
  }
}

declare global {
  interface RootState {
    router: RouterState;
    auth: AuthState;
    app: AppState;
    profile: ProfileState;
    report: ReportState;
    compare: CompareState;
    archive: ArchiveState;
    benchmarking: BenchmarkingState;
    trend: TrendState;
    branding: BrandingState;
    superAdmin: SuperAdminState;
    subscription: SubscriptionState;
    seo: SeoState;
    engagement: EngagementState;
    visuals: VisualsState;
    home: HomePageState;
    editPdfReport:EditPdfReportState;
    editSectionText:EditSectionTextState;
  }
}

/** Create reducer combined of all other. This is the global app state */
const rootReducer = (history: History) =>
  combineReducers<RootState>({
    router: connectRouter(history),
    auth: authReducer,
    seo: seoReducer,
    app: appReducer,
    profile: profileReducer,
    report: reportReducer,
    compare: compareReducer,
    archive: archiveReducer,
    benchmarking: benchmarkingReducer,
    trend: trendReducer,
    branding: brandingReducer,
    superAdmin: superAdminReducer,
    subscription: subscriptionReducer,
    engagement: engagementReducer,
    visuals: visualsReducer,
    home: homePageReducer,
    editPdfReport:editPdfReportReducer,
    editSectionText:editSectionTextReducer
  });

/** Enable redux dev tools */
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

export const store = createStore(
  rootReducer(history),
  composeEnhancers(applyMiddleware(sagaMiddleware, routerMiddleware(history))),
);

sagaMiddleware.run(rootSaga);
