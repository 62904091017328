import axios, { AxiosResponse } from 'axios';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import { EditPdfReportState, selectEditPdfReport } from '.';
import { apiEndpoints } from '../../constants/api';
import { handleResponseError } from '../../utils/responseErrors';
import * as actions from './editPdfReport.actions';

function* onGetPdfFooter() {
  try {
    const {data}: AxiosResponse<{footer:EditPdfReportState}> = yield call(axios.get, apiEndpoints.getReportFooters)
    yield put(actions.getPdfFooterData.success(data.footer))
  } catch (e) {
    console.log(e)
    yield handleResponseError(e, actions.getPdfFooterData.failure)
  }
}
function* onUpdatePdfFooter() {
  const {pages} = selectEditPdfReport(yield select());
  try {
    const {data}: AxiosResponse<{footer:EditPdfReportState}> = yield call(axios.put, apiEndpoints.updateReportFooters,{pages:[...pages]})
    yield put(actions.updatePdfFooterData.success(data.footer))
  } catch (e) {
    yield handleResponseError(e, actions.updatePdfFooterData.failure)
  }
}

export function* createEditPdfReportSagas():Generator {
  yield all([
    yield takeLatest(actions.updatePdfFooterData.request, onUpdatePdfFooter),
    yield takeLatest(actions.getPdfFooterData.request, onGetPdfFooter)
  ])
}