import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  className?: string
}
export const Loader = (props:Props) => {
  return (
    <div className={cx('container', props.className)}>
      <div className={cx('loader')}/>
    </div>
  );
};
