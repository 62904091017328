import React, {FC, useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {DiagramTextValuesKeys} from '../../store/editSectionText/types';
import {Button} from '../../components/ui/Button';
import {DropDownDescription} from '../../components/DropDownDescription';
import {NavBar} from './Tabs';
import {useDispatch, useSelector} from 'react-redux';
import {
  onEditorTextChange,
  selectEditSectionText,
  updateSectionTextData,
  onEditorAddTextBox,
  onEditorRemoveTextBox,
} from '../../store/editSectionText';
import QuillEditor from './QuillEditor';
import {Loader} from '../../components/ui/Loader';
const cx = classNames.bind(styles);
interface PageProps {
  index: string;
}

export const SectionTexts: FC<PageProps> = ({index}) => {
  const dispatch = useDispatch();
  const {diagramsPages, loading, loadingUpdate, savedDiagramsPages} = useSelector(selectEditSectionText);
  const [toggleShow, setToggleShow] = useState<boolean[]>(
    Array.from({length: diagramsPages[index]?.length || 0}, () => true)
  );
  const [toggleShowOriginal, setToggleShowOriginal] = useState<boolean[]>(
    Array.from({length: diagramsPages[index]?.length || 0}, () => false)
  );
  const [selectedTab, setSelectedTab] = useState<number[]>(
    Array.from({length: diagramsPages[index]?.length || 0}, () => 0)
  );
  const tabHandler = (value: number, diagramIndex?: number) => {
    const newArr = [...selectedTab];
    newArr[diagramIndex || 0] = value;
    setSelectedTab(newArr);
  };
  const previewHandler = (i: number) => {
    const newArr = [...toggleShow];
    newArr[i] = !toggleShow[i];
    setToggleShow(newArr);
  };
  const originalHandler = (i: number) => {
    const newArr = [...toggleShowOriginal];
    newArr[i] = !toggleShowOriginal[i];
    setToggleShowOriginal(newArr);
  };
  const handleQuillEditor = (
    value: string,
    side: DiagramTextValuesKeys,
    index: string,
    diagramId: number,
    textId: number
  ) => {
    dispatch(onEditorTextChange({index, value, side, diagramId, textId}));
  };
  const handleAddText = (index: string, diagramId: number) => {
    dispatch(onEditorAddTextBox({index, diagramId}));
  };
  const handleDeleteText = (index: string, diagramId: number, textId: number) => {
    dispatch(onEditorRemoveTextBox({index, diagramId, textId}));
  };
  const updateHandler = (value: string) => {
    dispatch(updateSectionTextData.request({value}));
  };

  const renderDiagramsDescription = () => {
    if (loading) return <Loader className={cx('loader')} />;
    return diagramsPages[index]?.map((diagram, diagramIndex) => (
      <div key={diagramIndex} className={cx('tab-content')}>
        <h2 className={cx('tab-content__title')}>{`${diagramIndex + 1} Diagram Description`}</h2>
        <div className={cx('inner-tabs-wrapper')}>
          <NavBar
            selectedTab={selectedTab[diagramIndex]}
            diagramIndex={diagramIndex}
            setSelectedTab={tabHandler}
            tabs={Array.from({length: diagram.length}, () => '')}
            isDropdown={true}
          />
          <div className={cx('tab-handler-wrapper')}>
            <div
              className={cx('tab-button')}
              onClick={() => {
                handleAddText(index, diagramIndex);
                tabHandler(
                  diagram.length === 0
                    ? selectedTab[diagramIndex]
                    : selectedTab[diagramIndex] !== diagram.length - 1
                    ? diagram.length
                    : selectedTab[diagramIndex] + 1,
                  diagramIndex
                );
              }}>
              +
            </div>
            <div
              className={cx('tab-button')}
              onClick={() => {
                handleDeleteText(index, diagramIndex, selectedTab[diagramIndex]);
                tabHandler(
                  selectedTab[diagramIndex] === diagram.length - 1 && selectedTab[diagramIndex] !== 0
                    ? selectedTab[diagramIndex] - 1
                    : selectedTab[diagramIndex],
                  diagramIndex
                );
              }}>
              -
            </div>
          </div>
        </div>
        {diagram.map((val, id) =>
          id === selectedTab[diagramIndex] ? (
            <div key={id} className={cx('editors-container', 'notranslate')}>
              <QuillEditor
                handleQuillEditor={handleQuillEditor}
                index={index}
                diagramId={diagramIndex}
                textId={id}
                val={val}
              />
            </div>
          ) : null
        )}
        {diagram.length > 0 ? (
          <>
            {toggleShow[diagramIndex] && (
              <div className={cx('drop-down-container')}>
                <h3 className={cx('title')}>PREVIEW</h3>
                <DropDownDescription
                  className={cx('mg')}
                  data={diagram}
                  activeIndex={selectedTab[diagramIndex]}
                />
              </div>
            )}
            {toggleShowOriginal[diagramIndex] && (
              <div className={cx('drop-down-container')}>
                <h3 className={cx('title')}>ORIGINAL</h3>
                <DropDownDescription className={cx('mg')} data={savedDiagramsPages[index][diagramIndex]} />
              </div>
            )}
            <Button
              as="div"
              msg={toggleShow[diagramIndex] ? 'hide-new-footer' : 'show-new-footer'}
              className={cx('button')}
              onClick={() => previewHandler(diagramIndex)}
            />
            <Button
              as="div"
              msg={toggleShowOriginal[diagramIndex] ? 'hide-original-footer' : 'show-original-footer'}
              className={cx('button')}
              onClick={() => originalHandler(diagramIndex)}
            />
          </>
        ) : null}
      </div>
    ));
  };
  return (
    <>
      {renderDiagramsDescription()}
      <Button
        onClick={() => updateHandler(index)}
        msg="save-editor"
        className={cx('download')}
        loading={loadingUpdate}
      />
    </>
  );
};
