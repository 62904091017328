import React, {useRef, useState} from 'react';
import {useOutsideClick} from '../../../utils/useOutsideClick';
import {Text} from "../Text";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {images} from "../../../assets/img";

const cx = classNames.bind(styles);

interface MenuProps {
  className?: string;
  children: React.ReactNode | React.ReactNode[];
  outsideClick?: () => void;
  onClick?: () => void;
}

const Menu = (props: MenuProps) => {
  const node = useRef<HTMLInputElement>(null);
  useOutsideClick(node, () => {
    if (props.outsideClick) props.outsideClick();
  });
  return (
    <div onClick={props.onClick} ref={node} className={cx('menu', props.className)}>
      {props.children}
    </div>
  );
};

/** Option for Select */

interface OptionProps {
  label?: any;
  value: any;
  disableOutsideClick?: string;
  className?: string;
  translate?: boolean;
}

export const Option = (props: OptionProps) => {
  const {label, className, disableOutsideClick, value, translate=true} = props;
  const optionLabel = translate ? <Text msg={props.label}/> :props.label
  return (
    <div
      data-outsideclick={disableOutsideClick ? 'disabled' : ''}
      className={cx('item', className)}
      // @ts-ignore
      onClick={() => props.onChange(props.name, value)!}
    >
      {label ? optionLabel : props.value}
    </div>
  );
};

/** Select */

interface SelectProps {
  name: string;
  disabled?: boolean;
  valueClassName?: string;
  labelClassName?: string;
  className?: string;
  optionsClassName?: string;
  withSearch?: boolean;
  value?: string | number | boolean;
  label: string;
  labelPosition?: 'top' | 'left' | 'bottom';
  onChange: (name: string, value: string) => void;
  error?: string;
  required?: boolean;
  disableOutsideClick?: boolean;
  children?: React.ReactNode;
  translate?: boolean;
  placeholder?: string;
}

const Select = (props: SelectProps) => {
  const [options, showOptions] = useState<boolean>(false);
  const [search, onSearch] = useState('');

  const node = useRef<HTMLInputElement>(null);
  const input = useRef<HTMLInputElement>(null);
  useOutsideClick(node, showOptions);
  const {error,placeholder, name,optionsClassName,disabled, labelClassName, valueClassName, label, withSearch, labelPosition = 'top'} = props;
  let {value, translate} = props;

  // Clone childrens and append parent element's props
  const childrens = props.children && React.Children.toArray(props.children);

  const elements =
    childrens &&
    childrens.filter(children => {
      // @ts-ignore
      if (!search) return true;
      // @ts-ignore
      return search && children.props.value.toLowerCase().includes(search.toLowerCase())
    }).map((children) => {
      // @ts-ignore
      return React.cloneElement(children, {
        onChange: props.onChange,
        name,
        disableOutsideClick: props.disableOutsideClick,
      });
    });

  props.children &&
  // @ts-ignore
  props.children.forEach((child: React.ReactComponentElement<any>) => {
    // console.log(child.props.label, child.props.value)
    if (child.props.label && JSON.stringify(child.props.value) === JSON.stringify(value)) {
      value = child.props.label;
    }
  });

  const className = cx(
    'select',
    disabled && 'disabled',
    error && 'select__error',
    props.className,
    `select__label-${labelPosition}`,
    options && 'active'
  )
  return (
    <div
      tabIndex={0}
      ref={node}
      className={className}
      onClick={() => {
        !options && !disabled && showOptions(true)
        input.current?.focus()
      }}
      onFocus={() => {
        !options && !disabled && showOptions(true)
      }}
    >
      {options && (
        <Menu
          className={cx('options', optionsClassName)}
          onClick={() => {
            showOptions(false);
          }}
        >
          {withSearch && <input className={cx('autocomplete')} ref={input} type="text" value={search}
                                onChange={(e) => onSearch(e.target.value)}/>}
          <div className={cx('wrapper')}>
            {elements}
          </div>
        </Menu>
      )}
      <div className={cx('label', labelClassName)}>
        <Text msg={error ? error : label}/>
      </div>
      <div className={cx('value', valueClassName)}>
        {translate && value && typeof value === 'string' ? <Text msg={value}/> : value}
        {!value && placeholder && <span className={cx('placeholder')}>{placeholder}</span>}
      </div>
      <img src={images.chevronDownDark} className={cx('chevron')} alt=""/>
    </div>
  );
};

export default Select;
