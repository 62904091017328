import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../constants/api";
import {getTrendsOverall, getTrendsRegional} from "./trend.actions";
import {TrendOverallResponse, TrendRegionalResponse} from './types';
import {selectTrend} from "./index";
import {mapGraphData} from "../../utils/mapResponseData";
import {handleResponseError} from "../../utils/responseErrors";

function* fetchTrendsOverall() {
  const {overall} = selectTrend(yield select());
  try {
    const {data}: AxiosResponse<{ data: TrendOverallResponse[], requestJson: string }> = yield call(axios.get, apiEndpoints.getTrendOverall(overall.interval));
    const graphData = mapGraphData(data.data);
    yield put(getTrendsOverall.success(graphData));
  } catch (e) {
    yield handleResponseError(e, getTrendsOverall.failure)
  }
}

function* fetchTrendsRegional() {
  const {regional} = selectTrend(yield select());
  try {
    const {data}: AxiosResponse<{ data: TrendRegionalResponse[], requestJson: string }> = yield call(axios.get, apiEndpoints.getTrendRegional(regional.interval));
    const graphData = mapGraphData(data.data);
    yield put(getTrendsRegional.success(graphData));
  } catch (e) {
    yield handleResponseError(e, getTrendsRegional.failure)
  }
}


export function* trendSagas() {
  yield all([
    yield takeLatest(getTrendsOverall.request, fetchTrendsOverall),
    yield takeLatest(getTrendsRegional.request, fetchTrendsRegional),
  ])
}
