import {createAction, createAsyncAction} from 'typesafe-actions';
import {LoginErrors, InputPayload, SocialProviders, LoginResponse} from './types';

export const onInputChange = createAction('auth/LOGIN_INPUT_CHANGE')<InputPayload>();

export const login = createAsyncAction(
  'auth/LOGIN_STARTED',
  'auth/LOGIN_DONE',
  'auth/LOGIN_FAILED',
)<void, LoginResponse, LoginErrors>()

export const loginWithSocialProvider = createAsyncAction(
  'auth/LOGIN_SOCIAL_PROVIDER_STARTED',
  'auth/LOGIN_SOCIAL_PROVIDER_DONE',
  'auth/LOGIN_SOCIAL_PROVIDER_FAILED',
)<{provider:SocialProviders, token:string}, LoginResponse, LoginErrors>()

export const clearLoginState = createAction('auth/LOGIN_CLEAR_STATE')()
