import {call, all, select, takeLatest, put} from 'redux-saga/effects';
import {updateComparePage, deletePage, getComparePages} from './compare.actions';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../constants/api";
import {handleErrors, validateCompareCard} from "../../utils/formValidations";
import {ComparePage} from "./types";
import {showErrorModal} from "../app/app.actions";
import {selectUser} from "../auth";
import {selectAppState} from "../app";
import {comparePage} from "./compare.reducer";

function* onCardUpdate(action: ReturnType<typeof updateComparePage.request>) {
  const {page, pageIndex, skipValidation} = action.payload;
  const {id} = page;
  try {
    if (!skipValidation) validateCompareCard(page);
    const formData = {...page}
    delete formData.errors;
    delete formData.success;
    delete formData.isLoading;
    let {pageUrl} = formData;
    if (pageUrl.includes('https://')) {
      formData.pageUrl = pageUrl.split('https://')[1];
    } else if (pageUrl.includes('http://')) {
      formData.pageUrl = pageUrl.split('http://')[1];
    }

    if (id) {
      const {data}: AxiosResponse<ComparePage> = yield call(axios.put, apiEndpoints.updatePage(id), formData)
      yield put(updateComparePage.success({
        pageIndex,
        page: {...data, freshSelected: skipValidation}
      }))
    } else {
      const {data}: AxiosResponse<ComparePage> = yield call(axios.post, apiEndpoints.createPage, page)
      yield put(updateComparePage.success({
        pageIndex,
        page: {...data, freshSelected: skipValidation},
      }))
    }
  } catch (e) {
    yield put(updateComparePage.failure({errors: handleErrors(e), pageIndex}))
  }
}

function* onDeleteCard(action: ReturnType<typeof deletePage.request>) {
  const {id, pageIndex} = action.payload;
  try {
    if (id) {
      const {data}: AxiosResponse<ComparePage> = yield call(axios.delete, apiEndpoints.deletePage(id))
      yield put(deletePage.success({pageIndex, page: data}))
    }
    yield put(getComparePages.request({showWarningIfPending: false}))
  } catch (e) {
    yield handleErrors(e)
  }
}

function* getCards(action: ReturnType<typeof getComparePages.request>) {
  const {subscriptionId} = selectUser(yield select());
  const {parameters} = selectAppState(yield select());
  const {showWarningIfPending} = action.payload;

  let letters = ['A', 'B', 'C', 'D', 'E', 'F'];
  let pageCount = 0;

  if (typeof subscriptionId === 'number' && parameters?.subscriptions) {
    pageCount = parameters.subscriptions[subscriptionId].comparisons;
  }

  try {
    const {data: {pages}}: AxiosResponse<{ pages: ComparePage[] }> = yield call(axios.get, apiEndpoints.pages);
    let preparedPages: ComparePage[] = []

    for (let i = 0; i < pageCount; i++) {
      if (pages?.[i]) {
        letters = letters.filter((letter) => letter !== pages[i].comparisonLetter);
        preparedPages.push(pages[i])
      } else {
        preparedPages.push({...comparePage, comparisonLetter: letters[0]})
        letters = letters.filter((_, i) => i !== 0)
      }
    }
    preparedPages = preparedPages.sort((a, b) => a.comparisonLetter > b.comparisonLetter ? 1 : -1)
    yield put(getComparePages.success(preparedPages))

    const pending = pages.find((item) => item.status === 'PENDING');
    if (showWarningIfPending && pending) yield put(showErrorModal({
        message: 'compare.not-synchronized.message',
        title: 'compare.not-synchronized.title'
      }
    ))
  } catch (e) {
    yield handleErrors(e)
  }
}

export function* compareSagas() {
  yield all([
    yield takeLatest(updateComparePage.request, onCardUpdate),
    yield takeLatest(getComparePages.request, getCards),
    yield takeLatest(deletePage.request, onDeleteCard),
  ])
}
