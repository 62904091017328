import React, {useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from '../ui/Text';
import {QuestionMark} from '../ui/QuestionMark';
import axios, {AxiosResponse} from 'axios';
import {useDispatch} from 'react-redux';
import {showErrorModal} from '../../store/app/app.actions';
import {HoverPopup} from '../ui/HoverPopup';

const cx = classNames.bind(styles);

export type Interval = '1' | 'Y' | 'Y2';

interface Props {
  onIntervalChange: (interval: Interval) => void;
  active?: string;
  title: string | string[];
  downloadApiUrl?: string;
  buttonsTitles?: [string, string, string];
  limitedAccess?: boolean;
  lastButtonDisabled?: boolean;
  questionMark?: string;
  pdfExport?: boolean;
}

export const TableMenu = (props: Props) => {
  const {
    onIntervalChange,
    pdfExport,
    limitedAccess,
    active = '1',
    lastButtonDisabled,
    buttonsTitles,
    title,
    downloadApiUrl = '',
    questionMark = '',
  } = props;
  const [isDownloading, toggleDownloading] = useState(false);
  const dispatch = useDispatch();

  const downloadPdf = async () => {
    if (isDownloading || !downloadApiUrl) return;
    toggleDownloading(true);
    try {
      const {data}: AxiosResponse<{downloadUrl: string}> = await axios.post(downloadApiUrl);
      window.open(data.downloadUrl, '_blank');
      toggleDownloading(false);
    } catch (e) {
      toggleDownloading(false);
      dispatch(showErrorModal({}));
      console.log(e.response);
    }
  };

  const changeInterval = (interval: Interval) => {
    if (interval === active) return;
    onIntervalChange(interval);
  };

  let tableTitle;
  if (!Array.isArray(title)) tableTitle = <Text msg={title} />;
  if (Array.isArray(title))
    tableTitle = title.map((item) => (
      <span key={item}>
        <Text msg={item} />
      </span>
    ));

  return (
    <div className={cx('container')}>
      <div className={cx('top-bar', pdfExport && 'top-bar--pdf')}>
        <div className={cx('text')}>{tableTitle}</div>
        <div className={cx('icons', pdfExport && 'icons--pdf')}>
          {/*<div className={cx('icons__share-box')}>*/}
          {/*  <img src={images.shareSolid} className={cx('icon')} alt=""/>*/}
          {/*  <HoverPopup text='table-menu.share-not-available' visible={true} className={cx('not-available')}/>*/}
          {/*</div>*/}
          {/*{isDownloading*/}
          {/*  ? <img src={images.loader} className={cx('icon', 'icon__loading')} alt=""/>*/}
          {/*  : <img onClick={downloadPdf} src={images.downloadSolid} className={cx('icon')} alt=""/>*/}
          {/*}*/}
          <QuestionMark tooltipPosition="left" content={questionMark} className={cx('tooltip')} />
        </div>
      </div>
      <div className={cx('bottom-bar', pdfExport && 'bottom-bar--pdf')}>
        <div
          className={cx(
            'button',
            pdfExport && 'button--pdf',
            active === '1' && 'button--active',
            active === '1' && pdfExport && 'button--active-pdf'
          )}
          onClick={() => changeInterval('1')}>
          <Text msg={buttonsTitles ? buttonsTitles[0] : 'component.table.menu.last90days'} />
        </div>
        <div
          className={cx(
            'button',
            pdfExport && 'button--pdf',
            limitedAccess && 'button--limited-access',
            active === 'Y' && 'button--active',
            active === 'Y' && pdfExport && 'button--active-pdf'
          )}
          onClick={() => {
            if (!limitedAccess) changeInterval('Y');
          }}>
          <Text msg={buttonsTitles ? buttonsTitles[1] : 'component.table.menu.last12months'} />
          <HoverPopup visible={limitedAccess} className={cx('not-available')} />
        </div>
        <div
          className={cx(
            'button',
            pdfExport && 'button--pdf',
            lastButtonDisabled && 'button--disabled',
            limitedAccess && 'button--limited-access',
            active === 'Y2' && 'button--active',
            active === 'Y2' && pdfExport && 'button--active-pdf'
          )}
          onClick={() => {
            if (!limitedAccess && !lastButtonDisabled) changeInterval('Y2');
          }}>
          <Text msg={buttonsTitles ? buttonsTitles[2] : 'component.table.menu.last24months'} />
          <HoverPopup visible={limitedAccess && !lastButtonDisabled} className={cx('not-available')} />
        </div>
      </div>
    </div>
  );
};
