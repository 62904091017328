import {getType, ActionType} from 'typesafe-actions';
import * as actions from './app.actions';
import {Lang, ConfirmationModal, ErrorModal,SuccessModal, SystemParameters} from './types';

type Action = ActionType<typeof actions>

export interface AppState {
  lang: Lang,
  confirmationModal: ConfirmationModal | undefined | null;
  errorModal: ErrorModal | undefined | null;
  successModal: SuccessModal | undefined | null;
  parameters?: SystemParameters
}

const initialState: AppState = {
  lang: Lang.de,
  confirmationModal: null,
  errorModal: null,
  successModal: null,
  parameters: undefined,
}

export const appReducer = (state = initialState, action: Action): AppState => {
  switch (action.type) {
    case getType(actions.getSystemParameters.success):
      return {...state, parameters: action.payload}
    case getType(actions.changeLang):
      return {...state, lang: action.payload}
    case getType(actions.showConfirmModal):
      return {...state, confirmationModal: action.payload}
    case getType(actions.showErrorModal):
      return {...state, errorModal: action.payload}
    case getType(actions.showSuccessModal):
      return {...state, successModal: action.payload}
    default :
      return state
  }
}

export const appState = (state: RootState) => state.app
