import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Container from "../../components/layout/Container";
import {useDispatch, useSelector} from "react-redux";
import {selectCompare} from "../../store/compare";
import {deletePage, getComparePages, onInputChange, updateComparePage} from "../../store/compare/compare.actions";
import {Text} from "../../components/ui/Text";
import {Page} from "./Page";
import {NoDataPage} from "./NoDataPage";
import {selectUser} from "../../store/auth";
import {selectAppState} from "../../store/app";

const cx = classNames.bind(styles);

export const Compare = () => {
  const dispatch = useDispatch()
  const {data} = useSelector(selectCompare);
  const {subscriptionId} = useSelector(selectUser);
  const {parameters} = useSelector(selectAppState);

  useEffect(() => {
    if (typeof subscriptionId === 'number' && parameters) {
      dispatch(getComparePages.request({showWarningIfPending: false}))
    }
  }, [dispatch, subscriptionId, parameters])

  const renderPages = () => {
    if (data.length === 0) {
      return (
        <div><Text msg='common.loading'/></div>
      )
    }
    return data.map((page, i) => {
      const {errors = {}} = page;
      if (!page.id && !page.new) {
        return (
          <NoDataPage
            letter={page.comparisonLetter}
            loading={page.isLoading}
            index={i}
            key={i}
          />
        )
      }
      return (
        <Page
          key={i}
          index={i}
          errors={errors}
          page={page}
          updateComparePage={() => dispatch(updateComparePage.request({pageIndex: i, page}))}
          deletePage={() => dispatch(deletePage.request({id: page.id!, pageIndex: i}))}
          onInputChange={(name, value) => dispatch(onInputChange({name, value, pageIndex: i}))}
        />
      )
    })
  }
  return (
    <Container className={cx('container')}>
      {renderPages()}
    </Container>
  );
};
