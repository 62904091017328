export const withComma = (number: number, decimals?: number) => {
  if (number >= 1000) {
    const [stringNumber, leftOver] = (Math.round(number *100)/ 100).toFixed(decimals).split('.');
    const l = stringNumber.length
    const start = stringNumber.slice(0, l - 3)
    const end = stringNumber.slice(l - 3, l)
    return `${start}'${end}${leftOver ? '.' + leftOver : ''}`
  }
  return (Math.round(number *100)/ 100).toFixed(decimals)
}
