import React, { useRef, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { apiEndpoints } from '../../../constants/api';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { Loader } from '../../../components/ui/Loader';
import { useOutsideClick } from '../../../utils/useOutsideClick';

const cx = classNames.bind(styles);

interface Props {
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSelect: (url: string) => void;
  className?: string;
}

export const UrlSearchInput = (props: Props) => {
  const { onSelect, className, value, onChange } = props;
  const ref = useRef<HTMLDivElement | null>(null);
  let latestValue = '';
  const [typing, setTyping] = useState<any>(false);
  const [isOpened, openList] = useState(false);
  const [data, setData] = useState<string[]>([]);
  useOutsideClick(ref, () => {
    openList(false);
  });

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    console.log('on change');
    !value ? openList(false) : openList(true);
    clearInterval(typing);
    onChange(e);
    setTyping(
      setTimeout(() => {
        if (value) {
          latestValue = value;
          getSuggestion();
        }
      }, 1000)
    );
  };

  const getSuggestion = async () => {
    try {
      const { data }: AxiosResponse<{ pages: string[] }> = await axios.post(
        apiEndpoints.publicPages,
        { url: latestValue }
      );
      setData(data.pages);
      setTyping(false);
    } catch (e) {
      console.log(e);
      // console.log(e.response);
    }
  };

  const renderSuggestions = () => {
    return data.map((url, i) => {
      return (
        <div
          className={cx('url')}
          key={i}
          onClick={() => {
            onSelect(url);
            setData([]);
          }}
        >
          {url}
        </div>
      );
    });
  };
  return (
    <div className={cx('wrapper', className)} ref={ref}>
      <input
        type="text"
        onChange={onInputChange}
        autoComplete="off"
        name="url"
        onClick={() => openList(true)}
        value={value}
        placeholder="URL Ihrer Beitragsseite hier eingeben"
      />
      {isOpened && (typing || data.length > 0) && (
        <div className={cx('list')}>
          {typing && (
            <div className={cx('loader')}>
              <Loader />
            </div>
          )}
          {renderSuggestions()}
        </div>
      )}
    </div>
  );
};
