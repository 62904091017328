import { createAction, createAsyncAction } from "typesafe-actions";
import { EditSectionTextState } from ".";
import { DiagramTextValues, DiagramTextValuesKeys } from "./types";

export const getSectionTextData = createAsyncAction(
  'editText/GET_TEXT_STARTED',
  'editText/GET_TEXT_DONE',
  'editText/GET_TEXT_FAILED',
)< void, EditSectionTextState, void>()
export const getCurrentSectionTextData = createAsyncAction(
  'editText/GET_CURRENT_TEXT_STARTED',
  'editText/GET_CURRENT_TEXT_DONE',
  'editText/GET_CURRENT_TEXT_FAILED',
)< string, Array<Array<DiagramTextValues>>, void>()
export const updateSectionTextData = createAsyncAction(
  'editText/UPDATE_TEXT_STARTED',
  'editText/UPDATE_TEXT_DONE',
  'editText/UPDATE_TEXT_FAILED',
)< {value:string}, EditSectionTextState, void>()
export const onEditorTextChange = createAction(
  'editText/TEXT_EDITOR_DATA_CHANGES'
  )<{index:string,value:string, side: DiagramTextValuesKeys,diagramId: number,textId: number}>()
export const onEditorAddTextBox = createAction(
  'editText/TEXT_EDITOR_ADD_TEXT_BOX'
  )<{index: string, diagramId: number}>()
export const onEditorRemoveTextBox = createAction(
  'editText/TEXT_EDITOR_REMOVE_TEXT_BOX'
  )<{index: string, diagramId: number, textId: number}>()    
