import React from 'react';
import {Text} from "../Text";
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  className?: string;
  visible?: boolean;
  text?: string;
}

export const HoverPopup: React.FC<Props> = ({className, text, visible}) => {
  if (!visible) return null;
  return (
    <div className={cx('popup', className,)}>
      <Text msg={text || 'common.not-available-fot-this-plan'}/>
    </div>
  );
};
