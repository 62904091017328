import {Validator} from './Validator';
import {LoginForm} from '../store/auth/login/types';
import {RegistrationForm} from '../store/auth/registration/types';
import {ForgotPasswordForm} from '../store/auth/forgot-password/types';
import {CreatePasswordForm} from '../store/auth/create-password/types';
import {UserProfile} from '../store/profile/userProfile/types';
import {PasswordForm} from '../store/profile/password/types';
import {ComparePage} from '../store/compare/types';
import {InvitedUser} from "../store/profile/invitedUsers/types";
import {SubUserLoginForm} from "../store/auth/sub-user/types";
import {Invoicing} from '../store/profile/invoice';
import {DemoForm} from "../store/home/home.reducer";

export interface APIError {
  server: string
}

export const validateDemoSignUp = (data: DemoForm, sum:number) => {
  const validator = new Validator<DemoForm>(data);
  validator.hasValue('code', 'Code is required')
  validator.hasValue('email', 'Email is required')
  validator.validateEmail('email', 'Invalid email')
  validator.hasValue('url', 'Url is required')
  validator.validateUrl('url', 'Invalid url')
  validator.validateSUM('code', parseFloat(data.code || ''), sum)
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}

export const validateInvoiceForm = (data: Invoicing) => {
  const validator = new Validator<Invoicing>(data)
  validator.hasValue('firstName', 'error-name-required');
  validator.hasValue('lastName', 'error-surname-required.2');
  validator.hasValue('email', 'error-email-required.2');
  validator.validateEmail('email', 'error-invalid-email');
  validator.hasValue('address', 'error-street-nr-required');
  validator.hasValue('companyName', 'error-company-required');
  validator.hasValue('city', 'error-city-required');
  validator.hasValue('postalCode', 'error-zip-code-required');
  validator.hasValue('land', 'error-land-required');
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}

export const validateInviteUser = (data: InvitedUser) => {
  const validator = new Validator<InvitedUser>(data)
  validator.validateEmail('email');
  validator.hasValue('email', 'error-email-required');
  validator.hasValue('firstName', 'error-name-required');
  validator.hasValue('lastName', 'error-surname-required');
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}

export const validateCompareCard = (data: ComparePage) => {
  const validator = new Validator<ComparePage>(data);
  validator.hasValue('pageUrl', 'compare.url');
  validator.validateUrl('pageUrl', 'compare.url');
  validator.hasValue('categoryLetter', 'compare.category');
  validator.hasValue('categoryId', 'compare.subcategory');
  validator.hasValue('countryId', 'compare.country');
  validator.hasValue('commentRating', 'compare.comment');
  validator.hasValue('advertising', 'compare.advertising');
  validator.hasValue('companySize', 'compare.employees-number');
  validator.hasValue('companyName', 'compare.company-name');
  // if(data.isin) validator.validateISIN('isin');
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}

export const validateNewPassword = (data: CreatePasswordForm) => {
  const validator = new Validator<CreatePasswordForm>(data);
  validator.hasValue('password', 'error-password-required');
  validator.hasValue('confirmPassword', 'error-confirm-password-required');
  validator.match('confirmPassword', 'password', 'error-passwords-dont-match')
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}

export const validateChangePassword = (data: ForgotPasswordForm) => {
  const validator = new Validator<ForgotPasswordForm>(data);
  validator.hasValue('email', 'error-email-required');
  validator.validateEmail('email')
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}

export const validateLogin = (data: LoginForm) => {
  const validator = new Validator<LoginForm>(data);
  validator.validateEmail('email');
  validator.hasValue('email', 'error-email-required');
  validator.hasValue('password', 'error-password-required');
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
};

export const validateRegistration = (data: RegistrationForm) => {
  const validator = new Validator<RegistrationForm>(data)
  validator.validateEmail('email', 'error-email-required');
  validator.hasValue('email', 'error-email-required');
  validator.hasValue('validationValue', 'error-validation-required');
  validator.hasValue('firstName', 'error-name-required');
  validator.hasValue('lastName', 'error-surname-required');
  validator.hasValue('password', 'error-password-required');
  validator.hasValue('repeatPassword', 'error-password-required');
  validator.match('repeatPassword', 'password', 'error-passwords-dont-match');
  validator.hasValue('status', 'error-status-required');
  validator.hasValue('land', 'error-region-required');
  validator.hasValue('companyName', 'error-company-required');
  validator.hasValue('agreeToGdpr', 'error-agree-terms-conditions');
  validator.hasValue('agreeToMarketingTerms', 'error-agree-terms-conditions');
  validator.hasValue('agreeToDataProcessing', 'error-agree-terms-conditions');
  validator.hasValue('agreeToPlatformTerms', 'error-agree-terms-conditions');
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}

export const validateSubUserRegistration = (data: SubUserLoginForm) => {
  const validator = new Validator<SubUserLoginForm>(data)
  validator.hasValue('password', 'error-password-required');
  validator.hasValue('repeatPassword', 'error-password-required');
  validator.match('repeatPassword', 'password', 'error-passwords-dont-match');
  validator.hasValue('agreeToGdpr', 'error-agree-terms-conditions');
  validator.hasValue('agreeToPlatformTerms', 'error-agree-terms-conditions');
  validator.hasValue('agreeToDataProcessing', 'error-agree-terms-conditions');
  validator.hasValue('agreeToMarketingTerms', 'error-agree-terms-conditions');
  validator.hasValue('validationValue', 'error-validation-required');
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}

export const handleErrors = <T extends APIError>(e: any): T | APIError => {
  if (e.errors) {
    return e.errors
  } else if (e.response) {
    return {server: e.response.data.message || 'error-contact-support'}
  } else {
    return {server: 'error-app'}
  }
}

export const validateUpdateProfile = (data: UserProfile) => {
  const validator = new Validator<UserProfile>(data)
  validator.validateEmail('email', 'error-email-required');
  validator.hasValue('email', 'error-email-required');
  validator.hasValue('firstName', 'error-name-required');
  validator.hasValue('lastName', 'error-surname-required');
  validator.hasValue('status', 'error-status-required');
  validator.hasValue('land', 'error-region-required');
  validator.hasValue('companyName', 'error-company-required');
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}

export const validateUpdatePassword = (data: PasswordForm) => {
  const validator = new Validator<PasswordForm>(data)
  validator.hasValue('oldPassword', 'error-password-required');
  validator.hasValue('newPassword', 'error-password-required');
  if (validator.hasErrors) throw Object.assign(new Error(), {errors: validator.errors});
}
