import {createAction, createAsyncAction} from 'typesafe-actions';
import {InputPayload} from "../../types/events";
import {ReportSettings} from './types';
import {APIError} from "../../utils/formValidations";

export const onDataToIncludeChange = createAction('report/ON_DATA_TO_INCLUDE_CHANGE')<InputPayload>();
export const onReportFormatsChange = createAction('report/ON_REPORT_FORMATS_CHANGE')<InputPayload>();
export const onSendingIntervalChange = createAction('report/ON_SENDING_INTERVAL_CHANGE')<InputPayload>();

export const getReportSettings = createAsyncAction(
  'report/GET_SETTINGS_STARTED',
  'report/GET_SETTINGS_DONE',
  'report/GET_SETTINGS_FAILED',
)<void, ReportSettings, void>()

export const saveReportSettings = createAsyncAction(
  'report/SAVE_SETTINGS_STARTED',
  'report/SAVE_SETTINGS_DONE',
  'report/SAVE_SETTINGS_FAILED',
)<{form:'first'| 'second'}, {form:'first'| 'second'}, APIError>();

export const saveReportSettingsAndGetPdf = createAsyncAction(
  'report/SAVE_SETTINGS_AND_GET_PDF_STARTED',
  'report/SAVE_SETTINGS_AND_GET_PDF_DONE',
  'report/SAVE_SETTINGS_AND_GET_PDF_FAILED',
)<{interval:string},void, APIError>();
