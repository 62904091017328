import React, {useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Button} from "../../components/ui/Button";
import {AutocompleteInput} from "../../components/ui/AutocompleteInput";
import {ComparePage} from "../../store/compare/types";
import {useDispatch} from "react-redux";
import {fillEmptyPage, updateComparePage} from "../../store/compare/compare.actions";
import {Heading} from "./Heading";

const cx = classNames.bind(styles);

interface Props {
  index: number;
  loading?: boolean;
  letter: string;
}

export const NoDataPage = (props: Props) => {
  const {index, letter, loading} = props;
  const [value, setValue] = useState();
  const dispatch = useDispatch();

  const selectLoadedPage = (page: ComparePage) => {
    dispatch(updateComparePage.request({
      pageIndex: index,
      page: {...page, comparisonLetter: letter},
      skipValidation: true
    }))
  }
  const selectEmptyPage = () => {
    dispatch(fillEmptyPage({pageUrl: value, index}))
  }
  return (
    <div className={cx('no-data-page')}>
      <Heading letter={letter} index={index}/>
      <AutocompleteInput
        className={cx('autocomplete')}
        onSelect={selectLoadedPage}
        value={value}
        onChange={setValue}
      />
      {/*<p className={cx('not-found')}>This url is not yet in the system</p>*/}
      <Button
        loading={loading}
        onClick={selectEmptyPage}
        className={cx('button-next')}
        msg='compare.send-change'
      />
    </div>
  );
};
