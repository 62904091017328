import { User } from './types';
import { ActionType, getType } from 'typesafe-actions';
import * as actions from './superAdmin.actions';
import { ComparePage, ComparePageErrors } from '../compare/types';
import { comparePage } from '../compare/compare.reducer';
import { Page, RequestsData } from './types';

type Action = ActionType<typeof actions>;

export interface SuperAdminState {
  users: {
    isLoading?: boolean;
    error?: boolean;
    data?: User[];
    success?: boolean;
    sendingReportToUser?: string;
    deletingUser?: string;
  };
  requests: {
    isLoading?: boolean;
    error?: boolean;
    updateFailed?: boolean;
    data?: RequestsData[];
    success?: boolean;
  };
  pages: {
    isLoading?: boolean;
    error?: boolean;
    updateFailed?: boolean;
    data?: Page[];
    success?: boolean;
    rescanningPageId?: string;
    archivingPageId?: string;
  };
  page: {
    data?: ComparePage;
    pageErrors: ComparePageErrors;
    pageModalIsOpen?: boolean;
    isSaving?: boolean;
    success?: boolean;
  };
  adminToken?: string;
}

const initialState: SuperAdminState = {
  users: {},
  pages: {},
  requests: {},
  page: {
    pageErrors: {},
  },
};

export const superAdminReducer = (
  state = initialState,
  action: Action
): SuperAdminState => {
  switch (action.type) {
    case getType(actions.updateRequest.success): {
      const { id, email, url } = action.payload;
      if (!state.requests.data) return state;
      const data = state.requests.data.map((item) => {
        return item._id === id ? { ...item, url, email } : item;
      });
      return { ...state, requests: { ...state.requests, data, success: true } };
    }
    case getType(actions.updateRequest.failure): {
      return { ...state, requests: { ...state.requests, updateFailed: true } };
    }
    case getType(actions.deleteRequest.failure): {
      return { ...state, requests: { ...state.requests, updateFailed: true } };
    }
    case getType(actions.deleteRequest.success): {
      if (!state.requests.data) return state;
      const data = state.requests.data.filter(
        (item) => item._id !== action.payload
      );
      return { ...state, requests: { ...state.requests, data, success: true } };
    }
    case getType(actions.getRequests.request): {
      return { ...state, requests: { ...state.requests, isLoading: true } };
    }
    case getType(actions.getRequests.failure): {
      return {
        ...state,
        requests: { ...state.requests, isLoading: false, error: true },
      };
    }
    case getType(actions.getRequests.success): {
      return { ...state, requests: { data: action.payload } };
    }
    case getType(actions.showCreatePageModal): {
      return {
        ...state,
        page: { pageModalIsOpen: !state.page.pageModalIsOpen, pageErrors: {} },
      };
    }
    case getType(actions.createPage.request): {
      return { ...state, page: { ...state.page, isSaving: true } };
    }
    case getType(actions.createPage.success): {
      return {
        ...state,
        page: {
          pageErrors: {},
          data: undefined,
          success: true,
          pageModalIsOpen: false,
          isSaving: false,
        },
      };
    }
    case getType(actions.updateUser.success): {
      if (!state.users.data) return state;
      const { data } = action.payload;
      const users = state.users.data.map((user) => {
        if (user.userId === data.userId) {
          return {
            ...user,
            subscriptionRequiresFollowUp: data.subscriptionRequiresFollowUp,
            isTrial: data.isTrial,
            lastBillDate: data.lastBillDate,
            nextBillDate: data.nextBillDate,
            role: data.role,
            subscriptionEndDate: data.subscriptionEndDate,
            firstName: data.firstName,
            lastName: data.lastName,
            companyName: data.companyName,
            email: data.email,
            subscriptionId: data.subscriptionId,
          };
        }
        return user;
      });
      return { ...state, users: { ...state.users, data: users } };
    }
    case getType(actions.createPage.failure): {
      return {
        ...state,
        page: {
          ...state.page,
          isSaving: false,
          pageErrors: action.payload.errors,
        },
      };
    }
    case getType(actions.openBlankPage): {
      return {
        ...state,
        page: { data: comparePage, pageModalIsOpen: true, pageErrors: {} },
      };
    }
    case getType(actions.getPages.request): {
      return { ...state, pages: { isLoading: true } };
    }
    case getType(actions.getPages.failure): {
      return { ...state, pages: { error: true } };
    }
    case getType(actions.getPages.success): {
      return { ...state, pages: { data: action.payload } };
    }
    case getType(actions.sendReportToUser.request): {
      return {
        ...state,
        users: { ...state.users, sendingReportToUser: action.payload },
      };
    }
    case getType(actions.sendReportToUser.failure): {
      return {
        ...state,
        users: { ...state.users, sendingReportToUser: undefined },
      };
    }
    case getType(actions.sendReportToUser.success): {
      return {
        ...state,
        users: { ...state.users, sendingReportToUser: undefined },
      };
    }
    case getType(actions.deleteUser.request): {
      return {
        ...state,
        users: { ...state.users, deletingUser: action.payload },
      };
    }
    case getType(actions.deleteUser.failure): {
      return { ...state, users: { ...state.users, deletingUser: undefined } };
    }
    case getType(actions.deleteUser.success): {
      const userId = action.payload;
      const data = state.users.data
        ? state.users.data.filter((user) => user.userId !== userId)
        : [];
      return {
        ...state,
        users: { ...state.users, data, deletingUser: undefined },
      };
    }
    case getType(actions.clearNotifications): {
      return {
        ...state,
        users: { ...state.users, error: false, success: false },
        pages: {
          ...state.pages,
          error: false,
          updateFailed: false,
          success: false,
        },
        page: { ...state.page, success: false },
        requests: { ...state.requests, success: false, updateFailed: false },
      };
    }
    case getType(actions.updateSuccess): {
      return { ...state, users: { ...state.users, success: true } };
    }
    case getType(actions.updateFailed): {
      return { ...state, users: { ...state.users, error: true } };
    }
    case getType(actions.getUsers.request): {
      return { ...state, users: { isLoading: true } };
    }
    case getType(actions.rescanPage.request): {
      return {
        ...state,
        pages: {
          ...state.pages,
          rescanningPageId: action.payload,
          success: undefined,
          error: undefined,
        },
      };
    }
    case getType(actions.rescanPage.failure): {
      return {
        ...state,
        pages: { ...state.pages, rescanningPageId: undefined, error: true },
      };
    }
    case getType(actions.rescanPage.success): {
      return {
        ...state,
        pages: {
          ...state.pages,
          success: true,
          rescanningPageId: undefined,
          error: undefined,
        },
      };
    }
    case getType(actions.archivePage.request): {
      return {
        ...state,
        pages: {
          ...state.pages,
          archivingPageId: action.payload,
          success: undefined,
          error: undefined,
        },
      };
    }
    case getType(actions.archivePage.failure): {
      return {
        ...state,
        pages: { ...state.pages, archivingPageId: undefined, error: true },
      };
    }
    case getType(actions.archivePage.success): {
      if (!state.pages.data) return state;
      const userId = action.payload;
      const data =state.pages.data.map(page=>page.id===userId?{...page,archived:'1'}:{...page})
      
      return {
        ...state,
        pages: {
          ...state.pages,
          data,
          success: true,
          archivingPageId: undefined,
          error: undefined,
        },
      };
    }
    case getType(actions.getUsers.success): {
      return { ...state, users: { data: action.payload } };
    }
    case getType(actions.setDeleteUser.success): {
      if (!state.users.data) return state;
      const users = action.payload;
      const data = state.users.data.map((user) => {
        users.map((archivedUser) => {
          if (archivedUser._id === user.userId) {
            user.deletedAt = archivedUser.deletedAt;
          }
        });
        return user;
      });

      return { ...state, users: { ...state.users, data } };
    }
    case getType(actions.setUnDeleteUser.success): {
      if (!state.users.data) return state;
      const { userId } = action.payload;
      const data = state.users.data.map((user) =>
        user.userId === userId ? { ...user, deletedAt: '' } : user
      );
      return { ...state, users: { ...state.users, data } };
    }
    case getType(actions.loginAsSelectedUser.request): {
      if (!state.users.data) return state;
      const { userId } = action.payload;
      const users: User[] = state.users.data.map((user) => {
        return user.userId === userId ? { ...user, isLogin: true } : user;
      });
      return { ...state, users: { data: users } };
    }
    case getType(actions.loginAsSelectedUser.success): {
      if (!state.users.data) return state;
      const { userId, adminToken } = action.payload;
      const users: User[] = state.users.data.map((user) => {
        return user.userId === userId
          ? { ...user, isLogin: false, isEditable: false }
          : user;
      });
      return { ...state, users: { data: users }, adminToken };
    }
    case getType(actions.switchBackToAdmin.success): {
      return { ...state, adminToken: undefined };
    }
    case getType(actions.setAdminToken): {
      return { ...state, adminToken: action.payload };
    }

    case getType(actions.onPageInputChange): {
      if (!state.page) return state;
      const { name, value } = action.payload;
      if (!state.page.data) return state;
      return {
        ...state,
        page: {
          ...state.page,
          data: { ...state.page.data, [name]: value },
          pageErrors: { ...state.page.pageErrors, [name]: undefined },
        },
      };
    }
    case getType(actions.updatePage.success): {
      if (!state.pages.data) return state;
      const { page } = action.payload;
      const data = state.pages.data.map((currentPage) => {
        if (currentPage.id === page.pageId) return { ...currentPage, ...page };
        return currentPage;
      });
      return {
        ...state,
        pages: { ...state.pages, success: true, error: undefined, data },
      };
    }
    case getType(actions.updatePage.failure): {
      return { ...state, pages: { ...state.pages, updateFailed: true } };
    }
    default:
      return state;
  }
};
