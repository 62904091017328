import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {TextInput} from '../../components/ui/TextInput';
import {HotTable} from '@handsontable/react';
import {
  renderRow,
  validateTableFields,
  colWidths,
  PAGE_SIZE,
  CATEGORYLETTER_COL_INDEX,
} from './pagesTableConfig';
import {useDispatch, useSelector} from 'react-redux';
import {
  archivePage,
  createPage,
  getPages,
  onPageInputChange,
  openBlankPage,
  rescanPage,
  selectSuperAdminState,
  showCreatePageModal,
} from '../../store/superAdmin';
import {selectAppState} from '../../store/app';
import {Button} from '../../components/ui/Button';
import {ModalWithLogo} from '../../components/ModalWithLogo';
import {Page} from '../Compare/Page';
import {Loader} from '../../components/ui/Loader';
import * as config from './pagesTableConfig';
import {updatePage} from '../../store/superAdmin';
import {SubCategories} from '../../store/app/types';
import {Text} from '../../components/ui/Text';
import {CheckBox} from '../../components/ui/CheckBox';
import {ARCHIVED} from '../../constants/constants';
import {Pagination} from '../../components/ui/Pagination';

const cx = classNames.bind(styles);

export const Pages = () => {
  const table = useRef<HotTable | null>(null);

  const [searchValue, setValue] = useState('');
  const [withArchivedPages, showArchivedPages] = useState(false);
  const [tabNumber, setTabNumber] = useState(1);

  const dispatch = useDispatch();
  const {parameters} = useSelector(selectAppState);
  const {pages, page} = useSelector(selectSuperAdminState);

  const {pageErrors, data: pageData, pageModalIsOpen, isSaving, success: pageCreateSuccess} = page;

  const input = useRef<HTMLInputElement | null>(null);

  let {data, isLoading, rescanningPageId, archivingPageId, success, updateFailed} = pages;

  data = (data || [])
    .filter((item) => !(item.archived === ARCHIVED && !withArchivedPages))
    .filter((item) => !(item.archived !== ARCHIVED && withArchivedPages))
    .filter((item) => {
      if (!searchValue) return true;
      const searchValues = searchValue.split(' ');
      return searchValues.some((s) => JSON.stringify(item).toLowerCase().includes(s.toLowerCase()));
    });

  const dataWithPagination = data.filter((item, i) => {
    return tabNumber * PAGE_SIZE > i && i >= (tabNumber - 1) * PAGE_SIZE;
  });

  useEffect(() => {
    dispatch(getPages.request());
  }, [dispatch]);

  const getCellData = (row: number, col: number): string => {
    if (!table || !table.current) return '';
    return table.current.hotInstance.getDataAtCell(row, col);
  };

  const row = renderRow({
    parameters,
    getCellData,
    rescanningPageId,
    archivingPageId,
    rescanPage: (id) => dispatch(rescanPage.request(id)),
    archivePage: (id) => dispatch(archivePage.request(id)),
  });

  const columns = row.map((item) => item.column);

  useEffect(() => {
    validateTableFields(row);
  }, [row, dispatch]);

  const mapTableData = () => {
    if (!dataWithPagination || !parameters) return [];
    return dataWithPagination.map((item) => {
      return renderRow({
        item,
        parameters,
        getCellData,
        rescanningPageId,
        archivingPageId,
        rescanPage: (id) => dispatch(rescanPage.request(id)),
        archivePage: (id) => dispatch(archivePage.request(id)),
      }).map((td) => td.value);
    });
  };

  const renderTable = () => {
    if (isLoading) return <Loader className={cx('loader')} />;
    if (pages.data?.length === 0)
      return (
        <div className={cx('not-found')}>
          <Text msg="super-admin.page-not-found" />
        </div>
      );
    return (
      <HotTable
        hiddenColumns={{
          columns: [config.INDEX_COL_INDEX],
          indicators: false,
        }}
        fixedColumnsLeft={3}
        afterChange={(changes, source) => {
          if (!changes || !changes[0]) return null;
          const [row, col, prevValue, nextValue] = changes[0];

          if (col === CATEGORYLETTER_COL_INDEX) {
            const categoryLetter = nextValue;
            const category = parameters?.categories.find((cat) => cat.name === categoryLetter) || {
              name: '',
              sub_categories: [],
            };
            const subCategories: Array<String> = [];
            category.sub_categories.forEach((s) => {
              subCategories.push(s.name);
            });

            table.current?.hotInstance.setCellMeta(row, 4, 'type', 'dropdown');
            table.current?.hotInstance.setCellMeta(row, 4, 'source', subCategories);

            return;
          }

          if (source === 'edit' && prevValue !== nextValue) {
            const dataFromTable = {
              index: getCellData(row, config.INDEX_COL_INDEX),
              pageId: getCellData(row, config.PAGEID_COL_INDEX),
              pageUrl: getCellData(row, config.PAGEURL_COL_INDEX),
              categoryLetter: getCellData(row, config.CATEGORYLETTER_COL_INDEX),
              categoryId: getCellData(row, config.CATEGORYID_COL_INDEX),
              brands: getCellData(row, config.BRANDS_COL_INDEX),
              companyName: getCellData(row, config.COMPANYNAME_COL_INDEX),
              hashtags: getCellData(row, config.HASHTAGS_COL_INDEX),
              commentRating: getCellData(row, config.COMMENTRATING_COL_INDEX),
              advertising: getCellData(row, config.ADVERTISING_COL_INDEX),
              isin: getCellData(row, config.ISIN_COL_INDEX),
              countryId: getCellData(row, config.COUNTRYID_COL_INDEX),
              companySize: getCellData(row, config.COMPANYSIZE_COL_INDEX),
              groupId: getCellData(row, config.GROUPID_COL_INDEX),
              email: getCellData(row, config.EMAIL_COL_INDEX),
              owner: getCellData(row, config.OWNER_COL_INDEX),
              status: getCellData(row, config.STATUS_COL_INDEX),
              rawPageStatus: getCellData(row, config.RAWPAGESTATUS_COL_INDEX),
              lastScan: getCellData(row, config.LASTSCAN_COL_INDEX),
              youngestPost: getCellData(row, config.YOUNGESTPOST_COL_INDEX),
              archived: getCellData(row, config.ARCHIVED_COL_INDEX),
            };

            const categories = parameters ? parameters.categories : [];
            const advertisings = parameters ? parameters.advertisings : [];
            const countries = parameters ? parameters.countries : [];
            const commentRatings = parameters ? parameters.commentRatings : [];
            const companySizes = parameters ? parameters.companySizes : [];
            const groups = parameters ? parameters.groups : [];
            const subCategories: Array<SubCategories> = [];
            categories.map((c) => c.sub_categories.forEach((s) => subCategories.push(s)));

            const subCategory = subCategories.find((cat) => cat.name === dataFromTable.categoryId);
            const advertising = advertisings.find((add) => add.name === dataFromTable.advertising);
            const country = countries.find((country) => country.abbreviation === dataFromTable.countryId);
            const commentRating = commentRatings.find(
              (rating) => rating.name === dataFromTable.commentRating
            );
            const group = groups.find((g) => g.name === dataFromTable.groupId);
            const companySize = companySizes.find((c) => c.name === dataFromTable.companySize);

            dispatch(
              updatePage.request({
                page: {
                  pageId: dataFromTable.pageId,
                  owner: dataFromTable.owner,
                  pageUrl: dataFromTable.pageUrl,
                  email: dataFromTable.email,
                  categoryId: subCategory?.id || ' ',
                  categoryLetter: subCategory?.sub_category || '',
                  countryId: country?.id || ' ',
                  groupId: group?.id || ' ',
                  commentRating: commentRating?.id || ' ',
                  // @ts-ignore
                  advertising: advertising?.id,
                  companySize: companySize?.id || ' ',
                  isin: dataFromTable.isin,
                  companyName: dataFromTable.companyName,
                  brands: dataFromTable.brands,
                  hashtags: dataFromTable.hashtags,
                  status: dataFromTable.status,
                },
              })
            );
          }
        }}
        ref={table}
        id="hot-table"
        colWidths={colWidths}
        columns={columns}
        dropdownMenu={['filter_by_condition', 'filter_action_bar']}
        filters={true}
        columnSorting={true}
        licenseKey="non-commercial-and-evaluation"
        data={mapTableData()}
        colHeaders={true}
        manualColumnResize
        manualRowResize
        rowHeaders={false}
        width="100%"
        height="500"
      />
    );
  };

  return (
    <div className={cx('pages-container')}>
      <ModalWithLogo
        className={cx('modal')}
        close={() => dispatch(showCreatePageModal())}
        visible={pageModalIsOpen}>
        <div>
          {pageData && (
            <Page
              className={cx('blank-page')}
              page={pageData}
              errors={pageErrors}
              index={0}
              disableDelete
              disableCustomOptionsWidth
              deletePage={() => null}
              updateComparePage={() => null}
              disableCreateButton
              onInputChange={(name, value) => dispatch(onPageInputChange({name, value}))}
            />
          )}
          <Button
            loading={isSaving}
            msg="compare.button-create"
            onClick={() => dispatch(createPage.request())}
          />
        </div>
      </ModalWithLogo>
      <div className={cx('search-box')}>
        <TextInput
          onChange={(_, value) => {
            setValue(value.toLowerCase());
            setTabNumber(1);
          }}
          label="super-admin.pages.url"
          value={searchValue}
          name="url"
          notTranslatedPlaceholder="Search by any value (min 2 char.)"
          className={cx('input')}
        />
        <div className={cx('checkbox-container')}>
          <CheckBox
            onChange={() => {
              showArchivedPages(!withArchivedPages);
              setTabNumber(1);
            }}
            checked={withArchivedPages}
            name=""
          />
          <div className={cx('label')}>Show archived pages</div>
        </div>
        <Button
          msg="super-user.create-page"
          onClick={() => dispatch(openBlankPage())}
          className={cx('btn-create-page')}
        />
        {(success || pageCreateSuccess) && (
          <div className={cx('notification', 'notification--success')}>Success</div>
        )}
        {updateFailed && <div className={cx('notification', 'notification--failed')}>Update failed</div>}
      </div>
      <div className={cx('table')}>
        {renderTable()}
        <Pagination
          dataTotal={data.length}
          pageSize={PAGE_SIZE}
          tabNumber={tabNumber}
          setTabNumber={setTabNumber}
        />
      </div>
    </div>
  );
};

// TODO: Needs refactoring!
