import React, {useEffect} from 'react';
import style from './index.module.scss';
import {Text, Button, TextInput} from "../../components/ui";
import Container from "../../components/layout/Container";
import {FormEvent} from "../../types/events";
import {useDispatch, useSelector} from "react-redux";
import {selectForgotPassword} from '../../store/auth';
import {
  onInputChange,
  changePassword,
  clearState
} from '../../store/auth/forgot-password/forgot-password.actions';

export const ForgotPassword = () => {
  const dispatch = useDispatch();
  const {form, loading, success, errors} = useSelector(selectForgotPassword);

  useEffect(() => {
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    dispatch(changePassword.request())
  }
  const onChange = (name: string, value: string) => {
    dispatch(onInputChange({name, value}))
  }

  if (success) {
    return (
      <Container className={style.success}>
        <div>
          <h1 className={style.heading}><Text msg='forgot-password.success.title'/></h1>
          <p className={style.desc}>
            <Text msg='forgot-password.success.text.p1'/>
          </p>
          <p className={style.desc}>
            <Text msg='forgot-password.success.text.p2'/>
          </p>
          <p className={style.desc}>
            <Text msg='forgot-password.success.text.p3'/>
          </p>
        </div>
        <p className={style.no_email}>
          <Text msg='forgot-password.success.no-email.p1'/>
          <a href="mailto:pagetracker@drkpi.com "> <Text msg='forgot-password.success.no-email.p2'/></a>
        </p>
      </Container>
    )
  }
  return (
    <Container>
      <h1 className={style.heading}><Text msg='forgot-password.title'/></h1>
      <form onSubmit={onSubmit}>
        <TextInput
          onChange={onChange}
          value={form.email}
          name='email'
          error={errors.email}
          label='forgot-password.email'
          className={style.input}
        />
        <Button
          loading={loading}
          msg='forgot-password.change-password'
          className={style.button}
        />
        {errors.server && <p className={style.error}><Text msg={errors.server}/></p>}
        {success && <p className={style.success}><Text msg='forgot-password.success'/></p>}
      </form>
    </Container>
  );
};
