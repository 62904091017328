import Handsontable from "handsontable";
import {RequestsData} from "../../store/superAdmin/types";
import {SystemParameters} from "../../store/app/types";
import moment from "moment";

export const ID_COL_INDEX = 0;
export const URL_COL_INDEX = 1;
export const EMAIL_COL_INDEX = 2;
export const REPORT_SENT_AT_INDEX = 3;
export const STATUS_INDEX = 4;
export const CREATED_AT_INDEX = 5;

export const colWidths = [100, 250, 250, 150, 100, 150, 100]

type ColumnSettings = Handsontable.ColumnSettings;

interface ColumnData {
  value?: string | boolean | number;
  fieldName: keyof RequestsData | ''; // used as reference
  column: ColumnSettings;
  order: number; // used as reference
}

interface Options {
  item?: RequestsData;
  parameters?: SystemParameters;
  getCellData: (row: number, col: number) => string;
  deleteRequest: (id: string) => void;
}

export const renderRow = (options: Options): ColumnData[] => {
  const item = options.item || {} as RequestsData;
  const {deleteRequest, getCellData} = options;

  return [
    {
      value: item._id,
      column: {title: '_Id', editor: false},
      fieldName: '_id',
      order: 0,
    },
    {
      value: item.url,
      column: {title: 'Url'},
      fieldName: 'url',
      order: 1,
    },
    {
      value: item.email,
      column: {title: 'Email'},
      fieldName: 'email',
      order: 2,
    },
    {
      value: item.reportSentAt ? moment(item.reportSentAt).format('YYYY-MM-DD') : '',
      column: {
        title: 'Report Sent At',
        editor: false,
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
      },
      fieldName: 'reportSentAt',
      order: 3,
    },
    {
      value: item.status,
      column: {title: 'Status', editor: false},
      fieldName: 'status',
      order: 4,
    },
    {
      value: moment(item.createdAt).format('YYYY-MM-DD'),
      column: {
        title: 'Created At',
        type: 'date',
        editor: false,
        dateFormat: 'YYYY-MM-DD',
      },
      fieldName: 'createdAt',
      order: 5,
    },
    {
      value: '',
      fieldName: '',
      column: {
        title: 'Delete',
        filter: false,
        editor: false,
        disableVisualSelection: true,
        renderer: (instance, td, row) => {
          const pageId = getCellData(row, ID_COL_INDEX);
          const button = document.createElement('BUTTON');
          button.classList.add('table-button-login')
          button.textContent = 'Delete'
          button.addEventListener('click', () => {
            deleteRequest(pageId)
          })
          Handsontable.dom.empty(td);
          td.appendChild(button);
        }
      },
      order: 6,
    },
  ]
}

// Simple column order index validation compared to constants (production only);
export const validateTableFields = (data: ColumnData[]) => {
  const err = 'Incorrect table order or constant value';
  const values: Partial<{ [key in keyof RequestsData]: any } & { '': any }> = {}
  data.forEach((item, index) => {
    if (item.order !== index) throw new Error('Incorrect order')
    values[item.fieldName] = index;
  })
  if (values._id !== ID_COL_INDEX) throw new Error(err)
  if (values.email !== EMAIL_COL_INDEX) throw new Error(err)
  if (values.url !== URL_COL_INDEX) throw new Error(err)
  if (values.reportSentAt !== REPORT_SENT_AT_INDEX) throw new Error(err)
  if (values.status !== STATUS_INDEX) throw new Error(err)
  if (values.createdAt !== CREATED_AT_INDEX) throw new Error(err)
}
