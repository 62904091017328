import {createAsyncAction, createAction} from 'typesafe-actions';
import {Interval} from '../../components/TableMenu';
import {StateKeys} from './engagement.reducer';
import {BarChartData} from "../../components/BarChart";
import {GraphData} from "../seo/types";

export const getData = createAsyncAction(
  'engagement/GET_DATA_STARTED',
  'engagement/GET_DATA_DONE',
  'engagement/GET_DATA_FAILED',
)<{ type: StateKeys }, { type: StateKeys, data: BarChartData[] | GraphData[]}, { type: StateKeys }>()

export const changeInterval = createAction(
  'engagement/CHANGE_INTERVAL',
)<{interval:Interval, type:StateKeys}>()
