import {LoginForm, LoginErrors} from './types';
import * as actions from './login.actions';
import {ActionType, getType} from 'typesafe-actions';

type Action = ActionType<typeof actions>


export interface LoginState {
  form: LoginForm;
  loading: 'email'| 'google' | false;
  errors: LoginErrors;
}

const form = {
  email: '',
  password: '',
};

const initialState: LoginState = {
  form,
  loading: false,
  errors: {},
};

export const loginReducer = (state = initialState, action: Action): LoginState => {
  switch (action.type) {
    case getType(actions.onInputChange): {
      const {name, value} = action.payload;
      return {
        ...state,
        form: {...state.form, [name]: value},
        errors: {...state.errors, [name]: ''}
      }
    }
    case getType(actions.clearLoginState):
      return initialState;
    case getType(actions.login.request) :
      return {...state, loading:'email'}
    case getType(actions.login.failure) :
      return {...state, errors:action.payload, loading:false}
    case getType(actions.login.success) :
      return {...state, errors:{}, loading:false, form}
    case getType(actions.loginWithSocialProvider.request) :
      return {...initialState, loading:'google'}
    case getType(actions.loginWithSocialProvider.failure):
      return {...initialState, errors:action.payload}
    case getType(actions.loginWithSocialProvider.success):
      return initialState
    default:
      return state;
  }
};
