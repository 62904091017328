import React, {useEffect} from 'react';
import {Text} from "../../components/ui/Text";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {TextInput} from "../../components/ui/TextInput";
import {TextCard} from "../../components/layout/TextCard";
import {QuestionMark} from "../../components/ui/QuestionMark";
import {CheckBox} from "../../components/ui/CheckBox";
import {Button} from "../../components/ui/Button";
import {useDispatch, useSelector} from "react-redux";
import {
  getInvitedUsers,
  onInputChange,
  onOptionChange,
  selectInviteUsers,
  updateInvitedUser
} from "../../store/profile/invitedUsers";
import {FormSuccessMessage} from "../../components/ui/FormSuccessMessage";
import {checkSubUserStatus} from "../../store/auth/user";
import {selectUser} from "../../store/auth";
import {HoverPopup} from "../../components/ui/HoverPopup";

const cx = classNames.bind(styles);


export const InviteUsers = () => {
  const dispatch = useDispatch();
  const users = useSelector(selectInviteUsers);
  const isSubUser = useSelector(checkSubUserStatus)
  const {subscriptionId} = useSelector(selectUser);

  useEffect(() => {
    dispatch(getInvitedUsers.request())
  }, [dispatch])

  if (isSubUser) return null;

  const onChange = (name: string, value: string, index: number) => {
    dispatch(onInputChange({name, value, index}))
  }

  const usersList = users.map((user, i) => {
    let isActive = false
    if (i === 0 && subscriptionId! >= 1) isActive = true;
    if (i === 1 && subscriptionId! >= 2) isActive = true;
    if (i === 2 && subscriptionId! >= 2) isActive = true;
    return (
      <div className={cx('user', !isActive && 'user--inactive')} key={i}>
        <TextCard>
          <HoverPopup className={cx('user__tooltip')} visible={!isActive}/>
          {i === 0 && <QuestionMark content='profile.users.questionmark' className={cx('user__question-mark')}/>}
          <h3 className={cx('user__title')}><Text msg='profile.users.title'/></h3>
          <TextInput
            onChange={(name, value) => onChange(name, value, i)}
            label='profile.users.email'
            value={user.email}
            name='email'
            error={user.errors?.email}
            className={cx('user__input')}
          />
          <TextInput
            onChange={(name, value) => onChange(name, value, i)}
            label='profile.users.first-name'
            value={user.firstName}
            name='firstName'
            error={user.errors?.firstName}
            className={cx('user__input')}
          />
          <TextInput
            onChange={(name, value) => onChange(name, value, i)}
            label='profile.users.last-name'
            value={user.lastName}
            name='lastName'
            error={user.errors?.lastName}
            className={cx('user__input')}
          />
          <div className={cx('user__option')}>
            <p className={cx('user__option-text')}>
              <strong><Text msg='profile.users.user'/> {i + 1} </strong>
              <span><Text msg='profile.users.add'/></span>
            </p>
            <CheckBox
              onChange={() => dispatch(onOptionChange({index: i, value: 'invite'}))}
              checked={user?.option === 'invite'}
              name=''
            />
          </div>
          <div className={cx('user__option')}>
            <p className={cx('user__option-text')}>
              <strong><Text msg='profile.users.user'/> {i + 1} </strong>
              <span><Text msg='profile.users.delete'/></span>
            </p>
            <CheckBox
              onChange={() => dispatch(onOptionChange({index: i, value: 'delete'}))}
              checked={user?.option === 'delete'}
              name=''
            />
          </div>
          <div className={cx('messages')}>
            {user.signedUp === false &&
            <p className={cx('user__status', !user.signedUp && 'user__status--visible')}><Text msg={'profile.users.invite-sent'}/></p>}
            {user.signedUp === true &&
            <p className={cx('user__status')}><Text msg={'profile.users.signed-up'}/></p>}
            <FormSuccessMessage message='common.success' visible={user.success}/>
          </div>
        </TextCard>
        <Button
          loading={user.isUpdating}
          disabled={!user.option}
          onClick={() => dispatch(updateInvitedUser.request({index: i}))}
          msg='profile.users.update'
          className={cx('user__button')}
        />
      </div>
    )
  })
  return (
    <div className={cx('row', 'row--mt-40', 'row--stretch')}>
      {usersList}
    </div>
  )
};
