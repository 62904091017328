// Constants
export const SITE_KEY = '6LeSt98ZAAAAAHm_jZXHReMd5B_VlDMIlGwEKMik'
export const pageStatus = {
  FAILED:'FAILED',
}
export const ARCHIVED = '1'

export enum GraphColors {
  color1 = '#279CDC',
  color2 = '#c2e6f6',
  color3 = '#DBB01D',
  color4 = '#D0D0D0',
  color5 = '#D0D0D0',
  color6 = '#D0D0D0',
}
