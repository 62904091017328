import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from "../../components/ui/Text";
import {withComma} from "../../utils/numbersWithCommas";
import {useSelector} from "react-redux";
import {selectUser} from "../../store/auth";
import {plans} from './constants';

const cx = classNames.bind(styles);

interface Props {
  planId?: number;
}

export const PlanDetails: React.FC<Props> = ({planId}) => {
  const {land} = useSelector(selectUser);

  if (!planId && planId !== 0) return null;
  const plan = plans[planId];
  const {type, subscriptionPrice, MwSt, rake} = plan;
  const withTax = land === 'Schweiz';
  const withExpenses = subscriptionPrice + (withTax ? subscriptionPrice * MwSt : 0)
  const setupFee = rake / 2 + (withTax ? rake * MwSt : 0) / 2
  const total = setupFee + withExpenses;

  return (
    <div className={cx('plan-details')}>
      <div className={cx('plan-details__left')}>
        <h3 className={cx('plan-details__heading')}>
          <Text msg={type}/>
        </h3>
        <h4 className={cx('plan-details__type')}>
          <Text msg={type}/>
        </h4>
        <div className={cx('row')}>
          <p className={cx('row__key')}><Text msg='subscription'/></p>
          <p className={cx('row__value')}>CHF {withComma(subscriptionPrice)}</p>
        </div>
        <div className={cx('row')}>
          <p className={cx('row__key', 'row__key')}>
            <Text msg='subscription.MwSt'/> {MwSt * 100}%*
          </p>
          {withTax && <p className={cx('row__value')}>CHF {withComma(MwSt * subscriptionPrice, 2)}</p>}
        </div>
        <div className={cx('row')}>
          <p className={cx('row__key', 'row__key--bold')}>
            <Text msg='subscription-expenses'/>
          </p>
          <p className={cx('row__value')}>
            CHF {withComma(withExpenses, 2)}
          </p>
        </div>
        <div className={cx('row')}>
          <p className={cx('row__key', 'row__key--bold')}>
            <Text msg='subscription-setup-fee'/>
          </p>
          <p className={cx('row__value')}>CHF {withComma(setupFee, 2)}</p>
        </div>
        {!withTax &&
        <div className={cx('row')}>
          <p className={cx('row__key', 'row__key--small')}>
            <Text msg='subscription-info-1'/>
          </p>
        </div>}
        <div className={cx('row')}>
          <p className={cx('row__key', 'row__key--bold')}>
            <Text msg='subscription-total'/>
          </p>
          <p className={cx('row__value', 'row__value--bold')}>CHF {withComma(total, 2)}</p>
        </div>
      </div>
      <div className={cx('plan-details__right')}>
        <h3 className={cx('plan-details__heading')}>
          <Text msg='subscription.info.title-2'/>
        </h3>
        <div className={cx('row')}>
          <p className={cx('row__key', 'row__key')}>
            <Text msg='subscription.rake'/>
          </p>
          <p className={cx('row__value')}>CHF {withComma(rake, 2)}</p>
        </div>
        <div className={cx('row')}>
          <p className={cx('row__key', 'row__key--small')}>
            <Text msg='subscription-info-2'/>
          </p>
          <p className={cx('row__value')}>CHF {withComma((rake / 2), 2)}</p>
        </div>
        <div className={cx('row')}>
          <p className={cx('row__key', 'row__key--small')}>
            <Text msg='subscription.MwSt'/> {MwSt * 100}%*
          </p>
          {withTax && <p className={cx('row__value')}>CHF {withComma(MwSt * rake / 2, 2)}</p>}
        </div>
        <div className={cx('row')}>
          <p className={cx('row__key', 'row__key--bold')}>
            <Text msg='subscription-setup-fee'/>
          </p>
          <p className={cx('row__value')}>CHF {withComma(setupFee, 2)}</p>
        </div>
      </div>
    </div>
  );
};
