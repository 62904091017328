import {ActionType, getType} from 'typesafe-actions';
import * as actions from './password.actions';
import {PasswordForm, PasswordFormErrors} from './types';

export interface ProfilePasswordState {
  form: Partial<PasswordForm>;
  loading: boolean;
  errors: PasswordFormErrors;
  hasChanged?: boolean;
  success?: boolean;
}

const initialState: ProfilePasswordState = {

  form: {},
  loading: false,
  errors: {}
}

type Action = ActionType<typeof actions>

export const passwordReducer = (state = initialState, action: Action): ProfilePasswordState => {
  switch (action.type) {
    case getType(actions.onInputChange):
      const {name, value} = action.payload;
      return {
        ...state,
        form: {...state.form, [name]: value},
        errors:{},
        hasChanged:true,
        success:false,
      }
    case getType(actions.clearAllSuccessMessages):
      return initialState;
    case getType(actions.changePassword.request):
      return {...state, loading:true, errors:{}}
    case getType(actions.changePassword.failure):
      return {...state, loading:false, errors:action.payload}
    case getType(actions.changePassword.success):
      return {...initialState, success:true};
    default :
      return state
  }
}
