import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';
import * as actions from './home.actions';
import {homePageState} from "./index";
import {validateDemoSignUp} from "../../utils/formValidations";
import {apiEndpoints} from "../../constants/api";
import {showErrorModal} from "../app/app.actions";
import {showFailedModal} from "./home.actions";

function* onSubmitURL(action: ReturnType<typeof actions.onSubmitURL.request>) {
  const {form: values} = homePageState(yield select());
  const {url} = action.payload;
  const {email, code} = values;
  const now = new Date();
  const day = now.getDate()
  const month = now.getMonth() + 1
  try {
    validateDemoSignUp(values, day + month);
    const body = {
      url,
      email,
      validationValue: code,
      offsetTime: new Date().getTimezoneOffset(),
    }
    const {data}: AxiosResponse<{ succeeded: boolean }> = yield call(axios.post, apiEndpoints.publicSendReport, body)

    if (data.succeeded) {
      yield put(actions.onSubmitURL.success())
      yield put(actions.showSuccessModal(true))
      yield put(showFailedModal(false))
    } else {
      yield put(actions.onSubmitURL.failure({}))
      yield put(actions.showFailedModal(true))
      yield put(actions.showDemoModal(false))
    }

  } catch (e) {
    if (e.errors) {
      yield put(actions.onSubmitURL.failure(e.errors))
    } else {
      yield put(actions.onSubmitURL.failure({}))
      yield put(actions.showDemoModal(false))
      yield put(showErrorModal({}))
    }
  }
}

function* submitReportRequest() {
  const {form: values} = homePageState(yield select());
  const {email, code, url} = values;
  const now = new Date();
  const day = now.getDate()
  const month = now.getMonth() + 1
  try {
    validateDemoSignUp(values, day + month);
    const body = {
      url,
      email,
      validationValue: code,
      offsetTime: new Date().getTimezoneOffset(),
    }
    const {data}: AxiosResponse<{ succeeded: boolean }> = yield call(axios.post, apiEndpoints.publicReportRequest, body)
    console.log(data);
    yield put(actions.submitReportRequest.success())
    yield put(showFailedModal(false))

  } catch (e) {
    if (e.errors) {
      yield put(actions.submitReportRequest.failure(e.errors));
    }
  }
}

export function* homePageSagas() {
  yield all([
    yield takeLatest(actions.onSubmitURL.request, onSubmitURL),
    yield takeLatest(actions.submitReportRequest.request, submitReportRequest)
  ])
}
