import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {images} from '../../assets/img';
import moment from 'moment';
import {PageValues} from '../../store/editPdfReport/types';

const cx = classNames.bind(styles);

interface Props {
  id?: number;
  pages?: string[];
  footerData?: PageValues;
}

export const Page1: React.FC<Props> = ({id, pages, footerData}) => {
  const letters = ['A', 'B', 'C', 'D', 'E', 'F'];
  const renderRows = pages?.map((row, i) => {
    return row ? (
      <div className={cx('row')} key={i}>
        <div className={cx('key')}>{letters[i] || ' '}</div>
        <div className={cx('value')}>
          <a rel="noopener noreferrer" href={row} target="_blank">
            {row}
          </a>
        </div>
      </div>
    ) : (
      ''
    );
  });
  return (
    <div className={cx('pdf-page')}>
      <Header />
      <div className={cx('container')}>
        <div className={cx('row')}>
          <div className={cx('key')}>URLs und Webseiten</div>
          <div className={cx('value', 'value--first')}>
            <div className={cx('icon')}>
              <img src={images.home} alt="" />
            </div>
            PageTracker-Bericht von {moment().format('YYYY-MM-DD')} für die Kunden-Nr. {id}
          </div>
        </div>
        {renderRows}
      </div>
      <Footer pageUrl={pages ? pages[0] : ''} withLink pageNumber={1} id={id} footerData={footerData} />
    </div>
  );
};
