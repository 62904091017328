import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from './userProfile.actions';
import {selectUserProfile} from '..';
import {handleErrors, validateUpdateProfile} from "../../../utils/formValidations";
import {UserProfile} from './types';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../../constants/api";
import {login, loginWithSocialProvider} from '../../auth/login/login.actions';
import {storage, StorageKeys} from "../../../utils/localStorage";
import {logout} from "../../auth/user";
import {selectAppState} from "../../app";
import {User} from "../../auth/user";

function* onUpdateProfile() {
  const {form} = selectUserProfile(yield select());
  try {
    validateUpdateProfile(form as UserProfile);
    yield call(axios.put, apiEndpoints.updateProfile, form)
    yield put(actions.updateProfile.success(form as UserProfile))
  } catch (e) {
    yield put(actions.updateProfile.failure(handleErrors(e)))
  }
}

function* onGetUserProfile() {
  const {parameters} = selectAppState(yield select());
  try {
    axios.defaults.baseURL = process.env.REACT_APP_BASE_URL
    const {data}: AxiosResponse<User> = yield call(axios.get, apiEndpoints.getProfile)
    if (parameters) {
      const subscription = parameters.subscriptions.find((sub) => sub.id === data.subscriptionId);
      yield put(actions.getUserProfile.success({...data, subscription}))
    }
    yield put(actions.getUserProfile.success(data))
  } catch (e) {
    storage.delete(StorageKeys.authToken);
    yield put(logout({redirect:true}))
    console.log(e)
    console.log(e.response)
  }
}


export function* createUserProfileSagas() {
  yield all([
    yield takeLatest(actions.updateProfile.request, onUpdateProfile),
    yield takeLatest(actions.getUserProfile.request, onGetUserProfile),
    yield takeLatest(login.success, onGetUserProfile),
    yield takeLatest(loginWithSocialProvider.success, onGetUserProfile),
  ])
}



