import styles from './index.module.scss';
import classNames from 'classnames/bind';
import React, {FC} from 'react';
const cx = classNames.bind(styles);

interface TabsProps {
  setSelectedTab: (tab: number, index?: number) => void;
  selectedTab: number;
  tabs: Array<string>;
  isDropdown?: boolean;
  diagramIndex?: number;
}

export const NavBar: FC<TabsProps> = ({setSelectedTab, selectedTab, tabs, isDropdown, diagramIndex}) => {
  return (
    <div className={cx('tabs-container')}>
      {tabs.map((item, i) => (
        <div
          key={i}
          className={cx('tab-button', selectedTab === i ? 'tab-button--active' : '')}
          onClick={() => setSelectedTab(i, diagramIndex)}>
          {isDropdown ? `${i + 1} Box` : item}
        </div>
      ))}
    </div>
  );
};
