export interface CommentsCount {
  comparisonLetter: string;
  scoreDach: string;
}

export interface AuthorRepliesCount {
  comparisonLetter: string;
  scoreDach: string;
}

export type AuthorRepliesChart = [string, string][];

export enum Types {
  commentsCount = 'commentsCount',
  authorRepliesChart = 'authorRepliesChart',
  authorRepliesCount = 'authorRepliesCount',
}
