import React from 'react';
import {images} from "../../../assets/img";
import {useTranslation} from "../Text";
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);


interface Props {
  content: string;
  className?: string;
  width?: number;
  tooltipPosition?: 'left' | 'right';
  verticalAlignment?: 'bottom' | 'top' | 'middle',
}

export const QuestionMark = (props: Props) => {
  const {content, className, width,verticalAlignment='bottom', tooltipPosition = 'right'} = props;
  const text = useTranslation(content);
  return (
    <div className={cx('question-mark', className)}>
      <img className={cx('icon')} src={images.questionMarkGrey} alt=""/>
      <div dangerouslySetInnerHTML={{__html:text || 'no translated'}} style={{width: width ? width + 'px' : ''}} className={cx('tooltip', `tooltip_--${tooltipPosition}`, `tooltip--${verticalAlignment}`)}>
      </div>
    </div>
  );
};
