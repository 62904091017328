import {takeLatest, select, put, call, all, delay} from 'redux-saga/effects';
import axios from 'axios';
import {submitPassword} from './create-password.actions';
import {selectCreatePassword} from '..';
import {handleErrors, validateNewPassword} from "../../../utils/formValidations";
import {apiEndpoints} from "../../../constants/api";
import {push} from 'connected-react-router';
import {NavigationPaths} from "../../../constants/navigationPaths";

function* onCreatePassword(action: ReturnType<typeof submitPassword.request>) {
  const {form} = selectCreatePassword(yield select());
  const {code, id} = action.payload;
  const {password} = form;
  try {
    axios.defaults.baseURL=process.env.REACT_APP_BASE_URL

    validateNewPassword(form);

    yield call(axios.post, apiEndpoints.createPassword, {
      password1: password,
      password2: password,
      userId: id,
      token: code
    });
    yield put(submitPassword.success())
    yield delay(5000);
    yield put(push(NavigationPaths.authentication))
  } catch (e) {
    yield put(submitPassword.failure(handleErrors(e)))
  }
}


export function* createPasswordSagas() {
  yield all([
    yield takeLatest(submitPassword.request, onCreatePassword)
  ])
}
