import {ActionType, getType} from 'typesafe-actions';
import {User} from './types';
import {logout, authenticateUser} from './user.actions';
import {login, loginWithSocialProvider} from '../login/login.actions';
import {updateProfile, getUserProfile} from '../../profile/userProfile/userProfile.actions';
import {storage, StorageKeys} from "../../../utils/localStorage";
import {getSystemParameters} from '../../app/app.actions';

const actions = {
  login,
  authenticateUser,
  loginWithSocialProvider,
  updateProfile,
  getUserProfile,
  logout,
  getSystemParameters
}
type Actions = ActionType<typeof actions>;

export type UserState = Partial<User>;

export const userReducer = (state:UserState = {}, action: Actions): UserState => {
  switch (action.type) {
    case getType(authenticateUser):
      return {...state, isAuthenticated: true}
    case getType(getUserProfile.success):
      return {...state, ...action.payload, isAuthenticated: true};
    case getType(updateProfile.success):
      return {...state, ...action.payload}
    case getType(actions.logout): {
      storage.delete(StorageKeys.authToken);
      return {}
    }
    case getType(actions.login.success) :
      return {
        ...action.payload,
        isAuthenticated: true,
      }
    case getType(actions.getSystemParameters.success) : {
      const subscription = action.payload.subscriptions.find((sub) => sub.id===state?.subscriptionId)
      return subscription ? {...state, subscription} : state
    }
    case getType(actions.loginWithSocialProvider.success):
      return {isAuthenticated: true, ...action.payload}
    default :
      return state
  }
}
