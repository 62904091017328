import {ActionType, getType} from 'typesafe-actions';
import * as actions from './home.actions';

type Action = ActionType<typeof actions>

export interface DemoForm {
  email?: string;
  url?: string;
  code?: string;
}

export interface HomePageState {
  form: DemoForm;
  errors: DemoForm;
  submittingUrl?: boolean;
  demoModal?: boolean;
  successModal?: boolean;
  secondarySuccessModal?: boolean;
  failedModal?: boolean;
  submittingReportRequest?: boolean;
}

const initialState: HomePageState = {
  form: {},
  errors: {},
}

export const homePageReducer = (state = initialState, action: Action): HomePageState => {
  switch (action.type) {
    case getType(actions.showDemoModal):
      return {...state, demoModal: action.payload}
    case getType(actions.showSuccessModal):
      return {...state, successModal: action.payload}
    case getType(actions.showFailedModal):
      return {...state, failedModal: action.payload}
    case getType(actions.onInputChange): {
      const {name, value} = action.payload;
      return {...state, form: {...state.form, [name]: value}}
    }
    case getType(actions.onSubmitURL.request): {
      return {...state, submittingUrl: true, errors: {}}
    }
    case getType(actions.onSubmitURL.success): {
      return {...state, submittingUrl: false, demoModal: false}
    }
    case getType(actions.onSubmitURL.failure): {
      return {...state, errors: action.payload, submittingUrl: false}
    }
    case getType(actions.submitReportRequest.request): {
      return {...state, errors:{}, submittingReportRequest:true}
    }
    case getType(actions.submitReportRequest.success): {
      return {...state, submittingReportRequest:false, secondarySuccessModal:true}
    }
    case getType(actions.submitReportRequest.failure): {
      return {...state, errors: action.payload, submittingReportRequest:false}
    }
    case getType(actions.showSecondarySuccessModal):{
      return {...state, secondarySuccessModal:action.payload, form:{}, errors:{}}
    }
    default :
      return state
  }
}
