import {all, select, takeLatest, put, call} from 'redux-saga/effects';
import {selectBenchmarking} from "./index";
import {getRankingsTop, getRankingsRegional, getWritingStyle, getOverviewSeo} from './benchmarking.actions';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../constants/api";
import {RankingsRegional, RankingsTop, SEO, WritingStyle} from "./types";
import {handleResponseError} from "../../utils/responseErrors";

function* fetchRankingsTop() {
  const {rankingsTop} = selectBenchmarking(yield select());
  try {
    const {data}: AxiosResponse<{ data: RankingsTop[], requestJson: string }> = yield call(
      axios.get, apiEndpoints.getRankingsTop(rankingsTop.interval)
    )
    yield put(getRankingsTop.success(data.data))
  } catch (e) {
    yield handleResponseError(e, getRankingsTop.failure)
  }
}

function* fetchRankingsRegional() {
  const {rankingsRegional} = selectBenchmarking(yield select());
  try {
    const {data}: AxiosResponse<{ data: RankingsRegional[], requestJson: string }> = yield call(
      axios.get, apiEndpoints.getRankingsRegional(rankingsRegional.interval)
    )
    yield put(getRankingsRegional.success(data.data))
  } catch (e) {
    yield handleResponseError(e, getRankingsRegional.failure)
  }
}

function* fetchWritingStyle() {
  const {writingStyle} = selectBenchmarking(yield select());
  try {
    const {data}: AxiosResponse<{ data: WritingStyle, requestJson: string }> = yield call(
      axios.get, apiEndpoints.getWritingStyle(writingStyle.interval)
    )
    yield put(getWritingStyle.success(data.data))
  } catch (e) {
    yield handleResponseError(e, getWritingStyle.failure)
  }
}

function* fetchOverviewSeo() {
  const {overviewSeo} = selectBenchmarking(yield select());
  try {
    const {data}: AxiosResponse<{ data: SEO, requestJson: string }> = yield call(
      axios.get, apiEndpoints.getOverviewSeo(overviewSeo.interval)
    )
    yield put(getOverviewSeo.success(data.data))
  } catch (e) {
    yield handleResponseError(e, getOverviewSeo.failure)
  }
}

export function* benchmarkingSagas() {
  yield all([
    yield takeLatest(getRankingsTop.request, fetchRankingsTop),
    yield takeLatest(getRankingsRegional.request, fetchRankingsRegional),
    yield takeLatest(getWritingStyle.request, fetchWritingStyle),
    yield takeLatest(getOverviewSeo.request, fetchOverviewSeo),
  ])
}
