import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {register} from './registration.actions';
import {selectRegistration} from '..';
import {handleErrors, validateRegistration} from '../../../utils/formValidations';
import axios from 'axios';
import {push} from 'connected-react-router';
import {apiEndpoints} from "../../../constants/api";
import {NavigationPaths} from "../../../constants/navigationPaths";
import {storage, StorageKeys} from "../../../utils/localStorage";

function* registrationStarted() {
  const {form} = selectRegistration(yield select());
  const validationValue = parseFloat(form.validationValue);
  const offsetTime = new Date().getTimezoneOffset();
  try {
    validateRegistration(form);
    yield call(axios.post, apiEndpoints.register, {...form, validationValue, offsetTime})
    yield put(register.success())
    yield put(push(NavigationPaths.confirmEmail + '?email=' + form.email))
    storage.save(StorageKeys.firstTimeLogin, 'true')
  } catch (e) {
    yield put(register.failure(handleErrors(e)))
  }
}

export function* registrationSagas() {
  yield all([
    yield takeLatest(register.request, registrationStarted),
  ]);
}
