import React from 'react';
import {Text} from "../../components/ui/Text";
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  index: number;
  letter: string;
  disableSubheading?: boolean;
}

export const Heading = (props: Props) => {
  const {index: i, letter, disableSubheading} = props;
  if (i === 0) {
    return (
      <div className={cx('heading-container')}>
        <h2 className={cx('heading', 'heading__with_margin')}>
          <Text msg='compare.card-title-my-blog'/> {!disableSubheading && letter}
        </h2>
        {!disableSubheading &&
        <p className={cx('sub-heading')}>
          <Text msg='compare.card-fist-description'/>
        </p>}
      </div>
    )
  }
  if (i > 0) {
    return (
      <div className={cx('heading-container')}>
        <h2 className={cx('heading', 'heading__with_margin')}>
          <Text msg='compare.card-title'/> {!disableSubheading && letter}
        </h2>
        {!disableSubheading &&
        <p className={cx('sub-heading')}>
          <Text msg='compare.card-second-description'/>
        </p>}
      </div>
    )
  }
  return null;
};
