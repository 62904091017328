import {all} from 'redux-saga/effects';
import {logInSagas} from './auth/login/login.sagas';
import {registrationSagas} from './auth/registration/registration.sagas';
import {forgotPasswordSagas} from './auth/forgot-password/forgot-password.sagas';
import {confirmEmailSagas} from './auth/confirm-email/confirm-email.sagas';
import {createPasswordSagas} from './auth/create-password/create-password.sagas';
import {createUserProfileSagas} from './profile/userProfile/userProfile.sagas';
import {changePasswordSagas} from './profile/password/password.sagas';
import {userSagas} from './auth/user';
import {reportSagas} from './report/report.sagas';
import {compareSagas} from './compare/compare.sagas';
import {archiveSagas} from './archive/archive.sagas';
import {appSagas} from './app/app.sagas';
import {benchmarkingSagas} from './benchmarking/benchmarking.sagas';
import {trendSagas} from './trend/trend.sagas';
import {brandingSagas} from './branding/branding.sagas';
import {superAdminSagas} from './superAdmin/superAdmin.sagas';
import {subscriptionSagas} from './subscription/subscription.sagas';
import {seoSagas} from './seo';
import {engagementSagas} from './engagement';
import {visualsSagas} from './visuals';
import {invitedUsersSaga} from './profile/invitedUsers';
import {subUserSagas} from './auth/sub-user';
import {invoiceSagas} from './profile/invoice';
import {homePageSagas} from './home';
import { createEditPdfReportSagas } from './editPdfReport/editPdfReport.sagas';
import { createEditSectionTextSagas } from './editSectionText/editSectionText.sagas';

export function* rootSaga() {
  yield all([
    logInSagas(),
    registrationSagas(),
    forgotPasswordSagas(),
    confirmEmailSagas(),
    createPasswordSagas(),
    createUserProfileSagas(),
    changePasswordSagas(),
    userSagas(),
    reportSagas(),
    compareSagas(),
    archiveSagas(),
    appSagas(),
    benchmarkingSagas(),
    trendSagas(),
    brandingSagas(),
    superAdminSagas(),
    subscriptionSagas(),
    seoSagas(),
    engagementSagas(),
    visualsSagas(),
    invitedUsersSaga(),
    subUserSagas(),
    invoiceSagas(),
    homePageSagas(),
    createEditPdfReportSagas(),
    createEditSectionTextSagas(),
  ]);
}
