import React from 'react';
import {Text, useTranslation} from "../Text";
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  placeholder?: string;
  notTranslatedPlaceholder?: string;
  label?: string;
  value: string;
  onChange: (name: string, value: string) => void;
  className?: string;
  disabled?: boolean;
  name: string;
  error?: string;
}

export const Textarea: React.FC<Props> = (props) => {
  const {placeholder, onChange,notTranslatedPlaceholder, name, value, error, label, disabled, className} = props;
  const placeholderTranslation = useTranslation(placeholder);
  return (
    <div className={cx('container',disabled && 'container__disabled' )}>
      {(label || error ) &&
      <div
        className={cx('label', error && 'label__error')}
      >
        <Text msg={error? error :label || ''}/>
      </div>
      }
      <textarea
        placeholder={notTranslatedPlaceholder || placeholderTranslation || ''}
        value={value}
        name={name}
        onChange={(e) => onChange(e.target.name, e.target.value)}
        disabled={disabled}
        className={cx('textarea', className)}
      />
    </div>
  );
};
