import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {getData, changeInterval} from '../../store/branding/branding.actions';
import {useDispatch, useSelector} from "react-redux";
import {selectBranding} from "../../store/branding";
import {Interval, TableMenu} from "../../components/TableMenu";
import {RankingsTable} from "../../components/RankingsTable";
import {TableFooter} from "../../components/TableFooter";
import {Types} from "../../store/branding/types";
import {apiEndpoints} from "../../constants/api";
import {Loader} from "../../components/ui/Loader";
import {mapHashtagsData} from "./data-mapper";
import {useHasLimitedAccess} from "../../utils/useCheckPermissions";
import {AccessSections} from "../../store/app/types";
import {ErrorMessage} from "../../components/ErrorMessage";
import {selectUser} from "../../store/auth";

const cx = classNames.bind(styles);

export const Hashtags = () => {
  const dispatch = useDispatch()
  const {rankingsHashtags} = useSelector(selectBranding);
  const {interval, data, error, isLoading} = rankingsHashtags;
  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['branding-rank-hashtag'],user)

  useEffect(() => {
    dispatch(getData.request({type: Types.rankingsHashtags}))
  }, [dispatch, interval])

  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeInterval({type: Types.rankingsHashtags, interval: quarter}))
  }

  const renderTable = () => {
    if (error) return <ErrorMessage/>
    if (!data || isLoading) return <Loader className={cx('loader')}/>;
    const tableData = mapHashtagsData(data);

    return (
      <>
        <RankingsTable
          tableHeaderTwo='branding.table-header-two'
          tableHeaderThree='branding.table-header-three'
          data={tableData}
          error={error}
          isLoading={isLoading}/>
        <TableFooter labels={['branding.label.hashtags', '', '']}/>
      </>
    )
  }
  return (
    <div className={cx('branding-container')}>
      <TableMenu
        limitedAccess={limitedAccess}
        active={interval}
        downloadApiUrl={apiEndpoints.getBrandingHashtag(interval || 'Y', true)}
        title='branding.title.rank-hashtags'
        questionMark='branding.title.rank-hashtags.question-mark'
        buttonsTitles={[
          'branding.table-menu.button1',
          'branding.table-menu.button2',
          'branding.table-menu.button3',
        ]}
        onIntervalChange={onIntervalChange}
      />
      {renderTable()}
    </div>
  );
};
