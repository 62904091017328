import React from 'react';
import {Response} from '../types';
import {Header} from '../Header';
import {Footer} from '../Footer';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {TableMenu} from '../../components/TableMenu';
import {BarChart} from '../../components/BarChart';
import {mapCompareData} from '../../routes/Branding/data-mapper';
import {Tooltip} from '../Tooltip';

const cx = classNames.bind(styles);

interface Props {
  pdfData: Response;
}

export const Page5: React.FC<Props> = ({pdfData}) => {
  const d1 = mapCompareData(pdfData?.['branding-rank-company-name-comparison']?.data || []);
  const d2 = mapCompareData(pdfData?.['branding-rank-brand-comparison']?.data || []);

  const i1 = pdfData?.['trendanalysis-dach']?.quarter;
  const i2 = pdfData?.['trendanalysis-regional']?.quarter;

  return (
    <div className={cx('pdf-page')}>
      <Header />
      <div className={cx('container')}>
        <div className={cx('item')}>
          <Tooltip text="Wie gut konnten Sie Ihren Firmennamen im Web positionieren?" />
          <TableMenu
            pdfExport
            active={i1}
            onIntervalChange={() => null}
            title="branding.title.rank-companies-comparison"
          />
          <BarChart pdfExport letterALabel="branding.label.firm" data={d1} />
        </div>
      </div>
      <div className={cx('container')}>
        <div className={cx('item')}>
          <Tooltip text="Wie gut konnten Sie Ihre Marke im Web positionieren?" />
          <TableMenu
            pdfExport
            active={i2}
            onIntervalChange={() => null}
            title="branding.title.rank-brands-comparison"
          />
          <BarChart pdfExport letterALabel="branding.label.brand" data={d2} />
        </div>
      </div>
      <Footer
        pageUrl={pdfData.pages ? pdfData.pages[0] : ''}
        id={pdfData?.customerId}
        onlyPageInfo
        pageNumber={4}
      />
    </div>
  );
};
