import {ComparePage} from './types';
import {getType, ActionType} from 'typesafe-actions';
import * as actions from './compare.actions';
import {getComparePages} from "./compare.actions";

type Action = ActionType<typeof actions>

export interface CompareState {
  data: ComparePage[];
  isLoading?: boolean;
}

export const comparePage: ComparePage = {
  pageUrl: '',
  comparisonLetter: '',
  categoryLetter: '',
  categoryId: '',
  countryId: '',
  commentRating: '',
  advertising: '',
  groupId: '',
  companySize: '',
  isin: '',
  companyName: '',
  brands: '',
  hashtags: '',
  status: '',
  isEditable: true,
}

const initialState: CompareState = {
  data: []
}

export const compareReducer = (state = initialState, action: Action): CompareState => {
  switch (action.type) {
    case getType(actions.onInputChange): {
      const {name, value, pageIndex} = action.payload;
      const updatedPage = {...state.data[pageIndex], [name]: value, success: ''};
      if (name === 'categoryLetter') delete updatedPage.categoryId;
      const data = state.data.map((page, i) => i === pageIndex ? updatedPage : page)
      return {...state, data}
    }
    case getType(actions.updateComparePage.request): {
      const {pageIndex} = action.payload;
      const updatedPage = {...state.data[pageIndex], isLoading: true};
      const data = state.data.map((page, i) => i === pageIndex ? updatedPage : page)
      return {...state, data}
    }
    case getType(actions.deletePage.request): {
      const {pageIndex} = action.payload;
      const updatedPage = {...state.data[pageIndex], isLoading: true};
      const data = state.data.map((page, i) => i === pageIndex ? updatedPage : page)
      return {...state, data}
    }
    case getType(actions.deletePage.success): {
      const {pageIndex} = action.payload;
      const data = state.data.map((page, i) => i === pageIndex ? comparePage : page)
      return {...state, data}
    }
    case getType(actions.updateComparePage.success): {
      const {pageIndex, page: updatedPage} = action.payload;
      const data = state.data.map((page, i) => {
        const success = page.id ? 'compare.success-update' : 'compare.success-create';
        return pageIndex === i
          ? {...updatedPage, errors: {}, isLoading: false, success}
          : {...page, errors: {}, isLoading: false}
      })
      return {...state, data}
    }
    case getType(actions.updateComparePage.failure): {
      const {pageIndex, errors} = action.payload;
      const data = state.data.map((page, i) => i === pageIndex ? {...page, errors, isLoading: false} : page)
      return {...state, data};
    }
    case getType(actions.fillEmptyPage): {
      const {index, pageUrl} = action.payload;
      const data = state.data.map((item, i) => i === index ? {...item, pageUrl, new: true} : item)
      return {...state, data};
    }
    case getType(actions.clearPreloadedPage): {
      const {index} = action.payload;
      const data = state.data.map((item, i) => i === index ? comparePage : item)
      return {...state, data};
    }
    case getType(actions.getComparePages.request):
      return {...state, isLoading: true};
    case getType(getComparePages.success):
      return {...state, data: action.payload}
    case getType(getComparePages.failure):
      return {...state, isLoading: false}
    default :
      return state
  }
}
