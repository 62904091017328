import React, {useEffect, useState} from 'react';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import {homeMenu, settingsMenu} from './data';
import {images} from '../../../assets/img';
import {Text} from '../../ui/Text';
import {NavigationPaths} from '../../../constants/navigationPaths';
import {QuestionMark} from '../../ui/QuestionMark';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {useSelector} from 'react-redux';
import {expiredSubscription, selectUserIsAdmin} from '../../../store/auth/user';
import {HoverPopup} from '../../ui/HoverPopup';
import {selectUser} from '../../../store/auth';

const cx = classNames.bind(styles);

export const SideMenu = () => {
  const location = useLocation();
  const {isAuthenticated} = useSelector(selectUser);
  const isAdmin = useSelector(selectUserIsAdmin);
  const isExpiredSubscription = useSelector(expiredSubscription);
  const [menu, changeMenu] = useState<'settings' | 'home'>('settings');
  const history = useHistory();

  useEffect(() => {
    const isSettingsMenu = settingsMenu
      .filter((item) => !(item.path === NavigationPaths.authentication && isAuthenticated))
      .find((item) => location.pathname.includes(item.path));
    const isHomeMenu = homeMenu.find((item) => location.pathname.includes(item.path));
    if (!!isSettingsMenu && menu !== 'settings') changeMenu('settings');
    if (!!isHomeMenu && menu !== 'home') changeMenu('home');
  }, [menu, location]);
  if (location.pathname === NavigationPaths.homePage) return null;
  if (window.location.href.includes('pdf')) return null;

  const menuData = menu === 'settings' ? settingsMenu : homeMenu;

  const onMenuChange = () => {
    if (isExpiredSubscription) return;
    changeMenu(menu === 'settings' ? 'home' : 'settings');
    if (menu === 'settings') history.push(NavigationPaths.benchmarking);
    if (menu === 'home') history.push(NavigationPaths.profile);
  };

  const menuItems = menuData
    .filter((item) => !(item.path === NavigationPaths.authentication && isAuthenticated))
    .filter(
      (item) =>
        !(
          (item.path === NavigationPaths.editPdfReport || item.path === NavigationPaths.editTextSections) &&
          !isAdmin
        )
    )
    .map((item, i) => {
      return (
        <NavLink
          className={cx('menu-item', isExpiredSubscription && !item.allowedForTrials && 'menu-item--expired')}
          onClick={(e) => {
            if (isExpiredSubscription && !item.allowedForTrials) e.preventDefault();
          }}
          key={item.title}
          to={item.path}
          activeClassName={cx(menu === 'settings' ? 'settings' : 'home')}>
          <div className={cx('box')}>
            <div className={cx('title-container')}>
              <span className={cx('title')}>
                <Text msg={item.title} />
              </span>
              <QuestionMark
                width={450}
                verticalAlignment={item.position}
                content={item.questionMark || ''}
                className={cx('question-mark')}
              />
            </div>
            <span className={cx('desc')}>
              <Text msg={item.desc} />
            </span>
          </div>
          <HoverPopup
            visible={isExpiredSubscription && !item.allowedForTrials}
            className={cx('not-available')}
          />
        </NavLink>
      );
    });
  return (
    <div className={cx('container')}>
      <p className={cx('settings', isExpiredSubscription && 'settings--expired')} onClick={onMenuChange}>
        <Text msg={menu === 'settings' ? 'menu.settings' : 'menu.home'} />
        <img className={cx('arrow-white')} src={images.arrowDownWhite} alt="" />
        <HoverPopup visible={isExpiredSubscription} className={cx('not-available')} />
      </p>
      {menuItems}
    </div>
  );
};
