import {createAction, createAsyncAction} from 'typesafe-actions';
import {InputPayload} from '../../../types/events';

export const onInputChange = createAction('auth/CREATE_PASSWORD_INPUT_CHANGE')<InputPayload>();

export const submitPassword = createAsyncAction(
  'auth/CREATE_PASSWORD_STARTED',
  'auth/CREATE_PASSWORD_DONE',
  'auth/CREATE_PASSWORD_FAILED',
)<{ id:string, code:string }, void, {}>()

export const clearState = createAction('auth/CREATE_PASSWORD_CLEAR_STATE')<void>();
