import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from '../ui/Text';

const cx = classNames.bind(styles);

interface Props {
  labels?: [string, string, string];
  withChartLabels?: boolean;
  className?: string;
  pdfExport?: boolean;
}

export const TableFooter = (props: Props) => {
  const {labels, withChartLabels, className, pdfExport} = props;
  let leftLabel, centerLabel, rightLabel;
  if (labels) [leftLabel, centerLabel, rightLabel] = labels;
  return (
    <footer className={cx('footer', className, pdfExport && 'footer--pdf')}>
      <div className={cx('row', 'row--main')}>
        <div className={cx('label', pdfExport && 'label--pdf')}>
          <div className={cx('symbol', 'symbol__dark-blue', pdfExport && 'symbol--pdf')} />
          <span>
            <Text msg={leftLabel ? leftLabel : 'benchmarking.footer.label.our-url'} />
          </span>
        </div>
        <div className={cx('label', pdfExport && 'label--pdf')}>
          <div className={cx('symbol', 'symbol__light-blue', pdfExport && 'symbol--pdf')} />
          <span>
            <Text msg={centerLabel ? centerLabel : 'benchmarking.footer.label.comparison-b'} />
          </span>
        </div>
        <div className={cx('label', pdfExport && 'label--pdf')}>
          <div className={cx('symbol', 'symbol__yellow', pdfExport && 'symbol--pdf')} />
          <span>
            <Text msg={rightLabel ? rightLabel : 'benchmarking.footer.label.comparison-c'} />
          </span>
        </div>
      </div>
      {withChartLabels && (
        <div className={cx('row', 'row--chart')}>
          <div className={cx('label', pdfExport && 'label--pdf')}>
            <div className={cx('dashes', 'dashes--short')}>
              <div />
              <div />
              <div />
              <div />
              <div />
              <div />
            </div>
            <span>
              <Text msg={leftLabel ? leftLabel : 'benchmarking.footer.label.comparison-d'} />
            </span>
          </div>
          <div className={cx('label', pdfExport && 'label--pdf', pdfExport && 'label--short')}>
            <div className={cx('dashes', 'dashes--vertical')}>
              <div />
              <div />
              <div />
              <div />
            </div>
            <span>
              <Text msg={centerLabel ? centerLabel : 'benchmarking.footer.label.comparison-e'} />
            </span>
          </div>
          <div className={cx('label', pdfExport && 'label--pdf')}>
            <div className={cx('dashes', 'dashes--long')}>
              <div />
              <div />
              <div />
            </div>
            <span>
              <Text msg={rightLabel ? rightLabel : 'benchmarking.footer.label.comparison-f'} />
            </span>
          </div>
        </div>
      )}
    </footer>
  );
};
