import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectUser } from '../../store/auth/user';
import { NavigationPaths } from '../../constants/navigationPaths';

interface Props {
  component: React.FC<RouteProps>;
  path: string | string[];
  exact?: boolean;
}

export const ProtectedRoute: React.FC<Props> = (props: Props) => {
  const { component: Component, path, exact } = props;
  const user = useSelector(selectUser);
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => {
        return user.isAuthenticated ? <Component {...props} /> : <Redirect to={NavigationPaths.authentication} />;
      }}
    />
  );
};
