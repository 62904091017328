import {createAsyncAction, createAction} from 'typesafe-actions';
import {ComparePage, ComparePageErrors} from './types';

export const onInputChange = createAction(
  'compare/ON_INPUT_CHANGE'
)<{ name: string, value: string | number | boolean, pageIndex: number }>()

export const updateComparePage = createAsyncAction(
  'compare/UPDATE_PAGE_STARTED',
  'compare/UPDATE_PAGE_DONE',
  'compare/UPDATE_PAGE_FAILED',
)<{ pageIndex: number, page: ComparePage, skipValidation?:boolean }, { pageIndex: number, page: ComparePage }, { errors: ComparePageErrors, pageIndex: number }>()

export const deletePage = createAsyncAction(
  'compare/DELETE_PAGE_STARTED',
  'compare/DELETE_PAGE_DONE',
  'compare/DELETE_PAGE_FAILED',
)<{ id: string, pageIndex: number }, { pageIndex: number, page: ComparePage }, void>()

export const getComparePages = createAsyncAction(
  'compare/GET_PAGES_STARTED',
  'compare/GET_PAGES_DONE',
  'compare/GET_PAGES_FAILED',
)<{ showWarningIfPending: boolean }, ComparePage[], void>()

export const clearPreloadedPage = createAction(
  'compare/CLEAR_PRELOADED_PAGE'
)<{ index: number }>()

export const fillEmptyPage = createAction(
  'compare/FILL_EMPTY_PAGE'
)<{ pageUrl:string, index: number }>()
