import {Invoicing, InvoiceErrors} from './types';
import {ActionType, getType} from 'typesafe-actions';
import * as actions from './invoice.actions';
import {logout} from '../../auth/user';

export interface InvoiceState {
  form: Partial<Invoicing>;
  errors: Partial<InvoiceErrors>;
  isLoading?: boolean;
  formUpdateSuccess: boolean,
  formHasChanged:boolean,
}

const initialState: InvoiceState = {
  form: {},
  errors: {},
  formUpdateSuccess: false,
  formHasChanged:false,

}
const combinedActions = {...actions, logout}
type Action = ActionType<typeof combinedActions>

export const invoiceReducer = (state: InvoiceState = initialState, action: Action) => {
  switch (action.type) {
    case getType(actions.onInputChange) : {
      const {name, value} = action.payload;
      return {
        ...state,
        form: {...state.form, [name]: value},
        errors: {...state.errors, [name]: ''},
        formUpdateSuccess: false,
        formHasChanged:true,
      }
    }
    case getType(actions.validateInvoiceDataFailed):
      return {...state, errors: action.payload}
    case getType(actions.getInvoiceData.success):
      return {...state, form: action.payload};
    case getType(actions.updateInvoiceData.request):
      return {...state, errors: {}, isLoading: true};
    case getType(actions.updateInvoiceData.failure):
      return {...state, isLoading: false, errors: action.payload};
    case getType(actions.updateInvoiceData.success) :
      return {...state, isLoading: false, errors: {}, formHasChanged:false, formUpdateSuccess: true};

    case getType(logout):
      return initialState;
    default :
      return state
  }
}
