import {createAction, createAsyncAction} from 'typesafe-actions';
import {InviteUserErrors, InvitedUser} from "./types";

export const onInputChange = createAction(
  'profile/INVITED_USER_INPUT_CHANGE'
)<{name:string, value:string, index:number}>()

export const onOptionChange = createAction(
  'profile/INVITED_USER_OPTION_CHANGE'
)<{value:string, index:number}>()

export const getInvitedUsers = createAsyncAction(
  'profile/INVITED_USER_GET_USERS_STARTED',
  'profile/INVITED_USER_GET_USERS_DONE',
  'profile/INVITED_USER_GET_USERS_FAILED',
)<void, InvitedUser[], void>()

export const updateInvitedUser= createAsyncAction(
  'profile/INVITED_USER_UPDATE_USER_STARTED',
  'profile/INVITED_USER_UPDATE_USER_DONE',
  'profile/INVITED_USER_UPDATE_USER_FAILED',
)<{ index:number }, { index:number }, { index:number , errors:InviteUserErrors}>()
