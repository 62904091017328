import React, {useRef, useState} from 'react';
import {images} from '../../../assets/img';
import {UserState} from '../../../store/auth/user';
import {useOutsideClick} from '../../../utils/useOutsideClick';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {ExpandableMenuItems} from './ExpandableMenuItems';

const cx = classNames.bind(styles);

interface Props {
  user: UserState;
  isHome: boolean;
}

export const ExpandableUserMenu = (props: Props) => {
  const {user} = props;
  const [isExpanded, expandMenu] = useState(false);
  const node = useRef(null);

  useOutsideClick(node, () => expandMenu(false));

  const expand = () => {
    if (window.innerWidth < 600) return;
    expandMenu(!isExpanded);
  };

  if (!user.isAuthenticated) {
    return <div></div>;
  }

  return (
    <div
      ref={node}
      onClick={expand}
      className={cx(
        'expandable-user-menu',
        props.isHome && 'expandable-user-menu-home',
        isExpanded && 'active'
      )}>
      <div className={cx('menu-item')}>
        <span className={cx('name')}>
          {user.firstName} {user.lastName}
        </span>
        <img
          src={props.isHome ? images.chevronDown : images.chevronDownDark}
          className={cx('chevron')}
          alt=""
        />
      </div>
      {isExpanded && <ExpandableMenuItems className={cx('menu-item')} />}
    </div>
  );
};
