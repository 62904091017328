import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {useDispatch, useSelector} from "react-redux";
import {appState} from "../../store/app/app.reducer";
import {showSuccessModal} from "../../store/app/app.actions";
import {Text} from "../ui/Text";

const cx = classNames.bind(styles);

export const SuccessModal = () => {
  const dispatch = useDispatch();
  const {successModal} = useSelector(appState);
  if (!successModal) return null;

  const {title, message} = successModal;

  return (
    <>
      <div className={cx('modal')}>
        <h4 className={cx('title')}>
          <Text msg={title || 'common.success'}/>
        </h4>
        {message && <p className={cx('message')}><Text msg={message}/></p>}
        <div className={cx('container')}>
          <div
            onClick={() => dispatch(showSuccessModal(null))}
            className={cx('button', 'button__confirm')}
          >
            <Text msg='common.ok'/>
          </div>
        </div>
      </div>
      <div onClick={() => dispatch(showSuccessModal(null))} className={cx('backdrop')}/>
    </>
  );
};
