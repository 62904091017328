import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {DiagramTextValues} from '../../store/editSectionText/types';
import {Loader} from '../ui/Loader';
const cx = classNames.bind(styles);

interface Props {
  data: Array<DiagramTextValues>;
  activeIndex?: number;
  className?: string;
  loading?: boolean;
}

export const DropDownDescription: React.FC<Props> = ({data = [], activeIndex, className, loading}) => {
  const [active, setActive] = useState<Array<boolean>>([]);
  useEffect(() => {
    setActive(Array.from({length: data.length}, (_, i) => (i === activeIndex ? true : false)));
  }, [data.length, activeIndex]);

  const handleTabClick = (i: number) => {
    const newArr = [...active];
    newArr.forEach((_, id) => {
      if (newArr[i]) {
        return;
      }
      newArr[id] = false;
    });
    newArr[i] = !newArr[i];
    setActive(newArr);
  };
  const renderDropBoxes = () => {
    if (loading) return <Loader />;
    return active.map((value, i) => (
      <div key={i} className={cx('drop-down__item')}>
        <div onClick={() => handleTabClick(i)} className={cx('tab', value ? 'tab__active' : '')}>
          <h5 className={cx('title')} dangerouslySetInnerHTML={{__html: data[i]?.title}}></h5>
          <span className={cx('active')}>{value ? '-' : '+'}</span>
        </div>
        <div className={cx('content', value ? 'content__show' : '')}>
          <div className={cx('description')} dangerouslySetInnerHTML={{__html: data[i]?.description}}></div>
        </div>
      </div>
    ));
  };
  return <div className={cx('drop-down', className)}>{renderDropBoxes()}</div>;
};
