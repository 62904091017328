import {all, takeLatest, put, select, call} from 'redux-saga/effects';
import {changePassword} from './password.actions';
import {selectChangePassword} from '..';
import {handleErrors, validateUpdatePassword} from "../../../utils/formValidations";
import {PasswordForm} from './types';
import axios from 'axios';
import {apiEndpoints} from "../../../constants/api";

function* onChangePassword() {
  try {
    const {form} = selectChangePassword(yield select());
    validateUpdatePassword(form as PasswordForm)
    yield call(axios.put, apiEndpoints.updatePassword, form)
    yield put(changePassword.success())
  } catch (e) {
    yield put(changePassword.failure(handleErrors(e)))
  }
}

export function* changePasswordSagas() {
  yield all([
    yield takeLatest(changePassword.request, onChangePassword)
  ])
}
