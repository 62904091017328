import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Interval, TableMenu} from "../../components/TableMenu";
import {changeInterval, getData, selectVisuals} from "../../store/visuals";
import {useDispatch, useSelector} from "react-redux";
import {Loader} from "../../components/ui/Loader";
import {Types} from "../../store/visuals/types";
import {apiEndpoints} from "../../constants/api";
import {BarChart} from "../../components/BarChart";
import {ErrorMessage} from "../../components/ErrorMessage";
import {useHasLimitedAccess, useHasNoAccess} from "../../utils/useCheckPermissions";
import {AccessSections} from "../../store/app/types";
import {NoAccessMessage} from "../../components/ui/NoAccessMessage";
import {selectUser} from "../../store/auth";

const cx = classNames.bind(styles);

export const SubtitlesCount = () => {
  const dispatch = useDispatch()
  const {subtitlesCount} = useSelector(selectVisuals);
  const {interval, data, error, isLoading} = subtitlesCount;

  useEffect(() => {
    dispatch(getData.request({type: Types.subtitlesCount}))
  }, [dispatch, interval])

  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['visuals-subtitles-count'],user)
  const noAccess =useHasNoAccess(AccessSections['visuals-subtitles-count'],user);

  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeInterval({interval: quarter, type: Types.subtitlesCount}))
  }

  const renderGraph = () => {
    if (noAccess) return <NoAccessMessage/>
    if (error) return <ErrorMessage/>
    if (!data || isLoading) return <Loader className={cx('loader')}/>;
    return (
      <BarChart letterALabel='benchmarking.footer.label.our-url' data={data}/>
    )
  }
  return (
    <div className={cx('graph-container')}>
      <TableMenu
        active={interval}
        limitedAccess={limitedAccess}
        downloadApiUrl={apiEndpoints.getVisualsSubtitlesCount(interval || 'Y', true)}
        title='visuals-subtitles-count'
        questionMark='visuals-subtitles-count.question-mark'
        buttonsTitles={[
          'table-menu.button1',
          'table-menu.button2',
          'table-menu.button3',
        ]}
        onIntervalChange={onIntervalChange}
      />
      {renderGraph()}
    </div>
  );
};
