import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {getData, changeInterval} from '../../store/branding/branding.actions';
import {useDispatch, useSelector} from "react-redux";
import {selectBranding} from "../../store/branding";
import {Interval, TableMenu} from "../../components/TableMenu";
import {Types} from "../../store/branding/types";
import {BarChart} from "../../components/BarChart";
import {apiEndpoints} from "../../constants/api";
import {Loader} from "../../components/ui/Loader";
import {mapCompareData} from "./data-mapper";
import {useHasLimitedAccess, useHasNoAccess} from "../../utils/useCheckPermissions";
import {AccessSections} from "../../store/app/types";
import {ErrorMessage} from "../../components/ErrorMessage";
import {NoAccessMessage} from "../../components/ui/NoAccessMessage";
import {selectUser} from "../../store/auth";

const cx = classNames.bind(styles);

export const CompaniesComparison = () => {
  const dispatch = useDispatch()
  const {rankingsCompaniesComparison} = useSelector(selectBranding);
  const {interval, data, error, isLoading} = rankingsCompaniesComparison;

  useEffect(() => {
    dispatch(getData.request({type: Types.rankingsCompaniesComparison}))
  }, [dispatch, interval])
  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['branding-rank-company-name-comparison'],user)
  const noAccess =useHasNoAccess(AccessSections['branding-rank-company-name-comparison'],user)
  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeInterval({interval: quarter, type: Types.rankingsCompaniesComparison}))
  }

  const renderChart = () => {
    if (noAccess) return <NoAccessMessage/>
    if (error) return <ErrorMessage/>
    if (!data || isLoading) return <Loader className={cx('loader')}/>;
    const barData = mapCompareData(data);
    return (
      <BarChart letterALabel='branding.label.firm' data={barData}/>
    )
  }
  return (
    <div className={cx('comparison','bar-chart')}>
      <TableMenu
        active={interval}
        limitedAccess={limitedAccess}
        downloadApiUrl={apiEndpoints.getBrandingCompaniesComparison(interval || 'Y', true)}
        title='branding.title.rank-companies-comparison'
        questionMark='branding.title.rank-companies-comparison.question-mark'
        buttonsTitles={[
          'branding.table-menu.button1',
          'branding.table-menu.button2',
          'branding.table-menu.button3',
        ]}
        onIntervalChange={onIntervalChange}
      />
      {renderChart()}
    </div>
  );
};
