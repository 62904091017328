import {ActionType, getType} from 'typesafe-actions';
import * as actions from './visuals.actions';
import {BarChartData} from "../../components/BarChart";
import {GraphData} from "../seo/types";

type Action = ActionType<typeof actions>

interface BaseProps {
  isLoading?: boolean;
  interval?: string;
  error?: boolean;
}

export interface VisualsState {
  imagesCount: {
    data?: BarChartData[];
  } & BaseProps,
  subtitlesCount: {
    data?: BarChartData[];
  } & BaseProps,
  chart: {
    data?: GraphData[];
  } & BaseProps
}

export type StateKeys = keyof VisualsState;

const initialState: VisualsState = {
  imagesCount: {},
  subtitlesCount: {},
  chart: {},
}

export const visualsReducer = (state = initialState, action: Action): VisualsState => {
  switch (action.type) {
    case getType(actions.getData.request): {
      const {type} = action.payload;
      return {...state, [type]: {isLoading: true, interval: state[type].interval}}
    }
    case getType(actions.getData.success): {
      const {type} = action.payload;
      return {...state, [type]: {data: action.payload.data, interval: state[type].interval}}
    }
    case getType(actions.getData.failure): {
      const {type} = action.payload;
      return {...state, [type]: {error: true, interval: state[type].interval}}
    }
    case getType(actions.changeInterval): {
      const {type} = action.payload;
      return {...state, [type]: {...state[type], interval: action.payload.interval}}
    }
    default :
      return state
  }
}
