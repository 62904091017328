import {ActionType, getType} from 'typesafe-actions';
import * as actions from './trend.actions';
import {TrendOverall, TrendRegional} from "./types";

type Action = ActionType<typeof actions>

export interface TrendState {
  overall: {
    isLoading?: boolean;
    interval: string;
    data?: TrendOverall[];
    error?: boolean;
  },
  regional: {
    isLoading?: boolean;
    interval: string;
    data?: TrendRegional[];
    error?: boolean;
  },
}

const initialState: TrendState = {
  overall: {
    interval: '1',
  },
  regional: {
    interval: '1',
  },
}

export const trendReducer = (state = initialState, action: Action): TrendState => {
  switch (action.type) {
    case getType(actions.getTrendsOverall.request): {
      return {
        ...state,
        overall: {isLoading: true, interval: state.overall.interval}
      }
    }
    case getType(actions.getTrendsOverall.success): {
      return {...state, overall: {data: action.payload, interval: state.overall.interval}}
    }
    case getType(actions.getTrendsOverall.failure): {
      return {...state, overall: {error: true, interval: state.overall.interval}}
    }
    case getType(actions.getTrendsRegional.request): {
      return {
        ...state,
        regional: {isLoading: true, interval: state.regional.interval}
      }
    }
    case getType(actions.getTrendsRegional.success): {
      return {...state, regional: {data: action.payload, interval: state.regional.interval}}
    }
    case getType(actions.getTrendsRegional.failure): {
      return {...state, regional: {error: true, interval: state.regional.interval}}
    }
    case getType(actions.changeTrendsOverallInterval):
      return {...state, overall: {interval: action.payload}}
    case getType(actions.changeTrendsRegionalInterval):
      return {...state, regional: {interval: action.payload}}
    default :
      return state
  }
}
