import {ActionType, getType} from 'typesafe-actions';
import {SubscriptionState} from './types';
import * as _actions from './subscription.actions';
import {getUserProfile} from '../profile/userProfile/userProfile.actions';

const actions = {
  ..._actions,
  getUserProfile,
}
type Actions = ActionType<typeof actions>;

export const subscriptionReducer = (state = {}, action: Actions): SubscriptionState => {
  switch (action.type) {
    case getType(actions.showModal.failure):{
      return {...state, errorMessage:action.payload}
    }
    case getType(actions.showModal.success): {
      return {...state, modalId: action.payload, errorMessage:undefined}
    }
    case getType(actions.getUserProfile.success): {
      return {...state, subscriptionId: action.payload.subscriptionId}
    }
    case getType(actions.changeSubscription.request): {
      return {...state, isLoading: true}
    }
    case getType(actions.changeSubscription.success): {
      return {...state, isLoading: false, success: true}
    }
    case getType(actions.changeSubscription.failure): {
      return {...state, isLoading: false}
    }
    default :
      return state
  }
}
