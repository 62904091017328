import logoBlue from './drkpi-logo-blue.png';
import logo from './drkpi-new-logo.png'
import share from './share.svg';
import logout from './logout.svg';
import chevronDown from './chevron-down.svg';
import chevronUp from './chevron-up.svg';
import de from './de.png';
import en from './en.png';
import hero from './mockup-pagetracker@2x.png';
import devices from './Screen-PageTracker@2x.png'
import login from './open-account-login.png'
import arrowRight from './arrow-right.svg';
import arrowRightWhite from './arrow-right-white.svg';
import questionMark from './question.svg';
import questionMarkGrey from './question-grey.svg';
import arrowDownWhite from './arrow-down-white.svg';
import bars from './bars-solid.svg';
import check from './check-solid.svg';
import profileWhite from './profile-white.svg';
import eyeSlash from './eye-slash.svg';
import eyeRegular from './eye-regular.svg';
import settings from './cogs-solid.svg';
import longArrowDown from './long-arrow-alt-down-solid.svg';
import longArrowUp from './long-arrow-alt-up-solid.svg';
import downloadSolid from './download-solid.svg';
import shareSolid from './share-alt-solid.svg';
import loader from './loader.svg';
import exchange from './exchange-alt-solid.svg';
import money from './money.svg';
import rocket from './rocket.png';
import star from './star-regular.svg';
// import logoPdf from './logo-pdf.png';
import logoPdf from './drkpi-new-logo.png';
import envelope from './envelope-solid.svg';
import home from './home-solid.svg';
import chevronRight from './chevron-right-solid.svg';
import chevronDownDark from './chevron-down-dark.svg';
import logo1 from './logo1.png';
import logo2 from './logo2.png';
import logo3 from './logo3.png';
import logo4 from './logo4.png';
import logo5 from './logo5.png';
import logo6 from './logo6.png';
import times from './times.svg';

export const images = {
  logoBlue,
  logo,
  share,
  logout,
  chevronDown,
  chevronUp,
  en,
  de,
  hero,
  arrowRight,
  questionMark,
  arrowDownWhite,
  questionMarkGrey,
  bars,
  check,
  profileWhite,
  eyeSlash,
  eyeRegular,
  settings,
  longArrowDown,
  longArrowUp,
  downloadSolid,
  shareSolid,
  loader,
  exchange,
  money,
  star,
  logoPdf,
  envelope,
  home,
  chevronRight,
  chevronDownDark,
  arrowRightWhite,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  rocket,
  times,
  login,
  devices,
};

