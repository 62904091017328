import {ActionType, getType} from 'typesafe-actions';
import * as actions from './editPdfReport.actions';
import { PageValues } from './types';

type Action = ActionType<typeof actions>
export interface EditPdfReportState {
  pages:PageValues[];
  savedPages:PageValues[]
  loading:boolean;
  loadingUpdate?:boolean;
  success?:boolean;
}
const initialState:EditPdfReportState={
  pages:[{left:'',right:''},{left:'',right:''},{left:'',right:''},{left:'',right:''},],
  savedPages:[],
  loading:true
}

export const editPdfReportReducer = (state = initialState, action: Action): EditPdfReportState => {
  switch (action.type) {
    case getType(actions.onEditorChange): {
      const {index,value,side}=action.payload;
      const pages = state.pages.map((page, i) => i === index ? {...page,[side]:value} : page)
      return {...state, pages}
    }
    case getType(actions.getPdfFooterData.request): {
      return {...state, loading:true}
    }
    case getType(actions.getPdfFooterData.success): {
      const {pages}=action.payload;
      return {...state, pages, loading:false,savedPages:pages}
    }
    case getType(actions.getPdfFooterData.failure): {
      return {...state, loading:false}
    }
    case getType(actions.updatePdfFooterData.request): {
      return {...state, loadingUpdate:true}
    }
    case getType(actions.updatePdfFooterData.success): {
      const {pages}=action.payload;
      return {...state, loadingUpdate:false,savedPages:pages}
    }
    case getType(actions.updatePdfFooterData.failure): {
      return {...state, loadingUpdate:false}
    }
    
    default :
      return state
  }
}