import {takeLatest, select, put, call, all} from 'redux-saga/effects';
import axios from 'axios';
import {changePassword} from './forgot-password.actions';
import {selectForgotPassword} from '..';
import {handleErrors,validateChangePassword} from "../../../utils/formValidations";
import {apiEndpoints} from "../../../constants/api";

function* onPasswordChange() {
  const {form} = selectForgotPassword(yield select());
  const {email} = form;
  console.log(email, "email");
  
  try {
    validateChangePassword(form);
    yield call(axios.post, apiEndpoints.forgotPassword, {email});
    yield put(changePassword.success())
  } catch (e) {
    yield put(changePassword.failure(handleErrors(e)))
  }
}


export function* forgotPasswordSagas() {
  yield all([
    yield takeLatest(changePassword.request, onPasswordChange)
  ])
}
