interface Plan {
  type: string;
  subscriptionPrice: number;
  quarterPrice: number;
  MwSt: number;
  rake: number;
}

export const plans: Plan[] = [
  {
    type: 'subscription.page-tracker-basic',
    subscriptionPrice: 1600,
    quarterPrice: 350,
    MwSt: 0.077,
    rake: 350,
  },
  {
    type: 'subscription.page-tracker-pro',
    subscriptionPrice: 2200,
    quarterPrice: 800,
    MwSt: 0.077,
    rake: 500,
  },
  {
    type: 'subscription.page-tracker-premium',
    subscriptionPrice: 6600,
    quarterPrice: 1600,
    MwSt: 0.077,
    rake: 1200,
  },
]
