import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {useDispatch, useSelector} from "react-redux";
import {selectAppState} from "../../store/app";
import {ModalWithLogo} from "../../components/ModalWithLogo";
import {CheckBox} from "../../components/ui/CheckBox";
import {Button} from "../../components/ui/Button";
import {changeSubscription, selectSubscriptionState, showModal} from "../../store/subscription";
import {showConfirmModal} from "../../store/app/app.actions";
import {PlanCard} from "./PlanCard";
import {PlanDetails} from "./PlanDetails";
import {SuccessModal} from "./SuccessModal";
import {selectUser} from "../../store/auth";
import {Text} from "../../components/ui/Text";
import {checkSubUserStatus} from "../../store/auth/user";
import {plans} from './constants';
import {NavigationPaths} from "../../constants/navigationPaths";

const cx = classNames.bind(styles);

export const SubscriptionWrapper = () => {
  const {parameters} = useSelector(selectAppState);
  const {subscriptionId, success, modalId, errorMessage} = useSelector(selectSubscriptionState);
  const {isTrial, subscriptionEndDate} = useSelector(selectUser);
  const [terms, agreeTerms] = useState({1: false, 2: false})
  const dispatch = useDispatch();
  const [successModal, toggleSuccessModal] = useState(false)
  const isSubUser = useSelector(checkSubUserStatus)

  useEffect(() => {
    if (success) toggleSuccessModal(true)
  }, [success])
  if (!parameters) return null;
  const {subscriptions} = parameters;

  const closeModal = () => {
    agreeTerms({1: false, 2: false});
    dispatch(showModal.request(undefined))
  }

  const cancelSubscription = (id: number) => {
    dispatch(showConfirmModal({
      title: 'subscription.cancel.confirm',
      onConfirm: () => dispatch(changeSubscription.request(id)),
    }))
  }

  return (
    <div className={cx('subscription-plan', isSubUser && 'subscription-plan--disabled')}>
      <SuccessModal onClose={() => toggleSuccessModal(false)} visible={successModal}/>
      <ModalWithLogo className={cx('modal')} close={closeModal} visible={typeof modalId ==='number'}>
        <h3 className={cx('modal-title')}><Text msg='subscription.plan.order'/></h3>
        <div className={cx('checkbox-row')}>
          <CheckBox
            onChange={() => agreeTerms({...terms, 1: !terms[1]})}
            checked={terms[1]} name='1'
          />
          <p className={cx('check-terms')}>
            <a target="_blank" rel="noreferrer noopener" href={NavigationPaths.dataProtection}><Text msg='subscription.plan.accept-gdpr.p1'/></a>
            <Text msg='subscription.plan.accept-gdpr.p2'/>
          </p>
        </div>
        <div className={cx('checkbox-row')}>
          <CheckBox
            onChange={() => agreeTerms({...terms, 2: !terms[2]})}
            checked={terms[2]} name='2'
          />
          <p className={cx('check-terms')}>
            <a target="_blank" rel="noreferrer noopener" href={NavigationPaths.dateTerms}><Text msg='subscription.plan.accept-terms.p1'/></a>
            <Text msg='subscription.plan.accept-terms.p2'/>
          </p>
        </div>
        <PlanDetails planId={modalId}/>
        <Button
          msg='subscription.plan.confirm-selection'
          className={cx('confirm-button')}
          onClick={() => {
            if (!terms["1"] || !terms["2"]) return;
            if (modalId) dispatch(changeSubscription.request(modalId));
            closeModal();
          }}
        />
      </ModalWithLogo>
      <PlanCard
        disableButtons={isSubUser}
        cardId={0}
        isTrial={isTrial}
        subscriptionId={subscriptionId}
        planName={subscriptions[0].systemName}
        cancelSubscription={cancelSubscription}
        toggleModal={(id) => dispatch(showModal.request(id))}
        yearPrice={plans[0].subscriptionPrice}
        quarterPrice={plans[0].quarterPrice}
        hasErrors={errorMessage === 0}
        subscriptionEndDate={subscriptionEndDate}
        infoUsers='subscription.plan.basic.info-users'
        infoUrls='subscription.plan.basic.info-urls'
      />
      <PlanCard
        disableButtons={isSubUser}
        cardId={1}
        isTrial={isTrial}
        subscriptionId={subscriptionId}
        planName={subscriptions[1].systemName}
        cancelSubscription={cancelSubscription}
        toggleModal={(id) => dispatch(showModal.request(id))}
        yearPrice={plans[1].subscriptionPrice}
        quarterPrice={plans[1].quarterPrice}
        subscriptionEndDate={subscriptionEndDate}
        hasErrors={errorMessage === 1}
        infoUsers='subscription.plan.pro.info-users'
        infoUrls='subscription.plan.pro.info-urls'
      />
      <PlanCard
        disableButtons={isSubUser}
        cardId={2}
        hasErrors={errorMessage === 2}
        isTrial={isTrial}
        subscriptionId={subscriptionId}
        planName={subscriptions[2].systemName}
        cancelSubscription={cancelSubscription}
        toggleModal={(id) => dispatch(showModal.request(id))}
        yearPrice={plans[2].subscriptionPrice}
        quarterPrice={plans[2].quarterPrice}
        subscriptionEndDate={subscriptionEndDate}
        infoUsers='subscription.plan.premium.info-users'
        infoUrls='subscription.plan.premium.info-urls'
      />
    </div>
  );
};
