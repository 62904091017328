import {TableData} from "../../components/RankingsTable";
import {SeoRankOutboundLinksTable, SeoRankBackLinksTable} from './types';

export const mapTableData = (data: SeoRankBackLinksTable[] | SeoRankOutboundLinksTable[]): TableData[] => {
  return data.map((item) => ({
    rank: item.rank,
    valueOne: item.value,
    comparisonLetter: item.comparisonLetter,
    country: item.country,
    valueTwo: item.scoreDach,
    valueThree: item.scoreCountry,
  }))
}

