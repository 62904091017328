import {createAsyncAction, createAction} from 'typesafe-actions';
import {Interval} from '../../components/TableMenu';
import {TrendOverall, TrendRegional} from './types';

export const getTrendsOverall = createAsyncAction(
  'trend/GET_TREND_OVERALL_STARTED',
  'trend/GET_TREND_OVERALL_DONE',
  'trend/GET_TREND_OVERALL_FAILED',
)<void, TrendOverall[], void>()

export const getTrendsRegional = createAsyncAction(
  'trend/GET_TREND_REGIONAL_STARTED',
  'trend/GET_TREND_REGIONAL_DONE',
  'trend/GET_TREND_REGIONAL_FAILED',
)<void, TrendRegional[], void>()

export const changeTrendsOverallInterval = createAction(
  'trend/CHANGE_OVERALL_INTERVAL',
)<Interval>()
export const changeTrendsRegionalInterval = createAction(
  'trend/CHANGE_REGIONAL_INTERVAL',
)<Interval>()

