import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {TableMenu} from '../../components/TableMenu';
import {
  changeRankingsRegionalInterval,
  getRankingsRegional,
} from '../../store/benchmarking/benchmarking.actions';
import {useDispatch, useSelector} from 'react-redux';
import {selectBenchmarking} from '../../store/benchmarking';
import {Interval} from '../../components/TableMenu';
import {TableFooter} from '../../components/TableFooter';
import {RankingsTable, TableData} from '../../components/RankingsTable';
import {apiEndpoints} from '../../constants/api';
import {Loader} from '../../components/ui/Loader';
import {useHasLimitedAccess} from '../../utils/useCheckPermissions';
import {AccessSections} from '../../store/app/types';
import {ErrorMessage} from '../../components/ErrorMessage';
import {selectUser} from '../../store/auth';

const cx = classNames.bind(styles);

export const RankingsRegional = () => {
  const dispatch = useDispatch();
  const {rankingsRegional} = useSelector(selectBenchmarking);
  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['benchmarking-rankings-regional'], user);

  const {interval, isLoading, error, data} = rankingsRegional;

  useEffect(() => {
    dispatch(getRankingsRegional.request());
  }, [interval, dispatch]);

  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeRankingsRegionalInterval(quarter));
  };

  const renderTable = () => {
    if (error) return <ErrorMessage />;
    if (!data || isLoading) return <Loader className={cx('loader')} />;
    const tableData: TableData[] = data.map((item) => ({
      rank: item.rank,
      valueOne: item.websiteUrl,
      comparisonLetter: item.comparisonLetter,
      country: item.country,
      valueTwo: item.blogsTotal,
      valueThree: item.areaOfExpertise,
    }));
    return (
      <>
        <RankingsTable
          tableHeaderTwo="benchmarking.table-heading.points-land"
          tableHeaderThree="benchmarking.table-heading.points-of-subject"
          isLoading={isLoading}
          error={error}
          data={tableData}
        />
        <TableFooter />
      </>
    );
  };

  return (
    <div className={cx('rankings-container')}>
      <TableMenu
        questionMark="benchmarking.title.rankings-regional.question-mark"
        limitedAccess={limitedAccess}
        active={interval}
        downloadApiUrl={apiEndpoints.getRankingsRegional(interval, true)}
        title="benchmarking.title.rankings-regional"
        onIntervalChange={onIntervalChange}
      />
      {renderTable()}
    </div>
  );
};
