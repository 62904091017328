import React, {useEffect} from 'react';
import {Text, Button} from '../../components/ui';
import {NavigationPaths} from '../../constants/navigationPaths';
import {Link} from 'react-router-dom';
import {images} from '../../assets/img';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {DemoModal} from './DemoModal';
import {useDispatch, useSelector} from 'react-redux';
import {homePageState, showDemoModal} from '../../store/home';
import {SuccessDemoModal} from './SuccessDemoModal';
import {FailedDemoModal} from './FailedDemoModal';
import {SecondarySuccessModal} from './SecondarySuccessModal';
import {useLocation, useHistory} from 'react-router-dom';
const cx = classNames.bind(styles);

export const Home = () => {
  const {demoModal, failedModal} = useSelector(homePageState);
  const {search, pathname} = useLocation<{search: string; pathname: string}>();
  const isOpen = new URLSearchParams(search).get('quickCheck');
  const history = useHistory();
  useEffect(() => {
    if (pathname === '/' && search !== '' && isOpen === '') {
      dispatch(showDemoModal(true));
      history.push('/');
    }
  }, []);
  const dispatch = useDispatch();
  return (
    <div className={cx('home-page')}>
      {demoModal && <DemoModal />}
      <SuccessDemoModal />
      <SecondarySuccessModal />
      {failedModal && <FailedDemoModal />}
      <div className={cx('container')}>
        <div className={cx('heading')}>
          <h2 className={cx('drkpi')}>
            <Text msg="landing.drkpi" />
          </h2>
          <h2 className={cx('subtitle')}>
            <Text msg="landing.subtitle" />
          </h2>
          <p className={cx('title')}>
            <span>
              <Text msg="landing.title.p1" />
            </span>
            <span>
              <Text msg="landing.title.p2" />
            </span>
            <span>
              <Text msg="landing.title.p3" />
            </span>
            <span>
              <Text msg="landing.title.p4" />
            </span>
          </p>
        </div>
        <img src={images.devices} className={cx('image')} alt="" />
        <div className={cx('content')}>
          <Link to={NavigationPaths.authentication}>
            <Button
              className={cx('button', 'button--blue')}
              as="button"
              theme="blue"
              onClick={() => null}
              msg="landing.button.free-test"
            />
          </Link>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={NavigationPaths.homePageToPdf}
          >
            <Button
              className={cx('button', 'button--download')}
              msg="landing.button.download"
            />
          </a>
        </div>
      </div>
      <div className={cx('footer')}>
        <p>
          <Text msg="landing.footer1" />
        </p>
        <p>
          <Text msg="landing.footer2" />
        </p>
        <p>
          <Text msg="landing.footer3" />
        </p>
        <p>
          <Text msg="landing.footer4" />
        </p>
        <p>
          <Text msg="landing.footer5" />
        </p>
      </div>
    </div>
  );
};
