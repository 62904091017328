import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from "../../components/ui/Text";
import {TextCard} from "../../components/layout/TextCard";
import {Textarea} from "../../components/ui/Textarea";
import {Button} from "../../components/ui/Button";

const cx = classNames.bind(styles);

interface Props {
  hidden?: boolean;
}
export const WriteReview:React.FC<Props> = ({hidden}) => {
  if(hidden) return null;
  return (
    <div className={cx('review')}>
      <TextCard>
        <h2 className={cx('heading')}><Text msg='profile.write-review'/></h2>
        <p className={cx('review__label')}><Text msg='profile.write-review.label'/></p>
        <Textarea className={cx('review__textarea')} placeholder='profile.write-review.placeholder' value='' onChange={() => null} name={'x'}/>
      </TextCard>
      <Button msg='profile.rating.button' className={cx('review__btn-submit')} />
    </div>
  );
};
