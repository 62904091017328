export enum StorageKeys {
  authToken = 'auth-token',
  adminToken = 'admin-token',
  firstTimeLogin = 'first-time-login',
  colWidths = 'column-widths-3',
}

export const storage = {
  save(key: StorageKeys, data: string) {
    localStorage.setItem(key, data)
  },
  get(key: StorageKeys): string | null {
    return localStorage.getItem(key);
  },
  delete(key: StorageKeys) {
    localStorage.removeItem(key)
  }
}
