import Handsontable from 'handsontable';
import { User } from '../../store/superAdmin/types';
import moment from 'moment';
import { countries } from '../../assets/countries';

export const USER_ID_COL_INDEX = 20;
export const IS_TRIAL_COL_INDEX = 9;
export const IS_DELETED_COL_INDEX = 18;
export const SUBSCRIPTION_ID_COL_INDEX = 8;
export const LAST_BILL_DATE_COL_INDEX = 12;
export const NEXT_BILL_DATE_COL_INDEX = 13;
export const SUBSCRIPTION_END_DATE_COL_INDEX = 14;
export const DELETED_AT_COL_INDEX = 22;
export const COMPANY_COL_INDEX = 1;
export const FIRST_NAME_COL_INDEX = 2;
export const LAST_NAME_COL_INDEX = 3;
export const EMAIL_COL_INDEX = 5;
export const ROLE_COL_INDEX = 4;
export const SUBSCRIPTION_REQUIRES_FOLLOWUP_COL_INDEX = 10;
export const LAST_LOGIN_DATE_COL_INDEX = 10;
export const DELETE_USER_COL_INDEX = 21;

type ColumnSettings = Handsontable.ColumnSettings;

interface ColumnData {
  value?: string | boolean | number;
  fieldName: keyof User | ''; // used as reference
  column: ColumnSettings;
  order: number; // used as reference
}

interface Options {
  item?: User;
  showDeleted?: boolean;
  loginAsSelectedUser?: (userId: string) => void;
  setUnDeleteUser?: (userId: string) => void;
  setDeleteUser?: (userId: string) => void;
  sendReportToUser?: (userId: string) => void;
  sendingReportToUser?: string;
  getCellData: (row: number, col: number) => string;
  subscriptionNames?: { [key: number]: string };
  deleteUser?: (userId: string) => void;
  deletingUser?: string;
}

export const renderRow = (options: Options): ColumnData[] => {
  const item = options.item || ({} as User);
  const loginAsSelectedUser = options.loginAsSelectedUser || null;
  const setUnDeleteUser = options.setUnDeleteUser || null;
  const setDeleteUser = options.setDeleteUser || null;
  const sendReportToUser = options.sendReportToUser || null;
  const getCellData = options.getCellData;
  const sendingReportToUser = options.sendingReportToUser || '';
  const subscriptionNames = options.subscriptionNames || [];
  const showDeleted = options.showDeleted;
  const deleteUser = options.deleteUser || null;
  const deletingUser = options.deletingUser || '';
  return [
    {
      value: item.externalId,
      column: { title: 'User ID', editor: false },
      fieldName: 'externalId',
      order: 0,
    },
    {
      value: item.companyName,
      column: { title: 'Company' },
      fieldName: 'companyName',
      order: 1,
    },
    {
      value: item.firstName,
      column: { title: 'Name' },
      fieldName: 'firstName',
      order: 2,
    },
    {
      value: item.lastName,
      column: { title: 'Surname' },
      fieldName: 'lastName',
      order: 3,
    },
    {
      value:
        item.role === 'admin'
          ? 'super admin'
          : !item.masterAccountId
          ? 'admin'
          : 'user',
      column: {
        title: 'Role',
        type: 'dropdown',
        source: ['user', 'admin', 'super admin'],
      },
      fieldName: 'role',
      order: 4,
    },
    {
      value: item.email,
      column: { title: 'Email address' },
      fieldName: 'email',
      order: 5,
    },
    {
      value: item.pageA,
      column: {
        title: 'URL A',
        editor: false,
        renderer: (instance, td, row, column, prop, value) => {
          const button = document.createElement('A');
          button.textContent = value;
          button.setAttribute('href', value);
          button.setAttribute('target', '_blank');
          button.classList.add('table-link');

          Handsontable.dom.empty(td);
          td.appendChild(button);
        },
      },
      fieldName: 'pageA',
      order: 6,
    },
    {
      value: item.land,
      column: {
        title: 'Country',
        editor: false,
        renderer: (instance, td, row, column, prop, value) => {
          const button = document.createElement('div');
          const country = countries.find(({ name }) => name === value);
          button.textContent = country ? country.code : '';
          Handsontable.dom.empty(td);
          td.appendChild(button);
        },
      },
      fieldName: 'land',
      order: 7,
    },
    {
      value: subscriptionNames[item.subscriptionId] || '',
      column: {
        title: 'Plan',
        type: 'dropdown',
        source: Object.values(subscriptionNames),
      },
      fieldName: 'subscriptionId',
      order: 8,
    },
    {
      value: item.isTrial,
      column: {
        title: 'Trial',
        filter: false,
        editor: false,
        disableVisualSelection: true,
        renderer: (instance, td, row, col) => {
          const input = document.createElement('INPUT');
          input.setAttribute('type', 'checkbox');
          const isTrial = !!getCellData(row, IS_TRIAL_COL_INDEX);
          if (isTrial) input.setAttribute('checked', 'on');
          input.classList.add('table-checkbox');
          input.addEventListener('click', () => {
            instance.setDataAtCell(row, col, !isTrial);
          });
          Handsontable.dom.empty(td);
          td.appendChild(input);
        },
      },
      fieldName: 'isTrial',
      order: 9,
    },
    {
      value: item.subscriptionRequiresFollowUp,
      column: {
        title: 'Follow up',
        filter: false,
        editor: false,
        disableVisualSelection: true,
        renderer: (instance, td, row, col) => {
          const input = document.createElement('INPUT');
          input.setAttribute('type', 'checkbox');
          const isFollowed = !!getCellData(
            row,
            SUBSCRIPTION_REQUIRES_FOLLOWUP_COL_INDEX
          );
          if (isFollowed) input.setAttribute('checked', 'on');
          input.classList.add('table-checkbox');
          input.addEventListener('click', () => {
            const followed = !!getCellData(
              row,
              SUBSCRIPTION_REQUIRES_FOLLOWUP_COL_INDEX
            );
            instance.setDataAtCell(row, col, !followed);
          });
          Handsontable.dom.empty(td);
          td.appendChild(input);
        },
      },
      fieldName: 'subscriptionRequiresFollowUp',
      order: 10,
    },
    {
      value: moment(item.signUpDate).format('YYYY-MM-DD'),
      column: {
        editor: false,
        title: 'Sign-up',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
      },
      fieldName: 'signUpDate',
      order: 11,
    },
    {
      value: item.lastBillDate
        ? moment(item.lastBillDate).format('YYYY-MM-DD')
        : '',
      column: {
        title: 'Last bill',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
      },
      fieldName: 'lastBillDate',
      order: 12,
    },
    {
      value: item.nextBillDate
        ? moment(item.nextBillDate).format('YYYY-MM-DD')
        : '',
      column: {
        title: 'Next bill',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
      },
      fieldName: 'nextBillDate',
      order: 13,
    },
    {
      value: moment(item.subscriptionEndDate).format('YYYY-MM-DD'),
      column: {
        title: 'Plan end',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
      },
      fieldName: 'subscriptionEndDate',
      order: 14,
    },
    {
      value: item.lastReportSentDate
        ? moment(item.lastReportSentDate).format('YYYY-MM-DD')
        : '',
      column: {
        title: 'Last Report Sent',
        type: 'date',
        editor: false,
        dateFormat: 'YYYY-MM-DD',
      },
      fieldName: 'lastReportSentDate',
      order: 15,
    },
    {
      value: item.lastLoginDate
        ? moment(item.lastLoginDate).format('YYYY-MM-DD')
        : '',
      column: {
        title: 'Last login',
        type: 'date',
        dateFormat: 'YYYY-MM-DD',
        editor: false,
      },
      fieldName: 'lastLoginDate',
      order: 16,
    },
    {
      value: 'Login',
      column: {
        title: 'Login',
        filter: false,
        editor: false,
        disableVisualSelection: true,
        renderer: (instance, td, row) => {
          const button = document.createElement('BUTTON');
          button.textContent = 'Login';
          button.classList.add('table-button-login');
          button.addEventListener('click', () => {
            const userId = getCellData(row, USER_ID_COL_INDEX);
            loginAsSelectedUser!(userId || '');
          });
          Handsontable.dom.empty(td);
          td.appendChild(button);
        },
      },
      fieldName: '',
      order: 17,
    },
    {
      value: 'Archive',
      column: {
        title: 'Archive',
        filter: false,
        editor: false,
        disableVisualSelection: true,
        renderer: (instance, td, row) => {
          const input = document.createElement('INPUT');
          input.setAttribute('type', 'checkbox');
          if (showDeleted) input.setAttribute('checked', 'on');
          input.classList.add('table-checkbox');
          input.addEventListener('click', () => {
            const userId = getCellData(row, USER_ID_COL_INDEX);
            if (!userId) return console.log('Unable to get user Id', row);
            showDeleted
              ? setUnDeleteUser!(userId || '')
              : setDeleteUser!(userId || '');
          });
          Handsontable.dom.empty(td);
          td.appendChild(input);
        },
      },
      fieldName: 'isDeleted',
      order: 18,
    },
    {
      value: 'Send report',
      column: {
        title: 'Send Report',
        filter: false,
        editor: false,
        disableVisualSelection: true,
        renderer: (instance, td, row) => {
          const userId = getCellData(row, USER_ID_COL_INDEX);
          const button = document.createElement('BUTTON');
          button.textContent =
            sendingReportToUser && userId && sendingReportToUser === userId
              ? 'In progress..'
              : 'Send Report';
          button.classList.add('table-button-login');
          button.addEventListener('click', () => {
            const id = getCellData(row, USER_ID_COL_INDEX);
            if (sendingReportToUser) return;
            sendReportToUser!(id || '');
          });
          Handsontable.dom.empty(td);
          td.appendChild(button);
        },
      },
      fieldName: '',
      order: 19,
    },
    {
      value: item.userId,
      column: { title: 'User Id', editor: false },
      fieldName: 'userId',
      order: 20,
    },
    {
      value: 'Delete user',
      column: {
        title: 'Delete User',
        filter: false,
        editor: false,
        disableVisualSelection: true,
        renderer: (instance, td, row) => {
          const userId = getCellData(row, USER_ID_COL_INDEX);
          const button = document.createElement('BUTTON');
          button.textContent =
            deletingUser && userId && deletingUser === userId
              ? 'In progress..'
              : 'Delete definitely';
          button.classList.add('table-button-login');
          button.addEventListener('click', () => {
            const id = getCellData(row, USER_ID_COL_INDEX);
            if (deletingUser) return;
            deleteUser!(id || '');
          });
          Handsontable.dom.empty(td);
          td.appendChild(button);
        },
      },
      fieldName: '',
      order: 21,
    },
    {
      value: item.deletedAt ? moment(item.deletedAt).format('YYYY-MM-DD') : '',
      column: { title: 'Archived on', editor: false },
      fieldName: 'deletedAt',
      order: 22,
    },
  ];
};

// Simple column order index validation compared to constants (production only);
export const validateTableFields = (data: ColumnData[]) => {
  const err = 'Incorrect table order or contant value';
  const values: Partial<{ [key in keyof User]: any } & { '': any }> = {};
  data.forEach((item, index) => {
    if (item.order !== index) throw new Error('Incorrect order');
    values[item.fieldName] = index;
  });
  if (values.userId !== USER_ID_COL_INDEX) throw new Error(err);
  if (values.isTrial !== IS_TRIAL_COL_INDEX) throw new Error(err);
  if (values.isDeleted !== IS_DELETED_COL_INDEX) throw new Error(err);
  if (values.subscriptionId !== SUBSCRIPTION_ID_COL_INDEX) throw new Error(err);
  if (values.lastBillDate !== LAST_BILL_DATE_COL_INDEX) throw new Error(err);
  if (values.nextBillDate !== NEXT_BILL_DATE_COL_INDEX) throw new Error(err);
  if (values.subscriptionEndDate !== SUBSCRIPTION_END_DATE_COL_INDEX)
    throw new Error(err);
  if (values.deletedAt !== DELETED_AT_COL_INDEX) throw new Error(err);
  if (values.companyName !== COMPANY_COL_INDEX) throw new Error(err);
  if (values.firstName !== FIRST_NAME_COL_INDEX) throw new Error(err);
  if (values.lastName !== LAST_NAME_COL_INDEX) throw new Error(err);
  if (values.email !== EMAIL_COL_INDEX) throw new Error(err);
  if (
    values.subscriptionRequiresFollowUp !==
    SUBSCRIPTION_REQUIRES_FOLLOWUP_COL_INDEX
  )
    throw new Error(err);
  if (values.role !== ROLE_COL_INDEX) throw new Error(err);
};
