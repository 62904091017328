import React from 'react';
import {getCountryFlag} from '../../utils/getCountryFlag';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from '../ui/Text';
import {syncFailCodes} from '../../constants/codes';
import {HoverPopup} from '../ui/HoverPopup';

const cx = classNames.bind(styles);

interface Props {
  isLoading?: boolean;
  error?: boolean | string;
  tableHeaderTwo: string;
  tableHeaderThree: string;
  data?: TableData[];
  pdfExport?: boolean;
}

export interface TableData {
  comparisonLetter: string;
  rank: string;
  valueOne?: string;
  country: string;
  valueTwo: string;
  valueThree: string;
}

export const RankingsTable = (props: Props) => {
  const {error, isLoading, pdfExport, data, tableHeaderThree, tableHeaderTwo} = props;

  const renderTable = () => {
    if (error) return <div>Error</div>;
    if (isLoading || !data) return <div className={cx('loader')}>Loading...</div>;

    return data.map((report, i) => {
      return (
        <div className={cx('row', `row__${report.comparisonLetter}`, pdfExport && 'row--pdf')} key={i}>
          <span className={cx('rank')}>{report.rank ? report.rank + '.' : ' '}</span>
          {report.valueOne &&
          report.valueOne.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
          ) ? (
            <a
              className={cx('website', 'websiteHover')}
              href={report.valueOne}
              target="_blank"
              rel="noopener noreferrer">
              {report.valueOne}
            </a>
          ) : (
            <span className={cx('website')}>{report.valueOne}</span>
          )}
          <span className={cx('letter')}>{report.comparisonLetter}</span>
          {/*<img src={getCountryFlag(report.country)} className={cx('flag')} alt=""/>*/}
          <div className={cx('flag')}>
            <div className="f32">
              <div className={`flag ${getCountryFlag(report.country)}`} title={report.country} />
            </div>
          </div>
          <span className={cx('totals', pdfExport && 'totals--pdf')}>
            {report.valueTwo}
            <HoverPopup
              className={cx('popup')}
              visible={!!syncFailCodes[report.valueTwo]}
              text={syncFailCodes[report.valueTwo]}
            />
          </span>
          <span className={cx('totals')}>
            {report.valueThree}
            <HoverPopup
              className={cx('popup')}
              visible={!!syncFailCodes[report.valueThree]}
              text={syncFailCodes[report.valueThree]}
            />
          </span>
        </div>
      );
    });
  };

  return (
    <div className={cx('container', pdfExport && 'container--pdf')}>
      <div className={cx('table')}>
        <div className={cx('table-headings')}>
          <span />
          <span />
          <span />
          <span className={cx('th-4')}></span>
          <span className={cx('th-5', pdfExport && 'th-5--pdf')}>
            <Text msg={tableHeaderTwo} />
          </span>
          <span className={cx('th-6', pdfExport && 'th-6--pdf')}>
            <Text msg={tableHeaderThree} />
          </span>
        </div>
        {renderTable()}
      </div>
    </div>
  );
};
