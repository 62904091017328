import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from '../ui/Text';
import {HoverPopup} from '../ui/HoverPopup';
import {syncFailCodes} from '../../constants/codes';
import {GraphColors} from '../../constants/constants';

const cx = classNames.bind(styles);

const colors = [GraphColors.color1, GraphColors.color2, GraphColors.color3, GraphColors.color4];

export interface BarChartData {
  letter: string;
  value: string;
  color?: string;
}

interface Props {
  data: BarChartData[];
  letterALabel?: string;
  pdfExport?: boolean;
}

export const BarChart = (props: Props) => {
  const {data, letterALabel, pdfExport} = props;
  let max = 0;
  data.forEach((item) => {
    const value = parseFloat(item.value);
    if (value > max) max = value;
  });

  const bars = data.map((item, i) => {
    const hasNoValue = item.value === '0' || item.value === 'k.A.';
    const background = hasNoValue ? '#666' : colors[i] || '#D0D0D0';
    let height = hasNoValue ? 2 : (110 * parseFloat(item.value)) / max + 'px';
    const median = (
      <span>
        Median
        <br />
        D-A-CH
      </span>
    );
    const brandingLetterText = <Text msg={letterALabel && i === 0 ? letterALabel : 'branding.letter'} />;
    const renderLetter = () => {
      if (item.letter === 'MEDIAN') return median;
      if (i === 0 && letterALabel) return '';
      return item.letter;
    };
    return (
      <div className={cx('bar')} key={i}>
        {syncFailCodes[item.value] && (
          <HoverPopup text={syncFailCodes[item.value]} visible className={cx('popup')} />
        )}
        <p className={cx('value')}>{item.value}</p>
        <div className={cx('line')} style={{background, height}} />
        <p className={cx('letter', pdfExport && 'letter--pdf')}>
          {item.letter !== 'MEDIAN' ? brandingLetterText : ''}
          <br />
          {renderLetter()}
        </p>
      </div>
    );
  });
  return <div className={cx('chart', pdfExport && 'chart--pdf')}>{bars}</div>;
};
