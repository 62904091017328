export const text: { [key: string]: string } = {
  // COMMON
  'common.ms': 'Frau',
  'common.mr': 'Herr',
  'common.various': 'Keine Angaben',
  'common.yes': 'Ja',
  'common.no': 'Nein',
  'common.ok': 'OK',
  'common.data.error': 'Failed to load the data',
  'common.expired-session': 'Your session has expired',
  'common.expired-session-desc': 'Please login to continue your work',
  'common.success': 'Ihre Angaben wurden gespeichert.',
  'common.loading': 'Wird geladen..',
  'common.not-available-fot-this-plan':
    'Ihr Abo bietet diese Funktion nicht an.',
  'common.save': 'Senden',
  'common.confirm': 'Bestätigen',
  'common.verification': ' ',

  // EMPLOYEES
  'employees.5-50': '2-50',
  'employees.51-250': '51-250',
  'employees.251-1000': '251-1000',
  'employees.1000+': 'mehr als 1000',

  // COMPONENTS

  'component.table.menu.last90days': 'Vergangene 90 Tage',
  'component.table.menu.last12months': 'Jahr 1',
  'component.table.menu.last24months': 'Jahr 2',

  // TABLE MENU
  'table-menu.button1': 'Vergangene 90 Tage',
  'table-menu.button2': 'Jahr 1',
  'table-menu.button3': 'Jahr 2',
  'table-menu.share-not-available': 'Funktion noch nicht verfügbar',

  // SYNC CODES
  'sync.crawler-not-available': 'Keine Angaben, da Crawler keine Infos erhält.',
  'sync.post-info-hidden':
    'Unser Crawler konnte keine Daten sicherstellen, da diese hinter Javascript versteckt sind.',
  'sync.youngest-post-older-then-90':
    'Der neueste Eintrag ist älter als 90 Tage.',
  'sync.post-not-deleted':
    'Einträge sind nicht datiert und wir können nicht ermitteln, was wann gepostet wurde.',
  'sync.page-archived':
    'Die Seite wurde wurde vom Owner offline genommen oder geändert.',
  'sync.website-indexing-disabled':
    'Die Website erlaubt es unseren Bots oder Crawlern nicht, sie zu indexieren.',
  'sync.test':
    'Der URL wird erst getestet und wird noch nicht vollständig dargestellt.',

  // ERRORS
  'error.any': 'Unerwarteter Fehler',
  'error.invalid-code': 'Falscher Code',
  'error-confirm-password-required': 'Kennwort',
  'error-passwords-dont-match': 'Passwörter stimmen nicht überein',
  'error-email-required': 'Email',
  'error-email-required.2': 'E-Mail:*',
  'error-invalid-email': 'Ungültige E-Mail',
  'error-invalid-password': 'Ungültiges Passwort',
  'error-invalid-phone': 'Ungültige Telefonnummer',
  'error-tel-required': 'Telefon',
  'error-name-required': 'Vorname:*',
  'error-invalid-isin': 'Ungültige ISIN',
  'error-surname-required': 'Name:*',
  'error-surname-required.2': 'Nachname:*',
  'error-password-required': 'Kennwort',
  'error-status-required': 'Ansprache',
  'error-region-required': 'Land/Region',
  'error-land-required': 'Land:*',
  'errors.registration': 'Es ist oben ein Fehler aufgetreten',
  'error-company-required': 'Unternehmen:*',
  'error-website-required': 'Website/Blog',
  'error-zip-code-required': 'PLZ:*',
  'error-city-required': 'Stadt:*',
  'error-street-nr-required': 'Strasse, Nr.:*',
  'error-validation-required': ' ',
  'error-agree-terms-conditions': 'Sie müssen den Bedingungen zustimmen',
  'error-contact-support':
    'Hoppla, wir haben einige Probleme. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.',
  'error-app':
    'Hoppla, wir haben einige Probleme. Bitte versuchen Sie es erneut oder wenden Sie sich an den Support.',
  'errors-register-first': 'Bitte registrieren Sie sich zuerst',
  'error-email-exist':
    'Diese E-Mailadresse kann für die Anmeldung nicht mehr genutzt werden.',
  'Incorrect username or password.': 'Incorrect username or password.',
  'Such user already exists.': 'Such user already exists.',
  'Canceled by user': '-',

  'error.website-invalid': 'Website',
  'error.category-required': 'Kategorie',
  'error.subCategory-required': 'Unterkategorie',
  'error.includeComments-required': 'Kommentare einschließen',
  'error.isBeingAdvertised-required': 'Wird beworben',
  'error.numberOfEmployees-required': 'Anzahl der Beschäftigten',

  // ERROR CODES

  API_ERROR: 'API_ERROR:',
  ERROR: 'ERROR',
  EMAIL_REGISTERED: 'EMAIL_REGISTERED',
  EMAIL_ALREADY_SENT: 'EMAIL_ALREADY_SENT',
  TOKEN_EXPIRED: 'TOKEN_EXPIRED',
  EXTERNAL_SERVER_ERROR: 'EXTERNAL_SERVER_ERROR',
  FAILED_TO_VERIFY_TOKEN: 'FAILED_TO_VERIFY_TOKEN',
  INCORRECT_CREDENTIALS: 'INCORRECT_CREDENTIALS',
  NOT_REGISTERED: 'NOT_REGISTERED',
  INCORECT_OLD_PASSWORD: 'INCORECT_OLD_PASSWORD',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  INSUFFICINT_PERMISIONS: 'INSUFFICINT_PERMISIONS',
  NO_TOKEN: 'NO_TOKEN',
  RECAPTCHA_VERIFICATION_FAILED: 'RECAPTCHA_VERIFICATION_FAILED',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  NOT_FOUND: 'NOT_FOUND',
  UNAUTHORIZED: 'UNAUTHORIZED',
  ENTERED_NAME_TOO_LONG: 'ENTERED_NAME_TOO_LONG',
  ENTERED_LASTNAME_TOO_LONG: 'ENTERED_LASTNAME_TOO_LONG',
  ENTERED_PHONENUMBER_TOO_LONG: 'ENTERED_PHONENUMBER_TOO_LONG',
  ENTERED_COUNTRY_TOO_LONG: 'ENTERED_COUNTRY_TOO_LONG',
  ENTERED_COMPANY_NAME_TOO_LONG: 'ENTERED_COMPANY_NAME_TOO_LONG',
  ENTERED_STATUS_TOO_LONG: 'ENTERED_STATUS_TOO_LONG',
  ENTERED_WEBSITE_TOO_LONG: 'ENTERED_WEBSITE_TOO_LONG',

  // TOP MENU
  'top-menu.title': 'MESSEN WAS ZÄHLT',
  'top-menu.about': 'AGB',
  'top-menu.wiki': 'Wiki',
  'top-menu.faq': 'FAQ',
  'top-menu.login': 'Login',
  'top-menu.logout': 'LOGOUT',
  'top-menu.logout2': 'Abmelden',
  'top-menu.switch-to-admin': 'Wechseln Sie zu Admin',
  'top-menu.kontakt': 'Kontakt',
  'top-menu.testversion': 'Testversion',
  'top-menu.profile': 'Profil bearbeiten',
  'top-menu.subscription': 'Abonnement',
  'top-menu.export-settings': 'Bericht einrichten',
  'top-menu.super-admin': 'Super admin',
  'mobile-menu.title.home': 'Home',
  'mobile-menu.title.settings': 'Einstellungen',
  'mobile-menu.title.profile': 'Profil',
  // SIDE MENU (settings)
  'menu.settings': 'Start',
  'menu.login.title': 'ANMELDEN',
  'menu.login.desc': 'Konto erstellen',
  'menu.profile.title': 'PROFIL',
  'menu.profile.desc': 'Abo / Leistungen',
  'menu.pagetracker.title': 'PAGETRACKER',
  'menu.pagetracker.desc': 'Community-/News-Page',
  'menu.compare.title': 'VERGLEICHE',
  'menu.compare.desc': 'Ihre Benchmarking-Daten',
  'menu.report.title': 'BERICHTE',
  'menu.report.desc': 'Gestaltung / Format',
  'menu.archive.title': 'ARCHIV',
  'menu.archive.desc': 'Berichte',
  // SIDE MENU (home)
  'menu.home': 'Einstellungen',
  'menu.benchmarking.title': 'BENCHMARKING',
  'menu.benchmarking.desc': 'Positionierung im Ranking',
  'menu.trendanalyse.title': 'TRENDANALYSE',
  'menu.trendanalyse.desc': 'Wettbewerbsfähigkeit',
  'menu.branding.title': 'BRANDING',
  'menu.branding.desc': 'Markenpräsenz und Bekanntheit',
  'menu.seo.title': 'SEO',
  'menu.seo.desc': 'Analyse der Linkprofile',
  'menu.engagement.title': 'ENGAGEMENT',
  'menu.engagement.desc': 'Einfluss und Vertrauen',
  'menu.visuals-seo.title': 'Visuals',
  'menu.visuals-seo.desc': 'Gestaltung und Usability',
  'menu.roi.title': 'ROI',
  'menu.roi.desc': 'Handlungsempfehlung',
  'menu.pdfReportEdit.title': 'Edit PDF report',
  'menu.pdfReportEdit.desc': 'TEST',
  'menu.sectionTextEdit.desc': 'Bearbeitung',
  'menu.sectionTextEdit.title': 'Abschnittstext',

  // SIDE MENU QUESTION MARKS
  'questionmark.benchmarking':
    '<h6>Ihre Positionierung auf dem digitalen Markt. </h6><p>Wie gut performt Ihre URL' +
    ' im Vergleich zur Konkurrenz?</p><p>Anhand von diversen relevanten Kennzahlen (KPIs) indexieren wir Beiträge' +
    ' auf News Pages, Corporate Blogs, Online-Magazinen oder ähnlichen dynamische Seiten für die regelmässige' +
    ' Publikation von Online-Artikeln.</p><p>Webpages werden demselben Fachgebiet zugeordnet, wenn 60% der Beiträge' +
    ' oder mehr zu demselben Thema verfasst wurden.</p><small></small><p><strong>Berechnung:</strong> Gesammelte Daten werden' +
    ' in einen' +
    ' z-Wert' +
    ' konvertiert. Mithilfe dieses standardisierten Werts in absoluten Zahlen können wir die Webpages auf einer Skala von 1 bis 100 einordnen. Dabei wird die höchste Punktzahl (100) an den besten Blog vergeben. 50 bildet dabei den Mittelwert. Mehrere URLs können die gleiche Punktzahl erreichen und sich einen Rangplatz teilen.\n</p>',
  'questionmark.trendanalyse':
    '<h6>Ihre Wettbewerbsfähigkeit im Langzeitvergleich. </h6><p>Wie entwickelt sich die' +
    ' Performance Ihrer URL im Vergleich zur Konkurrenz?</p><p>Anhand von diversen relevanten Kennzahlen (KPIs)' +
    ' indexieren wir Beiträge auf News Pages, Corporate Blogs, Online-Magazinen oder ähnlichen dynamische Seiten für' +
    ' die regelmässige Publikation von Online-Artikeln.</p><p>Diese sortieren wir dann nach Fachgebieten, um sie' +
    ' vergleichbar zu machen und sie anschliessend in aussagekräftige Rankings für die gesamte DACH-Region und für' +
    ' einzelne Länder einzuordnen.</p><p>Webpages werden demselben Fachgebiet zugeordnet, wenn 60% der Beiträge oder' +
    ' mehr zu demselben Thema verfasst wurden.</p><small></small><p><strong>Berechnung:</strong> Gesammelte Daten werden in' +
    ' einen z-Wert' +
    ' konvertiert. Mithilfe dieses standardisierten Werts in absoluten Zahlen können wir die Webpages auf einer Skala von 1 bis 100 einordnen. Dabei wird die höchste Punktzahl (100) an den besten Blog vergeben. 50 bildet dabei den Mittelwert. Mehrere URLs können die gleiche Punktzahl erreichen und sich einen Rangplatz teilen.</p>',
  'questionmark.branding':
    '<p><strong>Markenpräsenz und Bekanntheit in der digitalen Sphäre.</strong></p><p>Wie bekannt sind Ihre Firmennamen, Ihre Marken- und Produktnamen sowie Branded Hashtags? Wie präsent sind diese auf anderen News Pages, Blogs, in Leserkommentaren, etc. im Vergleich zu den Hashtags, Marken und Namen Ihrer Konkurrenten?</p><small></small><p><strong>Berechnung:</strong> Gesammelte Daten werden in einen z-Wert konvertiert. Mithilfe dieses standardisierten Werts in absoluten Zahlen können wir die Webpages auf einer Skala von 1 bis 100 einordnen. Dabei wird die höchste Punktzahl (100) an den besten Blog vergeben. 50 bildet dabei den Mittelwert. Mehrere URLs können die gleiche Punktzahl erreichen und sich einen Rangplatz teilen.</p>',

  'questionmark.seo':
    '<p><strong>Die technische Seite der Suchmaschinenoptimierung (SEO):</strong> Vergleich der Linkprofile von News Pages, Blogs, Online-Magazinen u.ä., die' +
    ' Suchmaschinen wie als eines der essentiellsten Kriterien nutzen, um die Qualität ihrer Inhalte und ihre' +
    ' Autorität zu einem Thema abschätzen zu können und Sie im Ranking der Suchergebnisse zu positionieren.' +
    ' </p><p>Grundlegend sind dabei die folgenden Fragen:</p><ol type="1"><li><strong>Analyse von Backlinks</strong> (=' +
    ' Rückverweise/Links von' +
    ' anderen Seiten aus dem Web, die zu Ihren Inhalten verlinken): Wie viele Links bekommen Sie von anderen' +
    ' Webseiten im Netz im Vergleich zur Konkurrenz?\n</li><li><strong>Analyse von Outbound Links</strong> (= externe Links in den eigenen' +
    ' Online-Beiträgen, die zu anderen Seiten im Netz verlinken): Wie viele Links, die zu anderen Seiten im Internet' +
    ' verweisen, nutzen Sie im Vergleich zur Konkurrenz? </li></ol></p><small></small><p><strong>Berechnung:</strong> Gesammelte Daten werden in einen z-Wert konvertiert. Mithilfe dieses standardisierten Werts in absoluten Zahlen können wir die Webpages auf einer Skala von 1 bis 100 einordnen. Dabei wird die höchste Punktzahl (100) an den besten Blog vergeben. 50 bildet dabei den Mittelwert. Mehrere URLs können die gleiche Punktzahl erreichen und sich einen Rangplatz teilen.</p>',

  'questionmark.engagement':
    '<p>Wie viel Einfluss und Vertrauen kann Ihrem Firmenblog, Ihrer News Page o.ä.' +
    ' zugesprochen werden im Vergleich zu anderen Content-Pages? Werden Leser angesprochen und motiviert, sich mit' +
    ' Leserkommentaren zu beteiligen? Erzeugen Ihre Inhalte Resonanz und findet ein Dialog statt?</p><p>Gezählt und bewertet werden Kommentare mit mehr als 20 Wörtern und Autorenantworten.</p>',

  'questionmark.visuals':
    '<p><strong>Die visuell-orientierte Seite der Suchmaschinenoptimierung (SEO):</strong> Vergleich der visuellen Attraktivität, Gestaltung und Struktur von News Pages,' +
    ' Blogs, Online-Magazinen u.ä., die vor allem die Nutzerfreundlichkeit (Usability) von digitalen Inhalten' +
    ' betreffen. </p><p>Auch Suchmaschinen wie Google arbeiten hart daran, diese Dinge zu analysieren und zu' +
    ' bewerten, um die User Experience für Nutzer zu verbessern, indem Suchenden bevorzugt Seiten mit hoher' +
    ' Usability angezeigt werden.</p><p>Grundlegende Fragen bei der Bewertung der nutzerfreundlichen Gestaltung' +
    ' sind:</p><ol><li><strong>Der Einsatz von “Rich Media”</strong> (= Verwendung von visuellen Medien, wie Bildmaterial, Fotos oder Grafiken, aber auch' +
    ' Videos in Beiträgen): Wie viel Bildmaterial verwenden Sie zur Gestaltung Ihrer Online-Beiträge im Vergleich' +
    ' zur Konkurrenz?</li><li><strong>Die sinnvolle Struktur der Inhalte</strong> (=die richtige Verwendung der Überschriften und Zwischenüberschriften in' +
    ' Beiträgen): Wie viele Überschriften verwenden Sie zur Strukturierung Ihrer Online-Beiträge im Vergleich zur Konkurrenz?</li></ol>',

  'questionmark.roi':
    '<p>Wo befindet sich Ihr grösstes Verbesserungspotenzial?<br>' +
    'Um die Effektivität der Texte und Inhalte zu verbessern, gilt es, die schlüsselrelevanten Kennzahlen zu analysieren. Anhand dieser sollten Sie die Änderungen vornehmen, um die grösste Wirkung zu erzielen.</p>',

  'questionmark.pdfReportEdit': '<p>Demo text</p>',
  'questionmark.sectionTextEdit':
    '<p>Abschnittstext erstellen und bearbeiten</p>',

  'questionmark.login':
    'Hier können Sie sich einloggen oder ein neues Konto erstellen.',
  'questionmark.profile':
    'Hier können Sie Ihre Nutzerdaten und weitere Details zu Ihrem Profil einsehen, überprüfen und bearbeiten.',
  'questionmark.page-tracker':
    'Einstellungen unseres Blogs oder Seite, die wir untersucht haben möchten.',
  'questionmark.compare':
    'Hier können Sie die eingetragenen Daten zu Ihrer URL (Firmenblog, News Page, o.ä.) und die eingetragenen Daten der Seiten, mit denen Sie sich vergleichen wollen, einsehen und ändern.',
  'questionmark.report':
    'Einstellungen zu Ihrem Bericht. Hier legen Sie fest, wie der Bericht geliefert wird.' +
    ' Default ist als PDF Datei alle 30 Tage. Im Bericht wird die Standardansicht der Tabellen abgebildet. Versionen Pro und Premium können das Aussehen, das Format und die Häufigkeit der Berichts ändern.',

  'questionmark.archive':
    'Diese Ansicht zeigt die bisher abgerufenen und archivierten Berichte.',

  // LANDING PAGE
  'landing.drkpi': 'DrKPI® PageTracker',
  'landing.title.p1': 'Sie wollen den Erfolg Ihrer Corporate Communication ',
  'landing.title.p2':
    'messen und Ihren ROI erhöhen? Ihre Website vergleichen, ',
  'landing.title.p3': 'optimieren, und beim Reporting überzeugen? ',
  'landing.title.p4': 'Auf Seite 1 von Google? ',
  'landing.subtitle': 'SEO, Content Marketing und Branding',
  'landing.info':
    'Vergleichen Sie News & Blog Pages jetzt, um Ihren ROI effektiv zu steigern. Mit genau den Daten, die Sie brauchen.',
  'landing.button.free-test': '30 Tage kostenlos testen*',
  'landing.button.download': 'Infobroschüre (PDF)',
  'landing.button.demo': 'QuickCheck Ihrer Seite',
  'landing.logos-footer':
    '... viele weitere Kunden verlassen sich auf uns. Sie auch?',
  'landing.footer1': 'Trendanalyse',
  'landing.footer2': 'SEO Benchmarks',
  'landing.footer3': 'Branding Metrics',
  'landing.footer4': 'Community Marketing',
  'landing.footer5': 'Usability und Design',

  // LOGIN
  'login.title': 'Einloggen',
  'login.already-have-an-account':
    'Sie haben bereits ein Konto? (* = Pflichtfelder)',
  'login.label.email': 'E-Mail Adresse *',
  'login.label.password': 'Kennwort *',
  'login.or': 'Oder Login mit:',
  'login.with.facebook': 'Login with Facebook',
  'login.with.apple': 'Login with Apple',
  'login.with.google': 'Login with Google',
  'login.button.send': 'Login',
  'login.check-email.p1':
    'E-Mail Adresse eintragen oder legen Sie ein neues Konto an.',
  'login.forgot-password': 'Kennwort vergessen',

  // SUB-USER
  'sub-user.title': 'Nutzer Zugang bestätigen',
  'sub-user.subtitle': 'Sie brauchen noch ein Kennwort',
  'sub-user.login.error': 'User not found',

  // REGISTER
  'register.title': 'Konto erstellen',
  'register.button.send': 'Registrieren Sie mich jetzt',
  'register.new-user': 'Neuer Benutzer? (* = Pflichtfelder)',
  'register.label.email': 'E-Mail Adresse:*',
  'register.label.first-name': 'Vorname:*',
  'register.label.last-name': 'Nachname:*',
  'register.label.status': 'Anrede:*',
  'register.label.password': 'Kennwort *',
  'register.label.repeat-password': 'Kennwort Wiederholung *',
  'register.label.region': 'Land/Region *',
  'register.label.company': 'Unternehmen *',
  'register.label.terms.p1.linkText': 'Datenschutzregeln',
  'register.label.terms.p1.linkText2': ' gemäss DSGVO akzeptieren. *',
  'register.label.terms.p2.linkText': 'AGB',
  'register.label.terms.p2.linkText2':
    ' zur Nutzung von PageTracker akzeptieren. *',
  'register.label.terms.p3':
    'Ja, ich bin in Kenntnis und damit einverstanden, dass die Verarbeitung der' +
    ' personenbezogenen Daten teilweise in den USA stattfinden kann.*',
  'register.label.terms.p4':
    'Ich möchte die #drkpiPageTracker Web-basierten Services nutzen. Ich bin einverstanden Infos (z.B. Reports) per E-Mail zugesendet zu bekommen.*',
  'register.bottom-text':
    'Bitte checken Sie Ihre E-Mail Inbox und bestätigen Sie Ihre E-Mail Adresse, indem Sie den' +
    ' mitgesandten URL anklicken.',

  // FORGOT PASSWORD
  'forgot-password.title': 'Geben Sie bitte Ihre Email-Adresse ein',
  'forgot-password.email': 'E-Mail-Adresse:',
  'forgot-password.connect-again': 'Nochmals einloggen',
  'forgot-password.change-password': 'Kennwort ändern',
  'forgot-password.success.title': 'Bestätigung der Änderung!',
  'forgot-password.success.text.p1': 'Wir haben Ihnen eine E-Mail gesandt.',
  'forgot-password.success.text.p2':
    'Bitte klicken Sie auf den URL in dieser E-Mail.',
  'forgot-password.success.text.p3':
    'Wenn Sie keine E-Mail erhalten haben, bitte checken Sie im Spam.',
  'forgot-password.success.no-email.p1': 'Sie haben kein E-Mail erhalten?',
  'forgot-password.success.no-email.p2': 'Bitte melden Sie sich bei uns.',

  // CONFIRM EMAIL
  'confirm-email.desc':
    'Bitte bestätigen Sie Ihre Email Adresse. Geben Sie den 6-stelligen Code ein, den wir Ihnen senden.',
  'confirm-email.confirm': 'Bestätigen',
  'confirm-email.confirmation-code': 'Bestätigungscode',
  'confirm-email.success': 'Erfolgreich, Sie können sich jetzt anmelden',
  'confirm-email.code-sent': 'Code senden?',

  // CREATE NEW PASSWORD
  'create-password.title': 'Neues passwort erstellen',
  'create-password.new-password': 'Neues Kennwort',
  'create-password.confirm-password': 'Kennwort bestätigen',
  'create-password.confirm': 'Bestätigen',
  'create-password.success': 'Ihre Angaben wurden gespeichert.',
  'create-password.error': 'Abgelaufen. Bitte versuche es erneut.',

  // PROFILE
  'profile.user-profile.admin': 'Profil / Admin',
  'profile.user-profile.user': 'Profil User',
  'profile.label.email': 'E-Mail Adresse:',
  'profile.label.first-name': 'Vorname:',
  'profile.label.last-name': 'Nachname:',
  'profile.label.status': 'Anrede:',
  'profile.label.telephone': 'Telefon:',
  'profile.label.region': 'Land/Region:',
  'profile.label.company': 'Unternehmen:',
  'profile.confirm': 'Bestätigen',
  'profile.users.questionmark':
    'Als Administrator können Sie hier weitere NutzerInnen direkt per Mail einladen oder später ggfs. löschen. Erst nach der erfolgten und bestätigten Einladung erhalten diese den Zugriff auf Ihr Dashboard sowie den monatlichen Bericht.',

  'profile.change-password': 'Kennwort ändern',
  'profile.label.old-password': 'Derzeitiges Kennwort *',
  'profile.label.new-password': 'Neues Kennwort *',

  'profile.save-success': 'Ihre Angaben wurden gespeichert.',

  'profile.running-time': 'Laufzeit',

  'profile.subscription': 'Unser Abo',
  'profile.subscription.premium': 'Premium',
  'profile.subscription.pro': 'Pro (beliebtestes Abo)',
  'profile.subscription.basic': 'Basis',
  'profile.subscription.trial': 'Testversion (30 Tage)',
  'profile.subscription.show-subscriptions': 'Abonnement ändern',
  'profile.subscription.expire': 'Ihr jetziges Abo läuft bis zum: ',

  'profile.info.p1':
    'Sie können die Informationen auf dieser Seite jederzeit vervollständigen oder aktualisieren.',
  'profile.info.p2':
    'Wenn Sie eine Änderung vornehmen, erhalten Sie als Admin eine E-Mail zur Bestätigung.',
  'profile.info.p3':
    'Wenn Sie als Admin eine Änderung vornahmen z.B. beim Ansprechpartner oder einem der Nutzer, bekommen diese Personen eine E-Mail zur Bestätigung.',

  'profile.questionmark.admin':
    'Als Administrator sollten Sie alle Felder vollständig ausfüllen. Die Adressangaben werden für die Rechnungsstellung genutzt.',
  'profile.questionmark.user': 'User profile',

  'profile.rating': 'Bewertung Abgeben',
  'profile.rating.button': 'Bewertung abgeben',

  'profile.break-line': 'Alle Abo-Pakete und Leistungen',

  'profile.write-review': 'Bewertung schreiben',
  'profile.write-review.label': 'Möchten Sie etwas zu unserem Produkt sagen?',
  'profile.write-review.placeholder':
    'Bitte tragen Sie hier Ihre Bewertung ein.',
  'profile.write-review.submit': 'Senden',
  'profile.invoice.title': 'Rechnungsstellung',
  'profile.invoice.address': 'Rechnungsadresse',

  'profile.invoice.salutation': 'Anrede:',
  'profile.invoice.name': 'Vorname:*',
  'profile.invoice.surname': 'Nachname:*',
  'profile.invoice.email': 'E-Mail:*',
  'profile.invoice.companies': 'Unternehmen:*',
  'profile.invoice.streetNr': 'Strasse, Nr.:*',
  'profile.invoice.additional-address': 'Adresszusatz:',
  'profile.invoice.city': 'Stadt:*',
  'profile.invoice.plz': 'PLZ:*',
  'profile.invoice.land': 'Land:*',

  'profile.invoice.questionmark':
    'Alle Adressangaben wurden aus den Einstellungen im Profil des Administrators übernommen. Bitte überprüfen Sie diese. Sollten Ihre Daten für die Rechnungsstellung abweichen, können Sie diese Angaben hier ändern.',

  'profile.users.title': 'Weitere NutzerInnen',
  'profile.users.email': 'E-Mail Adresse:',
  'profile.users.first-name': 'Vorname:',
  'profile.users.last-name': 'Nachname:',
  'profile.users.user': 'Nutzer',
  'profile.users.add': 'einladen:',
  'profile.users.delete': 'löschen:',
  'profile.users.update': 'Speichern / Senden',
  'profile.users.invite-sent': 'Nutzer wurde eingeladen',
  'profile.users.signed-up': 'Nutzer hat sich angemeldet',

  // SUBSCRIPTION
  'subscription.plan.basic.title': 'Basic',
  'subscription.plan.pro.title': 'Pro',
  'subscription.plan.premium.title': 'Premium',
  'subscription.plan.price': '/ Jahr*',
  'subscription.plan.price.subtitle': '(Einführungspreis)',
  'subscription.plan.basic.desc-with-price':
    'Consectetur adipisicing elit. Excepturi, in.',
  'subscription.plan.pro.desc-with-price':
    'Consectetur adipisicing elit. Excepturi, in.',
  'subscription.plan.premium.desc-with-price':
    'Consectetur adipisicing elit. Excepturi, in.',
  'subscription.plan.basic.info-users': 'Anzahl der Nutzer: 1',
  'subscription.plan.pro.info-users': 'Anzahl der Nutzer: bis 2',
  'subscription.plan.premium.info-users': 'Anzahl der Nutzer: bis 4',
  'subscription.plan.basic.info-urls': 'Anzahl der URL: bis 2',
  'subscription.plan.pro.info-urls': 'Anzahl der URL: bis 3',
  'subscription.plan.premium.info-urls': 'Anzahl der URL: bis 6',
  'subscription.plan.basic.info': 'Abschluss eines Jahres-Abos erforderlich*',
  'subscription.plan.pro.info': 'Abschluss eines Jahres-Abos erforderlich*',
  'subscription.plan.premium.info': 'Abschluss eines Jahres-Abos erforderlich*',
  'subscription.plan.order': 'Bestellung überprüfen',
  'subscription.plan.accept-gdpr.p1': 'Datenschutzregeln',
  'subscription.plan.accept-gdpr.p2': ' gemäss DSGVO akzeptieren',
  'subscription.plan.accept-terms.p1': 'AGB',
  'subscription.plan.accept-terms.p2':
    ' zur Nutzung von PageTracker akzeptieren',
  'subscription.plan.confirm-selection': 'Jetzt bestellen',
  'subscription.active': 'Aktiv bis ',
  'subscription.activate': 'Aktivieren Sie',
  'subscription.cancel': 'Stornieren',
  'subscription.cancel.confirm': 'Abonnement kündigen',
  'subscription.trial': 'Testversion',
  'subscription.card.description': 'Abschluss eines Jahresabos notwendig.',
  'subscription.basic.questionmark':
    '<p>Das Basic Abo ist für max. einen Nutzer gedacht und enthält die' +
    ' grundlegenden Leistungen, um die eigene Seite (News Page, Firmenblog, Online-Magazin oder ähnliche) mit einer' +
    ' weiteren Seite oder mit einem Konkurrenten zu vergleichen</p><p>Wir empfehlen diese Version für kleine Betriebe, die unsere Leistungen zunächst ausprobieren wollen. </p>',
  'subscription.pro.questionmark':
    '<p>Das Pro Abo ist für bis zu zwei Nutzer gedacht und enthält alle Leistungen,' +
    ' um sich mit zwei weiteren Seiten oder mit zwei Konkurrenten zu vergleichen. </p><p>Wir empfehlen diese Version' +
    ' für alle Betriebe, die ihre Seite (News Page, Blog, Magazin oder ähnliche) aktiv als Teil ihrer Marketing' +
    ' Strategie nutzen.</p><p>Beim Upgrade von der Basis-Version oder beim Buchen nach der Testversion wird die unten aufgeführte Einrichtungsgebühr reduziert.</p>',
  'subscription.premium.questionmark':
    '<p>Das Abo ist für bis zu vier Nutzer gedacht und enthält alle Leistungen,' +
    ' inklusive vertiefende Insights und uneingeschränkte Handlungsempfehlungen für Content Marketing, SEO und' +
    ' Corporate Communication</p><p>Wir empfehlen diese Version für alle Betriebe, die sich mit mehreren' +
    ' ausgesuchten URLs vergleichen wollen und den hohen ROI des Content Marketing effektiv ausnutzen' +
    ' wollen.</p><p>Beim Upgrade von der Basis- oder Pro-Version wird die unten aufgeführte Einrichtungsgebühr reduziert.</p>',

  'subscription.info.title-1': 'Ihre Bestellung - 12 Monate',
  'subscription.info.title-2': 'Einmalige ',
  'subscription.info.title-3': 'Einrichtungsgebühr',
  'subscription.page-tracker-basic': 'PageTracker Basic',
  'subscription.page-tracker-pro': 'PageTracker Pro',
  'subscription.page-tracker-premium': 'PageTracker Premium',
  subscription: 'Abonnement',
  'subscription.MwSt': 'MwSt',
  'subscription.rake': 'Gebühr',
  'subscription-expenses': 'Kosten für das Abo',
  'subscription-setup-fee': 'Einrichtungsgebühr',
  'subscription-total': 'Totalbetrag',
  'subscription-info-1':
    '* Bei Leistungslieferung ins Ausland ist der Empfänger für die Zahlung der MwSt verantwortlich.',
  'subscription-info-2':
    '50% Rabatt, wenn das Produkt innerhalb der 30-Tage dauernden Testphase gekauft wird.',
  'subscription.success-1': 'Guten Tag ',
  'subscription.success-2':
    'Wir möchten Sie informieren, dass eine Rechnung für Ihr ',
  'subscription.success-3': 'drkpi PageTracker-',
  'subscription.success-4':
    'Abo zu Ihnen via E-Mail in den nächsten Tagen verschickt wird.',
  'subscription.success-5':
    'Bitte bezahlen Sie die Rechnung umgehend, damit Sie weiterhin von den gebuchten Leistungen profitieren können',
  'subscription.success-6': 'Danke,',
  'subscription.success-7': 'drkpi® PageTracker Team',
  'subscription.success-8':
    'P.S. Schauen Sie in unseren FAQ nach Antworten auf häufig gestellte Fragen.',

  // COMPARE
  'compare.url': 'Ihre URL*: ',
  'compare.url.other': 'URL eingeben*:',
  'compare.email': "User's Email:",
  'compare.category': 'Hauptthema der Beiträge*:',
  'compare.subcategory': 'Verwandte Themen*:',
  'compare.country': 'Land der URL*:',
  'compare.comment': 'Gibt es eine Kommentarfunktion?:',
  'compare.advertising': 'Wird Werbung geschaltet?',
  'compare.list': 'Liste wählen:',
  'compare.sublist': 'Subliste:',
  'compare.employees-number': 'Anzahl Mitarbeiter*:',
  'compare.sisin-number': 'ISIN Nummer:',
  'compare.company-name': 'Firmenname im Web*:',
  'compare.brands': 'Marken- und Produktnamen im Web:',
  'compare.hashtags': 'Branded Hashtags:',
  'compare.comment.error': 'Wird Werbung geschaltet?',

  'compare.website.placeholder.1': 'drkpi.com/blog',
  'compare.website.placeholder.2': 'www.daimler.com/magazin/',
  'compare.website.placeholder.3': 'autoblog.amag.ch/',
  'compare.website.placeholder.4': 'www.audi-mediacenter.com/de',
  'compare.website.placeholder.5': 'www.news.uzh.ch/de.html',
  'compare.website.placeholder.6': 'volvocars-partner.ch/blog/',

  'compare.card-title': 'Vergleich',
  'compare.empty-card-title': 'URL für eigene(n) News/Community/Blog eingeben.',
  'compare.card-title-my-blog': 'Ihre URL',
  'compare.card-fist-description':
    'Ihre News-Page, Ihr Firmenblog, \n' +
    'oder andere Seiten für Ihre Online-Beiträge',
  'compare.card-second-description':
    'Eine weitere News-Page, Blogseite o.ä.,\n' +
    'mit der Sie sich vergleichen wollen',

  'compare.placeholder.brands': 'Lindor, Coca-Cola, Nutella, Persil',
  'compare.placeholder.hashtags': '#PageTracker, #Quotenfrau',
  'compare.placeholder.email': 'Email',

  'compare.button-update': 'Aktualisieren',
  'compare.button-create': 'Speichern',
  'compare.contact-support': 'Kontaktieren Sie uns bitte',
  'compare.button-delete': 'Zurücksetzen',
  'compare.send-change': 'Erstelle neu',

  'compare.confirm-modal.title': 'Angaben zurücksetzen?',
  'compare.success-create':
    'Danke für die Angaben. Unser System prüft sie jetzt, das kann bis zu 48 Stunden dauern.',
  'compare.success-update':
    'Danke für die Angaben. Bitte bedenken Sie, dass Ihr Archiv und die vorherigen Ranglisten mit den neuen Daten nicht zu vergleichen sind.',

  'compare.error': 'Etwas stimmt nicht...',
  'compare.not-synchronized.title': 'Achtung',
  'compare.not-synchronized.message':
    'Die Synchronisierung Ihrer neuen URL-Angaben wird einige Tage dauern. Die' +
    ' Ergebnisse sind noch nicht optimiert.',
  'compare.error-page-not-found': 'Page does not exist, please contact us.',
  'compare.question-mark.subcategory':
    'Bitte wählen Sie hier noch eine passende Subkategorie aus, der sich Ihre Beiträge thematisch überwiegend zuordnen lassen.',
  'compare.question-mark.subcategory.other':
    'Bitte wählen Sie hier noch eine passende Subkategorie aus, der sich die Beiträge der von Ihnen gewählten Seite thematisch überwiegend zuordnen lassen',
  'compare.question-mark.category':
    'Bitte wählen Sie hier die passendste Kategorie aus, der sich Ihre Beiträge thematisch überwiegend zuordnen lassen. D.h. Sie sollten der gewählten Kategorie mehr als 60% Ihrer Beiträge zuordnen können.',
  'compare.question-mark.category.other':
    '<p>Bitte wählen Sie hier die passendste Kategorie aus, der sich die Online-Beiträge der von Ihnen gewählten Seite thematisch überwiegend zuordnen lassen. D.h. Sie sollten der gewählten Kategorie mehr als 60% der Beiträge zuordnen können.</p>',
  'compare.question-mark.country':
    '<p>Bitte wählen Sie hier das Land Ihrer URL, in dem Ihre Online-Beiträge' +
    ' publiziert und vorwiegend gelesen werden.</p><p><strong>Beispiel:</strong> Wenn Sie eine Website von Deutschland aus betreiben,' +
    ' aber hauptsächlich für Schweizer Publikum schreiben und digital somit vorwiegend in der Schweiz präsent sind,' +
    ' dann wählen Sie hier bitte “Schweiz” als das Land Ihrer URL aus.</p><p></p>',
  'compare.question-mark.country.other':
    '<p>Bitte wählen Sie hier das Land der URL, in dem die gewählte Seite Online-Beiträge publiziert und in dem diese vorwiegend gelesen werden. </p>',
  'compare.question-mark.url':
    '<p><strong>Wie lautet die URL Ihrer Seite?</strong> (= Ihre News Page, Firmenblog-Seite, Ihr Online-Magazin oder ähnliche' +
    ' Seiten für die Publikation von Online-Beiträgen)</p><p>Bitte geben Sie hier den vollständigen Link an, der' +
    ' direkt auf die Unterseite “News”, “Blog”, “Magazin” o.ä. führt.</p><p><strong>Beispiel:</strong> Die meisten Links zu Seiten, auf' +
    ' denen Online-Beiträge publizierte werden, haben einen weiteren Anhang wie z.B. “.../blog”, “.../news”,' +
    ' “.../artikel”. </p><p>Die URL Ihrer zu vergleichenden Seite sieht vielleicht ähnlich aus wie eines der' +
    ' folgenden Beispiele:</p><p>“https://drkpi.com/de <strong>/blog/</strong> “</p><p>“ https://musterfirma.ch<strong>/aktuelles/</strong>' +
    ' “</p><p>“https://landingpage.musterfirma.de<strong>/mitarbeiter-news/</strong>“</p>',
  'compare.question-mark.url.other':
    '<p><strong>Wie lautet die URL der Seite, mit der Sie sich vergleichen' +
    ' wollen?</strong> <br> (=News Page, Blog-Seite, Online-Magazin oder ähnliche Seiten für die Publikation von' +
    ' Online-Beiträgen, mit denen Sie sich vergleichen wollen)</p><p>Bitte geben Sie hier den vollständigen Link an, der direkt auf die Unterseite “News”, “Blog”, “Magazin” o.ä. führt.</p><p><strong>Beispiel:</strong> Die meisten Links zu Seiten, auf denen Online-Beiträge publizierte werden, haben einen weiteren Anhang wie z.B. “.../blog”, “.../news”, “.../artikel”.</p><p>Die URL der zu vergleichenden Seite sieht vielleicht ähnlich aus wie eines der folgenden Beispiele:</p><p>“https://drkpi.com/de <strong>/blog/</strong> “</p><p>“ https://musterfirma.ch<strong>/aktuelles/</strong>' +
    ' “</p><p>“https://landingpage.musterfirma.de<strong>/mitarbeiter-news/</strong>“</p>',
  'compare.question-mark.comment':
    'Sagen Sie uns hier bitte, ob Sie Lesern Ihrer News Page, Firmenblog-Seite o. ä.  die Möglichkeit bieten, Beiträge zu kommentieren.',
  'compare.question-mark.comment.other':
    'Sagen Sie uns hier bitte, ob die Leser der für den Vergleich gewählten News Page, Firmenblog-Seite o. ä.  die Möglichkeit haben, Beiträge zu kommentieren.',
  'compare.question-mark.advertising':
    'Sagen Sie uns hier bitte, ob auf Ihrer News Page, Firmenblog-Seite o. ä. Werbung angezeigt oder abgespielt wird.',
  'compare.question-mark.advertising.other':
    'Sagen Sie uns hier bitte, ob auf der für den Vergleich gewählten News' +
    ' Page, Firmenblog-Seite o. ä. Werbung angezeigt oder abgespielt wird.',
  'compare.question-mark.list':
    'Tragen Sie hier optional die Industrie Ihrer Firma ein. Es hilft uns, die Datenqualität für Ihr Benchmarking noch weiter zu optimieren.',
  'compare.question-mark.list.other':
    'Tragen Sie hier optional die Industrie der gewählten Firma ein. Es hilft uns, die Datenqualität für Ihr Benchmarking noch weiter zu optimieren. ',
  'compare.question-mark.employees':
    'Bitte wählen Sie die Grösse Ihres Unternehmens aus.',
  'compare.question-mark.employees.other':
    'Bitte wählen Sie hier die Ihnen bekannte Grösse des Unternehmens aus.',
  'compare.question-mark.isin':
    'Tragen Sie hier optional die Nummer der Aktien ein, falls es sich bei Ihrer Firma um ein börsenkotiertes Unternehmens handelt.',
  'compare.question-mark.isin.other':
    'Falls bekannt, tragen Sie hier optional die Nummer der Aktien ein, falls es sich um ein börsenkotiertes Unternehmen handelt.',
  'compare.question-mark.company-name':
    '<p><strong>Welchen Firmennamen nutzt die gewählte Seite im Web?</strong><br>' +
    'Bitte geben Sie hier den korrekten Namen des Unternehmens, wie er am häufigsten genutzt wird, damit unsere' +
    ' Crawlers alle Erwähnungen des Firmennamens der zu vergleichenden Seite finden kann. </p><p><strong>Beispiel:</strong> Nutzen Sie' +
    ' statt den vollständigen Namen “Musterfirma GmbH & Co.KG” nur den Firmennamen “Musterfirma” (ohne Zusätze), wenn dieser so genutzt wird. Geben Sie zusätzlich weitere Firmennamen, z.B. Abkürzungen wie “Mfirma” oder andere an, wenn diese so genutzt werden. Bei der Angabe von mehreren Firmennamen, trennen Sie diese bitte mit Kommata ab.</p>',
  'compare.question-mark.company-name.other':
    '<p><strong>Welchen Firmennamen nutzt die gewählte Seite im' +
    ' Web?</strong> <br>' +
    'Bitte geben Sie hier den korrekten Namen des Unternehmens, wie er am häufigsten genutzt wird, damit unsere' +
    ' Crawlers alle Erwähnungen des Firmennamens der zu vergleichenden Seite finden kann. </p><p><strong>Beispiel:</strong> Nutzen Sie' +
    ' statt den vollständigen Namen “Musterfirma GmbH & Co.KG” nur den Firmennamen “Musterfirma” (ohne Zusätze), wenn dieser so genutzt wird. Geben Sie zusätzlich weitere Firmennamen, z.B. Abkürzungen wie “Mfirma” oder andere an, wenn diese so genutzt werden. Bei der Angabe von mehreren Firmennamen, trennen Sie diese bitte mit Kommata ab.</p>',
  'compare.question-mark.hashtags':
    '<p>Branded Hashtags sind Hashtags, die Unternehmen exklusiv für sich selbst,' +
    ' Marken, Slogans, Produkte, Kampagnen, etc.  nutzen. Beispiele sind #drkpiPageTracker, #KitKatBreak oder' +
    ' #JustDoIt.  Sie können hier mehrere Branded Hashtags angeben, indem Sie diese mit Kommata voneinander trennen.' +
    ' Gross- und Kleinschreibung sind dabei nicht relevant.</p><p>Achten Sie darauf, dass Sie hier Hashtags nutzen,' +
    ' die ausschliesslich für Ihr Unternehmen genutzt werden. Nutzen Sie keine generischen Hashtags wie' +
    ' #auto.</p><p>Auch Abkürzungen wie #BKW sollten vermieden werden, da diese häufig auch von anderen genutzt' +
    ' werden – ohne Bezug zu Ihrem Unternehmen zu stehen. Diese könnten das Benchmarking verfälschen. Deshalb nutzt' +
    ' das Gebäudetechnik-Unternehmen BKW auch den Hashtag #BKWgt.</p><p>Wenn Sie hier keine Angaben machen, können wir Ihren Erfolg im Branding mittels Hashtags leider nicht analysieren.</p>',
  'compare.question-mark.hashtags.other':
    '<p>Branded Hashtags sind Hashtags, die Unternehmen exklusiv für sich' +
    ' selbst, Marken, Slogans, Produkte, Kampagnen, etc.  nutzen. Beispiele sind #drkpiPageTracker, #KitKatBreak' +
    ' oder #JustDoIt. Sie können hier mehrere Branded Hashtags eingeben, indem Sie diese mit Kommata voneinander' +
    ' trennen. Gross- und Kleinschreibung sind dabei nicht relevant. </p><p>Achten Sie darauf, dass Sie hier' +
    ' Hashtags nutzen, die ausschliesslich für das Unternehmen genutzt werden. Nutzen Sie keine generischen Hashtags' +
    ' wie #auto. </p><p>Auch Abkürzungen wie #BKW sollten vermieden werden, da diese häufig auch von anderen genutzt' +
    ' werden – ohne Bezug zum hier gewählten Unternehmen. Diese könnten das Benchmarking verfälschen. Deshalb nutzt' +
    ' das Gebäudetechnik-Unternehmen BKW den Hashtag #BKWgt.</p><p>Wenn Sie hier keine Angaben machen, können wir den Erfolg der Vergleichsseiten im Branding mittels Hashtags leider nicht analysieren.</p>',
  'compare.question-mark.brands':
    '<p>Marken sind registrierte Namen mit oder ohne Markenzeichen, die für Produkte,' +
    ' Firmen oder Dienstleistungen genutzt werden. Beispiele sind drkpi (bzw. drkpi®), DiLumen (bzw. DiLumen™),' +
    ' Gillette, Gillette Fusion, etc.</p><p>Wenn Sie möchten, dass alle Erwähnungen Ihrer Marken- und Produktnamen' +
    ' im Web gezählt werden, dann geben Sie die Markennamen hier ohne Markenzeichen wie ® oder ™ an. Sie können' +
    ' mehrere Marken- und Produktnamen eingeben, indem Sie diese mit Kommata voneinander trennen. Gross- und' +
    ' Kleinschreibung sind dabei nicht relevant. </p><p>Wenn Sie hier keine Angaben machen, können wir Ihre Markenpräsenz und Ihren Erfolg im Branding leider nur eingeschränkt ermitteln.</p>',
  'compare.question-mark.brands.other':
    '<p>Marken sind registrierte Namen mit oder ohne Markenzeichen, die für' +
    ' Produkte, Firmen oder Dienstleistungen genutzt werden. Beispiele sind drkpi (bzw. drkpi®), DiLumen (bzw.' +
    ' DiLumen™), Gillette, Gillette Fusion, etc.</p><p>Wenn Sie möchten, dass alle Erwähnungen der Marken- und' +
    ' Produktnamen im Web gezählt werden, dann geben Sie die Markennamen hier ohne Markenzeichen wie ® oder ™ an.' +
    ' Sie können hier mehrere Marken- und Produktnamen eingeben, indem Sie diese mit Kommata voneinander trennen.' +
    ' Gross- und Kleinschreibung sind dabei nicht relevant.</p><p>Wenn Sie hier keine Angaben machen, können wir die Markenpräsenz und den Erfolg im Branding der Vergleichsseite leider nur eingeschränkt ermitteln.</p>',

  // REPORT
  'report.title.data-settings':
    'Bestimmen Sie, welche Daten Sie im Bericht erhalten möchten.',
  'report.title.data-format':
    'In welchem Format und mit welcher Frequenz möchten Sie den Bericht erhalten?',
  'report.title.send-to': 'Der Bericht soll geschickt werden alle:',

  'report.subtitle.benchmarking': 'Benchmarking:',
  'report.ranking-list': 'Rangliste D-A-CH',
  'report.ranking-regional': 'Rangliste Regional',
  'report.overview': 'Überblick Kennzahlen',
  'report.writing-style': 'Schreibstil Blogbeitrag',

  'report.subtitle.trend-analysis': 'Trendanalyse:',
  'report.blog': 'Blog D-A-CH',
  'report.country': 'Land & Fachgebiet',

  'report.subtitle.roi': 'ROI:',
  'report.recommended-action': 'Handlungsempfehlung',

  'report.subtitle.branding': 'Branding:',
  'report.ranking-company-name': 'Rangliste Firmenname',
  'report.brand-ranking': 'Rangliste Marke',
  'report.ranking-hashtags': 'Rangliste Hashtags',
  'report.company-names-comparison': 'Anzahl Firmenname',
  'report.hashtags-comparison': 'Anzahl Hashtags',
  'report.brand-comparison': 'Anzahl Marken',

  'report.subtitle.seo': 'SEO:',
  'report.backlinks-number': 'Rang und Punkte Backlinks D-A-CH',
  'report.outbound-links-number': 'Rang und Punkte Outbound Links D-A-CH',
  'report.backlinks-comparison': 'Backlinks Vergleich',
  'report.internal-urls-number': 'Anzahl interne URL`s',

  'report.subtitle.engagement': 'Engagement:',
  'report.reader-comments': 'Leserkommentare',
  'report.author-responses-number': 'Anzahl Autorenanworten',
  'report.author-words-number': 'Anzahl Worte Autorenantworten',

  'report.subtitle.visuals/seo': 'Visuals/SEO:',
  'report.photos-number': 'Anzahl Fotos',
  'report.headers-number': 'Anzahl Header (H1,H2)',
  'report.ranking-list-visual-benchmark': 'Rangliste Visual Benchmark',

  'report.pdf': 'PDF',
  'report.powerpoint': 'Powerpoint',
  'report.template-addon': 'PPT Template Add-On',
  'report.send-email': 'Bei Interesse, senden Sie uns eine E-Mail.',

  'report.7tags': '7 Tage',
  'report.30tags': '30 Tage',
  'report.90tags': '90 Tage',
  'report.dont-send': 'Nicht senden',
  'report.dont-send-warning':
    'Sie bekommen jetzt keine automatischen Berichte. Gehen Sie bitte so oft Sie möchten in den PageTracker, um aktuelle Daten zu sehen.',

  'report.status': 'Status:',

  'report.button-send': 'Speichern',
  'report.download-now': 'Jetzt herunterladen',
  'report.download-success': 'Der Bericht wurde erstellt.',
  'report.button.90': 'Jetzt herunterladen 90 Tage',
  'report.button.1-12': 'Jahr 1',
  'report.button.13-24': 'Jahr 2',

  'report.tooltip-content':
    'Hier können Sie detailliert auswählen, wie Ihr Bericht aussehen soll. Falls nichts ausgesucht wurde, erhalten Sie die Standardansicht.',
  'report.server-error':
    'Unerwarteter Fehler ist aufgetreten. Wir sind informiert und arbeiten an einer' +
    ' schnellstmöglichen Lösung des Problems. Bitte haben Sie ein wenig Geduld.',

  NEW: 'Neu',
  SYNCED: 'Synchronisierung abgeschlossen',
  FAILED: 'Fehlgeschlagen. Kontaktieren Sie uns bitte!',
  PENDING: 'Synchronisierung noch nicht abgeschlossen',
  'compare.failed.contact-support': ' Kontaktieren Sie uns bitte.',

  'compare.title.not-editable':
    'Wenn Sie eine Bearbeitung wünschen, kontaktieren Sie uns bitte.',

  'compare.failed.modal.title': 'Die von Ihnen eingegebene URL ist ungültig.',
  'compare.failed.modal.message':
    'Bitte überprüfen Sie die URL - Oft reicht die allgemeine URL nicht. Vielleicht' +
    ' ist ein Zusatz nötig wie z.B. https://drkpi.com/blog',

  // ARCHIVE
  'archive.title.reports': 'Archivierte Berichte herunterladen:',
  'archive.button.download': 'Jetzt downloaden',
  'archive.tooltip-content':
    'Wählen Sie die Berichte, die Sie herunterladen möchten.',

  // BENCHMARK
  'benchmarking.title.writing-style': 'Kennzahlen zum Schreibstil im Überblick',
  'benchmarking.title.writing-style.question-mark':
    '<p>Die wichtigsten Kennzahlen zum Schreibstil der Online-Beiträge auf Ihrer Seite – als Durchschnittswerte für den jeweils überprüften Zeitraum. Die Pfeile zeigen die Veränderung gegenüber der Vorperiode an.</p>',
  'benchmarking.title.overview-seo': 'SEO-Kennzahlen Ihre URL im Überblick',
  'benchmarking.title.overview-seo.question-mark':
    '<p>Die wichtigsten SEO-Kennzahlen für die Online-Beiträge auf Ihrer Seite auf einen Blick – als Durchschnittswerte für den jeweils überprüften Zeitraum. Die Pfeile zeigen die Veränderung gegenüber der Vorperiode an.</p>',
  'benchmarking.title.rankings-top':
    'Positionierung im Ranking für die DACH-Region (gesamt)',
  'benchmarking.title.rankings-top.question-mark':
    '<p>Vergleich der Performance Ihrer Seite (URL A), der Seiten Ihrer Konkurrenten (Vergleich B, C, ...) in Relation zu allen anderen Seiten desselben Fachgebiets in der gesamten DACH-Region. </p>',
  'benchmarking.title.rankings-regional':
    'Positionierung im Ranking für das Land Ihrer URL',
  'benchmarking.title.rankings-regional.question-mark':
    '<p>Vergleich der Performance Ihrer Seite (URL A), der' +
    ' Seiten Ihrer Konkurrenten (Vergleich B, C, ...) in Relation zu allen anderen Seiten desselben Fachgebiets' +
    ' innerhalb des Landes Ihrer URL.</p><p>Ihren Rangplatz erhalten Sie auf Basis der erreichten Punktzahl von 1-100 nach Erhebung und Analyse diverser relevanter Daten, die Sie im Menü links und unten im Detail einsehen können.</p>',
  'benchmarking.table.change-vs-last-period':
    'Veränderung ggü. der letzten Periode',

  'benchmarking.overall-rankings': 'Position Gesamtrangliste Blogs/News D-A-CH',
  'benchmarking.average-back-links':
    'Backlinks von anderen Websites je Eintrag im Durchschnitt',
  'benchmarking.links-to-other-websites':
    'Links auf andere Webseiten je Eintrag im Durchschnitt',
  'benchmarking.links': 'Links',
  'benchmarking.number-of-entities-published':
    'Anzahl publizierter Einträge in der Periode',

  'benchmarking.average-words-in-articles':
    'Anzahl Wörter in Einträgen im Durchschnitt',
  'benchmarking.average-words-in-sentence':
    'Anzahl Wörter im Satz im Durchschnitt',
  'benchmarking.average-number-subtitles':
    'Anzahl der Überschriften (H2-H4) in Einträgen im Durchschnitt',
  'benchmarking.average-characters-headings':
    'Anzahl Zeichen inkl. Leerschläge in der Überschrift H1 (Titel)' +
    ' in Einträgen im ' +
    ' Durchschnitt',

  'benchmarking.footer.label.our-url': 'Ihre URL A',
  'benchmarking.footer.label.comparison-b': 'Vergleich B',
  'benchmarking.footer.label.comparison-c': 'Vergleich C',
  'benchmarking.footer.label.comparison-d': 'Vergleich D',
  'benchmarking.footer.label.comparison-e': 'Vergleich E',
  'benchmarking.footer.label.comparison-f': 'Vergleich F',

  'benchmarking.table-heading.comparison': '',
  'benchmarking.table-heading.total-points': 'Punkte gesamt',
  'benchmarking.table-heading.points-of-subject': 'Punkte Hauptthema',
  'benchmarking.table-heading.points-land': 'Punkte Land A',
  'benchmarking.table-heading.points-dach': 'Punkte D-A-CH',

  'benchmarking.rank': ' Rang',
  'benchmarking.backlinks': 'Backlinks',
  'benchmarking.records': 'Einträge',
  'benchmarking.symbols-with-spaces': 'Zeichen inkl. Leerschläge',

  // TREND
  'trend.title.overall': 'Performance-Vergleich für die DACH-Region (gesamt)',
  'trend.title.overall.question-mark':
    '<p>Langzeitvergleich der Wettbewerbsfähigkeit. Wie hat sich die Performance' +
    ' Ihrer Seite (URL A) im Vergleich zu den Seiten Ihrer Konkurrenz (Vergleich B, C, ...) entwickelt? </p><p>Die Berechnung der erreichten Punktzahlen basiert auf dem Verhältnis zu allen anderen Seiten desselben Fachgebiets in der gesamten DACH-Region und diverser relevanter Kennzahlen, die Sie im Menü links und unten im Detail einsehen können.</p>',
  'trend.title.regional': 'Performance innerhalb des Landes Ihrer URL',
  'trend.title.regional.question-mark':
    '<p>Langzeitvergleich der Wettbewerbsfähigkeit. Wie hat sich die Performance' +
    ' Ihrer Seite (URL A) im Vergleich zu den Seiten Ihrer Konkurrenz (Vergleich B, C, ...) entwickelt? </p><p>Die Berechnung der erreichten Punktzahlen basiert auf dem Verhältnis zu allen anderen Seiten desselben Fachgebiets innerhalb des Landes Ihrer URL und diverser relevanter Kennzahlen, die Sie im Menü links und unten im Detail einsehen können.</p>',
  'trend.trademark': '© PageTracker.drkpi.com',

  // BRANDING
  'branding.title.rank-companies': 'Sichtbarkeit von Firmennamen im Vergleich',
  'branding.title.rank-companies.question-mark':
    '<p>In diesem Ranking vergleichen wir die digitale Präsenz und Sichtbarkeit (Visibilität) von Firmennamen innerhalb der DACH-Region und innerhalb des Landes Ihrer URL.</p>',
  'branding.title.rank-brands': 'Präsenz von Markennamen im Vergleich',
  'branding.title.rank-brands.question-mark':
    '<p>In diesem Ranking vergleichen wir die digitale Präsenz und Sichtbarkeit (Visibilität) von Markennamen innerhalb der DACH-Region und innerhalb des Landes Ihrer URL.</p>',
  'branding.title.rank-hashtags':
    'Visibilität von Branded Hashtags im Vergleich',
  'branding.title.rank-hashtags.question-mark':
    'In diesem Ranking vergleichen wir die digitale Präsenz und Sichtbarkeit (Visibilität) von strategisch genutzten Branded Hashtags innerhalb der DACH-Region und innerhalb des Landes Ihrer URL.',
  'branding.title.rank-companies-comparison':
    'Anzahl Nennungen der Firmennamen',
  'branding.title.rank-companies-comparison.question-mark':
    'Wie häufig wird Ihr Firmenname bzw. der Firmenname Ihrer Konkurrenten erwähnt?',
  'branding.title.rank-brands-comparison': 'Anzahl Nennungen von Markennamen',
  'branding.title.rank-brands-comparison.question-mark':
    'Wie häufig werden Ihre Marken bzw. die Markennamen Ihrer Konkurrenten erwähnt?',
  'branding.title.rank-hashtags-comparison':
    'Anzahl Nennungen von Branded Hashtags',
  'branding.title.rank-hashtags-comparison.question-mark':
    'Wie häufig werden Ihre Branded Hashtags bzw. die Hashtags Ihrer Konkurrenten genutzt?',
  'branding.table-menu.button1': 'Vergangene 90 Tage',
  'branding.table-menu.button2': 'Jahr 1',
  'branding.table-menu.button3': 'Jahr 2',
  'branding.table-header-two': 'Punkte D-A-CH',
  'branding.table-header-three': 'Punkte Land A',
  'branding.letter': 'Vergleich',
  'branding.server-error':
    'Unerwarteter Fehler ist aufgetreten. Wir sind informiert und arbeiten an einer' +
    ' schnellstmöglichen Lösung des Problems. Bitte haben Sie ein wenig Geduld.',
  'branding.label.firm': 'Ihr Firmenname A',
  'branding.label.brand': 'Ihre Marke(n) A',
  'branding.label.hashtags': 'Branded Hashtag(s) A',

  // SEO
  'seo.table-header-three': 'Punkte Hauptthema',

  // SUPER ADMIN
  'super-admin.title': 'drkpi Team Super Admin page ',
  'super-admin.search-pages.title': 'Search pages',
  'super-admin.requests.title': 'Requests',
  'super-admin.search-users.title': 'Search users',
  'super-admin.no-results': 'No results',
  'super-admin.company': 'Unternehmen',
  'super-admin.email': 'Email',
  'super-admin.url': 'URL',
  'super-admin.date': 'DATUM',
  'super-admin.last-bill-date': 'letzte Rechnung',
  'super-admin.next-bill-date': 'nächste Rechnung',
  'super-admin.subscription-end-date': 'Gültigkeit',
  'super-admin.admin': 'Admin',
  'super-admin.name': 'Name, Vorname',
  'super-admin.sub': 'Abo',
  'super-admin.trial': 'Trial',
  'super-admin.trial-yes': 'Yes',
  'super-admin.trial-no': 'No',
  'super-admin.user-id': 'User ID',
  'super-admin.search.page-url': 'Page URL',
  'super-admin.search.user-name': 'Search',
  'super-admin.search.user-id': 'Search Kunden Nr.',
  'super-admin.search.user-company': 'Search Firmenname',
  'super-user.create-page': 'Create new page',
  'super-user.success': 'Success',
  'super-admin.pages.url': 'Any value',
  'super-admin.pages.hashtags': 'Hashtags',
  'super-admin.pages.category': 'Category',
  'super-admin.pages.country': 'Country',
  'super-admin.page-not-found':
    'We cannot find this url in our database. Please register it now. Thank you.',
  'super-admin.page-no-requests-found': 'No request found',

  // SEO
  'seo.rankings-back-links.table.title':
    'Analyse der Backlinks (Rückverweise) ',
  'seo.rankings-back-links.table.question-mark':
    '<p>Vergleich der Backlink-Profile für Ihren eigene News- oder' +
    ' Blog-Seite (auf URL A) mit den von Ihnen gewählten, zu vergleichenden Seiten (Vergleich B, C, ...).' +
    ' </p><p><strong>Backlinks</strong>' +
    ' sind Verweise, die andere Seiten aus dem Web nutzen, um zu Ihren Inhalten bzw. zu den Inhalten Ihrer Konkurrenten zu verlinken. </p>',
  'seo.rankings-back-links.chart.title':
    'Punktzahl der Backlinks Ihrer Auswahl',
  'seo.rankings-back-links.chart.question-mark':
    'Wie viele Rückverweise (Backlinks) haben Sie von anderen Webseiten aus dem Internet im Vergleich zur Konkurrenz im gewählten Zeitraum bekommen?',
  'seo.rankings-outbound-links.table.title':
    'Analyse der Outbound Links (externe Verweise)',
  'seo.rankings-outbound-links.table.question-mark':
    '<p>Vergleich der Outbound Links in Ihren eigenen' +
    ' Online-Beiträgen (auf URL A) mit den von Ihnen gewählten, zu vergleichenden Seiten (Vergleich B, C, ...).' +
    ' </p><p><strong>Outbound</strong> Links (oder externe Links) sind Verweise, die Sie bzw. Ihre Konkurrenten in den eigenen Inhalten' +
    ' nutzen, um zu anderen Webseiten im Netz (ausserhalb der eigenen Seiten) zu verlinken.</p>',
  'seo.rankings-outbound-links.chart.title':
    'Punktzahl der Outbound Links im Vergleich',
  'seo.rankings-outbound-links.chart.question-mark':
    'Wie viele Verlinkungen, die zu anderen Seiten im Internet führen (externe Outbound Links), haben Sie im Vergleich zur Konkurrenz im gewählten Zeitraum genutzt?',

  // ENGAGEMENT
  'engagement-pdf-table-header':
    'Leserkommentare in Einträgen: Durchschnittliche Anzahl, D-A-CH',
  'engagement-comments-count': 'Benchmarking der Leserkommentare',
  'engagement-comments-count.question-mark':
    'Vergleich der durchschnittlichen Anzahl der Leserkommentare in Ihren Online-Beiträgen (URL A) mit dem Engagement der Leser in den Beiträgen Ihrer Konkurrenten (Vergleich B, C, …).',
  'engagement-author-replies-count': 'Benchmarking der Autorenantworten ',
  'engagement-author-replies-count.question-mark':
    'Vergleich der durchschnittlichen Anzahl der Autorenantworten auf Leserkommentare in Ihren Online-Beiträgen (URL A) mit dem Engagement der Autoren in den Beiträgen Ihrer Konkurrenten (Vergleich B, C, …).',
  'engagement-author-replies-chart': 'Vergleich des Autoren-Engagements',
  'engagement-author-replies-chart.question-mark':
    'Vergleich der durchschnittlichen Anzahl Wörter in den Antworten der Autoren unter Ihren Online-Beiträgen (Ihre URL A) mit den von Ihnen gewählten, zu vergleichenden Seiten (Vergleich B, C, ...).',

  // VISUALS
  'visuals-chart': 'Usability-Benchmarking (Gestaltung und Struktur)',
  'visuals-chart.question-mark':
    '<p><strong>Visueller Gesamteindruck</strong> Ihrer Online-Beiträge im Vergleich zur Konkurrenz basierend auf der' +
    ' Verwendung von Gestaltungs- und Strukturelementen.</p>',
  'visuals-images-count': 'Analyse der visuellen Gestaltung (Bildmaterial)',
  'visuals-images-count.question-mark':
    'Vergleich der durchschnittlichen Anzahl an Fotos, Videos oder Grafiken in Ihren Online-Beiträgen (URL A) mit den von Ihnen gewählten, zu vergleichenden Seiten (Vergleich B, C, ...).',
  'visuals-subtitles-count': 'Analyse der Beitragsstruktur (Überschriften)',
  'visuals-subtitles-count.question-mark':
    'Vergleich der durchschnittlichen Anzahl von Überschriften H2, H3, H4 in Ihren Online-Beiträgen (URL A) mit den von Ihnen gewählten, zu vergleichenden Seiten (Vergleich B, C, ...).',

  //EDIT PDF REPORT
  'preview-pdf': 'Preview PDF',
  'save-editor': 'Save',
  'hide-new-footer': 'Hide Preview',
  'show-new-footer': 'Preview',
  'hide-original-footer': 'Hide Original',
  'show-original-footer': 'Original',
};
