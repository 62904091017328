import moment from "moment";

export const generateTimedQueryParam = ()=>{
  const offset = moment().utcOffset() / 60
  const timeInUTC = moment().subtract('hours', offset)
  const timeInCEST = moment(timeInUTC).add('hours', 2)
  const month = parseInt(moment(timeInCEST).format('M'));
  const day = parseInt(moment(timeInCEST).format('D'));
  return month + day;
}
