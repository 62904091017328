import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Container from '../../components/layout/Container';
import React, {useEffect, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {PdfPage} from './PdfPage';
import {NavBar} from './Tabs';
import {useDispatch, useSelector} from 'react-redux';
import {
  getPdfFooterData,
  onEditorChange,
  selectEditPdfReport,
  updatePdfFooterData,
} from '../../store/editPdfReport';
import {PageValuesKeys} from '../../store/editPdfReport/types';
import {Button} from '../../components/ui';
import {saveReportSettingsAndGetPdf} from '../../store/report/report.actions';
import {selectReports} from '../../store/report';

const cx = classNames.bind(styles);
const footerHeights: Array<number> = [460, 460, 460, 460];
export const EditPdfReports = () => {
  const dispatch = useDispatch();
  const {pages, loading, loadingUpdate, savedPages} = useSelector(selectEditPdfReport);
  const {isGettingPdf} = useSelector(selectReports);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [isValidFooter, setIsValidFooter] = useState<Array<boolean>>([true, true, true, true]);

  useEffect(() => {
    dispatch(getPdfFooterData.request());
  }, [dispatch]);
  const handleQuillEditor = (value: string, side: PageValuesKeys, index: number) => {
    dispatch(onEditorChange({index, value, side}));
  };
  const changeIsValidFooter = (v: boolean, i: number) => {
    const newArr = [...isValidFooter];
    newArr[i] = v;
    setIsValidFooter(newArr);
  };
  const updateHandler = () => {
    dispatch(updatePdfFooterData.request());
  };
  const pdfPages = () =>
    footerHeights.map((height, i) =>
      i === selectedTab ? (
        <PdfPage
          isLoading={loading}
          key={i}
          index={i}
          isValidFooter={isValidFooter[i]}
          changeIsValidFooter={changeIsValidFooter}
          height={height}
          page={pages[i]}
          savedPage={savedPages[i]}
          withLink={i === 0}
          onChange={handleQuillEditor}
        />
      ) : null
    );

  return (
    <Container className={cx('container')}>
      <NavBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} />
      {pdfPages()}
      <Button
        disabled={isValidFooter.some((isValid) => !isValid)}
        onClick={updateHandler}
        msg="save-editor"
        className={cx('download')}
        loading={loadingUpdate}
      />
      <Button
        disabled={isValidFooter.some((isValid) => !isValid)}
        onClick={() => dispatch(saveReportSettingsAndGetPdf.request({interval: '1'}))}
        msg="preview-pdf"
        className={cx('download')}
        loading={isGettingPdf === '1'}
      />
    </Container>
  );
};
