import {combineReducers} from 'redux';
import {RegistrationState, registrationReducer} from './registration/registration.reducer';
import {LoginState, loginReducer} from './login/login.reducer';
import {userReducer, UserState} from './user';
import {forgotPasswordReducer, ForgotPasswordState} from './forgot-password/forgot-password.reducer';
import {confirmEmailReducer, ConfirmEmailState} from './confirm-email/confirm-email.reducer';
import {createPasswordReducer, CreatePasswordState} from './create-password/create-password.reducer';
import {SubUserLoginState, subUserReducer} from './sub-user';

export interface AuthState {
  registration: RegistrationState;
  login: LoginState;
  user: UserState;
  forgotPassword: ForgotPasswordState;
  confirmEmail: ConfirmEmailState;
  createPassword: CreatePasswordState;
  subUser:SubUserLoginState;
}

export const authReducer = combineReducers<AuthState>({
  registration: registrationReducer,
  login: loginReducer,
  user: userReducer,
  forgotPassword: forgotPasswordReducer,
  confirmEmail:confirmEmailReducer,
  createPassword:createPasswordReducer,
  subUser:subUserReducer,
});

export const selectUser = (state:RootState)=>state.auth.user
export const selectRegistration = (state: RootState) => state.auth.registration;
export const selectLogin = (state: RootState) => state.auth.login;
export const selectCreatePassword = (state:RootState)=>state.auth.createPassword
export const selectConfirmEmail = (state:RootState)=>state.auth.confirmEmail
export const selectForgotPassword = (state:RootState)=>state.auth.forgotPassword
