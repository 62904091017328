import React, {useEffect} from 'react';
import {TextCard} from "../../components/layout/TextCard";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from "../../components/ui/Text";
import {TextInput} from "../../components/ui/TextInput";
import {useDispatch, useSelector} from "react-redux";
import {getInvoiceData, onInputChange, updateInvoiceData,} from "../../store/profile/invoice";
import {Button} from "../../components/ui/Button";
import {FormSuccessMessage} from "../../components/ui/FormSuccessMessage";
import {checkSubUserStatus} from "../../store/auth/user";
import {QuestionMark} from "../../components/ui/QuestionMark";
import {selectInvoice} from "../../store/profile/invoice";

const cx = classNames.bind(styles);

export const Invoicing = () => {
  const {form,errors, formHasChanged, formUpdateSuccess, isLoading} = useSelector(selectInvoice);
  const dispatch = useDispatch();
  const isSubUser = useSelector(checkSubUserStatus)

  useEffect(() => {
    dispatch(getInvoiceData.request())
  }, [dispatch])

  const onChange = (name: string, value: string | boolean) => {
    dispatch(onInputChange({name, value}))
  }
  const onSubmit = () => {
    dispatch(updateInvoiceData.request())
  }

  if (isSubUser) return null;
  return (
    <div className={cx('invoice', isSubUser && 'invoice--disabled')}>
      <QuestionMark
        content='profile.invoice.questionmark'
        tooltipPosition='left'
        className={cx('question-mark')}
      />
      <TextCard>
        <h2 className={cx('heading')}><Text msg='profile.invoice.title'/></h2>
        <TextInput
          className={cx('invoice__input')}
          value={form.salutation}
          onChange={onChange}
          label='profile.invoice.salutation'
          name='salutation'
          error={errors.salutation}
          disabled={isSubUser}
        />
        <TextInput
          className={cx('invoice__input')}
          value={form.firstName}
          onChange={onChange}
          label='profile.invoice.name'
          name='firstName'
          error={errors.firstName}
          disabled={isSubUser}
        />
        <TextInput
          className={cx('invoice__input')}
          value={form.lastName}
          onChange={onChange}
          label='profile.invoice.surname'
          name='lastName'
          error={errors.lastName}
          disabled={isSubUser}
        />
        <TextInput
          className={cx('invoice__input')}
          value={form.email}
          onChange={onChange}
          label='profile.invoice.email'
          name='email'
          error={errors.email}
          disabled={isSubUser}
        />
        <TextInput
          className={cx('invoice__input')}
          value={form.companyName}
          onChange={onChange}
          label='profile.invoice.companies'
          name='companyName'
          error={errors.companyName}
          disabled={isSubUser}
        />
        <TextInput
          className={cx('invoice__input')}
          value={form.address}
          onChange={onChange}
          label='profile.invoice.streetNr'
          name='address'
          error={errors.address}
          disabled={isSubUser}
        />
        <TextInput
          className={cx('invoice__input')}
          value={form.additionalAddress}
          onChange={onChange}
          label='profile.invoice.additional-address'
          name='additionalAddress'
          disabled={isSubUser}
        />
        <TextInput
          className={cx('invoice__input')}
          value={form.city}
          onChange={onChange}
          label='profile.invoice.city'
          name='city'
          error={errors.city}
          disabled={isSubUser}
        />
        <TextInput
          className={cx('invoice__input')}
          value={form.postalCode}
          onChange={onChange}
          label='profile.invoice.plz'
          name='postalCode'
          error={errors.postalCode}
          disabled={isSubUser}
        />
        <TextInput
          className={cx('invoice__input')}
          value={form.land}
          onChange={onChange}
          label='profile.invoice.land'
          name='land'
          error={errors.land}
          disabled={isSubUser}
        />
        <FormSuccessMessage message='profile.save-success' visible={formUpdateSuccess}/>
      </TextCard>
      <Button
        hidden={!formHasChanged}
        loading={isLoading}
        as='button'
        onClick={onSubmit}
        msg='profile.confirm'
        className={cx('invoice__btn')}
      />
    </div>
  );
};
