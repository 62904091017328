import React, { useEffect } from 'react';
import style from './index.module.scss';
import { Text, Button, TextInput } from '../../components/ui';
import Container from '../../components/layout/Container';
import { FormEvent } from '../../types/events';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectCreatePassword } from '../../store/auth';
import {
  onInputChange,
  submitPassword,
  clearState,
} from '../../store/auth/create-password/create-password.actions';

export const CreateNewPassword = () => {
  const dispatch = useDispatch();
  const { form, loading, success, errors } = useSelector(selectCreatePassword);

  const { code, id } = useParams<{ code: string; id: string }>();

  useEffect(() => {
    return () => {
      dispatch(clearState());
    };
  }, [dispatch]);

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(submitPassword.request({ id, code }));
  };
  const onChange = (name: string, value: string) => {
    dispatch(onInputChange({ name, value }));
  };

  return (
    <Container className={style.container}>
      <h1 className={style.heading}>
        <Text msg="create-password.title" />
      </h1>
      <form onSubmit={onSubmit}>
        <TextInput
          onChange={onChange}
          value={form.password}
          name="password"
          type="password"
          error={errors.password}
          label="create-password.new-password"
          className={style.input}
        />
        <TextInput
          onChange={onChange}
          value={form.confirmPassword}
          name="confirmPassword"
          type="password"
          error={errors.confirmPassword}
          label="create-password.confirm-password"
          className={style.input}
        />
        <Button
          loading={loading}
          msg="create-password.confirm"
          className={style.button}
        />
        {errors.server && (
          <p className={style.error}>
            <Text msg="create-password.error" />
          </p>
        )}
        {success && (
          <p className={style.success}>
            <Text msg="create-password.success" />
          </p>
        )}
      </form>
    </Container>
  );
};
