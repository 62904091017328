import React from 'react';
import {Text, TextInput, Button, CheckBox} from '../../components/ui';
import {onInputChange, register} from '../../store/auth/registration/registration.actions';
import {selectRegistration} from '../../store/auth';
import {useDispatch, useSelector} from 'react-redux';
import {FormEvent} from '../../types/events';
import Select, {Option} from '../../components/ui/Select';
import {CountriesSelection} from '../../components/ui/CountriesSelection';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {IsHuman} from '../../components/ui/IsHuman';
import {NavigationPaths} from '../../constants/navigationPaths';

const cx = classNames.bind(styles);

const RegisterCard = () => {
  const dispatch = useDispatch();
  const onChange = (name: string, value: string | boolean) => {
    dispatch(onInputChange({name, value}));
  };
  const {form, errors, loading} = useSelector(selectRegistration);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(register.request());
  };

  let passwordErrors = '';
  if (form.password && form.repeatPassword && form.password !== form.repeatPassword) {
    passwordErrors = 'error-passwords-dont-match';
  }

  let registrationErrors = false;

  for (const [key, value] of Object.entries(errors)) {
          if (value) {
            registrationErrors = true;
          }
  }

  return (
    <div className={cx('register')}>
      <h2 className={cx('title')}>
        <Text msg="register.title" />
      </h2>
      <p className={cx('subtitle')}>
        <Text msg="register.new-user" />
      </p>
      <form className={cx('form')} onSubmit={handleSubmit}>
        <TextInput
          labelClassName={cx('label')}
          onChange={(name, value) => onChange(name, value.toLowerCase())}
          name="email"
          labelPosition="left"
          error={errors?.email || (errors?.server && 'error-email-required')}
          value={form.email.toLowerCase()}
          autocomplete="new-email"
          className={cx('input')}
          label="register.label.email"
        />
        <div className={cx('row')}>
          <TextInput
            labelClassName={cx('label')}
            onChange={onChange}
            name="password"
            type="password"
            error={errors?.password}
            autocomplete="new-password"
            value={form.password}
            className={cx('input')}
            label="register.label.password"
          />
          <TextInput
            labelClassName={cx('label')}
            onChange={onChange}
            name="repeatPassword"
            value={form.repeatPassword}
            type="password"
            error={passwordErrors}
            className={cx('input')}
            label="register.label.repeat-password"
          />
        </div>
        <TextInput
          labelClassName={cx('label')}
          onChange={onChange}
          name="firstName"
          labelPosition="left"
          error={errors?.firstName}
          value={form.firstName}
          className={cx('input')}
          label="register.label.first-name"
        />
        <TextInput
          labelClassName={cx('label')}
          onChange={onChange}
          name="lastName"
          labelPosition="left"
          error={errors?.lastName}
          value={form.lastName}
          className={cx('input')}
          label="register.label.last-name"
        />
        <Select
          labelClassName={cx('label')}
          className={cx('input')}
          name="status"
          value={form.status}
          label="register.label.status"
          onChange={onChange}
          labelPosition="left"
          translate
          error={errors.status}
        >
          <Option label="common.mr" value="Herr" />
          <Option label="common.ms" value="Frau" />
          <Option label="common.various" value="Keine Angaben" />
        </Select>
        <TextInput
          labelClassName={cx('label')}
          onChange={onChange}
          name="companyName"
          labelPosition="left"
          error={errors?.companyName}
          value={form.companyName}
          className={cx('input')}
          label="register.label.company"
        />
        <CountriesSelection
          name="land"
          onChange={onChange}
          className={cx('input')}
          value={form.land}
          showDefaultCountries={true}
          labelPosition="left"
          labelClassName={cx('label')}
          label="register.label.region"
          error={errors?.land}
        />
        <div className={cx('terms')}>
          <div className={cx('row')}>
            <CheckBox
              className={cx('checkbox')}
              onChange={(name, value) => onChange(name, !value)}
              checked={form.agreeToGdpr}
              name="agreeToGdpr"
            />
            <p>
              <a
                className={cx('link')}
                rel="noopener noreferrer"
                href={NavigationPaths.dataProtection}
                target="_blank"
              >
                <Text msg="register.label.terms.p1.linkText" />
              </a>
              <Text msg="register.label.terms.p1.linkText2" />
            </p>
          </div>
          {errors?.agreeToGdpr && (
            <p className={cx('terms-error')}>
              <Text msg="error-agree-terms-conditions" />
            </p>
          )}
          <div className={cx('row')}>
            <CheckBox
              className={cx('checkbox')}
              onChange={(name, value) => onChange(name, !value)}
              checked={form.agreeToPlatformTerms}
              name="agreeToPlatformTerms"
            />
            <p>
              <a
                className={cx('link')}
                rel="noopener noreferrer"
                href={NavigationPaths.agb}
                target="_blank"
              >
                <Text msg="register.label.terms.p2.linkText" />
              </a>
              <Text msg="register.label.terms.p2.linkText2" />
            </p>
          </div>
          {errors?.agreeToPlatformTerms && (
            <p className={cx('terms-error')}>
              <Text msg="error-agree-terms-conditions" />
            </p>
          )}
          <div className={cx('row')}>
            <CheckBox
              className={cx('checkbox')}
              onChange={(name, value) => onChange(name, !value)}
              checked={form.agreeToDataProcessing}
              name="agreeToDataProcessing"
            />
            <p>
              <Text msg="register.label.terms.p3" />
            </p>
          </div>
          {errors?.agreeToDataProcessing && (
            <p className={cx('terms-error')}>
              <Text msg="error-agree-terms-conditions" />
            </p>
          )}
          <div className={cx('row')}>
            <CheckBox
              className={cx('checkbox')}
              onChange={(name, value) => onChange(name, !value)}
              checked={form.agreeToMarketingTerms}
              name="agreeToMarketingTerms"
            />
            <p>
              <Text msg="register.label.terms.p4" />
            </p>
          </div>
          {errors?.agreeToMarketingTerms && (
            <p className={cx('terms-error')}>
              <Text msg="error-agree-terms-conditions" />
            </p>
          )}
        </div>
        <IsHuman
          error={errors.validationValue}
          onChange={(value) => onChange('validationValue', value)}
          value={form.validationValue}
        />
        <br />
        {registrationErrors && (
          <div className={cx('errors')}>
            {errors.server === 'EMAIL_REGISTERED' ? (
              <Text msg="error-email-exist" />
            ) : (
              <Text msg="errors.registration" />
            )}
          </div>
        )}
        <Button
          className={cx('send-button')}
          loading={loading}
          onClick={() => null}
          msg={'register.button.send'}
        />
      </form>
      <p className={cx('bottom-text')}>
        <Text msg="register.bottom-text" />
      </p>
    </div>
  );
};

export default RegisterCard;
