import {takeLatest, select, put, call, all, delay} from 'redux-saga/effects';
import axios from 'axios';
import * as actions from './confirm-email.actions';
import {selectConfirmEmail} from '..';
import {apiEndpoints} from "../../../constants/api";
import {push} from 'connected-react-router';
import {NavigationPaths} from "../../../constants/navigationPaths";
import {clearLoginState} from '../login/login.actions';

function* onConfirm() {
  const {code, email} = selectConfirmEmail(yield select());
  try {
    yield call(axios.post, apiEndpoints.confirmCode, {code: code?.toString(), email});
    yield put(actions.confirm.success())
    yield put(push(NavigationPaths.authentication))
    yield put(clearLoginState())
  } catch (e) {
    yield put(actions.confirm.failure('error.invalid-code'))
  }
}

function* resendEmail() {
  const {email} = selectConfirmEmail(yield select());
  yield delay(3000);
  try {
    yield call(axios.post, apiEndpoints.resendConfirmationEmail, {email})
    yield put(actions.resendEmail.success())
  } catch (e) {
    yield put(actions.resendEmail.failure())
  }
}


export function* confirmEmailSagas() {
  yield all([
    yield takeLatest(actions.confirm.request, onConfirm),
    yield takeLatest(actions.resendEmail.request, resendEmail),
  ])
}
