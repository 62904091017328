import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from "../ui/Text";
import {images} from "../../assets/img";

const cx = classNames.bind(styles);

interface Props {
  pdfExport?: boolean;
  data?: { title: string, withDot?: boolean, subtitle?: string, leftValue: number, rightValue: number }[]
}

export const BenchmarkingTable = (props: Props) => {
  const {data, pdfExport} = props;

  useEffect(() => {
    const texts = document.querySelectorAll('.text-to-replace');
    texts.forEach((text) => {
      if (!text.textContent) return
      const content = text.textContent.replace(/D-A-CH/g, '<nbr>D-A-CH</nbr>')
      text.innerHTML = content;
    })
  }, [data])

  if (!data) return null;

  const rows = data.map((row, i) => {
    return (
      <div key={i}>
        <div className={cx('heading', i === 0 && 'heading--first', pdfExport && 'heading--pdf')}>
          <span className='text-to-replace'><Text msg={row.title}/></span>
          {i === 0 && <span className={cx('text-right')}><Text msg='benchmarking.table.change-vs-last-period'/></span>}
        </div>
        <div className={cx('data', pdfExport && 'data--pdf')}>
          {i !== 0 && <div className={cx('col')}>
            {row.leftValue}{row.withDot && <span>.&nbsp;</span>} {row.subtitle && <Text msg={row.subtitle}/>}
          </div>}
          {i === 0 && <div className={cx('col')}>

            {row.leftValue}{row.title!=='benchmarking.average-words-in-articles' && <span>.&nbsp;</span>} {row.subtitle && <Text msg={row.subtitle}/>}
          </div>}
          <div className={cx('col', 'col--right')}>
            {row.rightValue < 0
            && <img src={images.longArrowDown} className={cx('arrow')} alt=""/>}
            {row.rightValue > 0
            && <img src={images.longArrowUp} className={cx('arrow')} alt=""/>}
            <span className={cx('value')}>{Math.abs(row.rightValue)}</span>
          </div>
        </div>
      </div>
    )
  })

  return (
    <div className={cx('writing-style', pdfExport && 'writing-style--pdf')}>
      {rows}
    </div>
  );
};
