import React, {FC} from 'react';
import {formats, modules} from './quillConfig';
import {DiagramTextValues, DiagramTextValuesKeys} from '../../store/editSectionText/types';
import ReactQuill from 'react-quill';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);
interface QuillEditorProps {
  diagramId: number;
  textId: number;
  val: DiagramTextValues;
  index: string;
  handleQuillEditor: (
    value: string,
    side: DiagramTextValuesKeys,
    index: string,
    diagramId: number,
    textId: number
  ) => void;
}
const QuillEditor: FC<QuillEditorProps> = ({diagramId, textId, val, index, handleQuillEditor}) => {
  return (
    <>
      <div className={cx('titles-container')}>
        <h3 className={cx('title')}>Title</h3>
        <ReactQuill
          placeholder="Start typing here"
          modules={modules}
          formats={formats}
          theme="snow"
          value={val.title || ''}
          onChange={(e) => handleQuillEditor(e, 'title', index, diagramId, textId)}
        />
      </div>
      <div className={cx('titles-container')}>
        <h3 className={cx('title')}>Description</h3>
        <ReactQuill
          placeholder="Start typing here"
          modules={modules}
          formats={formats}
          theme="snow"
          value={val.description || ''}
          onChange={(e) => handleQuillEditor(e, 'description', index, diagramId, textId)}
        />
      </div>
    </>
  );
};

export default QuillEditor;
