import React from 'react';
import {Text} from "../ui/Text";
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  message?: string;
  className?: string
}

export const ErrorMessage: React.FC<Props> = ({message, className}) => {
  return (
    <div className={cx('error', className)}>
      <Text msg={message || 'common.data.error'}/>
    </div>
  );
};
