import {createAction, createAsyncAction} from "typesafe-actions";
import {InputPayload} from "../../../types/events";
import {Invoicing, InvoiceErrors} from './types';

export const onInputChange = createAction('profile/PROFILE_INVOICE_INPUT_CHANGE')<InputPayload>();

export const getInvoiceData = createAsyncAction(
  'profile/PROFILE_GET_INVOICE_DATA_STARTED',
  'profile/PROFILE_GET_INVOICE_DATA_DONE',
  'profile/PROFILE_GET_INVOICE_DATA_FAILED',
)<void, Invoicing, void>()

export const updateInvoiceData = createAsyncAction(
  'profile/PROFILE_UPDATE_INVOICE_DATA_STARTED',
  'profile/PROFILE_UPDATE_INVOICE_DATA_DONE',
  'profile/PROFILE_UPDATE_INVOICE_DATA_FAILED',
)<void, void, InvoiceErrors>()

export const validateInvoiceDataFailed = createAction('profile/PROFILE_VALIDATE_INVOICE_DATA_FAILED')<InvoiceErrors>()
