import {InvitedUser} from './types';
import {getType, ActionType,} from 'typesafe-actions';
import * as actions from './invitedUsers.actions';
import produce from 'immer';

type Action = ActionType<typeof actions>

export type InviteUsersState = InvitedUser[]
const initialState: InviteUsersState = [{}, {}, {}]

export const invitedUsersReducer = produce((state, action: Action) => {
  switch (action.type) {
    case getType(actions.getInvitedUsers.success):
      action.payload.forEach((user, i) => state[i] = user)
      break;
    case getType(actions.onInputChange): {
      const {value, name, index} = action.payload;
      state[index] = {...state[index], [name]: value, errors: {}}
    }
      break;
    case getType(actions.updateInvitedUser.request): {
      const {index} = action.payload;
      state[index].isUpdating = true;
      state[index].errors = undefined;
      state[index].success = undefined;
    }
      break;
    case getType(actions.updateInvitedUser.success): {
      const {index} = action.payload;
      if (state[index].option === 'delete') {
        state[index] = {}
      }
      if (state[index].option === 'invite') {
        state[index].signedUp = false;
      }
      state[index].isUpdating = false;
      state[index].success = true;
    }
      break;
    case getType(actions.updateInvitedUser.failure): {
      const {index, errors} = action.payload;
      state[index].isUpdating = false;
      state[index].errors = errors;
    }
      break;
    case getType(actions.onOptionChange): {
      const {index, value} = action.payload;
      if (!state[index].email && value === 'delete') return;
      state[index].option = value;
    }
  }
}, initialState)
