import {all, select, takeEvery, put, call} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../constants/api";
import {getData} from "./engagement.actions";
import {CommentsCount, AuthorRepliesCount, Types, AuthorRepliesChart} from "./types";
import {selectEngagement} from "./index";
import {mapChartData} from './dataMapper';
import {handleResponseError} from "../../utils/responseErrors";
import {mapGraphData} from "../../utils/mapResponseData";

function* fetchAuthorRepliesCount() {
  const {authorRepliesCount} = selectEngagement(yield select());
  try {
    const {data}: AxiosResponse<{ data: AuthorRepliesCount[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getEngagementAuthorRepliesCount(authorRepliesCount.interval || '1')
    )
    const mappedData = mapChartData(data.data)
    yield put(getData.success({data: mappedData, type: Types.authorRepliesCount}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.authorRepliesCount}))
  }
}

function* fetchAuthorRepliesChart() {
  const {authorRepliesChart} = selectEngagement(yield select());
  try {
    const {data}: AxiosResponse<{ data: AuthorRepliesChart[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getEngagementAuthorRepliesChart(authorRepliesChart.interval || '1')
    )
    const graphData = mapGraphData(data.data)
    yield put(getData.success({data: graphData, type: Types.authorRepliesChart}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.authorRepliesChart}))
  }
}

function* fetchCommentsCount() {
  const {commentsCount} = selectEngagement(yield select());
  try {
    const {data}: AxiosResponse<{ data: CommentsCount[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getEngagementCommentsCount(commentsCount.interval || '1')
    )
    const mappedData = mapChartData(data.data)
    yield put(getData.success({data: mappedData, type: Types.commentsCount}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.commentsCount}))
  }
}

function* fetchData(action: ReturnType<typeof getData.request>) {
  const {type} = action.payload;
  switch (type) {
    case "commentsCount":
      return yield fetchCommentsCount()
    case "authorRepliesChart":
      return yield fetchAuthorRepliesChart();
    case "authorRepliesCount":
      return yield fetchAuthorRepliesCount();
    default :
      return;
  }
}

export function* engagementSagas() {
  yield all([
    yield takeEvery(getData.request, fetchData),
  ])
}
