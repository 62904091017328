import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Container from '../../components/layout/Container';
import {Companies} from './Companies';
import {Brands} from './Brands';
import {Hashtags} from './Hashtags';
import {BrandsComparison} from './BrandsComparison';
import {CompaniesComparison} from './CompaniesComparison';
import {HashtagsComparison} from './HashtagsComparison';
import {useDispatch, useSelector} from 'react-redux';
import {getComparePages} from '../../store/compare/compare.actions';
import {DropDownDescription} from '../../components/DropDownDescription';
import {getCurrentSectionTextData, selectEditSectionText} from '../../store/editSectionText';
const cx = classNames.bind(styles);

export const Branding = () => {
  const dispatch = useDispatch();
  const {currentPage, loading} = useSelector(selectEditSectionText);
  useEffect(() => {
    dispatch(getCurrentSectionTextData.request('branding'));
    dispatch(getComparePages.request({showWarningIfPending: true}));
  }, [dispatch]);
  return (
    <Container className={cx('container')}>
      <Companies />
      <DropDownDescription loading={loading} activeIndex={0} data={currentPage[0]} />
      <Brands />
      <DropDownDescription loading={loading} data={currentPage[1]} />
      <Hashtags />
      <DropDownDescription loading={loading} data={currentPage[2]} />
      <CompaniesComparison />
      <DropDownDescription loading={loading} data={currentPage[3]} />
      <BrandsComparison />
      <DropDownDescription loading={loading} data={currentPage[4]} />
      <HashtagsComparison />
      <DropDownDescription loading={loading} data={currentPage[5]} />
    </Container>
  );
};
