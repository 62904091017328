import {all, takeLatest, call, put} from 'redux-saga/effects';
import {getArchives} from './archive.actions';
import {ArchivesResponse} from './types';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../constants/api";
import {handleResponseError} from "../../utils/responseErrors";

function* onGetArchives() {
  try {
    const {data}: AxiosResponse<ArchivesResponse> = yield call(axios.get, apiEndpoints.getArchives)
    yield put(getArchives.success(data.archives))
  } catch (e) {
    yield handleResponseError(e, getArchives.failure)
  }
}

export function* archiveSagas() {
  yield all([
    yield takeLatest(getArchives.request, onGetArchives)
  ])
}
