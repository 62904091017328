export interface ImagesCount {
  comparisonLetter: string;
  scoreDach: string;
}

export interface SubtitlesCount {
  comparisonLetter: string;
  scoreDach: string;
}

export type Chart = [string, string][]

export enum Types {
  imagesCount = 'imagesCount',
  subtitlesCount = 'subtitlesCount',
  chart = 'chart',
}
