import {createAction, createAsyncAction} from 'typesafe-actions';
import {InputPayload} from '../../../types/events';
import {RegistrationErrors} from './types';

export const onInputChange = createAction('auth/REGISTRATION_INPUT_CHANGE')<InputPayload>()

export const verifyHuman = createAction('auth/REGISTRATION_VERIFY_HUMAN')<string>()

export const register = createAsyncAction(
  'auth/REGISTRATION_STARTED',
  'auth/REGISTRATION_DONE',
  'auth/REGISTRATION_FAILED',
)<void, void, RegistrationErrors>()
