import React from 'react';
import {Text} from "../../components/ui/Text";
import {TextInput} from "../../components/ui/TextInput";
import {Button} from "../../components/ui/Button";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {useDispatch, useSelector} from "react-redux";
import Container from "../../components/layout/Container";
import {CheckBox} from "../../components/ui/CheckBox";
import {selectSubUser, register, onInputChange} from "../../store/auth/sub-user";
import {FormEvent} from "../../types/events";
import {useLocation} from 'react-router-dom';
import {IsHuman} from "../../components/ui/IsHuman";
import {NavigationPaths} from "../../constants/navigationPaths";

const cx = classNames.bind(styles);

export const SubUserRegister = () => {
  const {form, loading, errors} = useSelector(selectSubUser)
  const dispatch = useDispatch();
  const location = useLocation();

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    const email = location.search.split('=')[1];
    if (!email) return;
    const processedEmailFromUrl = email.split(' ').join('+');
    dispatch(register.request(processedEmailFromUrl))
  }

  return (
    <Container className={cx('container')}>
      <div className={cx('sub-user')}>
        <h1 className={cx('title')}>
          <Text msg='sub-user.title'/>
          <br/>
          <span><Text msg='sub-user.subtitle'/></span>
        </h1>
        <form onSubmit={onSubmit}>
          <div className={cx('password')}>
            <TextInput
              labelClassName={cx('label')}
              onChange={(name, value) => dispatch(onInputChange({name, value}))}
              name='password'
              type='password'
              error={errors?.password}
              value={form.password}
              className={cx('input')}
              label='register.label.password'
            />
            <TextInput
              labelClassName={cx('label')}
              onChange={(name, value) => dispatch(onInputChange({name, value}))}
              name='repeatPassword'
              value={form.repeatPassword}
              type='password'
              error={errors?.repeatPassword}
              className={cx('input')}
              label='register.label.repeat-password'
            />
          </div>
          <div className={cx('terms')}>
            <div className={cx('terms__row')}>
              <CheckBox
                className={cx('checkbox')}
                onChange={(name, value) => dispatch(onInputChange({name, value: !value}))}
                checked={form.agreeToGdpr}
                name='agreeToGdpr'
              />
              <p>
                <a className={cx('link')} rel="noopener noreferrer" href={NavigationPaths.dataProtection} target='_blank'><Text msg='register.label.terms.p1.linkText'/></a><Text msg='register.label.terms.p1.linkText2'/>
              </p>
            </div>
            {errors?.agreeToGdpr &&
            <p className={cx('terms-error')}><Text msg='error-agree-terms-conditions'/></p>
            }
            <div className={cx('terms__row')}>
              <CheckBox
                className={cx('checkbox')}
                onChange={(name, value) => dispatch(onInputChange({name, value: !value}))}
                checked={form.agreeToPlatformTerms}
                name='agreeToPlatformTerms'
              />
              <p>
                <a className={cx('link')} rel="noopener noreferrer" href={NavigationPaths.agb} target='_blank'><Text msg='register.label.terms.p2.linkText'/></a><Text msg='register.label.terms.p2.linkText2'/>
              </p>
            </div>
            {errors?.agreeToPlatformTerms &&
            <p className={cx('terms-error')}><Text msg='error-agree-terms-conditions'/></p>
            }
            <div className={cx('terms__row')}>
              <CheckBox
                className={cx('checkbox')}
                onChange={(name, value) => dispatch(onInputChange({name, value: !value}))}
                checked={form.agreeToDataProcessing}
                name='agreeToDataProcessing'
              />
              <p>
                <Text msg='register.label.terms.p3'/>
              </p>
            </div>
            {errors?.agreeToDataProcessing &&
            <p className={cx('terms-error')}><Text msg='error-agree-terms-conditions'/></p>
            }
            <div className={cx('terms__row')}>
              <CheckBox
                className={cx('checkbox')}
                onChange={(name, value) => dispatch(onInputChange({name, value: !value}))}
                checked={form.agreeToMarketingTerms}
                name='agreeToMarketingTerms'
              />
              <p>
                <Text msg='register.label.terms.p4'/>
              </p>
            </div>
            {errors?.agreeToMarketingTerms &&
            <p className={cx('terms-error')}><Text msg='error-agree-terms-conditions'/></p>
            }
          </div>
          <br/>
          <IsHuman error={errors.validationValue} onChange={(value)=>dispatch(onInputChange({name:'validationValue',value}))} value={form.validationValue}/>
          <br/>
          {errors.server && <p className={cx('login-error')}><Text msg='sub-user.login.error'/></p>}
          <Button
            msg='login.button.send'
            loading={loading} as='button'
            className={cx('submit-button')}
          />
        </form>
      </div>
    </Container>
  );
};
