import {call, put, takeLatest, all} from 'redux-saga/effects';
import {getSystemParameters} from './app.actions';
import axios from 'axios';
import {apiEndpoints} from "../../constants/api";
import {handleResponseError} from "../../utils/responseErrors";


function* onGetSystemParameters() {
  try {
    axios.defaults.baseURL=process.env.REACT_APP_BASE_URL
    const {data} = yield call(axios.get, apiEndpoints.systemParameters)
    yield put(getSystemParameters.success(data))
  } catch (e) {
    yield handleResponseError(e)
  }
}

function scrollToTop() {
  window.scrollTo(0, 0)
}

export function* appSagas() {
  yield all([
    yield takeLatest(getSystemParameters.request, onGetSystemParameters),
    yield takeLatest('@@router/LOCATION_CHANGE', scrollToTop),
  ])
}
