
export * from './types';
export * from './user.actions';
export * from './user.reducer';
export * from './user.sagas';

export const selectUser = (state: RootState) => state.auth.user;
export const selectUserIsAdmin = (state:RootState)=>state.auth.user.role ==='admin';
export const expiredSubscription = (state:RootState)=> {
  return state.auth.user.subscriptionId === -1;
}
export const checkSubUserStatus = (state:RootState)=>!!state.auth.user.masterAccountId;

