import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Container from '../../components/layout/Container';
import {useDispatch, useSelector} from 'react-redux';
import {getComparePages} from '../../store/compare/compare.actions';
import {SeoRankBackLinksTable} from './SeoRankBackLinksTable';
import {SeoRankOutboundLinksTable} from './SeoRankOutboundLinksTable';
import {SeoRankBackLinksChart} from './SeoRankBackLinksChart';
import {SeoRankOutboundLinksChart} from './SeoRankOutboundLinksChart';
import {DropDownDescription} from '../../components/DropDownDescription';
import {getCurrentSectionTextData, selectEditSectionText} from '../../store/editSectionText';
const cx = classNames.bind(styles);

export const Seo = () => {
  const dispatch = useDispatch();
  const {currentPage, loading} = useSelector(selectEditSectionText);
  useEffect(() => {
    dispatch(getCurrentSectionTextData.request('seo'));
    dispatch(getComparePages.request({showWarningIfPending: true}));
  }, [dispatch]);
  return (
    <Container className={cx('container')}>
      <SeoRankBackLinksTable />
      <DropDownDescription loading={loading} activeIndex={0} data={currentPage[0]} />
      <SeoRankBackLinksChart />
      <DropDownDescription loading={loading} data={currentPage[1]} />
      <SeoRankOutboundLinksTable />
      <DropDownDescription loading={loading} data={currentPage[2]} />
      <SeoRankOutboundLinksChart />
      <DropDownDescription loading={loading} data={currentPage[3]} />
    </Container>
  );
};
