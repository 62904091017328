import { createAsyncAction, createAction } from 'typesafe-actions';
import { User, DataToUpdate } from './types';
import { Page } from './types';
import { ComparePage, ComparePageErrors } from '../compare/types';

export const openBlankPage = createAction('super-user/OPEN_BLANK_PAGE')();

export const getPages = createAsyncAction(
  'super-user/GET_PAGES_STARTED',
  'super-user/GET_PAGES_DONE',
  'super-user/GET_PAGES_FAILED'
)<void, Page[], void>();

export const getUsers = createAsyncAction(
  'super-user/GET_USERS_STARTED',
  'super-user/GET_USERS_DONE',
  'super-user/GET_USERS_FAILED'
)<void, User[], void>();

export const updateUser = createAsyncAction(
  'super-user/UPDATE_USER_STARTED',
  'super-user/UPDATE_USER_DONE',
  'super-user/UPDATE_USER_FAILED'
)<{ dataToUpdate: DataToUpdate }, { data: User }, { userId: string }>();

export const deleteUser = createAsyncAction(
  'super-user/DELETE_USER_STARTED',
  'super-user/DELETE_USER_DONE',
  'super-user/DELETE_USER_FAILED'
)<string, string, string>();

export const setDeleteUser = createAsyncAction(
  'super-user/SET_DELETE_USER_STARTED',
  'super-user/SET_DELETE_USER_DONE',
  'super-user/SET_DELETE_USER_FAILED'
)<{ userId: string }, User[], void>();

export const setUnDeleteUser = createAsyncAction(
  'super-user/SET_UNDELETE_USER_STARTED',
  'super-user/SET_UNDELETE_USER_DONE',
  'super-user/SET_UNDELETE_USER_FAILED'
)<{ userId: string }, { userId: string }, void>();

export const loginAsSelectedUser = createAsyncAction(
  'super-user/LOGIN_AS_SELECTED_USER_STARTED',
  'super-user/LOGIN_AS_SELECTED_USER_DONE',
  'super-user/LOGIN_AS_SELECTED_USER_FAILED'
)<
  { userId: string },
  { userId: string; adminToken: string },
  { userId: string }
>();

export const switchBackToAdmin = createAsyncAction(
  'super-user/SWITCH_BACK_TO_ADMIN_STARTED',
  'super-user/SWITCH_BACK_TO_ADMIN_DONE',
  'super-user/SWITCH_BACK_TO_ADMIN_FAILED'
)<void, void, void>();

export const setAdminToken = createAction(
  'super-user/SET_ADMIN_TOKEN'
)<string>();

export const loadComparePage = createAction(
  'super-user/LOAD_COMPARE_CARD'
)<ComparePage>();

export const onPageInputChange = createAction(
  'super-user/ON_PAGE_INPUT_CHANGE'
)<{ name: string; value: string | boolean }>();

export const sendReportToUser = createAsyncAction(
  'super-user/SEND_REPORT_TO_USER_STARTED',
  'super-user/SEND_REPORT_TO_USER_DONE',
  'super-user/SEND_REPORT_TO_USER_FAILED'
)<string, string, string>();

export const updatePage = createAsyncAction(
  'super-user/UPDATE_PAGE_STARTED',
  'super-user/UPDATE_PAGE_DONE',
  'super-user/UPDATE_PAGE_FAILED'
)<
  { page: ComparePage },
  { page: ComparePage },
  { errors: ComparePageErrors }
>();

export const loadNewPage = createAction('super-user/LOAD_NEW_PAGE')();

export const updateSuccess = createAction('super-user/ON_UPDATE_SUCCESS')();
export const updateFailed = createAction('super-user/ON_UPDATE_FAILED')();
export const clearNotifications = createAction(
  'super-user/ON_CLEAR_NOTIFICATIONS'
)();

export const updateComparePage = createAsyncAction(
  'super-user/UPDATE_COMPARE_PAGE_STARTED',
  'super-user/UPDATE_COMPARE_PAGE_DONE',
  'super-user/UPDATE_COMPARE_PAGE_FAILED'
)<ComparePage, void, void>();

export const rescanPage = createAsyncAction(
  'super-user/RESCAN_PAGE_STARTED',
  'super-user/RESCAN_PAGE_DONE',
  'super-user/RESCAN_PAGE_FAILED'
)<string, void, void>();

export const archivePage = createAsyncAction(
  'super-user/ARCHIVE_PAGE_STARTED',
  'super-user/ARCHIVE_PAGE_DONE',
  'super-user/ARCHIVE_PAGE_FAILED'
)<string, string, void>();

export const createPage = createAsyncAction(
  'super-user/CREATE_PAGE_STARTED',
  'super-user/CREATE_PAGE_DONE',
  'super-user/CREATE_PAGE_FAILED'
)<void, ComparePage, { errors: ComparePageErrors }>();

export const showCreatePageModal = createAction(
  'super-user/SHOW_CREATE_PAGE_MODAL'
)();

export const getRequests = createAsyncAction(
  'super-user/GET_REQUESTS_STARTED',
  'super-user/GET_REQUESTS_DONE',
  'super-user/GET_REQUESTS_FAILED'
)<void, any, void>();

export const updateRequest = createAsyncAction(
  'super-user/UPDATE_REQUEST_STARTED',
  'super-user/UPDATE_REQUEST_DONE',
  'super-user/UPDATE_REQUEST_FAILED'
)<
  { email: string; url: string; id: string },
  { email: string; url: string; id: string },
  void
>();

export const deleteRequest = createAsyncAction(
  'super-user/DELETE_REQUEST_STARTED',
  'super-user/DELETE_REQUEST_DONE',
  'super-user/DELETE_REQUEST_FAILED'
)<string, string, void>();
