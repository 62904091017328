import React, {useEffect} from 'react';
import Container from "../../components/layout/Container";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from "../../components/ui/Text";
import {QuestionMark} from "../../components/ui/QuestionMark";
import moment from 'moment';
import {useDispatch, useSelector} from "react-redux";
import {getArchives} from "../../store/archive/archive.actions";
import {selectArchives} from "../../store/archive";

const cx = classNames.bind(styles);

export const Archive = () => {
  const dispatch = useDispatch();
  const {data, isLoading} = useSelector(selectArchives);

  useEffect(() => {
    dispatch(getArchives.request())
  }, [dispatch])

  const renderArchives = () => {
    if (!data || isLoading) return <div>Loading...</div>
    return data
      .filter((archive)=>archive.type==='auto')
      .map((archive, i) => {
      return (
        <a rel="noopener noreferrer" key={i} href={`${archive.downloadLink}`} target='_blank' className={cx('row')}>
          <p>
            <span className={cx('date')}>{moment(archive.sentDate).format('YYYY-MM-DD')}</span>
            <span className={cx('name')}>{archive.name}</span>
          </p>
          <p className={cx('download')}>herunterladen</p>
        </a>
      )
    })
  }

  return (
    <Container>
      <div className={cx('table')}>
        <h2 className={cx('heading')}><Text msg='archive.title.reports'/></h2>
        <QuestionMark content='archive.tooltip-content' tooltipPosition='left' className={cx('question-mark')}/>
        {renderArchives()}
        {/*<Button msg='archive.button.download' className={cx('button')}/>*/}
      </div>
    </Container>
  );
};
