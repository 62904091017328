import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {MobileMenu} from './MobileMenu';
import {DesktopMenu} from './DesktopMenu';
import {useWindowSize} from '../../../utils/useWindowResize';

const cx = classNames.bind(styles);

export const TopMenu = () => {
  const dimensions = useWindowSize();
  return (
    <div className={cx('container')}>
      {dimensions.width! < 600 || dimensions.height! < 500 ? <MobileMenu /> : <DesktopMenu />}
    </div>
  );
};
