import React, {useState} from 'react';
import style from './index.module.scss';
import classNames from 'classnames/bind';
import {TextCard} from "../../components/layout/TextCard";
import {TextInput} from "../../components/ui/TextInput";
import {useDispatch, useSelector} from "react-redux";
import {selectChangePassword} from "../../store/profile";
import {onInputChange, changePassword} from "../../store/profile/password/password.actions";
import {FormEvent} from "../../types/events";
import {FormErrorMessage, FormSuccessMessage, Button, Text} from "../../components/ui";

const cx = classNames.bind(style)

export const ChangePassword = () => {
  const {form, errors, loading, success} = useSelector(selectChangePassword)
  const [hasChanged, setChanged] = useState(false);
  const dispatch = useDispatch();

  const onChange = (name: string, value: string | boolean) => {
    setChanged(true);
    dispatch(onInputChange({name, value}))
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    setChanged(false);
    dispatch(changePassword.request())
  }

  return (
    <div className={cx('change-password')}>
      <TextCard>
        <h2 className={cx('heading')}><Text msg='profile.change-password'/></h2>
        <form onSubmit={onSubmit}>
          <TextInput
            onChange={onChange}
            name='oldPassword'
            type='password'
            error={errors?.oldPassword}
            value={form.oldPassword}
            className={cx('input')}
            label='profile.label.old-password'
          />
          <TextInput
            onChange={onChange}
            name='newPassword'
            value={form.newPassword}
            type='password'
            error={errors?.newPassword}
            className={cx('input')}
            label='profile.label.new-password'
          />
          <FormErrorMessage className={cx('error')} message={errors?.server}/>
          <FormSuccessMessage message='profile.save-success' visible={success}/>
        </form>
      </TextCard>
      <Button
        hidden={!hasChanged && !loading}
        onClick={()=> {
          setChanged(false);
          dispatch(changePassword.request())
        }}
        loading={loading}
        msg='profile.confirm'
        className={cx('button')}
      />
    </div>
  );
};
