import {createAsyncAction} from "typesafe-actions";

export const changeSubscription = createAsyncAction(
  'subscription/CHANGE_SUBSCRIPTION_STARTED',
  'subscription/CHANGE_SUBSCRIPTION_DONE',
  'subscription/CHANGE_SUBSCRIPTION_FAILED',
)<number, number, void>()

export const showModal = createAsyncAction(
  'subscription/SHOW_MODAL_STARTED',
  'subscription/SHOW_MODAL_DONE',
  'subscription/SHOW_MODAL_FAILED',
)<number | undefined, number | undefined, number | undefined>()
