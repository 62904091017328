import React, {useEffect, useRef, useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Header} from '../Header';
import axios, {AxiosResponse} from 'axios';
import {useParams} from 'react-router-dom';
import {apiEndpoints} from '../../constants/api';
import {TableMenu} from '../../components/TableMenu';
import {Graph} from '../../components/LinearGraph/Graph';
import {TableFooter} from '../../components/TableFooter';
import {RankingsTable, TableData} from '../../components/RankingsTable';
import {BenchmarkingTable} from '../../components/BenchmarkingTable';
import {mapGraphData, seoData, writingStyleData} from '../utils';
import {Tooltip} from '../Tooltip';

const cx = classNames.bind(styles);

export const PdfBasic = () => {
  const {secret} = useParams<{secret: string}>();
  const [pdfData, setData] = useState<undefined | any>(undefined);

  const ref = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const {data}: AxiosResponse<Response> = await axios.post(apiEndpoints.basicReports(secret));

        setData(data);
        setTimeout(() => {
          const body = document.querySelector('body');
          body?.setAttribute('id', 'loaded');
        }, 500);
      } catch (e) {
        console.log(e.response);
      }
    };
    fetchData();
  }, [secret]);
  console.log(pdfData);
  const td1 = pdfData?.['benchmarking-rankings-regional'].data as any[];
  const td1Quarter = pdfData?.['benchmarking-rankings-regional'].quarter;
  const td2 = mapGraphData(pdfData?.['trendanalysis-dach']?.data) || [];
  const td2Quarter = 'Y'; // pdfData?.['trendanalysis-dach'].quarter;
  const td3 = seoData(pdfData?.['benchmarking-seo']?.data) || [];
  const td3Quarter = pdfData?.['trendanalysis-dach'].quarter;
  const td4 = writingStyleData(pdfData?.['benchmarking-writing-style']?.data) || [];
  const td4Quarter = pdfData?.['benchmarking-writing-style'].quarter;

  const tableData: TableData[] = td1?.map((item) => ({
    rank: item.rank,
    valueOne: item.websiteUrl,
    comparisonLetter: item.comparisonLetter,
    country: item.country,
    valueTwo: item.blogsTotal,
    valueThree: item.areaOfExpertise,
  }));

  return (
    <div className={cx('pdf-page')} ref={ref}>
      <Header />
      <div className={cx('tooltip-container')}>
        <Tooltip className={cx('tooltip')} text="Zeit und Geld sparen dank individueller Benchmarks" />
      </div>
      <div className={cx('container')}>
        <div className={cx('item')}>
          <TableMenu
            pdfExport
            active={td1Quarter}
            onIntervalChange={() => null}
            title="benchmarking.title.rankings-regional"
          />
          <RankingsTable
            pdfExport
            tableHeaderTwo="benchmarking.table-heading.points-land"
            tableHeaderThree="benchmarking.table-heading.points-of-subject"
            data={tableData || []}
          />
          <TableFooter pdfExport className={cx('graph-footer')} />
        </div>
        <div className={cx('item')}>
          <TableMenu
            pdfExport
            active={td2Quarter}
            onIntervalChange={() => null}
            title="trend.title.overall"
          />
          <div className={cx('graph-container')}>
            <Graph pdfExport max={104} data={td2} />
          </div>
          <TableFooter pdfExport className={cx('graph-footer')} />
        </div>
      </div>
      <div className={cx('container', 'container--last')}>
        <div className={cx('item')}>
          <TableMenu
            pdfExport
            active={td3Quarter}
            onIntervalChange={() => null}
            title="benchmarking.title.overview-seo"
          />
          <div className={cx('table-container')}>
            <BenchmarkingTable pdfExport data={td3} />
          </div>
        </div>
        <div className={cx('item')}>
          <TableMenu
            pdfExport
            active={td4Quarter}
            onIntervalChange={() => null}
            title="benchmarking.title.writing-style"
          />
          <div className={cx('table-container')}>
            <BenchmarkingTable pdfExport data={td4} />
          </div>
        </div>
        <div className={cx('statement')}>
          Bemerkung: Individuelle Vergleiche - B & C - erhalten Sie, nachdem Sie ein Profil erstellt haben.
        </div>
      </div>
    </div>
  );
};
