import React from 'react';
import style from './index.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
}

export const TextCard = (props: Props) => {
  return (
    <div className={`${props.className || ''} ${style.card}`}>
      <>
        {props.children}
      </>
    </div>
  );
};
