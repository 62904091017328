import { RefObject, useEffect } from 'react';
import { InputEvent } from '../types/events';

export const useOutsideClick = (ref: RefObject<HTMLInputElement | HTMLDivElement>, callback: (v: boolean) => void) => {
  const handleClick = (e: InputEvent) => {
    if (e.target.dataset.outsideclick === 'disabled') return;
    if (ref.current && !ref.current.contains(e.target)) {
      callback(false);
    }
  };

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    document.addEventListener('click', handleClick);

    return () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      document.removeEventListener('click', handleClick);
    };
  });
};
