import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from "../../components/ui/Text";
import {TextCard} from "../../components/layout/TextCard";
import {images} from "../../assets/img";
import {Button} from "../../components/ui/Button";

const cx = classNames.bind(styles);

interface Props {
  hidden?: boolean;
}
export const Rating:React.FC<Props> = (props) => {
  if(props.hidden) return null;
  return (
    <div className={cx('rating')}>
      <TextCard className={cx('rating__card')}>
        <h2 className={cx('heading')}><Text msg='profile.rating'/></h2>
        <img src={images.star} alt="" className={cx('rating__star')}/>
        <img src={images.star} alt="" className={cx('rating__star')}/>
        <img src={images.star} alt="" className={cx('rating__star')}/>
        <img src={images.star} alt="" className={cx('rating__star')}/>
        <img src={images.star} alt="" className={cx('rating__star')}/>
      </TextCard>
      <Button msg='profile.rating.button' disabled className={cx('rating__button')}/>
    </div>
  );
};
