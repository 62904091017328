import React from 'react';
import style from './index.module.scss';

interface Props {
  className?: string;
  children: React.ReactNode;
  centered?: boolean;
}

const Container = (props: Props) => {
  const classNames = [style.container, props.className, props.centered ? style.centered : ''].join(' ');
  return <div className={classNames}>{props.children}</div>;
};

export default Container;
