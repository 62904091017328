import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Container from '../../components/layout/Container';
import React, {useEffect, useState} from 'react';
import 'react-quill/dist/quill.snow.css';
import {SectionTexts} from './SectionText';
import {NavBar} from './Tabs';
import {useDispatch} from 'react-redux';
import {getSectionTextData} from '../../store/editSectionText';
const cx = classNames.bind(styles);
const tabs = ['benchmarking', 'trendanalyse', 'branding', 'seo', 'engagement', 'visuals'];

export const EditSectionTexts = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState<number>(0);
  useEffect(() => {
    dispatch(getSectionTextData.request());
  }, [dispatch]);
  const renderSectionTexts = () =>
    tabs.map((value, i) => (i === selectedTab ? <SectionTexts key={i} index={value} /> : null));

  return (
    <Container className={cx('container')}>
      <NavBar selectedTab={selectedTab} setSelectedTab={setSelectedTab} tabs={tabs} />
      {renderSectionTexts()}
    </Container>
  );
};
