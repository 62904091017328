import {getType, ActionType} from 'typesafe-actions';
import {ForgotPasswordForm, ForgotPasswordErrors} from "./types";
import * as actions from './forgot-password.actions';

type Action = ActionType<typeof actions>

export interface ForgotPasswordState {
  form: ForgotPasswordForm,
  loading: boolean;
  success?: boolean;
  errors: ForgotPasswordErrors
}

const form: ForgotPasswordForm = {
  email: '',
  password: '',
}

const initialState: ForgotPasswordState = {
  form,
  loading: false,
  errors: {}
}

export const forgotPasswordReducer = (state = initialState, action: Action): ForgotPasswordState => {
  switch (action.type) {
    case getType(actions.clearState):
      return initialState;
    case getType(actions.onInputChange):
      const {name, value} = action.payload;
      return {
        ...state,
        form: {...state.form, [name]: value},
        errors: {...state.errors, [name]: ''}
      }
    case getType(actions.changePassword.request):
      return {...state, loading: true, errors: {}, success: false}
    case getType(actions.changePassword.failure):
      return {...state, loading: false, errors: action.payload, success: false}
    case getType(actions.changePassword.success):
      return {...initialState, success: true}
    default :
      return state
  }
}
