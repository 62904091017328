import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from "../Text";
import {images} from '../../../assets/img';
import {Link} from 'react-router-dom';
import {HoverPopup} from "../HoverPopup";

const cx = classNames.bind(styles);

interface ButtonProps {
  onClick?: () => void;
  as?: 'a' | 'button' | 'div';
  href?: string,
  className?: string;
  loading?: boolean;
  msg: string;
  disabled?: boolean;
  newTab?: boolean;
  disabledMessage?: string;
  hidden?: boolean;
  theme?: 'blue' | 'grey';
}

export const Button = (props: ButtonProps) => {
  const {loading, onClick, className, theme, as, msg, href, hidden, disabled, disabledMessage} = props;
  const classNames = cx(className, `button--${theme}`, 'button', disabled && 'button--disabled')
  if (hidden) return null;

  const content = (
    <>
      <span><Text msg={msg}/></span>
      {loading
        ? <div className={cx('loader', `loader--theme`)}/>
        : <img src={theme === 'blue' ? images.arrowRightWhite : images.arrowRight} alt="" className={cx('arrow')}/>
      }
    </>
  )

  const onButtonClick = () => {
    if (disabled || !onClick) return;
    onClick()
  }

  if (as === 'a') {
    return (
      <Link to={href!} className={classNames}>
        {disabledMessage && <HoverPopup text={disabledMessage} visible={disabled} className={cx('not-available')}/>}
        {content}
      </Link>
    )
  }
  if (as === 'div') {
    return (
      <div className={classNames} onClick={onButtonClick}>
        {disabledMessage && <HoverPopup text={disabledMessage} visible={disabled} className={cx('not-available')}/>}
        {content}
      </div>
    )
  }
  return (
    <button disabled={loading} className={classNames} onClick={onButtonClick}>
      {disabledMessage && <HoverPopup text={disabledMessage} visible={disabled} className={cx('not-available')}/>}
      {content}
    </button>
  );
};

Button.defaultProps = {
  as: 'button',
}
