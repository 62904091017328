import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {ConnectedRouter} from 'connected-react-router';
import {store, history} from './store';
import './style/index.scss';
import axios from 'axios';
import {Provider} from 'react-redux';
import {generateTimedQueryParam} from './utils/getUsersTimeInCest';

if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;
}

const queryParam = generateTimedQueryParam();
axios.defaults.params = {};
axios.defaults.params['as'] = queryParam;
console.log(axios.defaults.baseURL);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();

/**
 2021-06-06T15:07:31+00:00
 2021-06-06T15:07:31Z
 20210606T150731Z
 */
