import React, {useEffect, useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {DemoForm, homePageState} from "../../../store/home";
import {useDispatch, useSelector} from "react-redux";
import {ModalWithLogo} from "../../../components/ModalWithLogo";
import {images} from "../../../assets/img";
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../../constants/api";
import {onSubmitURL, showFailedModal, submitReportRequest, onInputChange} from '../../../store/home';
import {Loader} from "../../../components/ui/Loader";

const cx = classNames.bind(styles);

export const FailedDemoModal = () => {
  const dispatch = useDispatch();
  const {form: values, submittingReportRequest,submittingUrl, errors} = useSelector(homePageState);
  const [pages, setPages] = useState<string[] | null>(null)
  const [submittingReportFromClickedLink, setSubmittingReport] = useState(false);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;
    dispatch(onInputChange({value, name: name as keyof DemoForm}))
  }

  useEffect(() => {
    const getUrlList = async () => {
      try {
        const {data}: AxiosResponse<{ pages: string[] }> = await axios.post(apiEndpoints.publicPages, {url: values.url});
        console.log(data);
        setPages(data.pages)
      } catch (e) {
        console.log(e)
      }
    }
    if (values.url) {
      getUrlList()
    }
  }, [])

  const renderPages = () => {
    if (!pages && !values.url) return null;
    if (!pages) return <Loader className={cx('loader')}/>
    if (submittingReportFromClickedLink && submittingUrl) return <Loader className={cx('loader')}/>
    return pages.map((page, i) => {
      return (
        <div
          className={cx('url')}
          key={i}
          onClick={() => {
            dispatch(onSubmitURL.request({url: page}))
            setSubmittingReport(true);
          }}>
          {page}
        </div>
      )
    })
  }

  return (
    <ModalWithLogo
      withCloseButton
      contentClassName={cx('modal-content')}
      close={() => dispatch(showFailedModal(false))}
      visible={true} className={cx('modal')}
    >
      <div className={cx('container')}>
        <h3 className={cx('heading')}>Lieber Nutzer,</h3>
        <p className={cx('text')}>
          wir sind nicht ganz sicher, ob sie die URL richtig eingegeben haben. Meinen Sie eine dieser Adressen?
        </p>
        <div className={cx('url-list')}>
          {renderPages()}
        </div>
      </div>
      <div className={cx('background')}>
        <div className={cx('container')}>
          <p className={cx('text')}>
            Die URL, die Sie meinten ist nicht dabei? Kein Problem, wir kümmern uns darum und senden Ihnen nach der
            Überprüfung das Ergebnis per E-Mail zu.
          </p>
          <div className={cx('label')}>Bitte bestätigen Sie Ihre URL:</div>
          {errors.url && <div className={cx('error')}>{errors.url}</div>}
          <input name='url' onChange={onChange} value={values.url} className={cx('input')} type="text"
                 placeholder='URL Ihrer Beitragsseite hier eingeben'/>
          <div className={cx('label')}>Bitte überprüfen Sie Ihre E-Mail-Adresse, um Ihre Daten in den nächsten 3
            Werktagen zu erhalten:
          </div>
          {errors.email && <div className={cx('error')}>{errors.email}</div>}
          <input name='email' onChange={onChange} value={values.email} className={cx('input')} type="text"
                 placeholder='E-Mail-Adresse importieren'/>
          <div className={cx('label')}>Ich bin kein Roboter</div>
          {errors.code && <div className={cx('error')}>{errors.code}</div>}
          <div className={cx('row')}>
            <input onChange={onChange} value={values.code} className={cx('input', 'input--small')} type="text"
                   name='code'
                   placeholder='7+14='/>
            <button className={cx('button')} onClick={() => dispatch(submitReportRequest.request())}>
              {submittingReportRequest ?
                <Loader className={cx('loader')}/> :
                <>
                  Senden
                  <img src={images.arrowRight} className={cx('arrow')} alt=""/>
                </>
              }
            </button>
          </div>
        </div>
      </div>
    </ModalWithLogo>
  );
};
