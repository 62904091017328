import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {useDispatch, useSelector} from "react-redux";
import {appState} from "../../../store/app/app.reducer";
import {Button} from "../Button";
import {showConfirmModal} from "../../../store/app/app.actions";
import {Text} from "../Text";

const cx = classNames.bind(styles);

export const ConfirmationModal = () => {
  const dispatch = useDispatch();
  const {confirmationModal} = useSelector(appState);
  if (!confirmationModal) return null;

  const {title, onConfirm} = confirmationModal;

  return (
    <>
      <div className={cx('modal')}>
        <h4 className={cx('title')}>
          <Text msg={title}/>
        </h4>
        <div className={cx('container')}>
          <Button
            msg='common.no'
            onClick={() => dispatch(showConfirmModal(null))}
            as='button'
            className={cx('button', 'button__cancel')}
          />
          <Button
            msg='common.yes'
            onClick={() => {
              onConfirm()
              dispatch(showConfirmModal(null))
            }}
            as='button'
            className={cx('button', 'button__confirm')}
          />
        </div>
      </div>
      <div onClick={() => dispatch(showConfirmModal(null))} className={cx('backdrop')}/>
    </>
  );
};
