import React from 'react';
import Container from "../../components/layout/Container";
import LoginCard from "./LoginCard";
import RegisterCard from "./RegisterCard";
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const Authentication = () => (
  <Container className={cx('container')}>
    <LoginCard/>
    <RegisterCard/>
  </Container>
);
