import React from 'react';
import { TextCard } from '../../components/layout/TextCard';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Select, { Option } from '../../components/ui/Select';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserProfile } from '../../store/profile';
import {
  onInputChange,
  updateProfile,
} from '../../store/profile/userProfile/userProfile.actions';
import { FormEvent } from '../../types/events';
import {
  FormErrorMessage,
  FormSuccessMessage,
  Button,
  CountriesSelection,
  TextInput,
  Text,
} from '../../components/ui';
import { selectUser } from '../../store/auth';
import { QuestionMark } from '../../components/ui/QuestionMark';

const cx = classNames.bind(styles);

export const UserProfile = () => {
  const {
    form,
    formErrors,
    formIsUpdating,
    formHasChanged,
    formUpdateSuccess,
  } = useSelector(selectUserProfile);
  const { masterAccountId } = useSelector(selectUser);

  const dispatch = useDispatch();

  const onChange = (name: string, value: string | boolean) => {
    dispatch(onInputChange({ name, value }));
  };
  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(updateProfile.request());
  };

  return (
    <div className={cx('user-profile')}>
      <QuestionMark
        content={
          masterAccountId
            ? 'profile.questionmark.user'
            : 'profile.questionmark.admin'
        }
        tooltipPosition="left"
        className={cx('question-mark')}
      />
      <TextCard>
        <h2 className={cx('heading')}>
          <Text
            msg={
              masterAccountId
                ? 'profile.user-profile.user'
                : 'profile.user-profile.admin'
            }
          />
        </h2>
        <form onSubmit={onSubmit}>
          <TextInput
            labelClassName={cx('label')}
            onChange={onChange}
            name="email"
            labelPosition="left"
            error={formErrors?.email}
            value={form.email}
            className={cx('input')}
            label="profile.label.email"
          />
          <TextInput
            labelClassName={cx('label')}
            onChange={onChange}
            name="firstName"
            capitalize
            labelPosition="left"
            error={formErrors?.firstName}
            value={form.firstName}
            className={cx('input')}
            label="profile.label.first-name"
          />
          <TextInput
            labelClassName={cx('label')}
            onChange={onChange}
            name="lastName"
            capitalize
            labelPosition="left"
            error={formErrors?.lastName}
            value={form.lastName}
            className={cx('input')}
            label="profile.label.last-name"
          />
          <TextInput
            labelClassName={cx('label')}
            onChange={onChange}
            name="telephone"
            capitalize
            labelPosition="left"
            error={formErrors?.telephone}
            value={form.telephone}
            className={cx('input')}
            label="profile.label.telephone"
          />
          <Select
            labelClassName={cx('label')}
            className={cx('input')}
            name="status"
            value={form.status}
            label="profile.label.status"
            onChange={onChange}
            labelPosition="left"
            translate
            error={formErrors.status}
          >
            <Option label="common.mr" value="Herr" />
            <Option label="common.ms" value="Frau" />
            <Option label="common.various" value="Keine Angaben" />
          </Select>
          <TextInput
            labelClassName={cx('label')}
            onChange={onChange}
            name="companyName"
            labelPosition="left"
            error={formErrors?.companyName}
            value={form.companyName}
            className={cx('input')}
            label="profile.label.company"
          />
          <CountriesSelection
            name="land"
            label="profile.label.region"
            labelClassName={cx('label')}
            onChange={onChange}
            error={formErrors?.land}
            showDefaultCountries={true}
            value={form.land}
            className={cx('input')}
          />
          <FormErrorMessage
            message={formErrors?.server}
            className={cx('error')}
          />
          <FormSuccessMessage
            message="profile.save-success"
            visible={formUpdateSuccess}
          />
        </form>
      </TextCard>
      <Button
        hidden={!formHasChanged}
        loading={formIsUpdating}
        as="button"
        onClick={() => dispatch(updateProfile.request())}
        msg={'profile.confirm'}
        className={cx('button')}
      />
    </div>
  );
};
