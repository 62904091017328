import {Page, PageResponse} from "./types";

export const mapPages = (data: PageResponse): Page[] => {
  const pagesArray = data;
  return pagesArray.pages.map((page, i) => {
    return {
      index: i+1,
      ...page
    }
  })
}
