import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {images} from '../../assets/img';

const cx = classNames.bind(styles);

interface Props {
  className?: string;
  visible?: boolean;
  close: () => void;
  contentClassName?: string;
  withCloseButton?: boolean;
}

export const ModalWithLogo: React.FC<Props> = (props) => {
  const {className, withCloseButton, close, visible, children, contentClassName} = props;

  useEffect(() => {
    const onEscape = (e: KeyboardEvent) => {
      if (e.keyCode === 27) close();
    };
    document.addEventListener('keydown', onEscape);
    return () => {
      document.removeEventListener('keydown', close);
    };
  }, [close]);
  if (!visible) return null;
  return (
    <>
      <div onClick={close} className={cx('backdrop')} />
      <div className={cx('modal', className)}>
        <header>
          <img src={images.logo} alt="" className={cx('logo')} />
          {withCloseButton && <img onClick={close} src={images.times} alt="" className={cx('close')} />}
        </header>
        <div className={cx('content', contentClassName)}>{children}</div>
      </div>
    </>
  );
};
