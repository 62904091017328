import {AccessSections} from "../store/app/types";
import {User} from "../store/auth/user";

export const useHasNoAccess = (section:AccessSections, user:Partial<User>)=>{
  // if(user.role==='admin') return false
  const {subscription} = user
  return subscription?.noAccessSections.includes(section)
}

export const useHasLimitedAccess = (section:AccessSections, user: Partial<User>)=>{
  // if(user.role==='admin') return false
  return user.subscription?.limitedAccessSections.includes(section)
}
