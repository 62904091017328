import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { QuestionMark } from '../../components/ui/QuestionMark';
import { Text } from '../../components/ui/Text';
import { Button } from '../../components/ui/Button';
import { withComma } from '../../utils/numbersWithCommas';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const cx = classNames.bind(styles);

interface Props {
  subscriptionId?: number;
  cardId: number;
  cancelSubscription: (id: number) => void;
  toggleModal: (id: number) => void;
  planName: string;
  yearPrice: number;
  quarterPrice: number;
  isTrial?: boolean;
  subscriptionEndDate?: string;
  disableButtons?: boolean;
  infoUsers: string;
  infoUrls: string;
  hasErrors?: boolean;
}

export const PlanCard: React.FC<Props> = (props) => {
  const {
    yearPrice,
    quarterPrice,
    subscriptionEndDate,
    hasErrors,
    disableButtons,
    planName,
    infoUrls,
    infoUsers,
    isTrial,
    subscriptionId,
    cardId,
    cancelSubscription,
    toggleModal,
  } = props;

  const renderButtons = () => {
    if (disableButtons) return null;
    return cardId === subscriptionId ? (
      <Button
        msg="subscription.cancel"
        onClick={() => cancelSubscription(cardId)}
        className={cx('button', 'button--cancel')}
      />
    ) : (
      <Button
        msg="subscription.activate"
        onClick={() => toggleModal(cardId)}
        className={cx('button')}
      />
    );
  };
  const tooltips = [
    'subscription.basic.questionmark',
    'subscription.pro.questionmark',
    'subscription.premium.questionmark',
  ];
  return (
    <div>
      <div
        className={cx(
          'plan-card',
          subscriptionId === cardId && 'plan-card__active'
        )}
      >
        <QuestionMark
          content={tooltips[cardId]}
          tooltipPosition={cardId === 0 ? 'right' : 'left'}
          className={cx('question-mark')}
        />
        <h2 className={cx('heading')}>{planName}</h2>
        <h3 className={cx('price')}>
          {withComma(yearPrice, 0)} <Text msg="subscription.plan.price" />
        </h3>
        <div className={cx('price-subtitle')}>
          <Text msg="subscription.plan.price.subtitle" />
        </div>
        <div className={cx('price-quarter')}>
          <span>
            <Text msg="subscription.info.title-2" />
          </span>
          <br />
          <Text msg="subscription.info.title-3" /> {quarterPrice} CHF
        </div>
        <p className={cx('desc')}>
          <Text msg="subscription.card.description" />
        </p>
        <p
          className={cx(
            'active-plan',
            cardId === subscriptionId && 'active-plan--visible'
          )}
        >
          <b>
            <Text msg={'subscription.active'} />
          </b>
          <small>({moment(subscriptionEndDate).format('YYYY-MM-DD')})</small>
        </p>
        <p
          className={cx(
            'trial',
            cardId === subscriptionId && isTrial && 'trial--visible'
          )}
        >
          <Text msg="subscription.trial" />
        </p>
        <p className={cx('info')}>
          <Text msg={infoUsers} />
          <br />
          <Text msg={infoUrls} />
        </p>
      </div>
      {hasErrors && (
        <div className={cx('plan-error')}>
          Bitte ergänzen Sie alle notwendigen Angaben für die Rechnungsstellung
        </div>
      )}
      {renderButtons()}
    </div>
  );
};
