import {ReportResponse, ReportSettings} from '../store/report/types';
import {Labels} from "../components/LinearGraph/Layers";
import {GraphColors} from "../constants/constants";

export const mapGetReportResponse = (data: ReportResponse): ReportSettings => {
  const {dataToInclude, reportFormats, sendingInterval} = data;

  const settings: ReportSettings = {
    dataToInclude: {},
    reportFormats: {},
    sendingInterval: sendingInterval[0],
  }
  dataToInclude.forEach((setting) => {
    settings.dataToInclude[setting] = true;
  })
  reportFormats.forEach((setting) => {
    settings.reportFormats[setting] = true;
  })
  return settings;
}

export const mapPostReportData = (settings: ReportSettings) => {
  const dataToInclude: string[] = [];
  const reportFormats: string[] = [];

  Object.entries(settings.dataToInclude).forEach(([key, value]) => value && dataToInclude.push(key))
  Object.entries(settings.reportFormats).forEach(([key, value]) => value && reportFormats.push(key))

  return {dataToInclude, reportFormats, sendingInterval: settings.sendingInterval};
}

export const mapGraphData = (data: [string, string][][]) => {
  const mapResponse = (data: [string, string][], id: string, color: string) => {
    return {
      data: data?.map((item) => ({x: item[0], y: parseFloat(item[1])})),
      color,
      id
    };
  }
  const mappedResponse = []
  if (data[0]?.length) mappedResponse.push(mapResponse(data[0], Labels.A, GraphColors.color1));
  if (data[1]?.length) mappedResponse.push(mapResponse(data[1], Labels.B, GraphColors.color2));
  if (data[2]?.length) mappedResponse.push(mapResponse(data[2], Labels.C, GraphColors.color3));
  if (data[3]?.length) mappedResponse.push(mapResponse(data[3], Labels.D, GraphColors.color4));
  if (data[4]?.length) mappedResponse.push(mapResponse(data[4], Labels.E, GraphColors.color5));
  if (data[5]?.length) mappedResponse.push(mapResponse(data[5], Labels.F, GraphColors.color6));
  
  return mappedResponse;
}
