import {put} from 'redux-saga/effects';
import {showErrorModal} from "../store/app/app.actions";
import {logout} from "../store/auth/user";

export function* handleResponseError(e: any, cb?: any):Generator {
  // Catch any unexpected app errors
  if (!e.response && !cb) {
    console.log(e)
    return yield put(showErrorModal({}))
  }
  // Check for expired tokens
  if(e.response?.status === 400 && e.response?.data?.message ==='NO_TOKEN'){
    yield put(logout({redirect:true}))
    return yield put(showErrorModal({
      title: 'common.expired-session',
      message: 'common.expired-session-desc',
    }))
  }
  if (e.response?.status === 403) {
    yield put(logout({redirect:true}))
    return yield put(showErrorModal({
      title: 'common.expired-session',
      message: 'common.expired-session-desc',
    }))
  }
  // Execute callback function or show standard error modal
  if (cb) {
    yield put(cb())
  } else {
    yield put(showErrorModal({}))
  }

}
