import React from 'react';
import {NavigationPaths} from "../../../constants/navigationPaths";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {useLocation} from 'react-router-dom';
const cx = classNames.bind(styles);

export const Footer = () => {
  const location = useLocation();
  console.log(location)
  if(location.pathname.includes('pdf')) return null
  return (
    <footer className={cx('footer')}>
      <div className={cx('container')}>
        <a rel="noopener noreferrer" className={cx('left-side')} href="https://pageTracker.drkpi.com" target='_blank'>Powered by PageTracker.drkpi.com</a>
        {location.pathname==='/' && <span className={cx('center')}>*Keine Kosten. Keine automatische Verlängerung der Testversion.</span>}
        <span className={cx('right-side')}>
        <a rel="noopener noreferrer" href={NavigationPaths.dataProtection} target='_blank'>Impressum + Datenschutz</a> I <a rel="noopener noreferrer" href={NavigationPaths.agb} target='_blank'>AGB</a>
      </span>
      </div>
    </footer>
  );
};
