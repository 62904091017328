import {NavigationPaths} from '../../../constants/navigationPaths';

export interface Menu {
  title: string;
  desc: string;
  path: string;
  width: string;
  questionMark?: string;
  allowedForTrials?: boolean;
  position?: 'bottom' | 'top' | 'middle';
}

export const settingsMenu: Menu[] = [
  {
    title: 'menu.login.title',
    desc: 'menu.login.desc',
    path: NavigationPaths.authentication,
    width: '50%',
    questionMark: 'questionmark.login',
    allowedForTrials: true,
  },
  {
    title: 'menu.profile.title',
    desc: 'menu.profile.desc',
    path: NavigationPaths.profile,
    width: '40%',
    questionMark: 'questionmark.profile',
    allowedForTrials: true,
  },
  {
    title: 'menu.compare.title',
    desc: 'menu.compare.desc',
    path: NavigationPaths.compare,
    width: '90%',
    questionMark: 'questionmark.compare',
  },
  {
    title: 'menu.report.title',
    desc: 'menu.report.desc',
    path: NavigationPaths.report,
    width: '60%',
    questionMark: 'questionmark.report',
  },
  {
    title: 'menu.archive.title',
    desc: 'menu.archive.desc',
    path: NavigationPaths.archive,
    width: '50%',
    questionMark: 'questionmark.archive',
  },
  {
    title: 'menu.pdfReportEdit.title',
    desc: 'menu.pdfReportEdit.desc',
    path: NavigationPaths.editPdfReport,
    width: '50%',
    questionMark: 'questionmark.pdfReportEdit',
  },
  {
    title: 'menu.sectionTextEdit.title',
    desc: 'menu.sectionTextEdit.desc',
    path: NavigationPaths.editTextSections,
    width: '50%',
    questionMark: 'questionmark.sectionTextEdit',
  },
];

export const homeMenu: Menu[] = [
  {
    title: 'menu.benchmarking.title',
    desc: 'menu.benchmarking.desc',
    path: NavigationPaths.benchmarking,
    width: '50%',
    questionMark: 'questionmark.benchmarking',
  },
  {
    title: 'menu.trendanalyse.title',
    desc: 'menu.trendanalyse.desc',
    path: NavigationPaths.trend,
    width: '40%',
    questionMark: 'questionmark.trendanalyse',
  },
  {
    title: 'menu.branding.title',
    desc: 'menu.branding.desc',
    path: NavigationPaths.branding,
    width: '70%',
    questionMark: 'questionmark.branding',
  },
  {
    title: 'menu.seo.title',
    desc: 'menu.seo.desc',
    path: NavigationPaths.seo,
    width: '90%',
    questionMark: 'questionmark.seo',
    position: 'middle',
  },
  {
    title: 'menu.engagement.title',
    desc: 'menu.engagement.desc',
    path: NavigationPaths.engagement,
    width: '60%',
    questionMark: 'questionmark.engagement',
    position: 'top',
  },
  {
    title: 'menu.visuals-seo.title',
    desc: 'menu.visuals-seo.desc',
    path: NavigationPaths.visuals,
    width: '50%',
    questionMark: 'questionmark.visuals',
    position: 'top',
  },
  
]
