import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Container from '../../components/layout/Container';
import {useDispatch, useSelector} from 'react-redux';
import {getComparePages} from '../../store/compare/compare.actions';
import {AuthorRepliesCount} from './AuthorRepliesCount';
import {AuthorRepliesChart} from './AuthorRepliesChart';
import {CommentsCount} from './CommentsCount';
import {DropDownDescription} from '../../components/DropDownDescription';
import {getCurrentSectionTextData, selectEditSectionText} from '../../store/editSectionText';

const cx = classNames.bind(styles);

export const Engagement = () => {
  const dispatch = useDispatch();
  const {currentPage, loading} = useSelector(selectEditSectionText);
  useEffect(() => {
    dispatch(getCurrentSectionTextData.request('engagement'));
    dispatch(getComparePages.request({showWarningIfPending: true}));
  }, [dispatch]);
  return (
    <Container className={cx('container')}>
      <CommentsCount />
      <DropDownDescription loading={loading} activeIndex={0} data={currentPage[0]} />
      <AuthorRepliesCount />
      <DropDownDescription loading={loading} data={currentPage[1]} />
      <AuthorRepliesChart />
      <DropDownDescription loading={loading} data={currentPage[2]} />
    </Container>
  );
};
