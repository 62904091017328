import {createAction, createAsyncAction} from 'typesafe-actions';
import {Lang, ConfirmationModal, ErrorModal, SystemParameters} from './types';

export const changeLang = createAction('app/CHANGE_LANGUAGE')<Lang>();

export const showConfirmModal = createAction('app/SHOW_CONFIRM_MODAL')<ConfirmationModal | undefined | null>()

export const showErrorModal = createAction('app/SHOW_ERROR_MODAL')<ErrorModal | undefined | null>()

export const showSuccessModal = createAction('app/SHOW_SUCCESS_MODAL')<ErrorModal | undefined | null>()

export const getSystemParameters = createAsyncAction(
  'app/GET_SYSTEM_PARAMETERS_STARTED',
  'app/GET_SYSTEM_PARAMETERS_DONE',
  'app/GET_SYSTEM_PARAMETERS_FAILED',
)<void, SystemParameters, void>()
