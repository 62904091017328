import {createAsyncAction, createAction} from 'typesafe-actions';
import {DemoForm} from "./home.reducer";

export const onInputChange = createAction('home-page/ON_INPUT_CHANGE')<{ name: keyof DemoForm, value: string }>()

export const onSubmitURL = createAsyncAction(
  'home-page/ON_SUBMIT_URL_STARTED',
  'home-page/ON_SUBMIT_URL_DONE',
  'home-page/ON_SUBMIT_URL_FAILED',
)<{ url?: string }, void, DemoForm>()

export const submitReportRequest = createAsyncAction(
  'home-page/ON_SUBMIT_REPORT_REQUEST_STARTED',
  'home-page/ON_SUBMIT_REPORT_REQUEST_DONE',
  'home-page/ON_SUBMIT_REPORT_REQUEST_FAILED',
)<void, void, DemoForm>()

export const showDemoModal = createAction('home-page/SHOW_DEMO_MODAL')<boolean>()
export const showSuccessModal = createAction('home-page/SHOW_SUCCESS_MODAL')<boolean>()
export const showFailedModal = createAction('home-page/SHOW_FAILED_MODAL')<boolean>()
export const showSecondarySuccessModal = createAction('home-page/SHOW_SECONDARY_SUCCESS_MODAL')<boolean>()
