import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {images} from '../../../assets/img';
import {DemoForm, homePageState, showDemoModal} from '../../../store/home';
import * as actions from '../../../store/home/home.actions';
import {useDispatch, useSelector} from 'react-redux';
import {UrlSearchInput} from '../UrlSearchInput';
import {Loader} from '../../../components/ui/Loader';

const cx = classNames.bind(styles);

export const DemoModal = () => {
  const dispatch = useDispatch();
  const {form: values, submittingUrl, errors} = useSelector(homePageState);
  const now = new Date();
  const day = now.getDate();
  const month = now.getMonth() + 1;

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const {value, name} = e.target;
    dispatch(actions.onInputChange({value, name: name as keyof DemoForm}));
  };

  const onSubmit = () => {
    if (submittingUrl) return;
    dispatch(actions.onSubmitURL.request({url: values.url}));
  };

  return (
    <div className={cx('modal')}>
      <img
        src={images.times}
        alt=""
        onClick={() => dispatch(showDemoModal(false))}
        className={cx('button-close')}
      />
      <img src={images.logo} className={cx('logo')} alt="" />
      <img src={images.rocket} className={cx('background')} alt="" />
      <div className={cx('content')}>
        <h2 className={cx('heading')}>BEREIT FÜR IHREN ERSTEN WEB PERFORMANCE TEST?</h2>
        {errors.url && <div className={cx('error')}>{errors.url}</div>}
        <div className={cx('row')}>
          <UrlSearchInput
            value={values.url}
            onChange={onChange}
            className={cx('input--long')}
            onSelect={(url) => dispatch(actions.onInputChange({name: 'url', value: url}))}
          />
          <div className={cx('explanation', 'explanation--url')}>
            z.B.: <br /> https://mclago.com/marketing-blog/
          </div>
        </div>
        <div className={cx('row')}>
          <div className={cx('input', 'input--medium')}>
            {errors.email && <div className={cx('error')}>{errors.email}</div>}
            <input
              type="text"
              onChange={onChange}
              name="email"
              value={values.email}
              placeholder="Ihre E-Mail für ein Resultat"
            />
          </div>
          <div className={cx('input', 'input--short')}>
            {errors.code && <div className={cx('error')}>{errors.code}</div>}
            <input
              type="number"
              onChange={onChange}
              name="code"
              value={values.code}
              placeholder={`${month}+${day}=`}
            />
          </div>
          <button onClick={onSubmit} className={cx('input', 'input--short', 'button')}>
            {submittingUrl ? (
              <Loader className={cx('loader')} />
            ) : (
              <>
                Senden
                <img src={images.arrowRight} className={cx('chevron')} alt="" />
              </>
            )}
          </button>
          <div className={cx('explanation', 'explanation--robot')}>
            Ich bin kein <br /> Roboter!
          </div>
        </div>
      </div>
    </div>
  );
};
