import {createAction, createAsyncAction} from 'typesafe-actions';
import {InputPayload} from "../../../types/events";
import {ProfileErrors, UserProfile} from './types';
import {User} from "../../auth/user";

export const onInputChange = createAction('profile/PROFILE_INPUT_CHANGE')<InputPayload>();

export const updateProfile = createAsyncAction(
  'profile/PROFILE_UPDATE_STARTED',
  'profile/PROFILE_UPDATE_DONE',
  'profile/PROFILE_UPDATE_FAILED',
)<void, UserProfile, ProfileErrors>()

export const getUserProfile = createAsyncAction(
  'profile/PROFILE_GET_USER_PROFILE_STARTED',
  'profile/PROFILE_GET_USER_PROFILE_DONE',
  'profile/PROFILE_GET_USER_PROFILE_FAILED',
)<void, User, void>()

export const clearAllSuccessMessages = createAction('profile/CLEAR_ALL_SUCCESS_MESSAGES')()
