import {getType, ActionType} from 'typesafe-actions';
import {CreatePasswordForm, ForgotPasswordErrors} from "./types";
import * as actions from './create-password.actions';

type Action = ActionType<typeof actions>

export interface CreatePasswordState {
  form: CreatePasswordForm,
  loading: boolean;
  success?: boolean;
  errors: ForgotPasswordErrors
}

const form: CreatePasswordForm = {
  confirmPassword: '',
  password: '',
}

const initialState: CreatePasswordState = {
  form,
  loading: false,
  errors: {}
}

export const createPasswordReducer = (state = initialState, action: Action): CreatePasswordState => {
  switch (action.type) {
    case getType(actions.onInputChange): {
      const {name, value} = action.payload;
      return {
        ...state,
        form: {...state.form, [name]: value},
        errors: {...state.errors, [name]: ''},
        success:false,
      }
    }
    case getType(actions.submitPassword.request):
      return {...state, loading: true, errors: {}, success:false}
    case getType(actions.submitPassword.success):
      return {...state, loading:false, errors:{}, success:true, form}
    case  getType(actions.submitPassword.failure) :
      return {...state, loading:false, errors:action.payload}
    default:
      return state;
  }
}
