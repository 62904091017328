import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {countries} from "../../../assets/countries";
import Select, {Option} from "../Select";
import {useSelector} from "react-redux";
import {selectAppState} from "../../../store/app";

const cx = classNames.bind(styles);

interface Props {
  className?: string;
  error?: string;
  value?: string;
  valueClassName?: string;
  labelClassName?: string;
  showDefaultCountries?: boolean;
  label: string;
  placeholder?: string;
  name:string;
  disabled?: boolean;
  labelPosition?: 'left' | 'top';
  onChange: (name: string, value: string | boolean) => void;
}

export const CountriesSelection = (props: Props) => {
  const {parameters} = useSelector(selectAppState);
  const {className,placeholder, value,name,disabled, label, valueClassName, showDefaultCountries, labelPosition = 'left', labelClassName, onChange, error} = props;

  const renderCountries = () => {
    if (!parameters || showDefaultCountries) {
      return countries.map((country, i) => {
        return (
          <Option
            className={cx('option')}
            translate={false}
            key={i}
            value={country.name}
            label={country.name}
          />
        )
      })
    }
    return parameters.countries.map((country, i) => {
      return (
        <Option
          className={cx('option')}
          translate={false}
          key={i}
          value={country.id}
          label={country.name}
        />
      )
    })
  }

  return (
    <Select
      disabled={disabled}
      placeholder={placeholder}
      labelClassName={labelClassName}
      className={className}
      name={name}
      valueClassName={valueClassName}
      labelPosition={labelPosition}
      error={error}
      value={value}
      withSearch
      label={label}
      onChange={onChange}
    >
      {renderCountries()}
    </Select>
  );
};
