import {TrendAnalysis} from "./types";
import {mapGraphData as mapLineGraphData} from "../utils/mapResponseData";
import {SEO, WritingStyle} from "../store/benchmarking/types";

export const mapGraphData = (data?: TrendAnalysis[]) => {
  if (!data) return;
  return mapLineGraphData(data)
}

export const seoData = (data?:SEO)=>{
  if(!data) return;
  return [
    {
      title:'benchmarking.overall-rankings',
      subtitle:'benchmarking.rank',
      leftValue: data.rank,
      rightValue: data.rankDelta,
    },
    {
      title:'benchmarking.average-back-links',
      subtitle:'benchmarking.backlinks',
      leftValue: data.avgBackLinks,
      rightValue: data.avgBackLinksDelta,
    },
    {
      title:'benchmarking.links-to-other-websites',
      leftValue: data.avgLinkOut,
      rightValue: data.avgLinkOutDelta,
    },
    {
      title:'benchmarking.number-of-entities-published',
      subtitle: 'benchmarking.records',
      leftValue: data.posts,
      rightValue: data.postsDelta,
    },
  ]
}

export const writingStyleData = (data: WritingStyle|undefined) => {
  if (!data) return;
  return [
    {
      title: 'benchmarking.average-words-in-articles',
      leftValue: data.avgWordsPerPost,
      rightValue: data.avgWordsPerPostDelta,
    },
    {
      title: 'benchmarking.average-words-in-sentence',
      leftValue: data.avgWordsPerSentence,
      rightValue: data.avgWordsPerSentenceDelta,
    },
    {
      title: 'benchmarking.average-number-subtitles',
      leftValue: data.avgLengthTitle,
      rightValue: data.avgLengthTitleDelta,
    },
    {
      title: 'benchmarking.average-characters-headings',
      subtitle: 'benchmarking.symbols-with-spaces',
      leftValue: data.avgSubtitles,
      rightValue: data.avgSubtitlesDelta,
    },
  ]
}
