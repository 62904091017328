import React from 'react';
import {useSelector} from 'react-redux';
import {appState} from '../../../store/app/app.reducer';
import {text as de} from '../../../constants/text.de';


export const Text: React.FC<{ msg: string }> = ({msg}) => {
  const {lang} = useSelector(appState)
  const translations = {
    de,
  };
  let active = translations[lang]
  let translation = active[msg] ? active[msg] : translations['de'][msg];
  if (!translation) translation = 'not_translated'
  return (
    <>
      {translation}
    </>
  );
};

export const useTranslation = (msg?: string) => {
  const {lang} = useSelector(appState)
  if (!msg) return
  const translations = {
    de,
  };
  let active = translations[lang]
  let translation = active[msg] ? active[msg] : translations['de'][msg];
  if (!translation) translation = 'not_translated'
  return translation
}
