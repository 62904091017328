import {all, takeLatest, select, put, call,} from 'redux-saga/effects';
import {getReportSettings, saveReportSettings, saveReportSettingsAndGetPdf} from './report.actions';
import {ReportResponse} from './types';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../constants/api";
import {mapGetReportResponse, mapPostReportData} from "../../utils/mapResponseData";
import {selectReports} from "./index";
import {handleErrors} from "../../utils/formValidations";
import {handleResponseError} from "../../utils/responseErrors";


function* getReports() {
  try {
    const {data}: AxiosResponse<ReportResponse> = yield call(axios.get, apiEndpoints.reportSettings)
    const mappedData = mapGetReportResponse(data)
    yield put(getReportSettings.success(mappedData))
  } catch (e) {
    yield put(getReportSettings.failure())
    // yield handleResponseError(e)
  }
}

function* saveReports(action: ReturnType<typeof saveReportSettings.request>) {
  const {form} = action.payload;
  const {settings} = selectReports(yield select());
  try {
    const mappedData = mapPostReportData(settings)
    yield call(axios.post, apiEndpoints.reportSettings, mappedData)
    yield put(saveReportSettings.success({form}))
  } catch (e) {
    yield handleResponseError(e, () => saveReportSettings.failure(handleErrors(e)))

  }
}

function* getPdf(action: ReturnType<typeof saveReportSettingsAndGetPdf.request>) {
  const {interval} = action.payload;
  const {settings} = selectReports(yield select());
  try {
    const mappedData = mapPostReportData(settings)
    yield call(axios.post, apiEndpoints.reportSettings, mappedData)
    const {data}: AxiosResponse<{ downloadUrl: string }> = yield call(axios.post, apiEndpoints.exportSettings(interval))
    
    yield put(saveReportSettingsAndGetPdf.success())
    window.open(data.downloadUrl, '_blank');
  } catch (e) {
    yield handleResponseError(e, () => saveReportSettingsAndGetPdf.failure(handleErrors(e)))
  }
}

export function* reportSagas():Generator {
  yield all([
    yield takeLatest(getReportSettings.request, getReports),
    yield takeLatest(saveReportSettingsAndGetPdf.request, getPdf),
    yield takeLatest(saveReportSettings.request, saveReports),
  ])
}
