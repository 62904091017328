import {all, select, takeEvery, put, call} from 'redux-saga/effects';
import {selectBranding} from "./index";
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../constants/api";
import {RankingsHashtags, RankingsBrands, RankingsCompanies, Comparison, Types} from "./types";
import {getData} from "./branding.actions";
import {handleResponseError} from "../../utils/responseErrors";

function* fetchRankingsCompanies() {
  const {rankingsCompanies} = selectBranding(yield select());
  try {
    const {data}: AxiosResponse<{ data: RankingsCompanies[], requestJson: string }> = yield call(
      axios.get, apiEndpoints.getBrandingCompanies(rankingsCompanies.interval || '1')
    )
    yield put(getData.success({data: data.data, type: Types.rankingsCompanies}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.rankingsCompanies}))
  }
}

function* fetchRankingsBrands() {
  const {rankingsBrands} = selectBranding(yield select());
  try {
    const {data}: AxiosResponse<{ data: RankingsBrands[], requestJson: string }> = yield call(
      axios.get, apiEndpoints.getBrandingBrand(rankingsBrands.interval || '1')
    )
    yield put(getData.success({type: Types.rankingsBrands, data: data.data}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.rankingsBrands}))
  }
}

function* fetchRankingsHashtags() {
  const {rankingsHashtags} = selectBranding(yield select());
  try {
    const {data}: AxiosResponse<{ data: RankingsHashtags[], requestJson: string }> = yield call(
      axios.get, apiEndpoints.getBrandingHashtag(rankingsHashtags.interval || '1')
    )
    yield put(getData.success({type: Types.rankingsHashtags, data: data.data}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.rankingsHashtags}))
  }
}

function* fetchRankingsCompaniesComparison() {
  const {rankingsCompaniesComparison} = selectBranding(yield select());
  try {
    const {data}: AxiosResponse<{ data: Comparison[], requestJson: string }> = yield call(
      axios.get, apiEndpoints.getBrandingCompaniesComparison(rankingsCompaniesComparison.interval || '1')
    )
    yield put(getData.success({data: data.data, type: Types.rankingsCompaniesComparison}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.rankingsCompaniesComparison}))
  }
}

function* fetchRankingsBrandsComparison() {
  const {rankingsBrandsComparison} = selectBranding(yield select());
  try {
    const {data}: AxiosResponse<{ data: Comparison[], requestJson: string }> = yield call(
      axios.get, apiEndpoints.getBrandingBrandComparison(rankingsBrandsComparison.interval || '1')
    )
    yield put(getData.success({data: data.data, type: Types.rankingsBrandsComparison}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.rankingsBrandsComparison}))
  }
}

function* fetchRankingsHashtagsComparison() {
  const {rankingsHashtagsComparison} = selectBranding(yield select());
  try {
    const {data}: AxiosResponse<{ data: Comparison[], requestJson: string }> = yield call(
      axios.get, apiEndpoints.getBrandingHashtagComparison(rankingsHashtagsComparison.interval || '1')
    )
    yield put(getData.success({data: data.data, type: Types.rankingsHashtagsComparison}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.rankingsHashtagsComparison}))
  }
}

function* fetchData(action: ReturnType<typeof getData.request>) {
  const {type} = action.payload;
  switch (type) {
    case Types.rankingsCompanies :
      return yield fetchRankingsCompanies();
    case Types.rankingsBrands :
      return yield fetchRankingsBrands();
    case Types.rankingsHashtags :
      return yield fetchRankingsHashtags();
    case Types.rankingsCompaniesComparison :
      return yield fetchRankingsCompaniesComparison();
    case Types.rankingsBrandsComparison :
      return yield fetchRankingsBrandsComparison();
    case Types.rankingsHashtagsComparison :
      return yield fetchRankingsHashtagsComparison();
    default :
      return;
  }

}

export function* brandingSagas() {
  yield all([
    yield takeEvery(getData.request, fetchData),
  ])
}
