import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {TableMenu} from '../../components/TableMenu';
import {changeWritingStyleInterval, getWritingStyle} from '../../store/benchmarking/benchmarking.actions';
import {useDispatch, useSelector} from 'react-redux';
import {selectBenchmarking} from '../../store/benchmarking';
import {Interval} from '../../components/TableMenu';
import {BenchmarkingTable} from '../../components/BenchmarkingTable';
import {apiEndpoints} from '../../constants/api';
import {Loader} from '../../components/ui/Loader';
import {useHasLimitedAccess} from '../../utils/useCheckPermissions';
import {AccessSections} from '../../store/app/types';
import {ErrorMessage} from '../../components/ErrorMessage';
import {selectUser} from '../../store/auth';

const cx = classNames.bind(styles);

export const WritingStyle = () => {
  const dispatch = useDispatch();
  const {writingStyle} = useSelector(selectBenchmarking);
  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['benchmarking-writing-style'], user);

  const {interval, isLoading, error, data} = writingStyle;

  useEffect(() => {
    dispatch(getWritingStyle.request());
  }, [interval, dispatch]);

  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeWritingStyleInterval(quarter));
  };

  const renderTable = () => {
    if (error) return <ErrorMessage />;
    if (isLoading || !data) return <Loader className={cx('loader')} />;

    const tableData = [
      {
        title: 'benchmarking.average-words-in-articles',
        leftValue: data.avgWordsPerPost,
        rightValue: data.avgWordsPerPostDelta,
      },
      {
        title: 'benchmarking.average-words-in-sentence',
        leftValue: data.avgWordsPerSentence,
        rightValue: data.avgWordsPerSentenceDelta,
      },
      {
        title: 'benchmarking.average-number-subtitles',
        leftValue: data.avgLengthTitle,
        rightValue: data.avgLengthTitleDelta,
      },
      {
        title: 'benchmarking.average-characters-headings',
        subtitle: 'benchmarking.symbols-with-spaces',
        leftValue: data.avgSubtitles,
        rightValue: data.avgSubtitlesDelta,
      },
    ];

    return <BenchmarkingTable data={tableData} />;
  };
  return (
    <div className={cx('rankings-container')}>
      <TableMenu
        questionMark="benchmarking.title.writing-style.question-mark"
        lastButtonDisabled
        limitedAccess={limitedAccess}
        title="benchmarking.title.writing-style"
        downloadApiUrl={apiEndpoints.getWritingStyle(interval, true)}
        active={interval}
        onIntervalChange={onIntervalChange}
      />
      <div>{renderTable()}</div>
    </div>
  );
};
