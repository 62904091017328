import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
// import {images} from "../../../assets/img";
// import {validateDemoSignUp} from "../../../utils/formValidations";
// import axios from 'axios';
// import {apiEndpoints} from "../../../constants/api";
import { homePageState, showSuccessModal } from '../../../store/home';
// import * as actions from "../../../store/home/home.actions";
import { useDispatch, useSelector } from 'react-redux';
import { ModalWithLogo } from '../../../components/ModalWithLogo';

const cx = classNames.bind(styles);

export const SuccessDemoModal = () => {
  const { successModal } = useSelector(homePageState);
  const dispatch = useDispatch();
  return (
    <ModalWithLogo
      withCloseButton
      close={() => dispatch(showSuccessModal(false))}
      visible={successModal}
      className={cx('modal')}
    >
      <h3 className={cx('heading')}>Lieber Nutzer,</h3>
      <p className={cx('text')}>
        Danke für Ihre Angaben. Wir haben Ihnen eine E-Mail auf die angegebene
        Adresse mit dem Ergebnis geschickt.
      </p>
      <div className={cx('team')}>Ihr drkpi-Team</div>
    </ModalWithLogo>
  );
};
