export enum Lang {
  de = 'de'
}

export enum AccessSections {
  "trendanalysis-regional" = "trendanalysis-regional",
  "trendanalysis-dach" = "trendanalysis-dach",
  "benchmarking-seo" = "benchmarking-seo",
  "benchmarking-writing-style" = "benchmarking-writing-style",
  "benchmarking-rankings-dach" = "benchmarking-rankings-dach",
  "benchmarking-rankings-regional" = "benchmarking-rankings-regional",
  "branding-rank-brand" = "branding-rank-brand",
  "branding-rank-company-name" = "branding-rank-company-name",
  "branding-rank-hashtag" = "branding-rank-hashtag",
  "branding-rank-brand-comparison" = "branding-rank-brand-comparison",
  "branding-rank-company-name-comparison" = "branding-rank-company-name-comparison",
  "branding-rank-hashtag-comparison" = "branding-rank-hashtag-comparison",
  "seo-rank-outbound-links-table" = "seo-rank-outbound-links-table",
  "seo-rank-outbound-links-chart" = "seo-rank-outbound-links-chart",
  "seo-rank-back-links-chart" = "seo-rank-back-links-chart",
  "seo-rank-back-links-table" = "seo-rank-back-links-table",
  "engagement-author-replies-chart" = "engagement-author-replies-chart",
  "engagement-author-replies-count" = "engagement-author-replies-count",
  "engagement-comments-count" = "engagement-comments-count",
  "visuals-subtitles-count" = "visuals-subtitles-count",
  "visuals-images-count" = "visuals-images-count",
  "visuals-chart" = "visuals-chart",
}

export interface ConfirmationModal {
  title: string;
  onConfirm: () => void;
  // confirmText?: string;
  // cancelText?: string;
}

export interface ErrorModal {
  title?: string;
  message?: string;
}

export interface SuccessModal {
  title?: string;
  message?: string;
}


export interface SystemParameters {
  categories: Categories[];
  advertisings: Advertisings[];
  countries: Countries[];
  commentRatings: CommentRatings[];
  companySizes: CompanySizes[];
  groups: Groups[];
  subscriptions: Subscription[];
}

export interface Subscription {
  id: number;
  systemName: string;
  fullAccessSections: AccessSections[];
  limitedAccessSections: AccessSections[];
  noAccessSections: AccessSections[];
  fullExportPeriods: string[];
  subUsers:number;
  comparisons: number;
}

export const ExportPeriods = {
  "1": "1",
  "Y": "Y",
  "2Y": "2Y",
}

export interface Categories {
  name: string
  sub_categories: SubCategories[]
  sub_category: string;
}

export interface SubCategories {
  id: string;
  name: string;
  sub_category: string;
}

interface CommentRatings {
  id: string;
  name: string;
}

interface CompanySizes {
  id: string;
  name: string;
}

interface Countries {
  id: string;
  name: string;
  abbreviation: string;
}

interface Advertisings {
  id: string;
  name: string;
}

interface Groups {
  name: string
  id: string;
}

