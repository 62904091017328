import styles from './index.module.scss';
import classNames from 'classnames/bind';
import React, {FC} from 'react';
const cx = classNames.bind(styles);

interface TabsProps {
  setSelectedTab: (tab: number) => void;
  selectedTab: number;
}

export const NavBar: FC<TabsProps> = ({setSelectedTab, selectedTab}) => (
  <div className={cx('tabs-container')}>
    {Array.from({length: 4}).map((_, i) => (
      <div
        key={i}
        className={cx('tab-button', selectedTab === i ? 'tab-button--active' : '')}
        onClick={() => setSelectedTab(i)}>
        {i === 3 ? i + 2 : i + 1} Page
      </div>
    ))}
  </div>
);
