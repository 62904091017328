import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {useDispatch, useSelector} from "react-redux";
import {changeInterval, getData} from "../../store/seo";
import {Types} from "../../store/seo/types";
import {Interval, TableMenu} from "../../components/TableMenu";
import {Loader} from "../../components/ui/Loader";
import {RankingsTable} from "../../components/RankingsTable";
import {TableFooter} from "../../components/TableFooter";
import {apiEndpoints} from "../../constants/api";
import {selectSeo} from "../../store/seo";
import {ErrorMessage} from "../../components/ErrorMessage";
import {useHasLimitedAccess, useHasNoAccess} from "../../utils/useCheckPermissions";
import {AccessSections} from "../../store/app/types";
import {NoAccessMessage} from "../../components/ui/NoAccessMessage";
import {selectUser} from "../../store/auth";

const cx = classNames.bind(styles);

export const SeoRankOutboundLinksTable = () => {
  const dispatch = useDispatch()
  const {seoRankOutboundLinksTable} = useSelector(selectSeo);
  const {interval, data, error, isLoading} = seoRankOutboundLinksTable;

  useEffect(() => {
    dispatch(getData.request({type: Types.seoRankOutboundLinksTable}))
  }, [dispatch, interval])

  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['seo-rank-outbound-links-table'],user)
  const noAccess =useHasNoAccess(AccessSections['seo-rank-outbound-links-table'],user);

  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeInterval({interval: quarter, type: Types.seoRankOutboundLinksTable}))
  }

  const renderTable = () => {
    if (noAccess) return <NoAccessMessage/>
    if (error) return <ErrorMessage/>
    if (!data || isLoading) return <Loader className={cx('loader')}/>;
    return (
      <>
        <RankingsTable
          tableHeaderTwo='branding.table-header-two'
          tableHeaderThree='seo.table-header-three'
          data={data}
          error={error}
          isLoading={isLoading}/>
        <TableFooter labels={['benchmarking.footer.label.our-url', '', '']}/>
      </>
    )
  }
  return (
    <div className={cx('table-container')}>
      <TableMenu
        limitedAccess={limitedAccess}
        active={interval}
        downloadApiUrl={apiEndpoints.getSeoRankOutboundLinksTable(interval || 'Y', true)}
        title='seo.rankings-outbound-links.table.title'
        questionMark='seo.rankings-outbound-links.table.question-mark'
        buttonsTitles={[
          'table-menu.button1',
          'table-menu.button2',
          'table-menu.button3',
        ]}
        onIntervalChange={onIntervalChange}
      />
      {renderTable()}
    </div>
  );
};
