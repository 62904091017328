import {createAsyncAction, createAction} from 'typesafe-actions';
import {RankingsRegional, WritingStyle, RankingsTop, SEO} from "./types";
import {Interval} from '../../components/TableMenu';

export const getRankingsTop = createAsyncAction(
  'benchmarking/GET_RANKINGS_TOP_STARTED',
  'benchmarking/GET_RANKINGS_TOP_DONE',
  'benchmarking/GET_RANKINGS_TOP_FAILED',
)<void, RankingsTop[], void>()

export const getRankingsRegional= createAsyncAction(
  'benchmarking/GET_RANKINGS_REGIONAL_STARTED',
  'benchmarking/GET_RANKINGS_REGIONAL_DONE',
  'benchmarking/GET_RANKINGS_REGIONAL_FAILED',
)<void, RankingsRegional[], void>()

export const getWritingStyle = createAsyncAction(
  'benchmarking/GET_RANKINGS_WRITING_STYLE_STARTED',
  'benchmarking/GET_RANKINGS_WRITING_STYLE_DONE',
  'benchmarking/GET_RANKINGS_WRITING_STYLE_FAILED',
)<void, WritingStyle, void>()

export const getOverviewSeo = createAsyncAction(
  'benchmarking/GET_RANKINGS_SEO_STARTED',
  'benchmarking/GET_RANKINGS_SEO_DONE',
  'benchmarking/GET_RANKINGS_SEO_FAILED',
)<void, SEO, void>()

export const changeRankingsTopInterval = createAction(
  'benchmarking/CHANGE_TOP_RANKINGS_INTERVAL',
)<Interval>()

export const changeRankingsRegionalInterval = createAction(
  'benchmarking/CHANGE_REGIONAL_RANKINGS_INTERVAL',
)<Interval>()

export const changeWritingStyleInterval = createAction(
  'benchmarking/CHANGE_WRITING_STYLE_INTERVAL',
)<Interval>()

export const changeOverviewSeoInterval = createAction(
  'benchmarking/CHANGE_OVERVIEW_SEO_INTERVAL',
)<Interval>()
