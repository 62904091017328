export interface SeoRankBackLinksTable {
  comparisonLetter: string;
  country: string;
  rank: string;
  scoreCountry: string;
  scoreDach: string;
  value: string;
}

export interface SeoRankOutboundLinksTable {
  comparisonLetter: string;
  country: string;
  rank: string;
  scoreCountry: string;
  scoreDach: string;
  value: string;
}

export type SeoRankBackLinksChart = [string, string][];
export type SeoRankOutboundLinksChart = [string, string][];

export interface GraphData {
  id: string,
  color: string,
  data: { x: string; y: number }[]
}

export enum Types {
  seoRankBackLinksTable = 'seoRankBackLinksTable',
  seoRankOutboundLinksTable = 'seoRankOutboundLinksTable',
  seoRankBackLinksChart = 'seoRankBackLinksChart',
  seoRankOutboundLinksChart = 'seoRankOutboundLinksChart',
}
