import React from 'react';
import {ResponsiveLine} from '@nivo/line';
import {XAxisTick, YAxisTick, TradeMark, CustomLine, TopOverlay} from './Layers';
import {AxisProps} from '@nivo/axes';
import {theme} from './config';

interface GraphProps {
  data?: GraphData[];
  min?: number;
  max?: number;
  legend?: string;
  pdfExport?: boolean;
  margin?: {
    top: number;
    right: number;
    bottom: number;
    left: number;
  };
}

interface GraphData {
  id: string;
  color: string;
  data: Datum[];
}

interface Datum {
  x: string; // date format: yyyy-mm-dd
  y: number;
}

export const Graph = (props: GraphProps) => {
  let {data, pdfExport, min, max, margin, legend} = props;
  if (!data) return null;
  data = data.filter((item) => item);
  const colors: string[] = data.map((record) => (record ? record.color : '#cccccc'));
  const verticalAxis: AxisProps = {
    legend: legend || 'Punktzahl',
    legendPosition: 'middle',
    legendOffset: pdfExport ? -45 : -70,
    tickValues: 10,
    renderTick: (tick) => YAxisTick(tick, pdfExport),
  };

  return (
    <ResponsiveLine
      data={data}
      margin={
        margin || {
          top: pdfExport ? 10 : 30,
          right: pdfExport ? 12 : 15,
          bottom: pdfExport ? 35 : 50,
          left: pdfExport ? 60 : 80,
        }
      }
      yScale={{
        type: 'linear',
        min: typeof min === 'number' ? min : undefined,
        max: typeof max === 'number' ? max : undefined,
      }}
      enableGridX={true}
      axisTop={null}
      xFormat="time:%Y-%m-%d"
      xScale={{type: 'point'}}
      axisBottom={{
        orient: 'bottom',
        renderTick: (tick) => XAxisTick(tick, pdfExport),
      }}
      layers={[
        'grid',
        'markers',
        'axes',
        'areas',
        CustomLine,
        (layer) => TradeMark(layer, pdfExport),
        'crosshair',
        (layer) => TopOverlay(layer, pdfExport),
        'points',
        'slices',
        'mesh',
        'legends',
      ]}
      axisRight={null}
      axisLeft={verticalAxis}
      enablePoints={true}
      pointSize={pdfExport ? 6 : 10}
      colors={colors}
      useMesh={true}
      theme={theme(pdfExport)}
      enableSlices="x"
    />
  );
};
