import Handsontable from "handsontable";
import {Page} from "../../store/superAdmin/types";
import {SystemParameters, SubCategories} from "../../store/app/types";
// import {countries} from "../../assets/countries";
// import {USER_ID_COL_INDEX} from "./usersTableConfig";

export const PAGE_SIZE = 10;

export const INDEX_COL_INDEX = 0;
export const PAGEID_COL_INDEX = 1;
export const PAGEURL_COL_INDEX = 2;
export const CATEGORYLETTER_COL_INDEX = 3;
export const CATEGORYID_COL_INDEX = 4;
export const BRANDS_COL_INDEX = 5;
export const COMPANYNAME_COL_INDEX = 6;
export const HASHTAGS_COL_INDEX = 7;
export const COMMENTRATING_COL_INDEX = 8;
export const ADVERTISING_COL_INDEX = 9;
export const ISIN_COL_INDEX = 10;
export const COUNTRYID_COL_INDEX = 11;
export const COMPANYSIZE_COL_INDEX = 12;
export const GROUPID_COL_INDEX = 13;
export const EMAIL_COL_INDEX = 14;
export const OWNER_COL_INDEX = 15;
export const STATUS_COL_INDEX = 16;
export const RAWPAGESTATUS_COL_INDEX = 17;
export const LASTSCAN_COL_INDEX = 18;
export const YOUNGESTPOST_COL_INDEX = 19;
export const ARCHIVED_COL_INDEX = 20;
// export const RESCAN_COL_INDEX = 21;

export const colWidths = [100, 100, 250, 250, 250, 150, 150, 150, 150, 80, 80, 80, 80, 100, 100, 100, 100, 120, 120, 150, 50, 150, 150]

type ColumnSettings = Handsontable.ColumnSettings;

interface ColumnData {
  value?: string | boolean | number;
  fieldName: keyof Page | ''; // used as reference
  column: ColumnSettings;
  order: number; // used as reference
}

interface Options {
  item?: Page;
  parameters?: SystemParameters;
  rescanPage: (pageId: string) => void;
  rescanningPageId?: string;
  archivePage: (pageId: string) => void;
  archivingPageId?: string;
  getCellData: (row: number, col: number) => string;
}

export const renderRow = (options: Options): ColumnData[] => {
  const {parameters, getCellData, rescanPage,rescanningPageId, archivePage, archivingPageId} = options;
  const categories = parameters ? parameters.categories : [];
  const advertisings = parameters ? parameters.advertisings : [];
  const countries = parameters ? parameters.countries : [];
  const commentRatings = parameters ? parameters.commentRatings : [];
  const companySizes = parameters ? parameters.companySizes : [];
  const groups = parameters ? parameters.groups : [];

  const item = options.item || {} as Page;

  const category = categories.find((cat) => cat.sub_category === item.categoryLetter) || {name: '', sub_categories: []};
  const subCategories: Array<SubCategories> = [];
  // category.sub_categories.forEach(s => subCategories.push(s));
  categories.map(c => c.sub_categories.forEach(s => subCategories.push(s)));

  const subCategory = subCategories.find((cat) => cat.id === item.categoryId)
  const advertising = advertisings.find((add) => add.id === item.advertising);
  const country = countries.find((country) => country.id === item.countryId);
  const commentRating = commentRatings.find((rating) => rating.id === item.commentRating);
  const group = groups.find((g) => g.id === item.groupId);
  const companySize = companySizes.find((c) => c.id === item.companySize);

  const countryAbbreviations = countries.map(c => c.abbreviation);
  const companySizesNames = companySizes.map(c => c.name);
  const groupNames = groups.map(c => c.name);
  const commentRatingNames = commentRatings.map(c => c.name);
  const advertisingNames = advertisings.map(c => c.name);
  // const subCategoryNames = subCategories.map(c => c.name);
  const categoryNames = categories.map(c => c.name);

  return [
    {
      value: item.index,
      column: {title: 'Index', editor: false},
      fieldName: 'index',
      order: 0,
    },
    {
      value: item.id,
      column: {title: 'Page ID', editor: false},
      fieldName: 'id',
      order: 1,
    },
    {
      value: item.pageUrl,
      column: {title: 'URL'},
      fieldName: 'pageUrl',
      order: 2,
    },
    {
      value: category ? category.name : '',
      column: {
        title: 'Category',
        type: 'dropdown',
        source: Object.values(categoryNames)
      },
      fieldName: 'categoryLetter',
      order: 3,
    },
    {
      value: subCategory ? subCategory.name : '',
      fieldName: 'categoryId',
      column: {
        title: 'SubCategory',
        type: 'dropdown',
        renderer: (instance, td, row) => {
          const categoryLetter = instance.getDataAtCell(row, CATEGORYLETTER_COL_INDEX);
          const category = categories.find((cat) => cat.name === categoryLetter) || {name: '', sub_categories: []};
          const subCategories: Array<String> = [];
          category.sub_categories.forEach(s => {
            subCategories.push(s.name);
          });

          instance.setCellMeta(row, 4, 'type', 'dropdown');
          instance.setCellMeta(row, 4, 'source', subCategories);
        }
      },
      order: 4,
    },
    {
      value: item.brands,
      column: {title: 'Brands'},
      fieldName: 'brands',
      order: 5,
    },
    {
      value: item.companyName,
      column: {title: 'Company Name'},
      fieldName: 'companyName',
      order: 6,
    },
    {
      value: item.hashtags,
      column: {title: 'Hashtags'},
      fieldName: 'hashtags',
      order: 7,
    },
    {
      value: commentRating?.name || '',
      column: {
        title: 'Comment Rating',
        type: 'dropdown',
        source: Object.values(commentRatingNames)
      },
      fieldName: 'commentRating',
      order: 8,
    },
    {
      value: advertising?.name || '',
      column: {
        title: 'Advertising',
        type: 'dropdown',
        source: Object.values(advertisingNames)
      },
      fieldName: 'advertising',
      order: 9,
    },
    {
      value: item.isin,
      column: {title: 'ISIN Nr'},
      fieldName: 'isin',
      order: 10,
    },
    {
      value: country?.abbreviation || '',
      column: {
        title: 'Country',
        type: 'dropdown',
        source: Object.values(countryAbbreviations),
      },
      fieldName: 'countryId',
      order: 11,
    },
    {
      value: companySize?.name || '',
      column: {
        title: 'Company Size',
        type: 'dropdown',
        source: Object.values(companySizesNames)
      },
      fieldName: 'companySize',
      order: 12,
    },
    {
      value: group?.name || '',
      column: {
        title: 'Group Id',
        type: 'dropdown',
        source: Object.values(groupNames)
      },
      fieldName: 'groupId',
      order: 13,
    },
    {
      value: item.email,
      column: {title: 'Email'},
      fieldName: 'email',
      order: 14,
    },
    {
      value: item.owner,
      column: {title: 'Owner'},
      fieldName: 'owner',
      order: 15,
    },
    {
      value: item.status,
      column: {title: 'Status', editor: false},
      fieldName: 'status',
      order: 16,
    },
    {
      value: item.rawPageStatus,
      column: {title: 'Raw Page Status', editor: false},
      fieldName: 'rawPageStatus',
      order: 17,
    },
    {
      value: item.lastScan,
      column: {title: 'Last Scan', editor: false},
      fieldName: 'lastScan',
      order: 18,
    },
    {
      value: item.youngestPost,
      column: {title: 'Youngest Post', editor: false},
      fieldName: 'youngestPost',
      order: 19,
    },
    {
      value: item.archived === '0' ? 'NEIN' : 'JA',
      column: {title: 'Archived', editor: false},
      fieldName: 'archived',
      order: 20,
    },
    {
      value: 'Rescan',
      column: {
        title: 'Rescan',
        filter: false,
        editor: false,
        disableVisualSelection: true,
        renderer: (instance, td, row) => {
          const pageId = getCellData(row, PAGEID_COL_INDEX);
          const button = document.createElement('BUTTON');
          button.textContent = rescanningPageId === pageId ? 'In progress..' : 'Rescan page';
          button.classList.add('table-button-login')
          button.addEventListener('click', () => {
            if (rescanningPageId) return;
            rescanPage(pageId)
          })
          Handsontable.dom.empty(td);
          td.appendChild(button);
        }
      },
      fieldName: '',
      order: 21,
    },
    {
      value: 'Archive',
      column: {
        title: 'Archive',
        filter: false,
        editor: false,
        disableVisualSelection: true,
        renderer: (instance, td, row) => {
          const pageId = getCellData(row, PAGEID_COL_INDEX);
          const button = document.createElement('BUTTON');
          button.textContent = archivingPageId === pageId ? 'In progress..' : 'Archive page';
          button.classList.add('table-button-login')
          button.addEventListener('click', () => {
            console.log(pageId, row, PAGEID_COL_INDEX)
            if (archivingPageId) return;
            archivePage(pageId)
          })
          Handsontable.dom.empty(td);
          td.appendChild(button);
        }
      },
      fieldName: '',
      order: 22,
    }
  ]
}

// Simple column order index validation compared to constants (production only);
export const validateTableFields = (data: ColumnData[]) => {
  const err = 'Incorrect table order or constant value';
  const values: Partial<{ [key in keyof Page]: any } & { '': any }> = {}
  data.forEach((item, index) => {
    if (item.order !== index) throw new Error('Incorrect order')
    values[item.fieldName] = index;
  })
  if (values.index !== INDEX_COL_INDEX) throw new Error(err)
  if (values.id !== PAGEID_COL_INDEX) throw new Error(err)
  if (values.pageUrl !== PAGEURL_COL_INDEX) throw new Error(err)
  if (values.categoryLetter !== CATEGORYLETTER_COL_INDEX) throw new Error(err)
  if (values.categoryId !== CATEGORYID_COL_INDEX) throw new Error(err)
  if (values.brands !== BRANDS_COL_INDEX) throw new Error(err)
  if (values.companyName !== COMPANYNAME_COL_INDEX) throw new Error(err)
  if (values.hashtags !== HASHTAGS_COL_INDEX) throw new Error(err)
  if (values.commentRating !== COMMENTRATING_COL_INDEX) throw new Error(err)
  if (values.advertising !== ADVERTISING_COL_INDEX) throw new Error(err)
  if (values.isin !== ISIN_COL_INDEX) throw new Error(err)
  if (values.countryId !== COUNTRYID_COL_INDEX) throw new Error(err)
  if (values.companySize !== COMPANYSIZE_COL_INDEX) throw new Error(err)
  if (values.groupId !== GROUPID_COL_INDEX) throw new Error(err)
  if (values.email !== EMAIL_COL_INDEX) throw new Error(err)
  if (values.owner !== OWNER_COL_INDEX) throw new Error(err)
  if (values.status !== STATUS_COL_INDEX) throw new Error(err)
  if (values.rawPageStatus !== RAWPAGESTATUS_COL_INDEX) throw new Error(err)
  if (values.lastScan !== LASTSCAN_COL_INDEX) throw new Error(err)
  if (values.youngestPost !== YOUNGESTPOST_COL_INDEX) throw new Error(err)
  if (values.archived !== ARCHIVED_COL_INDEX) throw new Error(err)
}
