import {createAction, createAsyncAction} from 'typesafe-actions';
import {InputPayload} from "../../../types/events";
import {PasswordFormErrors} from './types';

export const onInputChange = createAction('profile/PASSWORD_INPUT_CHANGE')<InputPayload>()

export const changePassword = createAsyncAction(
  'profile/PASSWORD_CHANGE_STARTED',
  'profile/PASSWORD_CHANGE_DONE',
  'profile/PASSWORD_CHANGE_FAILED',
)<void, void, PasswordFormErrors>()

export {clearAllSuccessMessages} from '../userProfile/userProfile.actions'
