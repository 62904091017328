import {takeLatest, all, put, select, call} from 'redux-saga/effects';
import * as actions from './invitedUsers.actions';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../../constants/api";
import {handleResponseError} from "../../../utils/responseErrors";
import {selectInviteUsers} from "./index";
import {InvitedUser} from "./types";
import {handleErrors, validateInviteUser} from "../../../utils/formValidations";

function* getUsers() {
  try {
    const {data}: AxiosResponse<{ subUsers: InvitedUser[] }> = yield call(axios.get, apiEndpoints.getInvites);
    yield put(actions.getInvitedUsers.success(data.subUsers))
  } catch (e) {
    yield put(actions.getInvitedUsers.failure())
    yield handleResponseError(e);
  }
}

function* updateInvitedUser(action: ReturnType<typeof actions.updateInvitedUser.request>) {
  const {index} = action.payload;
  const user = selectInviteUsers(yield select())[index];
  if (user?.option === 'invite') yield inviteUser(index, user);
  if (user?.option === 'delete') yield deleteUser(index, user);
}

function* inviteUser(index: number, user?: InvitedUser) {
  if (!user) return;
  const {firstName, lastName, email, id} = user;
  try {
    validateInviteUser(user);
    yield call(axios.post, apiEndpoints.createInvite, {firstName, lastName, email, id})
    yield put(actions.updateInvitedUser.success({index}))
  } catch (e) {
    yield put(actions.updateInvitedUser.failure({index, errors: handleErrors(e)}))
  }
}

function* deleteUser(index: number, user?: InvitedUser) {
  if (!user?.email) return;
  try {
    yield call(axios.delete, apiEndpoints.deleteInvite(user.email))
    yield put(actions.updateInvitedUser.success({index}))
  } catch (e) {
    yield put(actions.updateInvitedUser.failure({index, errors: {server: ''}}))
  }
}

export function* invitedUsersSaga() {
  yield all([
    yield takeLatest(actions.getInvitedUsers.request, getUsers),
    yield takeLatest(actions.updateInvitedUser.request, updateInvitedUser),
  ])
}
