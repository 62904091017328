import {getType, ActionType} from 'typesafe-actions';
import {RegistrationForm, RegistrationErrors} from './types';
import * as actions from './registration.actions';

type Action = ActionType<typeof actions>

export interface RegistrationState {
  form: RegistrationForm;
  loading: boolean;
  errors: RegistrationErrors;
  success?: boolean;
  recaptchaToken: string;
}

const form = {
  email: '',
  telephone: '',
  firstName: '',
  lastName: '',
  password: '',
  repeatPassword: '',
  status: '',
  land: '',
  companyName: '',
  website: '',
  validationValue:'',
  agreeToPlatformTerms: false,
  agreeToGdpr: false,
  agreeToDataProcessing: false,
  agreeToMarketingTerms: false,
};

const initialState: RegistrationState = {
  form,
  recaptchaToken: '',
  loading: false,
  errors: {},
};

export const registrationReducer = (state = initialState, action: Action): RegistrationState => {
  switch (action.type) {
    case getType(actions.onInputChange): {
      const {name, value} = action.payload;
      return {
        ...state,
        form: {...state.form, [name]: value},
        errors: {...state.errors, [name]: ''},
        success: false,
      }
    }
    case getType(actions.verifyHuman) :
      return {...state, recaptchaToken: action.payload}
    case getType(actions.register.request):
      return {...state, loading: true, errors: {}, success: false}
    case getType(actions.register.success):
      return {...state, loading: false, errors: {}, success: true, form: {...form, email: state.form.email}}
    case  getType(actions.register.failure) :
      return {...state, loading: false, errors: action.payload}
    default:
      return state;
  }
};
