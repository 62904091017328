export enum NavigationPaths {
  homePage = '/',
  staticHomePage = '/static',
  forgotPassword = '/forgot-password',
  confirmEmail = '/confirm-email',
  createPassword = '/create-password/:id/:code',
  // settings
  authentication = '/login',
  subUser = '/subUser',
  profile = '/profile',
  pageTracker = '/page-tracker',
  compare = '/compare',
  report = '/report',
  archive = '/archive',
  editPdfReport = '/edit-report',
  editTextSections = '/edit-sections',
  // home
  benchmarking = '/benchmarking',
  trend = '/trend',
  branding = '/branding',
  seo = '/seo',
  engagement = '/engagement',
  visuals = '/visuals',
  roi = '/roi',
  // top menu
  register = '/registration',
  about = 'https://drkpi.com/de/agb/',
  contact = 'pagetracker@drkpi.com',
  faq = 'https://drkpi.com/de/faq-pagetracker/',
  wiki = 'https://drkpi.com/de/pagetracker/',
  learnMore = 'https://drkpi.com/de/faq-pagetracker/',
  pdf = '/pdf/:secret',
  pdfBasic = '/pdfBasic/:secret',
  superAdmin = '/super-admin',
  subscription = '/subscription',
  dataProtection = 'https://drkpi.com/de/datenschutz-impressum/',
  agb = 'https://drkpi.com/de/agb/',
  dateTerms = 'https://drkpi.com/de/pagetracker/',
  profileFAQ = 'https://drkpi.com/de/faq-pagetracker/#toggle-id-15',
  homePageToPdf = 'https://drkpi.com/de/wp-content/files/pagetracker-seo-analytics-tool-info.pdf',
}
