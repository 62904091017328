import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {TextInput} from '../TextInput';

const cx = classNames.bind(styles);

interface Props {
  onChange: (value: string) => void;
  value: string;
  error?: string;
}

export const IsHuman: React.FC<Props> = ({onChange, value, error}) => {
  const now = new Date();
  const month = now.getMonth() + 1;
  const day = now.getDate();
  return (
    <div className={cx('is-human')}>
      <div className={cx('title')}>
        Captcha: {month} + {day}=
      </div>
      <TextInput
        error={error}
        onChange={(_, v) => onChange(v)}
        label="common.verification"
        value={value}
        name="verification"
        className={cx('input')}
      />
    </div>
  );
};
