import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Container from '../../components/layout/Container';
import {Text} from '../../components/ui/Text';
import moment from 'moment';
import 'react-calendar/dist/Calendar.css';
import {Users} from './Users';
import {Pages} from './Pages';
import {Requests} from './Requests';

const cx = classNames.bind(styles);

export const SuperAdmin = () => {
  return (
    <Container className={cx('container')}>
      <section className={cx('section')}>
        <h1 className={cx('title')}>
          <Text msg="super-admin.title" />
          {moment().format('YYYY-MM-DD')}
        </h1>
        <Users />
      </section>
      <section className={cx('section')}>
        <h1 className={cx('title')}>
          <Text msg="super-admin.requests.title" />
        </h1>
        <Requests />
      </section>
      <section className={cx('section')}>
        <h1 className={cx('title')}>
          <Text msg="super-admin.search-pages.title" />
        </h1>
        <Pages />
      </section>
    </Container>
  );
};
