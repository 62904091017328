import {getType, ActionType} from 'typesafe-actions';
import * as actions from './report.actions';
import {ReportSettings} from './types';

type Action = ActionType<typeof actions>

export interface ReportState {
  settings: ReportSettings;
  isFirstFormSaving?: boolean;
  isSecondFormSaving?: boolean;
  isGettingPdf?: string;
  isFirstSuccess?: boolean;
  isSecondSuccess?: boolean;
  isPdfDownloadSuccess?: boolean;
  isLoading?: boolean;
  error?: boolean;
}

const initialState: ReportState = {
  settings: {
    dataToInclude: {},
    reportFormats: {},
    sendingInterval: '',
  },
}

export const reportReducer = (state = initialState, action: Action): ReportState => {
  switch (action.type) {
    case getType(actions.onDataToIncludeChange): {
      const {name, value} = action.payload;
      const dataToInclude = {...state.settings.dataToInclude, [name]: value}
      return {
        ...state,
        isPdfDownloadSuccess:false,
        isFirstSuccess: false,
        settings: {...state.settings, dataToInclude}
      }
    }
    case getType(actions.onReportFormatsChange): {
      const {name, value} = action.payload;
      const reportFormats = {...state.settings.reportFormats, [name]: value}
      return {
        ...state,
        isPdfDownloadSuccess:false,
        isSecondSuccess: false,
        settings: {...state.settings, reportFormats}
      }
    }
    case getType(actions.onSendingIntervalChange): {
      const {name} = action.payload;
      return {
        ...state,
        isPdfDownloadSuccess:false,
        isSecondSuccess: false,
        settings: {...state.settings, sendingInterval: name}
      }
    }
    case getType(actions.saveReportSettings.request): {
      const {form} = action.payload;
      return {
        ...state,
        isFirstFormSaving: form === 'first',
        isSecondFormSaving: form === 'second',
        error: false
      };
    }
    case getType(actions.saveReportSettings.success): {
      const {form} = action.payload;
      return {
        ...state,
        isFirstFormSaving: false,
        isSecondFormSaving: false,
        isFirstSuccess: form === 'first',
        isSecondSuccess: form === 'second',
        error: false
      };
    }
    case getType(actions.saveReportSettingsAndGetPdf.request): {
      const {interval} = action.payload;
      return {
        ...state,
        isGettingPdf: interval,
        error: false,
        isPdfDownloadSuccess: false,
      }
    }
    case getType(actions.saveReportSettingsAndGetPdf.failure):
      return {...state, isGettingPdf: undefined, error: true, isPdfDownloadSuccess:false}
    case getType(actions.saveReportSettingsAndGetPdf.success):
      return {...state, isGettingPdf: undefined, error: false, isPdfDownloadSuccess:true};
    case getType(actions.saveReportSettings.failure):
      return {...state, isFirstFormSaving: false, isSecondFormSaving: false, error: true};
    case getType(actions.getReportSettings.success):
      return {settings: action.payload}
    default :
      return state
  }
}
