import {generateTimedQueryParam} from "../utils/getUsersTimeInCest";

export const apiEndpoints = {
  login: '/api/authenticate',
  register: '/api/users',
  subUserRegister: '/api/users/sub-users',
  socialLogin: (token: string) => `/api/google/token?access_token=${token}`,
  forgotPassword: '/api/users/reset',
  confirmCode: '/api/users/confirmCode',
  createPassword: '/api/users/complete-reset',
  confirmEmail: '/api/users/confirm-email',
  updateProfile: '/api/users/profile',
  getProfile: `/api/users/profile/?as=${generateTimedQueryParam()}`,
  resendConfirmationEmail: '/api/users/resend ',
  updatePassword: '/api/users/password',
  reportSettings: '/api/export-settings',
  billingEntity: '/api/billing-entity',
  pages: '/api/pages',
  getInvites: '/api/users/sub-users',
  createInvite: '/api/invites',
  deleteInvite: (email: string) => '/api/users/sub-users/' + email,
  createPage: '/api/pages',
  updatePage: (id: string) => `/api/pages/${id}`,
  deletePage: (id: string) => `/api/pages/${id}`,
  getArchives: '/api/archives',
  systemParameters: `/api/system-parameters?as=${generateTimedQueryParam()}`,
  exportSettings: (interval: string) => `/api/reports/export-based-on-settings/${interval}`,
  // engagement
  getEngagementCommentsCount: (interval: string, pdf?: boolean) =>
    `/api/reports/engagement-comments-count/${interval}${pdf ? '/export' : ''}`,
  getEngagementAuthorRepliesCount: (interval: string, pdf?: boolean) =>
    `/api/reports/engagement-author-replies-count/${interval}${pdf ? '/export' : ''}`,
  getEngagementAuthorRepliesChart: (interval: string, pdf?: boolean) =>
    `/api/reports/engagement-author-replies-chart/${interval}${pdf ? '/export' : ''}`,
  // visuals
  getVisualsImagesCount: (interval: string, pdf?: boolean) =>
    `/api/reports/visuals-images-count/${interval}${pdf ? '/export' : ''}`,
  getVisualsSubtitlesCount: (interval: string, pdf?: boolean) =>
    `/api/reports/visuals-subtitles-count/${interval}${pdf ? '/export' : ''}`,
  getVisualsChart: (interval: string, pdf?: boolean) =>
    `/api/reports/visuals-chart/${interval}${pdf ? '/export' : ''}`,
  // seo
  getSeoRankBackLinksTable: (interval: string, pdf?: boolean) =>
    `/api/reports/seo-rank-back-links-table/${interval}${pdf ? '/export' : ''}`,
  getSeoRankOutboundLinksTable: (interval: string, pdf?: boolean) =>
    `/api/reports/seo-rank-outbound-links-table/${interval}${pdf ? '/export' : ''}`,
  getSeoRankBackLinksChart: (interval: string, pdf?: boolean) =>
    `/api/reports/seo-rank-back-links-chart/${interval}${pdf ? '/export' : ''}`,
  getSeoRankOutboundLinksChart: (interval: string, pdf?: boolean) =>
    `/api/reports/seo-rank-outbound-links-chart/${interval}${pdf ? '/export' : ''}`,

  getRankingsTop: (interval: string, pdf?: boolean) =>
    `/api/reports/benchmarking-rankings-dach/${interval}${pdf ? '/export' : ''}`,
  getRankingsRegional: (interval: string, pdf?: boolean) =>
    `/api/reports/benchmarking-rankings-regional/${interval}${pdf ? '/export' : ''}`,
  getWritingStyle: (interval: string, pdf?: boolean) =>
    `/api/reports/benchmarking-writing-style/${interval}${pdf ? '/export' : ''}`,
  getOverviewSeo: (interval: string, pdf?: boolean) =>
    `/api/reports/benchmarking-seo/${interval}${pdf ? '/export' : ''}`,
  getTrendOverall: (interval: string, pdf?: boolean) =>
    `/api/reports/trendanalysis-dach/${interval}${pdf ? '/export' : ''}`,
  getTrendRegional: (interval: string, pdf?: boolean) =>
    `/api/reports/trendanalysis-regional/${interval}${pdf ? '/export' : ''}`,
  getPdfData: (jwt: string) => `/api/reports/data-for-export/${jwt}`,
  getReportsPdf: '/api/reports/export-based-on-settings',
  getBrandingCompanies: (interval: string, pdf?: boolean) =>
    `/api/reports/branding-rank-company-name/${interval}${pdf ? '/export' : ''}`,
  getBrandingBrand: (interval: string, pdf?: boolean) =>
    `/api/reports/branding-rank-brand/${interval}${pdf ? '/export' : ''}`,
  getBrandingHashtag: (interval: string, pdf?: boolean) =>
    `/api/reports/branding-rank-hashtag/${interval}${pdf ? '/export' : ''}`,
  getBrandingCompaniesComparison: (interval: string, pdf?: boolean) =>
    `/api/reports/branding-rank-company-name-comparison/${interval}${pdf ? '/export' : ''}`,
  getBrandingBrandComparison: (interval: string, pdf?: boolean) =>
    `/api/reports/branding-rank-brand-comparison/${interval}${pdf ? '/export' : ''}`,
  getBrandingHashtagComparison: (interval: string, pdf?: boolean) =>
    `/api/reports/branding-rank-hashtag-comparison/${interval}${pdf ? '/export' : ''}`,
  similarPages: '/api/pages/similar',
  publicPages: '/api/public/similar',
  publicSendReport: '/api/public/send-report',
  publicReportRequest: '/api/public/report-request',
  getUsers: '/api/super-admin/users',
  setDeleteUser: (id: string) => `/api/super-admin/delete-user/${id}`,
  setUnDeleteUser: (id: string) => `/api/super-admin/un-delete-user/${id}`,
  searchPages: `/api/super-admin/pages`,
  sendReportToUser: '/api/reports/send-report-to-user',
  updateUser: (id: string) => `/api/super-admin/users/${id}`,
  loginAsSelectedUser: (id: string) => `/api/super-admin/users/auth/${id}`,
  updatePageAsAdmin: `/api/super-admin/pages`,
  createPageAsAdmin: `/api/super-admin/pages`,
  changeSubscription: (id: number) => `/api/subscriptions/${id}`,
  rescanPage: '/api/super-admin/pages/rescan',
  archivePage: '/api/super-admin/pages/archive',
  getRequests: '/api/super-admin/report-requests',
  updateRequests:(id:string)=> '/api/super-admin/report-requests/'+id,
  basicReports: (secret:string)=>'/api/reports/data-for-export-basic/'+secret,
  getReportFooters:`/api/footer`,
  updateReportFooters:`/api/footer/update`,
  createReportFooters:`/api/footer/create`,
  getSectionTexts:`/api/section-text`,
  getCurrentSectionText:(index:string)=> `/api/section-text/${index}`,
  updateSectionTexts: `/api/section-text/update`,
  createSectionTexts: `/api/section-text/create`,
};

