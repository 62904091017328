import {createAsyncAction, createAction} from 'typesafe-actions';
import {ResponseData} from "./types";
import {Interval} from '../../components/TableMenu';
import {StateKeys} from './branding.reducer';

export const getData = createAsyncAction(
  'benchmarking/GET_DATA_STARTED',
  'benchmarking/GET_DATA_DONE',
  'benchmarking/GET_DATA_FAILED',
)<{ type: StateKeys }, { type: StateKeys, data: ResponseData }, { type: StateKeys }>()

export const changeInterval = createAction(
  'benchmarking/CHANGE_INTERVAL',
)<{interval:Interval, type:StateKeys}>()
