import React from 'react';
import {NavigationPaths} from '../../constants/navigationPaths';
import {Text, TextInput, Button} from "../../components/ui";
import {useDispatch, useSelector} from "react-redux";
import {selectLogin} from "../../store/auth";
import {onInputChange, login, loginWithSocialProvider} from "../../store/auth/login/login.actions";
import {FormEvent} from '../../types/events';
import {GoogleLogin, GoogleLoginResponse, GoogleLoginResponseOffline} from 'react-google-login';
import {GOOGLE_CLIENT_ID} from "../../constants/socialLogin";
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const LoginCard = () => {
  const {form, loading, errors} = useSelector(selectLogin)
  const dispatch = useDispatch()

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    dispatch(login.request())
  }

  const responseGoogleSuccess = (res: GoogleLoginResponse | GoogleLoginResponseOffline) => {
    if ("accessToken" in res) {
      dispatch(loginWithSocialProvider.request({
        provider: 'google',
        token: res.tokenId
      }))
    } else {
      console.log(res)
      // dispatch(loginWithSocialProvider.failure({social: 'Canceled by user'}))
    }
  }
  const formErrors = Object.entries(errors).map(([key, value], i) => {
    return key && value ? <div className={cx('error')} key={i}><Text msg={value as string}/></div> : null;
  })
  return (
    <div className={cx('login')}>
      <h2 className={cx('title')}><Text msg='login.title'/></h2>
      <p className={cx('subtitle')}><Text msg='login.already-have-an-account'/></p>
      <form onSubmit={onSubmit}>
        <TextInput
          onChange={(name, value) => dispatch(onInputChange({name, value:value.toLowerCase()}))}
          name='email'
          autocomplete='true'
          error={errors.email}
          value={form.email.toLowerCase()}
          className={cx('input')}
          label='login.label.email'
        />
        <p className={cx('info')}>
          <Text msg='login.check-email.p1'/>
        </p>
        <div className={cx('row', 'row__forgot-password')}>
          <TextInput
            onChange={(name, value) => dispatch(onInputChange({name, value}))}
            value={form.password}
            name='password'
            type='password'
            autocomplete='true'
            error={errors.password}
            className={cx('input', 'password-input')}
            label='login.label.password'
          />
          <Button
            className={cx('submit-button')}
            msg={'login.forgot-password'}
            as='a'
            href={NavigationPaths.forgotPassword}
          />
        </div>
        {formErrors}
        <Button
          msg='login.button.send'
          loading={loading === 'email'}
          className={cx('submit-button')}
        />
        <p className={cx('or')}>
          <Text msg='login.or'/>
        </p>
        <GoogleLogin
          clientId={GOOGLE_CLIENT_ID}
          buttonText="Login"
          render={renderProps => (
            <Button
              as='div'
              loading={loading === 'google'}
              onClick={renderProps.onClick}
              disabled={renderProps.disabled}
              msg='login.with.google'
              className={cx('social-button')}
            />
          )}
          autoLoad={false}
          onSuccess={responseGoogleSuccess}
          onFailure={responseGoogleSuccess}
          cookiePolicy={'single_host_origin'}
        />
      </form>
    </div>
  );
};

export default LoginCard;
