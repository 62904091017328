import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import moment from 'moment';
import {PageValues} from '../../store/editPdfReport/types';
import {PdfFooter} from '../../components/PdfFooter';

const cx = classNames.bind(styles);

interface Props {
  id?: number;
  pageNumber?: number;
  withLink?: boolean;
  onlyPageInfo?: boolean;
  pageUrl?: string;
  footerData?: PageValues;
}

export const Footer: React.FC<Props> = ({id, pageUrl, onlyPageInfo, footerData, withLink, pageNumber}) => {
  const infoBox = (
    <div className={cx('info')}>
      <p>
        PageTracker-Bericht von {moment().format('YYYY-MM-DD')} für {pageUrl}, Kunden-Nr. {id}
      </p>
      <p>Seite {pageNumber}</p>
    </div>
  );
  return (
    <div className={cx('container')}>
      {!onlyPageInfo && <PdfFooter page={footerData} withLink={withLink} />}
      {infoBox}
    </div>
  );
};
