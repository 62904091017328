import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {NavigationPaths} from '../../../constants/navigationPaths';
import {images} from '../../../assets/img';
import {Text} from '../../ui/Text';
import {ExpandableUserMenu} from './ExpandableUserMenu';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {useSelector} from 'react-redux';
import {selectUser} from '../../../store/auth';
import {useLocation} from 'react-router-dom';
const cx = classNames.bind(styles);

export const DesktopMenu = () => {
  const location = useLocation();
  const user = useSelector(selectUser);
  if (window.location.href.includes('pdf')) return null;
  return (
    <div
      className={cx(
        'desktop-menu',
        location.pathname === NavigationPaths.homePage && 'home'
      )}
    >
      <div className={cx('beta')}>BETA</div>
      <div className={cx('left-side')}>
        <Link to={NavigationPaths.homePage}>
          <img
            src={
              location.pathname === NavigationPaths.homePage
                ? images.logo
                : images.logoBlue
            }
            className={cx('logo')}
            alt=""
          />
        </Link>
      </div>
      <div className={cx('right-side')}>
        <div className={cx('row')}>
          {user.isAuthenticated && (
            <>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={NavigationPaths.about}
                className={cx('link')}
              >
                <Text msg="top-menu.about" />
              </a>
              {'|'}
            </>
          )}

          <a
            target="_blank"
            href={NavigationPaths.faq}
            rel="noopener noreferrer"
            className={cx('link')}
          >
            <Text msg="top-menu.faq" />
          </a>
          {'|'}
          <a
                target="_blank"
                rel="noopener noreferrer"
                href={NavigationPaths.wiki}
                className={cx('link')}
              >
                <Text msg="top-menu.wiki" />
          </a> 
          {'|'}
          <a href={`mailto:${NavigationPaths.contact}`} className={cx('link')}>
            <Text msg="top-menu.kontakt" />
          </a>
          <div className={cx('flag-row')}>
            <img src={images.de} className={cx('flag')} alt="" />
            <img src={images.en} className={cx('flag')} alt="" />
          </div>
        </div>
        <div className={cx('login-row')}>
          {!user.isAuthenticated && (
            <Link
              to={NavigationPaths.authentication}
              className={cx('login-link')}
            >
              <img src={images.login} alt="icon login" />
              <p>
                <Text msg="top-menu.login" />
              </p>
            </Link>
          )}
          <ExpandableUserMenu
            isHome={location.pathname === NavigationPaths.homePage}
            user={user}
          />
        </div>
      </div>
    </div>
  );
};
