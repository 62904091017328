import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Interval, TableMenu} from "../../components/TableMenu";
import {changeInterval, getData, selectVisuals} from "../../store/visuals";
import {useDispatch, useSelector} from "react-redux";
import {Graph} from "../../components/LinearGraph/Graph";
import {Loader} from "../../components/ui/Loader";
import {Types} from "../../store/visuals/types";
import {apiEndpoints} from "../../constants/api";
import {TableFooter} from "../../components/TableFooter";
import {ErrorMessage} from "../../components/ErrorMessage";
import {useHasLimitedAccess, useHasNoAccess} from "../../utils/useCheckPermissions";
import {AccessSections} from "../../store/app/types";
import {NoAccessMessage} from "../../components/ui/NoAccessMessage";
import {selectUser} from "../../store/auth";

const cx = classNames.bind(styles);

export const Chart = () => {
  const dispatch = useDispatch()
  const {chart} = useSelector(selectVisuals);
  const {interval, data, error, isLoading} = chart;

  useEffect(() => {
    dispatch(getData.request({type: Types.chart}))
  }, [dispatch, interval])

  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['visuals-chart'],user)
  const noAccess = useHasNoAccess(AccessSections['visuals-chart'],user)

  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeInterval({interval: quarter, type: Types.chart}))
  }
  const renderGraph = () => {
    if (noAccess) return <NoAccessMessage/>
    if (error) return <ErrorMessage/>
    if (isLoading || !data) return <Loader className={cx('loader')}/>;
    return (
      <Graph legend='Punktzahl' data={data} max={104}/>
    )
  }
  return (
    <div className={cx('graph-container')}>
      <TableMenu
        active={interval}
        downloadApiUrl={apiEndpoints.getVisualsChart(interval || 'Y', true)}
        title='visuals-chart'
        questionMark='visuals-chart.question-mark'
        limitedAccess={limitedAccess}
        buttonsTitles={[
          'table-menu.button1',
          'table-menu.button2',
          'table-menu.button3',
        ]}
        onIntervalChange={onIntervalChange}
      />
      <div className={cx('graph')}>
        {renderGraph()}
      </div>
      <TableFooter withChartLabels={data && data.length > 3}/>
    </div>
  );
};
