import {UserProfile, ProfileErrors} from './types';
import {ActionType, getType} from 'typesafe-actions';
import * as actions from './userProfile.actions';
import {logout} from '../../auth/user';

export interface UserProfileState {
  form: Partial<UserProfile>;
  formIsUpdating?: boolean;
  formUpdateSuccess?: boolean;
  formErrors: ProfileErrors;
  formHasChanged?: boolean;
}

const initialState: UserProfileState = {
  form: {},
  formErrors: {},
}
const combinedActions = {...actions, logout}
type Action = ActionType<typeof combinedActions>

export const userProfileReducer = (state: UserProfileState = initialState, action: Action) => {
  switch (action.type) {
    case getType(actions.onInputChange) : {
      const {name, value} = action.payload;
      return {
        ...state,
        form: {...state.form, [name]: value},
        formErrors: {...state.formErrors, [name]: ''},
        formUpdateSuccess: false,
        formHasChanged:true,
      }
    }
    case getType(actions.clearAllSuccessMessages) :
      return {...state, formUpdateSuccess: false, invoicingUpdateSuccess: false}

    case getType(actions.getUserProfile.success):
      return {...state, form: action.payload};
    case getType(actions.updateProfile.request):
      return {...state, formErrors: {}, formIsUpdating:true};
    case getType(actions.updateProfile.failure):
      return {...state, formIsUpdating: false, errors: action.payload};
    case getType(actions.updateProfile.success) :
      return {...state, formIsUpdating: false, errors: {},formHasChanged:false, formUpdateSuccess: true};
    case getType(logout):
      return initialState;
    default :
      return state
  }
}
