import {createAsyncAction, createAction} from 'typesafe-actions';
import {Interval} from '../../components/TableMenu';
import {StateKeys} from './seo.reducer';
import {TableData} from "../../components/RankingsTable";
import {GraphData} from './types';

export const getData = createAsyncAction(
  'seo/GET_DATA_STARTED',
  'seo/GET_DATA_DONE',
  'seo/GET_DATA_FAILED',
)<{ type: StateKeys }, { type: StateKeys, data: TableData[] | GraphData[]}, { type: StateKeys }>()

export const changeInterval = createAction(
  'seo/CHANGE_INTERVAL',
)<{interval:Interval, type:StateKeys}>()
