export const syncFailCodes: {[key:string]:string} = {
  'k.A.':'sync.crawler-not-available',
  'js':'sync.post-info-hidden',
  'alt':'sync.youngest-post-older-then-90',
  'n.d.':'sync.post-not-deleted',
  'XX':'sync.page-archived',
  'no':'sync.website-indexing-disabled',
  'n/a':'sync.crawler-not-available',
  'old':'sync.youngest-post-older-then-90',
  'Test': 'sync.test',
  'pnd':'sync.post-not-deleted',
  'No':'sync.website-indexing-disabled',
  'nein':'sync.website-indexing-disabled',
}
