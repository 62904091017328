import axios, { AxiosResponse } from 'axios';
import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import { EditSectionTextState, selectEditSectionText } from '.';
import { apiEndpoints } from '../../constants/api';
import { handleResponseError } from '../../utils/responseErrors';
import * as actions from './editSectionText.actions';
import { DiagramTextValues } from './types';

function* onGetSectionText() {
  const {diagramsPages} = selectEditSectionText(yield select());
  try {
    const {data}: AxiosResponse<{sectionText:EditSectionTextState}> = yield call(axios.get, apiEndpoints.getSectionTexts)
    if(!data.sectionText) {
      const {data}: AxiosResponse<{sectionText:EditSectionTextState}> =yield call(axios.post, apiEndpoints.createSectionTexts,{diagramsPages:{...diagramsPages}})
      yield put(actions.getSectionTextData.success(data.sectionText));
    }
    yield put(actions.getSectionTextData.success(data.sectionText));
  } catch (e) {
    console.log('err')
    yield handleResponseError(e, actions.getSectionTextData.failure);
  }
}
function* onCurrentGetSectionText(action: ReturnType<typeof actions.getCurrentSectionTextData.request>) {
  const index = action.payload;
  try {
    const {data}: AxiosResponse<{sectionText:Array<Array<DiagramTextValues>>}> = yield call(axios.get, apiEndpoints.getCurrentSectionText(index))
    yield put(actions.getCurrentSectionTextData.success(data.sectionText));
  } catch (e) {
    console.log('err')
    yield handleResponseError(e, actions.getCurrentSectionTextData.failure);
  }
}
function* onUpdateSectionText(action: ReturnType<typeof actions.updateSectionTextData.request>) {
  const {diagramsPages} = selectEditSectionText(yield select());
  const {value}=action.payload;
  try {
    const {data}: AxiosResponse<{sectionText:EditSectionTextState}> = yield call(axios.put, apiEndpoints.updateSectionTexts,{index:value,diagramsPages:[...diagramsPages[value]]})
    yield put(actions.updateSectionTextData.success(data.sectionText));
  } catch (e) {
    yield handleResponseError(e, actions.updateSectionTextData.failure);
  }
}

export function* createEditSectionTextSagas():Generator {
  yield all([
    yield takeLatest(actions.updateSectionTextData.request, onUpdateSectionText),
    yield takeLatest(actions.getSectionTextData.request, onGetSectionText),
    yield takeLatest(actions.getCurrentSectionTextData.request, onCurrentGetSectionText),
  ]);
}
