import React, { useEffect, useRef } from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import { HotTable } from '@handsontable/react';
import {
  renderRow,
  validateTableFields,
  colWidths,
} from './requestsTableConfig';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteRequest,
  getRequests,
  selectSuperAdminState,
  updateRequest,
} from '../../store/superAdmin';
import { selectAppState } from '../../store/app';
import { Loader } from '../../components/ui/Loader';
import * as config from './requestsTableConfig';
import { Text } from '../../components/ui/Text';

const cx = classNames.bind(styles);

export const Requests = () => {
  const table = useRef<HotTable | null>(null);
  const dispatch = useDispatch();
  const { parameters } = useSelector(selectAppState);
  const { requests } = useSelector(selectSuperAdminState);

  let { data, isLoading, success, updateFailed } = requests;

  useEffect(() => {
    dispatch(getRequests.request());
  }, [dispatch]);

  const getCellData = (row: number, col: number): string => {
    if (!table || !table.current) return '';
    return table.current.hotInstance.getDataAtCell(row, col);
  };

  const row = renderRow({
    parameters,
    getCellData,
    deleteRequest: (id) => dispatch(deleteRequest.request(id)),
  });

  const columns = row.map((item) => item.column);

  useEffect(() => {
    validateTableFields(row);
  }, [row, dispatch]);

  const mapTableData = () => {
    if (!data || !parameters) return [];
    return data.map((item) => {
      return renderRow({
        item,
        parameters,
        getCellData,
        deleteRequest: (id) => dispatch(deleteRequest.request(id)),
      }).map((td) => td.value);
    });
  };

  const renderTable = () => {
    if (isLoading) return <Loader className={cx('loader')} />;
    if (requests.data?.length === 0)
      return (
        <div className={cx('not-found')}>
          <Text msg="super-admin.page-no-requests-found" />
        </div>
      );
    return (
      <HotTable
        hiddenColumns={{
          columns: [config.ID_COL_INDEX],
          indicators: false,
        }}
        fixedColumnsLeft={3}
        afterChange={(changes, source) => {
          if (!changes || !changes[0]) return null;
          const [row, , prevValue, nextValue] = changes[0];
          if (source === 'edit' && prevValue !== nextValue) {
            const id = getCellData(row, config.ID_COL_INDEX);
            const email = getCellData(row, config.EMAIL_COL_INDEX);
            const url = getCellData(row, config.URL_COL_INDEX);
            dispatch(updateRequest.request({ id, email, url }));
          }
        }}
        ref={table}
        id="hot-table"
        colWidths={colWidths}
        columns={columns}
        dropdownMenu={['filter_by_condition', 'filter_action_bar']}
        filters={true}
        columnSorting={true}
        licenseKey="non-commercial-and-evaluation"
        data={mapTableData()}
        colHeaders={true}
        manualColumnResize
        manualRowResize
        rowHeaders={false}
        width="100%"
        height="500"
      />
    );
  };

  return (
    <div className={cx('pages-container')}>
      <div className={cx('search-box')}>
        {success && (
          <div className={cx('notification', 'notification--success')}>
            Success
          </div>
        )}
        {updateFailed && (
          <div className={cx('notification', 'notification--failed')}>
            Update failed
          </div>
        )}
      </div>
      <div className={cx('table', 'table--short')}>{renderTable()}</div>
    </div>
  );
};
