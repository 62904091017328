import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from "../Text";

const cx = classNames.bind(styles);

interface Props {
  message: string;
  visible?: boolean;
  className?: string;
}

export const FormSuccessMessage = (props: Props) => {
  const {message, visible, className} = props;
  if (!visible) return null
  return (
    <div className={cx('message', className)}>
      <Text msg={message}/>
    </div>
  );
};
