import React, {useState} from 'react';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../../constants/api";
import {ComparePage} from "../../../store/compare/types";
import {TextInput} from "../TextInput";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Loader} from "../Loader";

const cx = classNames.bind(styles);

interface Props {
  value: string;
  onChange: (value: string) => void;
  onSelect: (page: ComparePage) => void;
  className?: string;
}

export const AutocompleteInput = (props: Props) => {
  const {onSelect,className, value, onChange} = props
  let latestValue = '';
  const [typing, setTyping] = useState<any>(false);
  const [data, setData] = useState<ComparePage[]>([]);

  const onInputChange = (name: string, value: string) => {
    clearInterval(typing);
    onChange(value)
    setTyping(setTimeout(() => {
      if (value) {
        latestValue = value;
        getSuggestion()
      }
    }, 1000))
  }

  const getSuggestion = async () => {
    try {
      const {data}: AxiosResponse<{ pages: ComparePage[] }> = await axios.post(apiEndpoints.similarPages, {url: latestValue});
      setData(data.pages)
      setTyping(false);
    } catch (e) {
      console.log(e)
     // console.log(e.response)
    }
  }

  const renderSuggestions = () => {
    return data.map((page, i) => {
      return (
        <div
          className={cx('url')} key={i}
          onClick={() => {
            onSelect(page);
            setData([]);
            onChange('')
          }}
        >
          {page.pageUrl}
        </div>
      )
    })
  }

  return (
    <div className={cx('wrapper', className)}>
      <TextInput
        value={value}
        onChange={onInputChange}
        label='compare.url'
        name=''
      />
      {(typing || data.length > 0) &&
      <div className={cx('list')}>
        {typing &&
        <div className={cx('loader')}>
          <Loader/>
        </div>}
        {renderSuggestions()}
      </div>
      }
      {/*{data.length ===0 && value && <p className={cx('not-found')}>This url is not yet in the system</p>}*/}
    </div>
  );
};
