import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {images} from '../../assets/img';

const cx = classNames.bind(styles);

export const Header = () => {
  return (
    <header className={cx('header')}>
      <img src={images.logoBlue} alt="logo" className={cx('logo')} />
      <div className={cx('area')}>BETA</div>
    </header>
  );
};
