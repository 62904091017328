import {TableData} from "../../components/RankingsTable";
import {RankingsCompanies, RankingsBrands, RankingsHashtags, Comparison} from '../../store/branding/types';
import {SeoRankBackLinksTable, SeoRankOutboundLinksTable} from "../../store/seo/types";

export const mapCompaniesData = (data: RankingsCompanies[]):TableData[] => {
  return data.map((item) => ({
    rank: item.rank,
    valueOne: item.companyName,
    comparisonLetter: item.comparisonLetter,
    country: item.country,
    valueTwo: item.scoreDach,
    valueThree: item.scoreCountry,
  }))
}

export const mapBrandsData = (data: RankingsBrands[]):TableData[] => {
  return data.map((item) => ({
    rank: item.rank,
    valueOne: item.brand,
    comparisonLetter: item.comparisonLetter,
    country: item.country,
    valueTwo: item.scoreDach,
    valueThree: item.scoreCountry,
  }))
}

export const mapHashtagsData = (data: RankingsHashtags[]):TableData[] => {
  return data.map((item) => ({
    rank: item.rank,
    valueOne: item.hashtag,
    comparisonLetter: item.comparisonLetter,
    country: item.country,
    valueTwo: item.scoreDach,
    valueThree: item.scoreCountry,
  }))
}

export const mapSeoTableData = (data: SeoRankBackLinksTable[] | SeoRankOutboundLinksTable[]): TableData[] => {
  return data.map((item) => ({
    rank: item.rank,
    valueOne: item.value,
    comparisonLetter: item.comparisonLetter,
    country: item.country,
    valueTwo: item.scoreDach,
    valueThree: item.scoreCountry,
  }))
}

export const mapSeoGraphData = (data: [string, string][], id: string, color: string) => {
  return {
    data: data.map((item) => ({x: item[0], y: parseFloat(item[1])})),
    color,
    id
  };
}

export const mapCompareData = (data:Comparison[])=>{
  return data.map((item)=>({letter:item.comparisonLetter, value:item.scoreDach}))
}
