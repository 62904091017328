import {all, put, takeLatest} from 'redux-saga/effects';
import {push} from 'connected-react-router';
import {logout} from './user.actions';
import {NavigationPaths} from "../../../constants/navigationPaths";
import axios from "axios";

function* onLogout(action: ReturnType<typeof logout>) {
  const {redirect} = action.payload;
  if(redirect) yield put(push(NavigationPaths.authentication))
  delete axios.defaults.headers.common['authorization']
}

export function* userSagas() {
  yield all([
    yield takeLatest(logout, onLogout),
  ])
}
