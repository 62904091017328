import React from 'react';
import {Text} from "../Text";
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  message?: string;
  className?: string;
}

export const FormErrorMessage = (props: Props) => {
  const {message, className} = props;
  if (!message) return null
  return (
    <div className={cx('error', className)}>
      <Text msg={message}/>
    </div>
  );
};
