import {createAction, createAsyncAction} from 'typesafe-actions';
import {InputPayload} from '../../../types/events';

export const onInputChange = createAction('auth/FORGOT_PASSWORD_INPUT_CHANGE')<InputPayload>();

export const changePassword = createAsyncAction(
  'auth/CHANGE_FORGOT_PASSWORD_STARTED',
  'auth/CHANGE_FORGOT_PASSWORD_DONE',
  'auth/CHANGE_FORGOT_PASSWORD_FAILED',
)<void, void, {}>()

export const clearState = createAction('auth/FORGOT_PASSWORD_CLEAR_STATE')<void>();
