export interface RankingsCompanies {
  companyName: string;
  comparisonLetter: string;
  country: string;
  rank: string;
  scoreCountry: string;
  scoreDach: string;
}

export interface RankingsBrands {
  brand: string;
  comparisonLetter: string;
  rank: string;
  country: string;
  scoreCountry: string;
  scoreDach: string;
}

export interface RankingsHashtags {
  hashtag: string;
  comparisonLetter: string;
  rank: string;
  scoreCountry: string;
  country: string;
  scoreDach: string;
}

export interface Comparison {
  comparisonLetter: string;
  scoreDach: string;
}

export type ResponseData = RankingsBrands[]
  | RankingsCompanies[]
  | RankingsHashtags[]
  | Comparison[]

export enum Types {
  rankingsCompanies = 'rankingsCompanies',
  rankingsBrands = 'rankingsBrands',
  rankingsHashtags = 'rankingsHashtags',
  rankingsCompaniesComparison = 'rankingsCompaniesComparison',
  rankingsBrandsComparison = 'rankingsBrandsComparison',
  rankingsHashtagsComparison = 'rankingsHashtagsComparison',
}
