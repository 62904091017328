import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Interval, TableMenu} from "../../components/TableMenu";
import {changeInterval, getData,selectEngagement} from "../../store/engagement";
import {useDispatch, useSelector} from "react-redux";
import {Loader} from "../../components/ui/Loader";
import {Types} from "../../store/engagement/types";
import {apiEndpoints} from "../../constants/api";
import {BarChart} from "../../components/BarChart";
import {ErrorMessage} from "../../components/ErrorMessage";
import {useHasLimitedAccess, useHasNoAccess} from "../../utils/useCheckPermissions";
import {AccessSections} from "../../store/app/types";
import {NoAccessMessage} from "../../components/ui/NoAccessMessage";
import {selectUser} from "../../store/auth";

const cx = classNames.bind(styles);

export const CommentsCount = () => {
  const dispatch = useDispatch()
  const {commentsCount} = useSelector(selectEngagement);
  const {interval, data, error, isLoading} = commentsCount;

  useEffect(() => {
    dispatch(getData.request({type: Types.commentsCount}))
  }, [dispatch, interval])

  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['engagement-comments-count'],user)
  const noAccess =useHasNoAccess(AccessSections['engagement-comments-count'],user);

  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeInterval({interval: quarter, type: Types.commentsCount}))
  }

  const renderGraph = () => {
    if (noAccess) return <NoAccessMessage/>
    if (error) return <ErrorMessage/>
    if (!data || isLoading) return <Loader className={cx('loader')}/>;
    return (
      <BarChart letterALabel='benchmarking.footer.label.our-url' data={data}/>
    )
  }
  return (
    <div className={cx('graph-container','bar-chart')}>
      <TableMenu
        limitedAccess={limitedAccess}
        active={interval}
        downloadApiUrl={apiEndpoints.getEngagementCommentsCount(interval || 'Y', true)}
        title={['engagement-comments-count']}
        questionMark='engagement-comments-count.question-mark'
        buttonsTitles={[
          'table-menu.button1',
          'table-menu.button2',
          'table-menu.button3',
        ]}
        onIntervalChange={onIntervalChange}
      />
      {renderGraph()}
    </div>
  );
};
