import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Container from '../../components/layout/Container';
import {CheckBox} from '../../components/ui/CheckBox';
import {Text} from '../../components/ui/Text';
import {useDispatch, useSelector} from 'react-redux';
import {
  getReportSettings,
  onDataToIncludeChange,
  onReportFormatsChange,
  onSendingIntervalChange,
  saveReportSettings,
  saveReportSettingsAndGetPdf,
} from '../../store/report/report.actions';
import {selectReports} from '../../store/report';
import {DataToIncludeKeys, ReportFormatKeys, SendingIntervalKeys} from '../../store/report/types';
import {Button} from '../../components/ui/Button';
import {FormSuccessMessage} from '../../components/ui/FormSuccessMessage';
import {QuestionMark} from '../../components/ui/QuestionMark';
import {FormErrorMessage} from '../../components/ui/FormErrorMessage';
import {AccessSections, ExportPeriods} from '../../store/app/types';
import {selectUser} from '../../store/auth';
import {checkSubUserStatus, selectUserIsAdmin} from '../../store/auth/user';

const cx = classNames.bind(styles);

interface DataToIncludeForm {
  placeholder: string;
  name: DataToIncludeKeys;
  noAccess?: AccessSections;
}

interface SendingIntervalForm {
  placeholder: string;
  name: SendingIntervalKeys;
}

interface ReportFormatForm {
  placeholder: string;
  name: ReportFormatKeys;
}

const benchmarking: DataToIncludeForm[] = [
  {
    placeholder: 'report.ranking-list',
    name: 'benchmarkingRankingsDach',
    noAccess: AccessSections['benchmarking-rankings-dach'],
  },
  {
    placeholder: 'report.ranking-regional',
    name: 'benchmarkingRankingsRegional',
    noAccess: AccessSections['benchmarking-rankings-regional'],
  },
  {
    placeholder: 'report.overview',
    name: 'benchmarkingWritingStyle',
    noAccess: AccessSections['benchmarking-writing-style'],
  },
  {
    placeholder: 'report.writing-style',
    name: 'benchmarkingSeo',
    noAccess: AccessSections['benchmarking-seo'],
  },
];
const trendAnalysis: DataToIncludeForm[] = [
  {placeholder: 'report.blog', name: 'trendanalysisDach'},
  {placeholder: 'report.country', name: 'trendanalysisRegional'},
];
const roi: DataToIncludeForm[] = [{placeholder: 'report.recommended-action', name: 'recommendedAction'}];
const branding: DataToIncludeForm[] = [
  {
    placeholder: 'report.ranking-company-name',
    name: 'brandingRankCompanyName',
    noAccess: AccessSections['branding-rank-company-name'],
  },
  {
    placeholder: 'report.brand-ranking',
    name: 'brandingRankBrand',
    noAccess: AccessSections['branding-rank-brand'],
  },
  {
    placeholder: 'report.ranking-hashtags',
    name: 'brandingRankHashtag',
    noAccess: AccessSections['branding-rank-hashtag'],
  },
  {
    placeholder: 'report.company-names-comparison',
    name: 'brandingRankCompanyNameComparison',
    noAccess: AccessSections['branding-rank-company-name-comparison'],
  },
  {
    placeholder: 'report.brand-comparison',
    name: 'brandingRankBrandComparison',
    noAccess: AccessSections['branding-rank-brand-comparison'],
  },
  {
    placeholder: 'report.hashtags-comparison',
    name: 'brandingRankHashtagComparison',
    noAccess: AccessSections['branding-rank-hashtag-comparison'],
  },
];
const seo: DataToIncludeForm[] = [
  {placeholder: 'report.backlinks-number', name: 'seoRankBackLinksTable'},
  {placeholder: 'report.outbound-links-number', name: 'seoRankOutboundLinksTable'},
  // {placeholder: 'report.backlinks-comparison', name: 'seoRankBackLinksChart'},
  // {placeholder: 'report.internal-urls-number', name: 'seoRankOutboundLinksChart'},
];
const engagement: DataToIncludeForm[] = [
  {placeholder: 'report.reader-comments', name: 'engagementCommentsCount'},
  {placeholder: 'report.author-responses-number', name: 'engagementAuthorRepliesCount'},
  // {placeholder: 'report.author-words-number', name: 'engagementAuthorRepliesChart'},
];
const visuals: DataToIncludeForm[] = [
  {placeholder: 'report.photos-number', name: 'visualsImagesCount'},
  {placeholder: 'report.headers-number', name: 'visualsSubtitlesCount'},
  // {placeholder: 'report.ranking-list-visual-benchmark', name: 'visualsChart'},
];
const format: ReportFormatForm[] = [{placeholder: 'report.pdf', name: 'pdf'}];
const intervals: SendingIntervalForm[] = [
  {placeholder: 'report.7tags', name: 'every7days'},
  {placeholder: 'report.30tags', name: 'every30days'},
  {placeholder: 'report.90tags', name: 'every90days'},
  {placeholder: 'report.dont-send', name: '-1'},
];

export const Report = () => {
  const dispatch = useDispatch();
  const {subscription} = useSelector(selectUser);
  const isSubUser = useSelector(checkSubUserStatus);
  const isAdmin = useSelector(selectUserIsAdmin);

  const {
    settings,
    isFirstFormSaving,
    isGettingPdf,
    isPdfDownloadSuccess,
    isSecondFormSaving,
    isSecondSuccess,
    isFirstSuccess,
  } = useSelector(selectReports);

  useEffect(() => {
    dispatch(getReportSettings.request());
  }, [dispatch]);
  if (!subscription && isSubUser) {
    return (
      <Container>
        <div className={cx('not-allowed')}>
          <Text msg="common.not-available-fot-this-plan" />
        </div>
      </Container>
    );
  }
  if (!subscription) return null;

  const dataToIncludeForm = (data: DataToIncludeForm[]) => {
    if (!data) return <div>Error rendering includes form data</div>;
    return data.map(({name, placeholder, noAccess}, i) => {
      const value = settings.dataToInclude[name];
      const disabled = noAccess && subscription?.noAccessSections?.includes(noAccess);
      return (
        <div key={i} className={cx('row')}>
          <p className={cx('placeholder')}>
            <Text msg={placeholder} />
          </p>
          <CheckBox
            disabled={disabled}
            className={cx('checkbox')}
            onChange={() => dispatch(onDataToIncludeChange({name, value: !value}))}
            checked={!!value}
            name={name}
          />
        </div>
      );
    });
  };

  const reportFormatForm = (data: ReportFormatForm[]) => {
    if (!data) return <div>Error rendering form data</div>;
    return data.map(({name, placeholder}, i) => {
      const value = settings?.reportFormats[name];
      return (
        <div key={i} className={cx('row')}>
          <p className={cx('placeholder')}>
            <Text msg={placeholder} />
          </p>
          <CheckBox
            className={cx('checkbox')}
            onChange={() => dispatch(onReportFormatsChange({name, value: !value}))}
            checked={!!value}
            name={name}
          />
        </div>
      );
    });
  };

  const sendingIntervalForm = (data: SendingIntervalForm[]) => {
    if (!data) return <div>Error rendering interval form data</div>;
    return data.map(({name, placeholder}, i) => {
      return (
        <div key={i} className={cx('row')}>
          <p className={cx('placeholder')}>
            <Text msg={placeholder} />
          </p>
          <CheckBox
            className={cx('checkbox')}
            onChange={() => dispatch(onSendingIntervalChange({name, value: true}))}
            checked={settings.sendingInterval === name}
            name={name}
          />
        </div>
      );
    });
  };
  return (
    <Container className={cx('container')}>
      <div className={cx('table', 'table__data-settings')}>
        <div className={cx('temp-header')}>Coming soon..</div>
        <QuestionMark
          content="report.tooltip-content"
          tooltipPosition="left"
          className={cx('question-mark')}
        />
        <h2 className={cx('heading')}>
          <Text msg="report.title.data-settings" />
        </h2>
        <div className={cx('box')}>
          <h3 className={cx('heading')}>
            <Text msg="report.subtitle.benchmarking" />
          </h3>
          {dataToIncludeForm(benchmarking)}
        </div>
        <div className={cx('box')}>
          <h3 className={cx('heading')}>
            <Text msg="report.subtitle.trend-analysis" />
          </h3>
          {dataToIncludeForm(trendAnalysis)}
          <h3 className={cx('heading', 'heading__with-margin')}>
            <Text msg="report.subtitle.roi" />
          </h3>
          {dataToIncludeForm(roi)}
        </div>
        <div className={cx('box')}>
          <h3 className={cx('heading')}>
            <Text msg="report.subtitle.branding" />
          </h3>
          {dataToIncludeForm(branding)}
        </div>
        <div className={cx('box')}>
          <h3 className={cx('heading')}>
            <Text msg="report.subtitle.seo" />
          </h3>
          {dataToIncludeForm(seo)}
        </div>
        <div className={cx('box')}>
          <h3 className={cx('heading')}>
            <Text msg="report.subtitle.engagement" />
          </h3>
          {dataToIncludeForm(engagement)}
        </div>
        <div className={cx('box')}>
          <h3 className={cx('heading')}>
            <Text msg="report.subtitle.visuals/seo" />
          </h3>
          {dataToIncludeForm(visuals)}
        </div>
        <div className={cx('footer')}>
          <Button
            msg="report.button-send"
            className={cx('download')}
            loading={isFirstFormSaving}
            disabled
            onClick={() => dispatch(saveReportSettings.request({form: 'first'}))}
          />
          <Button
            onClick={() => dispatch(saveReportSettingsAndGetPdf.request({interval: '1'}))}
            msg="report.button.90"
            className={cx('download')}
            // disabledMessage='common.not-available-fot-this-plan'
            // disabled={!subscription.fullExportPeriods.includes(ExportPeriods['1'])}
            loading={isGettingPdf === '1'}
          />
          <Button
            onClick={() => dispatch(saveReportSettingsAndGetPdf.request({interval: 'Y'}))}
            msg="report.button.1-12"
            className={cx('download')}
            // disabledMessage='common.not-available-fot-this-plan'
            disabled={!isAdmin}
            // disabled={!subscription.fullExportPeriods.includes(ExportPeriods['Y'])}
            loading={isGettingPdf === 'Y'}
          />
          <Button
            onClick={() => dispatch(saveReportSettingsAndGetPdf.request({interval: 'Y2'}))}
            msg="report.button.13-24"
            className={cx('download')}
            // disabledMessage='common.not-available-fot-this-plan'
            disabled={!isAdmin}
            // disabled={!subscription.fullExportPeriods.includes(ExportPeriods['2Y'])}
            loading={isGettingPdf === 'Y2'}
          />
          <FormSuccessMessage visible={isFirstSuccess} message="common.success" className={cx('success')} />
          <FormSuccessMessage
            visible={!isFirstSuccess && isPdfDownloadSuccess}
            message="report.download-success"
            className={cx('success')}
          />
        </div>
      </div>

      <div className={cx('table', 'table__format-settings')}>
        <div className={cx('box', 'box__wide')}>
          <h2 className={cx('heading')}>
            <Text msg="report.title.data-format" />
          </h2>
          {reportFormatForm(format)}
        </div>
        <div className={cx('box', 'box__wide')}>
          <h2 className={cx('heading')}>
            <Text msg="report.title.send-to" />
          </h2>
          <FormErrorMessage
            message={settings.sendingInterval === '-1' ? 'report.dont-send-warning' : undefined}
          />
          {sendingIntervalForm(intervals)}
        </div>
        <div className={cx('footer')}>
          <Button
            onClick={() => dispatch(saveReportSettings.request({form: 'second'}))}
            msg="report.button-send"
            loading={isSecondFormSaving}
          />
          <FormSuccessMessage visible={isSecondSuccess} message="common.success" className={cx('success')} />
        </div>
      </div>
    </Container>
  );
};
