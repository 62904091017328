import React from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface Props {
  text:string;
  className?: string
}
export const Tooltip:React.FC<Props> = ({text, className}) => {
  return (
    <div className={cx('tooltip', className)}>
      {text}
    </div>
  );
};
