import React, {useEffect, useRef, useState} from 'react';
import {Page1} from './Page1';
import {Page2} from './Page2';
import {Page3} from './Page3';
import {Page4} from './Page4';
import {Page5} from './Page5';
import {Page6} from './Page6';
import {useParams} from 'react-router-dom';
import {Response} from './types';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from '../constants/api';

export const PDF = () => {
  const {secret} = useParams<{secret: string}>();
  const [pdfData, setData] = useState<undefined | Response>(undefined);
  const ref = useRef<HTMLDivElement | null>(null);
  console.log(pdfData);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const {data}: AxiosResponse<Response> = await axios.post(apiEndpoints.getPdfData(secret));
        console.log('data ', data);
        setData(data);
        setTimeout(() => {
          const body = document.querySelector('body');
          body?.setAttribute('id', 'loaded');
        }, 500);
      } catch (e) {
        console.log(e.response);
        console.log('erroras');
      }
    };
    fetchData();
  }, []);
  console.log('pdfData', pdfData);
  if (!pdfData) return null;
  return (
    <div ref={ref}>
      <Page1 pages={pdfData?.pages} id={pdfData?.customerId} footerData={pdfData?.footers.pages[0]} />
      {pdfData.subscriptionId !== 2 && <Page2 pdfData={pdfData} footerData={pdfData?.footers.pages[1]} />}
      {pdfData.subscriptionId === 2 && <Page3 pdfData={pdfData} footerData={pdfData?.footers.pages[1]} />}
      <Page4 pdfData={pdfData} footerData={pdfData?.footers.pages[2]} />
      <Page5 pdfData={pdfData} />
      <Page6 pdfData={pdfData} footerData={pdfData?.footers.pages[3]} />
    </div>
  );
};
