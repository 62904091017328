import {createAction, createAsyncAction} from 'typesafe-actions';
import {Archive} from './types';

export const onCheck = createAction(
  'archive/ON_CHECK'
)<{ index: number, value: boolean }>()


export const getArchives = createAsyncAction(
  'archive/GET_ARCHIVES_STARTED',
  'archive/GET_ARCHIVES_DONE',
  'archive/GET_ARCHIVES_FAILED',
)<void, Archive[], void>()
