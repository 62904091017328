import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from './subscription.actions';
import axios from "axios";
import {apiEndpoints} from "../../constants/api";
import {showErrorModal} from "../app/app.actions";
import {Invoicing, selectInvoice, validateInvoiceDataFailed} from "../profile/invoice";
import {validateInvoiceForm} from "../../utils/formValidations";

function* onChangeSubscription(action: ReturnType<typeof actions.changeSubscription.request>) {
  const planId = action.payload;
  try {
    yield call(axios.put, apiEndpoints.changeSubscription(planId));
    yield put(actions.changeSubscription.success(0))
  } catch (e) {
    yield put(showErrorModal({}))
    yield put(actions.changeSubscription.failure())
  }
}

function* validateInvoice(action: ReturnType<typeof actions.showModal.request>) {
  try {
    const {form: formData} = selectInvoice(yield select());
    validateInvoiceForm(formData as Invoicing);
    yield put(actions.showModal.success(action.payload))
  } catch (e) {
    yield put(validateInvoiceDataFailed(e.errors))
    yield put(actions.showModal.failure(action.payload))
  }
}

export function* subscriptionSagas() {
  yield all([
    yield takeLatest(actions.changeSubscription.request, onChangeSubscription),
    yield takeLatest(actions.showModal.request, validateInvoice)
  ])
}
