import React, {FC, useEffect, useRef, useState} from 'react';
import ReactQuill from 'react-quill';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {formats, modules} from './quillConfig';
import {PageValues, PageValuesKeys} from '../../store/editPdfReport/types';
import {Button} from '../../components/ui/Button';
import {Loader} from '../../components/ui/Loader';
import {PdfFooter} from '../../components/PdfFooter';
const cx = classNames.bind(styles);
interface PageProps {
  page: PageValues;
  isLoading?: boolean;
  height: number;
  index: number;
  isValidFooter: boolean;
  withLink?: boolean;
  savedPage: PageValues;
  changeIsValidFooter: (v: boolean, index: number) => void;
  onChange: (e: string, side: PageValuesKeys, index: number) => void;
}
export const PdfPage: FC<PageProps> = (props) => {
  const {page, onChange, height, index, isValidFooter, changeIsValidFooter, isLoading, withLink, savedPage} =
    props;
  const [toggleShow, setToggleShow] = useState<boolean>(true);
  const [toggleShowOriginal, setToggleShowOriginal] = useState<boolean>(true);
  const footerRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (footerRef && footerRef.current)
      footerRef.current.clientHeight > height
        ? changeIsValidFooter(false, index)
        : changeIsValidFooter(true, index);
  }, [footerRef, page]);
  if (isLoading) return <Loader className={cx('loader')} />;
  const renderQuillEditor = () => {
    return (
      <>
        <ReactQuill
          placeholder="Start typing here"
          modules={modules}
          formats={formats}
          theme="snow"
          value={page?.left || ''}
          onChange={(e) => onChange(e, 'left', index)}
          className={cx('quill-editor')}
        />
        <ReactQuill
          placeholder="Start typing here"
          modules={modules}
          formats={formats}
          theme="snow"
          value={page?.right || ''}
          onChange={(e) => onChange(e, 'right', index)}
          className={cx('quill-editor')}
        />
      </>
    );
  };
  return (
    <div className={cx('tab-content')}>
      <div className={cx('editors-container', 'notranslate', !isValidFooter && 'editors-container__error')}>
        {renderQuillEditor()}
      </div>
      <div className={cx('footer-container', !toggleShow && 'footer-container__hide')}>
        <h3 className={cx('footer-container__title')}>PREVIEW</h3>
        <PdfFooter className={cx('footer-height')} page={page} footerRef={footerRef} withLink={withLink} />
      </div>
      {toggleShowOriginal && (
        <div className={cx('footer-container')}>
          <h3 className={cx('footer-container__title')}>ORIGINAL</h3>
          <PdfFooter className={cx('footer-height')} page={savedPage} withLink={withLink} />
        </div>
      )}
      <Button
        as="div"
        msg={toggleShow ? 'hide-new-footer' : 'show-new-footer'}
        className={cx('button')}
        onClick={() => setToggleShow(!toggleShow)}
      />
      <Button
        as="div"
        msg={toggleShowOriginal ? 'hide-original-footer' : 'show-original-footer'}
        className={cx('button')}
        onClick={() => setToggleShowOriginal(!toggleShowOriginal)}
      />
    </div>
  );
};
