import {getType, ActionType} from 'typesafe-actions';
import * as actions from './confirm-email.actions';

type Action = ActionType<typeof actions>

export interface ConfirmEmailState {
  code?: number | string;
  email?: string;
  loading: boolean;
  success?: boolean;
  error:string;
  isResending?: boolean;
}

const initialState: ConfirmEmailState = {
  code:undefined,
  loading: false,
  error:'',
}

export const confirmEmailReducer = (state = initialState, action: Action): ConfirmEmailState => {
  switch (action.type) {
    case getType(actions.resendEmail.request):
      return {...state, isResending:true}
    case getType(actions.resendEmail.failure):
      return {...state, isResending:false}
    case getType(actions.resendEmail.success):
      return {...state, isResending:false}
    case getType(actions.saveDataFromUrl) : {
      const {email, code} = action.payload;
      return {...state, email, code}
    }
    case getType(actions.clearState):
      return initialState;
    case getType(actions.onInputChange):
      return {...state, error:'', code:action.payload}
    case getType(actions.confirm.request):
      return {...state, loading: true, error:'', success: false}
    case getType(actions.confirm.failure):
      return {...state, loading: false, error: action.payload, success: false}
    case getType(actions.confirm.success):
      return {...initialState, success: true}
    default :
      return state
  }
}
