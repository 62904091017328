import {combineReducers} from 'redux';
import {userProfileReducer, UserProfileState} from './userProfile/userProfile.reducer';
import {passwordReducer, ProfilePasswordState} from './password/password.reducer';
import {invitedUsersReducer, InviteUsersState} from './invitedUsers';
import {invoiceReducer, InvoiceState} from './invoice';

export interface ProfileState {
  userProfile: UserProfileState;
  password: ProfilePasswordState;
  users: InviteUsersState;
  invoice: InvoiceState;
}

export const profileReducer = combineReducers<ProfileState>({
  userProfile: userProfileReducer,
  password: passwordReducer,
  users: invitedUsersReducer,
  invoice: invoiceReducer,
})

export const selectUserProfile = (state: RootState) => state.profile.userProfile;
export const selectChangePassword = (state: RootState) => state.profile.password;
