import * as React from "react";

export const textStyle = {
  fill: '#575756',
  fontSize: 12,
  fontWeight: 500,
};

export const pdfTextStyle = {
  fill: '#575756',
  fontSize: 9,
  fontWeight: 500,
};

export const theme = (pdfExport?: boolean) => (
  {
    crosshair: {
      line: {
        strokeDasharray: '0',
        strokeWidth: 1,
        stroke: '#D3D3D3',
      },
    },
    axis: {
      legend: {
        text: {fill: '#8E8E8E', fontSize: pdfExport ? 9 : 12,}
      },
      domain: {
        line: {
          strokeDasharray: '0',
          strokeWidth: pdfExport ? 1 : 1,
          stroke: '#e3e3e3',
        }
      }
    },
    grid: {
      line: {
        stroke: '#e3e3e3',
        strokeWidth: pdfExport ? 1 : 1,
      }
    }
  }
)
