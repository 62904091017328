import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {clearLoginState, login, loginWithSocialProvider} from './login.actions';
import {LoginResponse} from './types';
import {apiEndpoints} from '../../../constants/api';
import {selectLogin} from '..';
import axios, {AxiosResponse} from 'axios';
import {push} from 'connected-react-router';
import {NavigationPaths} from '../../../constants/navigationPaths';
import {handleErrors, validateLogin} from '../../../utils/formValidations';
import {storage, StorageKeys} from "../../../utils/localStorage";
import {setToken} from "../../../utils/setToken";
import {getSystemParameters} from '../../app/app.actions';

function* loginStarted() {
  const {form} = selectLogin(yield select())
  const {email, password} = form;
  try {
    validateLogin(form);
    const {data}: AxiosResponse<LoginResponse> = yield call(axios.post, apiEndpoints.login, {email, password})
    storage.save(StorageKeys.authToken, data.accessToken);
    setToken(data.accessToken)

    yield put(login.success(data))
    yield put(getSystemParameters.request())
    const firstTimeLogin = storage.get(StorageKeys.firstTimeLogin);
    yield put(push(firstTimeLogin ? NavigationPaths.compare : NavigationPaths.benchmarking))
    storage.delete(StorageKeys.firstTimeLogin);
  } catch (e) {
    //@ts-ignore
    if (e?.response?.status === 403) {
      yield put(push(NavigationPaths.confirmEmail + '?email=' + email))
      yield put(clearLoginState())
      axios.post(apiEndpoints.resendConfirmationEmail, {email})
    }
    yield put(login.failure(handleErrors(e)))
  }
}

function* socialLoginStarted(action: ReturnType<typeof loginWithSocialProvider.request>) {
  const {token} = action.payload;
  // user provider for later
  try {
    const {data}: AxiosResponse<LoginResponse> = yield call(axios.get, apiEndpoints.socialLogin(token))

    storage.save(StorageKeys.authToken, data.token);
    setToken(data.token);

    yield put(loginWithSocialProvider.success(data))
    yield put(getSystemParameters.request())
    yield put(push(NavigationPaths.profile))
  } catch (e) {
    //@ts-ignore
    if (e?.response.status === 401) {
      yield put(loginWithSocialProvider.failure({server: 'errors-register-first'}))
    } else {
      yield put(loginWithSocialProvider.failure(handleErrors(e)))
    }
  }
}

export function* logInSagas():Generator {
  yield all([
    yield takeLatest(login.request, loginStarted),
    yield takeLatest(loginWithSocialProvider.request, socialLoginStarted),
  ]);
}
