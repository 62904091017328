import React, {useEffect} from 'react';
import Container from "../../components/layout/Container";
import {UserProfile} from "./UserProfile";
import {ChangePassword} from "./ChangePassword";
import {clearAllSuccessMessages} from "../../store/profile/userProfile/userProfile.actions";
import {useDispatch} from "react-redux";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {SubscriptionWrapper} from "../Subscription/SubscriptionWrapper";
import {Invoicing} from "./Invoicing";
import {InviteUsers} from "./InviteUsers";
import {WriteReview} from "./WriteReview";
import {Rating} from "./Rating";
import {Text} from "../../components/ui/Text";
import {images} from "../../assets/img";
import {NavigationPaths} from "../../constants/navigationPaths";

const cx = classNames.bind(styles);

export const Profile = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      dispatch(clearAllSuccessMessages())
    }
  }, [dispatch])

  return (
    <Container className={cx('container')}>
      <div className={cx('row')}>
        <UserProfile/>
        <ChangePassword/>
        <Invoicing/>
      </div>
      <a className={cx('break-line')} rel="noopener noreferrer" target="_blank" href={NavigationPaths.profileFAQ}>
        <img src={images.chevronRight} alt="" className={cx('chevron')}/>
        <span><Text msg='profile.break-line'/></span>
      </a>
      <SubscriptionWrapper/>
      <InviteUsers/>
      <div className={cx('row', 'row--stretch')}>
        <Rating/>
        <WriteReview/>
      </div>
    </Container>
  );
};
