import {ActionType, getType} from 'typesafe-actions';
import * as actions from './branding.actions';
import {RankingsCompanies, RankingsHashtags, RankingsBrands, Comparison} from "./types";

type Action = ActionType<typeof actions>

interface BaseProps {
  isLoading?: boolean;
  interval?: string;
  error?: boolean;
}
export interface BrandingState {
  rankingsCompanies: {
    data?: RankingsCompanies[];
  } & BaseProps,
  rankingsBrands: {
    data?: RankingsBrands[];
  } & BaseProps,
  rankingsHashtags: {
    data?: RankingsHashtags[];
  } & BaseProps,
  rankingsCompaniesComparison: {
    data?: Comparison[];
  } & BaseProps,
  rankingsBrandsComparison: {
    data?: Comparison[];
  } & BaseProps,
  rankingsHashtagsComparison: {
    data?: Comparison[];
  } & BaseProps,
}

export type StateKeys = keyof BrandingState;

const initialState: BrandingState = {
  rankingsCompanies: {},
  rankingsBrands: {},
  rankingsHashtags: {},
  rankingsCompaniesComparison: {},
  rankingsBrandsComparison: {},
  rankingsHashtagsComparison: {},
}

export const brandingReducer = (state = initialState, action: Action): BrandingState => {
  switch (action.type) {
    case getType(actions.getData.request):{
      const {type} = action.payload;
      return {...state, [type]:{isLoading:true, interval:state[type].interval}}
    }
    case getType(actions.getData.success): {
      const {type} = action.payload;
      return {...state, [type]: {data: action.payload.data, interval: state[type].interval}}
    }
    case getType(actions.getData.failure): {
      const {type} = action.payload;
      return {...state, [type]: {error: true, interval: state[type].interval}}
    }
    case getType(actions.changeInterval):{
      const {type} = action.payload;
      return {...state, [type]: {...state[type], interval: action.payload.interval}}
    }
    default :
      return state
  }
}
