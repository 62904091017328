import React from 'react';
import {Route, Switch} from 'react-router-dom';
// Utils
import {NavigationPaths} from './constants/navigationPaths';
import style from './index.module.scss';
import {storage, StorageKeys} from './utils/localStorage';
import {getUserProfile} from './store/profile/userProfile/userProfile.actions';
import {store} from './store';
import {setAdminToken} from './store/superAdmin';
import {authenticateUser, logout} from './store/auth/user';
import {getSystemParameters} from './store/app/app.actions';
// Components
import {TopMenu} from './components/layout/TopMenu';
import {SideMenu} from './components/layout/SideMenu';
import {Footer} from './components/layout/Footer';
import {ProtectedRoute} from './components/ProtectedRoute';
// Routes
import {Home} from './routes/Home';
import {Authentication} from './routes/Authentication';
import {ForgotPassword} from './routes/ForgotPassword';
import {Profile} from './routes/Profile';
import {ConfirmEmail} from './routes/ConfirmEmail';
import {CreateNewPassword} from './routes/CreateNewPassword';
import {setToken} from './utils/setToken';
import {Report} from './routes/Report';
import {Compare} from './routes/Compare';
import {ConfirmationModal} from './components/ui/ConfirmationModal';
import {Archive} from './routes/Archive';
import {ErrorModal} from './components/ErrorModal';
import {Benchmarking} from './routes/Benchmarking';
import {Trend} from './routes/Trend';
import {PDF} from './PDF';
import {PdfBasic} from './PDF/Basic';
import {Branding} from './routes/Branding';
import {SuperAdmin} from './routes/SuperAdmin';
import {SuccessModal} from './components/SuccessModal';
import {Seo} from './routes/Seo';
import {Engagement} from './routes/Engagement';
import {Visuals} from './routes/Visuals';
import {SubUserRegister} from './routes/Authentication/SubUserRegister';
import {EditPdfReports} from './routes/EditPdfReports';
import {EditSectionTexts} from './routes/EditSectionTexts';

if (window.location.pathname === NavigationPaths.subUser) {
  storage.delete(StorageKeys.authToken);
  storage.delete(StorageKeys.adminToken);
  store.dispatch(logout({redirect: false}));
}
const token = storage.get(StorageKeys.authToken);
const adminToken = storage.get(StorageKeys.adminToken);
if (adminToken) {
  store.dispatch(setAdminToken(adminToken));
}
if (token) {
  setToken(token);
  store.dispatch(authenticateUser());
  store.dispatch(getUserProfile.request());
  store.dispatch(getSystemParameters.request());
}

const App = () => {
  return (
    <div className={style.layout}>
      <ConfirmationModal />
      <ErrorModal />
      <SuccessModal />
      <TopMenu />
      <SideMenu />
      <Footer />
      <Switch>
        <Route exact path={NavigationPaths.homePage} component={Home} />
        <Route exact path={NavigationPaths.staticHomePage} component={Home} />
        <Route path={NavigationPaths.authentication} component={Authentication} />
        <Route path={NavigationPaths.subUser} component={SubUserRegister} />
        <Route path={NavigationPaths.forgotPassword} component={ForgotPassword} />
        <Route path={NavigationPaths.confirmEmail} component={ConfirmEmail} />
        <Route path={NavigationPaths.createPassword} component={CreateNewPassword} />
        <Route path={NavigationPaths.trend} component={Trend} />
        <Route path={NavigationPaths.pdf} component={PDF} />
        <ProtectedRoute path={NavigationPaths.profile} component={Profile} />
        <ProtectedRoute path={NavigationPaths.report} component={Report} />
        <ProtectedRoute path={NavigationPaths.compare} component={Compare} />
        <ProtectedRoute path={NavigationPaths.archive} component={Archive} />
        <ProtectedRoute path={NavigationPaths.benchmarking} component={Benchmarking} />
        <ProtectedRoute path={NavigationPaths.branding} component={Branding} />
        <Route path={NavigationPaths.superAdmin} component={SuperAdmin} />
        <ProtectedRoute path={NavigationPaths.seo} component={Seo} />
        <ProtectedRoute path={NavigationPaths.engagement} component={Engagement} />
        <ProtectedRoute path={NavigationPaths.visuals} component={Visuals} />
        <ProtectedRoute path={NavigationPaths.editPdfReport} component={EditPdfReports} />
        <ProtectedRoute path={NavigationPaths.editTextSections} component={EditSectionTexts} />
        <Route path={NavigationPaths.pdfBasic} component={PdfBasic} />
      </Switch>
    </div>
  );
};

export default App;
