import {
  all,
  call,
  put,
  delay,
  takeEvery,
  select,
  takeLatest,
} from 'redux-saga/effects';
import axios, { AxiosResponse } from 'axios';
import * as actions from './superAdmin.actions';
import { apiEndpoints } from '../../constants/api';
import { LoginAsSelectedUserResponse, PageResponse, User } from './types';
import { setToken } from '../../utils/setToken';
import { getUserProfile } from '../profile/userProfile/userProfile.actions';
import { storage, StorageKeys } from '../../utils/localStorage';
import { selectSuperAdminState } from './index';
import { NavigationPaths } from '../../constants/navigationPaths';
import { push } from 'connected-react-router';
import { handleErrors, validateCompareCard } from '../../utils/formValidations';
import { ComparePage } from '../compare/types';
import { handleResponseError } from '../../utils/responseErrors';
import { showErrorModal } from '../app/app.actions';
import { mapPages } from './dataMapper';

function* getUsers() {
  try {
    const { data } = yield call(axios.get, apiEndpoints.getUsers);
    yield put(actions.getUsers.success(data));
  } catch (e) {
    yield handleResponseError(e, actions.getUsers.failure);
  }
}

function* updateUser(action: ReturnType<typeof actions.updateUser.request>) {
  const { dataToUpdate } = action.payload;
  try {
    const { data }: AxiosResponse<User> = yield call(
      axios.put,
      apiEndpoints.updateUser(dataToUpdate.userId),
      dataToUpdate
    );
    yield put(actions.updateUser.success({ data }));
    yield put(actions.updateSuccess());
  } catch (e) {
    console.log(e);
    console.log(e.response);
    yield put(actions.updateFailed());
    yield handleResponseError(e, () =>
      actions.updateUser.failure({ userId: dataToUpdate.userId })
    );
  }
}

function* loginAsSelectedUser(
  action: ReturnType<typeof actions.loginAsSelectedUser.request>
) {
  const { userId } = action.payload;
  console.log('User id to log-in with:', userId);
  try {
    const { data }: AxiosResponse<LoginAsSelectedUserResponse> = yield call(
      axios.post,
      apiEndpoints.loginAsSelectedUser(userId)
    );

    const { accessToken: userToken } = data;

    // Switch admin token to users token. Save admin token to storage
    const adminToken = storage.get(StorageKeys.authToken);
    if (adminToken) {
      storage.save(StorageKeys.adminToken, adminToken);
      yield put(actions.loginAsSelectedUser.success({ userId, adminToken }));
    }

    setToken(userToken);
    storage.save(StorageKeys.authToken, userToken);

    yield put(getUserProfile.request());
    yield put(push(NavigationPaths.profile));
  } catch (e) {
    yield handleResponseError(e);
  }
}

function* switchBackToAdmin() {
  const { adminToken } = selectSuperAdminState(yield select());
  storage.delete(StorageKeys.adminToken);

  if (adminToken) {
    storage.save(StorageKeys.authToken, adminToken);
    setToken(adminToken);
    yield put(actions.switchBackToAdmin.success());
    yield put(getUserProfile.request());
    yield put(push(NavigationPaths.superAdmin));
  }
}

function* updatePage(action: ReturnType<typeof actions.updatePage.request>) {
  const { page } = action.payload;
  try {
    // TODO what do do when we get not fully filled page?
    // if (page.new) validateCompareCard(page);
    const formData = { ...page };
    delete formData.errors;
    delete formData.success;
    delete formData.isLoading;
    let { pageUrl } = formData;
    if (pageUrl.includes('https://')) {
      formData.pageUrl = pageUrl.split('https://')[1];
    } else if (pageUrl.includes('http://')) {
      formData.pageUrl = pageUrl.split('http://')[1];
    }

    const { data }: AxiosResponse<ComparePage> = yield call(
      axios.put,
      apiEndpoints.updatePageAsAdmin,
      formData
    );
    yield put(actions.updatePage.success({ page: data }));
  } catch (e) {
    yield put(actions.updatePage.failure({ errors: handleErrors(e) }));
  }
}

function* createPage() {
  const {
    page: { data: pageData },
  } = selectSuperAdminState(yield select());
  try {
    if (!pageData) return;
    const errors = validateCompareCard(pageData);
    console.log(errors);
    let { pageUrl } = pageData;
    if (pageUrl.includes('https://')) {
      pageData.pageUrl = pageUrl.split('https://')[1];
    } else if (pageUrl.includes('http://')) {
      pageData.pageUrl = pageUrl.split('http://')[1];
    }

    const { data }: AxiosResponse<ComparePage> = yield call(
      axios.post,
      apiEndpoints.createPageAsAdmin,
      pageData
    );
    yield put(actions.createPage.success(data));
  } catch (e) {
    if (e.errors) {
      yield put(actions.createPage.failure(e));
    } else {
      yield put(actions.createPage.failure({ errors: {} }));
      yield put(showErrorModal({}));
    }
  }
}

function* setDeleteUser(
  action: ReturnType<typeof actions.setDeleteUser.request>
) {
  const { userId } = action.payload;
  try {
    const { data } = yield call(
      axios.delete,
      apiEndpoints.setDeleteUser(userId)
    );
    yield put(actions.setDeleteUser.success(data));
    yield put(actions.updateSuccess());
  } catch (e) {
    yield handleResponseError(e, actions.setDeleteUser.failure());
    yield put(actions.updateFailed());
  }
}

function* setUnDeleteUser(
  action: ReturnType<typeof actions.setUnDeleteUser.request>
) {
  const { userId } = action.payload;
  try {
    yield call(axios.get, apiEndpoints.setUnDeleteUser(userId));
    yield put(actions.setUnDeleteUser.success({ userId }));
    yield put(actions.updateSuccess());
  } catch (e) {
    yield handleResponseError(e, actions.setUnDeleteUser.failure());
    yield put(actions.updateFailed());
  }
}

function* sendReportToUser(
  action: ReturnType<typeof actions.sendReportToUser.request>
) {
  const userId = action.payload;
  try {
    yield call(axios.post, apiEndpoints.sendReportToUser, { userId });
    yield put(actions.sendReportToUser.success(userId));
    yield put(actions.updateSuccess());
  } catch (e) {
    yield put(actions.sendReportToUser.failure(userId));
    yield put(showErrorModal({}));
  }
}

function* deleteUser(action: ReturnType<typeof actions.deleteUser.request>) {
  const userId = action.payload;
  try {
    yield call(axios.delete, apiEndpoints.updateUser(userId));
    yield put(actions.deleteUser.success(userId));
    yield put(actions.updateSuccess());
  } catch (e) {
    yield put(actions.deleteUser.failure(userId));
    yield put(showErrorModal({}));
  }
}

function* clearNotifications() {
  yield delay(3000);
  yield put(actions.clearNotifications());
}

function* getPages(action: ReturnType<typeof actions.getPages.request>) {
  try {
    const { data }: AxiosResponse<PageResponse> = yield call(
      axios.get,
      apiEndpoints.searchPages
    );
    const pages = mapPages(data);
    yield put(actions.getPages.success(pages));
  } catch (e) {
    yield put(actions.getPages.failure());
  }
}

function* rescanPage(action: ReturnType<typeof actions.rescanPage.request>) {
  try {
    yield call(axios.post, apiEndpoints.rescanPage, { pageId: action.payload });
    yield put(actions.rescanPage.success());
  } catch (e) {
    yield put(showErrorModal({}));
  }
}

function* archivePage(action: ReturnType<typeof actions.archivePage.request>) {
  const id=action.payload;
  try {
    yield call(axios.post, apiEndpoints.archivePage, {
      pageId: id,
    });
    yield put(actions.archivePage.success(id));
  } catch (e) {
    yield put(showErrorModal({}));
  }
}
function* getRequests() {
  try {
    const { data } = yield call(axios.get, apiEndpoints.getRequests);
    yield put(actions.getRequests.success(data));
  } catch (e) {
    yield put(showErrorModal({}));
  }
}

function* deleteRequest(
  action: ReturnType<typeof actions.deleteRequest.request>
) {
  const { payload: id } = action;
  try {
    yield call(axios.delete, apiEndpoints.updateRequests(id));
    yield put(actions.deleteRequest.success(id));
  } catch (e) {
    yield put(actions.deleteRequest.failure());
  }
}
function* updateRequest(
  action: ReturnType<typeof actions.updateRequest.request>
) {
  const { id, url, email } = action.payload;
  try {
    yield call(axios.put, apiEndpoints.updateRequests(id), { url, email });
    yield put(actions.updateRequest.success({ id, email, url }));
  } catch (e) {
    yield put(actions.updateRequest.failure());
  }
}

export function* superAdminSagas(): Generator {
  yield all([
    yield takeLatest(actions.setDeleteUser.request, setDeleteUser),
    yield takeLatest(actions.setUnDeleteUser.request, setUnDeleteUser),
    yield takeLatest(actions.getUsers.request, getUsers),
    yield takeLatest(actions.updateUser.request, updateUser),
    yield takeEvery(actions.updateSuccess, clearNotifications),
    yield takeEvery(actions.updateFailed, clearNotifications),
    yield takeEvery(actions.rescanPage.success, clearNotifications),
    yield takeEvery(actions.rescanPage.failure, clearNotifications),
    yield takeEvery(actions.archivePage.success, clearNotifications),
    yield takeEvery(actions.archivePage.failure, clearNotifications),
    yield takeEvery(actions.deleteRequest.failure, clearNotifications),
    yield takeEvery(actions.deleteRequest.success, clearNotifications),
    yield takeEvery(actions.updateRequest.failure, clearNotifications),
    yield takeEvery(actions.updateRequest.success, clearNotifications),
    yield takeLatest(actions.loginAsSelectedUser.request, loginAsSelectedUser),
    yield takeLatest(actions.switchBackToAdmin.request, switchBackToAdmin),
    yield takeLatest(actions.updatePage.request, updatePage),
    yield takeLatest(actions.updatePage.success, clearNotifications),
    yield takeLatest(actions.updatePage.failure, clearNotifications),
    yield takeLatest(actions.createPage.success, clearNotifications),
    yield takeLatest(actions.sendReportToUser.request, sendReportToUser),
    yield takeLatest(actions.deleteUser.request, deleteUser),
    yield takeLatest(actions.getPages.request, getPages),
    yield takeLatest(actions.rescanPage.request, rescanPage),
    yield takeLatest(actions.archivePage.request, archivePage),
    yield takeLatest(actions.createPage.request, createPage),
    yield takeLatest(actions.getRequests.request, getRequests),
    yield takeLatest(actions.deleteRequest.request, deleteRequest),
    yield takeLatest(actions.updateRequest.request, updateRequest),
  ]);
}
