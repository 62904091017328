import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Interval, TableMenu} from '../../components/TableMenu';
import {changeOverviewSeoInterval, getOverviewSeo} from '../../store/benchmarking/benchmarking.actions';
import {useDispatch, useSelector} from 'react-redux';
import {selectBenchmarking} from '../../store/benchmarking';
import {BenchmarkingTable} from '../../components/BenchmarkingTable';
import {apiEndpoints} from '../../constants/api';
import {Loader} from '../../components/ui/Loader';
import {useHasLimitedAccess} from '../../utils/useCheckPermissions';
import {AccessSections} from '../../store/app/types';
import {ErrorMessage} from '../../components/ErrorMessage';
import {selectUser} from '../../store/auth';

const cx = classNames.bind(styles);

export const OverviewSeo = () => {
  const dispatch = useDispatch();
  const {overviewSeo} = useSelector(selectBenchmarking);
  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['benchmarking-seo'], user);
  const {interval, isLoading, error, data} = overviewSeo;

  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeOverviewSeoInterval(quarter));
  };

  useEffect(() => {
    dispatch(getOverviewSeo.request());
  }, [interval, dispatch]);

  const renderTable = () => {
    if (error) return <ErrorMessage />;
    if (isLoading || !data) return <Loader className={cx('loader')} />;

    const tableData = [
      {
        title: 'benchmarking.overall-rankings',
        subtitle: 'benchmarking.rank',
        withDot: true,
        leftValue: data.rank,
        rightValue: data.rankDelta,
      },
      {
        title: 'benchmarking.average-back-links',
        subtitle: 'benchmarking.backlinks',
        leftValue: data.avgBackLinks,
        rightValue: data.avgBackLinksDelta,
      },
      {
        title: 'benchmarking.links-to-other-websites',
        subtitle: 'benchmarking.links',
        leftValue: data.avgLinkOut,
        rightValue: data.avgLinkOutDelta,
      },
      {
        title: 'benchmarking.number-of-entities-published',
        subtitle: 'benchmarking.records',
        leftValue: data.posts,
        rightValue: data.postsDelta,
      },
    ];

    return <BenchmarkingTable data={tableData} />;
  };

  return (
    <div className={cx('rankings-container')}>
      <TableMenu
        lastButtonDisabled
        questionMark="benchmarking.title.overview-seo.question-mark"
        limitedAccess={limitedAccess}
        title="benchmarking.title.overview-seo"
        downloadApiUrl={apiEndpoints.getOverviewSeo(interval, true)}
        active={interval}
        onIntervalChange={onIntervalChange}
      />
      <div>{renderTable()}</div>
    </div>
  );
};
