import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import {register} from './subUser.actions';
import {handleErrors, validateSubUserRegistration} from '../../../utils/formValidations';
import axios, {AxiosResponse} from 'axios';
import {push} from 'connected-react-router';
import {apiEndpoints} from "../../../constants/api";
import {NavigationPaths} from "../../../constants/navigationPaths";
import {storage, StorageKeys} from "../../../utils/localStorage";
import {selectSubUser} from "./index";
import {LoginResponse} from "../login/types";
import {setToken} from "../../../utils/setToken";
import {getSystemParameters} from "../../app/app.actions";
import {login} from '../login/login.actions';

function* loginStarted(action:ReturnType<typeof register.request>) {
  const email = action.payload;

  const {form} = selectSubUser(yield select());
  const {password} = form;

  try {
    validateSubUserRegistration(form);
    const validationValue = parseFloat(form.validationValue);
    const offsetTime = new Date().getTimezoneOffset();
    const {data}:AxiosResponse<LoginResponse> = yield call(axios.post, apiEndpoints.subUserRegister, {password, email, validationValue, offsetTime })
    storage.save(StorageKeys.authToken, data.accessToken);
    setToken(data.accessToken)

    yield put(login.success(data))
    yield put(register.success())
    yield put(getSystemParameters.request())
    const firstTimeLogin = storage.get(StorageKeys.firstTimeLogin);
    yield put(push(firstTimeLogin ? NavigationPaths.compare : NavigationPaths.benchmarking))
    storage.delete(StorageKeys.firstTimeLogin);
  } catch (e) {
    yield put(register.failure(handleErrors(e)))
  }
}

export function* subUserSagas() {
  yield all([
    yield takeLatest(register.request, loginStarted),
  ]);
}
