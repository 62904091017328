import {createAction, createAsyncAction} from 'typesafe-actions';

export const onInputChange = createAction('auth/CONFIRM_EMAIL_INPUT_CHANGE')<number>();

export const confirm = createAsyncAction(
  'auth/CONFIRM_EMAIL_STARTED',
  'auth/CONFIRM_EMAIL_PASSWORD_DONE',
  'auth/CONFIRM_EMAIL_PASSWORD_FAILED',
)<void, void, string>()

export const clearState = createAction('auth/CONFIRM_EMAIL_CLEAR_STATE')<void>();
export const saveDataFromUrl = createAction('auth/SAVE_DATA_FROM_URL')<{ email:string; code:string }>();

export const resendEmail = createAsyncAction(
  'auth/RESEND_EMAIL_STARTED',
  'auth/RESEND_EMAIL_PASSWORD_DONE',
  'auth/RESEND_EMAIL_PASSWORD_FAILED',
)<void, void, void>()
