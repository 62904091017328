import {all, call, put, select, takeLatest} from 'redux-saga/effects';
import * as actions from './invoice.actions';
import {selectUserProfile} from '..';
import {validateInvoiceForm} from "../../../utils/formValidations";
import {Invoicing} from './types';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../../constants/api";
import {login, loginWithSocialProvider} from '../../auth/login/login.actions';
import {handleResponseError} from "../../../utils/responseErrors";
import {selectInvoice, updateInvoiceData} from "./";


function* updateInvoice() {
  const {form: formData} = selectInvoice(yield select());
  try {
    yield call(axios.post, apiEndpoints.billingEntity, formData);
    yield put(actions.updateInvoiceData.success())
  } catch (e) {
    yield handleResponseError(e)
  }
}

function* getInvoiceData() {
  try {
    const {data}: AxiosResponse<Invoicing> = yield call(
      axios.get,
      apiEndpoints.billingEntity
    );
    yield put(actions.getInvoiceData.success(data))
  } catch (e) {
    yield handleResponseError(e)
  }
}

export function* invoiceSagas() {
  yield all([
    yield takeLatest(actions.getInvoiceData.request, getInvoiceData),
    yield takeLatest(actions.updateInvoiceData.request, updateInvoice),
  ])
}



