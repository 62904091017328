import { createAction, createAsyncAction } from "typesafe-actions";
import { EditPdfReportState } from ".";
import { PageValuesKeys } from "./types";

export const getPdfFooterData = createAsyncAction(
  'editPdf/GET_FOOTER_STARTED',
  'editPdf/GET_FOOTER_DONE',
  'editPdf/GET_FOOTER_FAILED',
)< void, EditPdfReportState, void>()
export const updatePdfFooterData = createAsyncAction(
  'editPdf/UPDATE_FOOTER_STARTED',
  'editPdf/UPDATE_FOOTER_DONE',
  'editPdf/UPDATE_FOOTER_FAILED',
)< void, EditPdfReportState, void>()
export const onEditorChange = createAction('editPdf/PDF_EDITOR_DATA_CHANGES')<{index:number,value:string, side: PageValuesKeys}>()