import React from 'react';
import {ModalWithLogo} from "../../components/ModalWithLogo";
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Text} from "../../components/ui/Text";
import {useSelector} from "react-redux";
import {selectUser} from "../../store/auth";

const cx = classNames.bind(styles);

interface Props {
  onClose: ()=>void;
  visible?: boolean;
}
export const SuccessModal:React.FC<Props> = ({onClose, visible}) => {
  const {firstName, status} = useSelector(selectUser);
  return (
    <ModalWithLogo visible={visible} className={cx('success-modal')} close={onClose}>
      <p className={cx('text')}><Text msg='subscription.success-1'/>{status} <span className={cx('text', 'text--name')}>{firstName}</span>,</p>
      <p className={cx('text')}>
        <Text msg='subscription.success-2'/>
        <span className={cx('text', 'text--bold')}><Text msg='subscription.success-3'/></span>
        <Text msg='subscription.success-4'/>
      </p>
      <p className={cx('text')}><Text msg='subscription.success-5'/></p>
      <p className={cx('text', 'text--bold')}><Text msg='subscription.success-6'/><br/><Text msg='subscription.success-7'/></p>
      <p className={cx('text')}><Text msg='subscription.success-8'/></p>
    </ModalWithLogo>
  );
};
