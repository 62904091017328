import React, {FC, RefObject} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {PageValues} from '../../store/editPdfReport/types';
import {images} from '../../assets/img';
const cx = classNames.bind(styles);
interface PdfFooterProps {
  page?: PageValues;
  footerRef?: RefObject<HTMLDivElement>;
  withLink?: boolean;
  className?: string;
}
export const PdfFooter: FC<PdfFooterProps> = ({page, footerRef, withLink, className}) => {
  return (
    <div ref={footerRef} className={cx('footer', className)}>
      <div className={cx('left-side')}>
        <div className={cx('left-side__comma')}>''</div>
        <div className={cx('left-side__text')} dangerouslySetInnerHTML={{__html: page?.left || ''}} />
      </div>
      <div className={cx('right-side')}>
        <div className={cx('right-side__text')} dangerouslySetInnerHTML={{__html: page?.right || ''}} />
        {withLink && (
          <a href="mailto:pagetracker@drkpi.com" className={cx('mail-margin')}>
            <img src={images.envelope} className={cx('image-envelope')} alt="" />
            pagetracker@drkpi.com
          </a>
        )}
      </div>
    </div>
  );
};
