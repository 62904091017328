import React from 'react';
import {Response} from '../types';
import {Header} from '../Header';
import {Footer} from '../Footer';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {TableMenu} from '../../components/TableMenu';
import {BarChart} from '../../components/BarChart';
import {mapCompareData} from '../../routes/Branding/data-mapper';
import {Tooltip} from '../Tooltip';
import {PageValues} from '../../store/editPdfReport/types';

const cx = classNames.bind(styles);

interface Props {
  pdfData: Response;
  footerData?: PageValues;
}

export const Page6: React.FC<Props> = ({pdfData, footerData}) => {
  const data = mapCompareData(pdfData?.['engagement-comments-count']?.data || []);
  const interval = pdfData?.['branding-rank-hashtag-comparison']?.quarter;

  return (
    <div className={cx('pdf-page')}>
      <Header />
      <div className={cx('container')}>
        <div className={cx('item')}>
          <Tooltip text="Sind Ihre Beiträge attraktiv und hinterlassen die Leser Kommentare?" />
          <TableMenu
            pdfExport
            active={interval}
            onIntervalChange={() => null}
            title="engagement-pdf-table-header"
          />
          <BarChart pdfExport letterALabel="benchmarking.footer.label.our-url" data={data} />
        </div>
      </div>
      <Footer
        pageUrl={pdfData.pages ? pdfData.pages[0] : ''}
        id={pdfData?.customerId}
        pageNumber={5}
        footerData={footerData}
      />
    </div>
  );
};
