import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Container from '../../components/layout/Container';
import {RankingsTop} from './RankingsTop';
import {RankingsRegional} from './RankingsRegional';
import {WritingStyle} from './WritingStyle';
import {OverviewSeo} from './OverviewSeo';
import {useDispatch, useSelector} from 'react-redux';
import {getComparePages} from '../../store/compare/compare.actions';
import {getCurrentSectionTextData} from '../../store/editSectionText';
import {DropDownDescription} from '../../components/DropDownDescription';
import {selectEditSectionText} from '../../store/editSectionText';
const cx = classNames.bind(styles);

export const Benchmarking = () => {
  const dispatch = useDispatch();
  const {currentPage, loading} = useSelector(selectEditSectionText);
  useEffect(() => {
    dispatch(getCurrentSectionTextData.request('benchmarking'));
    dispatch(getComparePages.request({showWarningIfPending: true}));
  }, [dispatch]);
  return (
    <Container className={cx('container')}>
      <RankingsTop />
      <DropDownDescription loading={loading} activeIndex={0} data={currentPage[0]} />
      <RankingsRegional />
      <DropDownDescription loading={loading} data={currentPage[1]} />
      <OverviewSeo />
      <DropDownDescription loading={loading} data={currentPage[2]} />
      <WritingStyle />
      <DropDownDescription loading={loading} data={currentPage[3]} />
    </Container>
  );
};
