import React from 'react';
import {Response} from '../types';
import {Header} from '../Header';
import {Footer} from '../Footer';
import {Graph} from '../../components/LinearGraph/Graph';
import {mapGraphData, seoData} from '../utils';
import {TableFooter} from '../../components/TableFooter';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {TableMenu} from '../../components/TableMenu';
import {BenchmarkingTable} from '../../components/BenchmarkingTable';
import {PageValues} from '../../store/editPdfReport/types';

const cx = classNames.bind(styles);

interface Props {
  pdfData: Response;
  footerData?: PageValues;
}

export const Page4: React.FC<Props> = ({pdfData, footerData}) => {
  const d1 = seoData(pdfData?.['benchmarking-seo']?.data) || [];
  const d2 = mapGraphData(pdfData?.['visuals-chart']?.data) || [];

  const i1 = pdfData?.['benchmarking-seo']?.quarter;
  const i2 = pdfData?.['visuals-chart']?.quarter;

  return (
    <div className={cx('pdf-page')}>
      <Header />
      <div className={cx('container')}>
        <div className={cx('item')}>
          <TableMenu
            pdfExport
            active={i1}
            onIntervalChange={() => null}
            title="benchmarking.title.overview-seo"
          />
          <div className={cx('table-container')}>
            <BenchmarkingTable pdfExport data={d1} />
          </div>
        </div>
        <div className={cx('trend', 'item')}>
          <TableMenu pdfExport active={i2} onIntervalChange={() => null} title="visuals-chart" />
          <div className={cx('graph-container')}>
            <Graph pdfExport max={104} data={d2} />
          </div>
          <TableFooter pdfExport withChartLabels className={cx('graph-footer')} />
        </div>
      </div>
      <Footer
        pageUrl={pdfData.pages ? pdfData.pages[0] : ''}
        id={pdfData?.customerId}
        pageNumber={3}
        footerData={footerData}
      />
    </div>
  );
};
