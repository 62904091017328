import {all, select, takeEvery, put, call} from 'redux-saga/effects';
import {selectSeo} from "./index";
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../constants/api";
import {
  SeoRankBackLinksTable,
  SeoRankBackLinksChart,
  SeoRankOutboundLinksTable,
  SeoRankOutboundLinksChart,
  Types
} from "./types";
import {getData} from "./seo.actions";
import {mapTableData} from './dataMapper';
import {handleResponseError} from "../../utils/responseErrors";
import {mapGraphData} from "../../utils/mapResponseData";

function* fetchSeoRankBackLinksTable() {
  const {seoRankBackLinksTable} = selectSeo(yield select());
  try {
    const {data}: AxiosResponse<{ data: SeoRankBackLinksTable[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getSeoRankBackLinksTable(seoRankBackLinksTable.interval || '1')
    )
    yield put(getData.success({data: mapTableData(data.data), type: Types.seoRankBackLinksTable}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.seoRankBackLinksTable}))
  }
}

function* fetchSeoRankOutboundLinksTable() {
  const {seoRankOutboundLinksTable} = selectSeo(yield select());
  try {
    const {data}: AxiosResponse<{ data: SeoRankOutboundLinksTable[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getSeoRankOutboundLinksTable(seoRankOutboundLinksTable.interval || '1')
    )
    yield put(getData.success({data: mapTableData(data.data), type: Types.seoRankOutboundLinksTable}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.seoRankOutboundLinksTable}))
  }
}

function* fetchSeoRankBackLinksChart() {
  const {seoRankBackLinksChart} = selectSeo(yield select());
  try {
    const {data}: AxiosResponse<{ data: SeoRankBackLinksChart[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getSeoRankBackLinksChart(seoRankBackLinksChart.interval || '1')
    )
    const graphData = mapGraphData(data.data)
    yield put(getData.success({data: graphData, type: Types.seoRankBackLinksChart}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.seoRankBackLinksChart}))
  }
}

function* fetchSeoRankOutboundLinksChart() {
  const {seoRankOutboundLinksChart} = selectSeo(yield select());
  try {
    const {data}: AxiosResponse<{ data: SeoRankOutboundLinksChart[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getSeoRankOutboundLinksChart(seoRankOutboundLinksChart.interval || '1')
    )
    const graphData = mapGraphData(data.data)
    yield put(getData.success({data: graphData, type: Types.seoRankOutboundLinksChart}))
  } catch (e) {
    yield handleResponseError(e, () => getData.failure({type: Types.seoRankOutboundLinksChart}))
  }
}


function* fetchData(action: ReturnType<typeof getData.request>) {
  const {type} = action.payload;
  switch (type) {
    case Types.seoRankBackLinksTable:
      return yield fetchSeoRankBackLinksTable();
    case Types.seoRankOutboundLinksTable:
      return yield fetchSeoRankOutboundLinksTable();
    case Types.seoRankBackLinksChart:
      return yield fetchSeoRankBackLinksChart();
    case Types.seoRankOutboundLinksChart:
      return yield fetchSeoRankOutboundLinksChart();
    default :
      return;
  }
}

export function* seoSagas() {
  yield all([
    yield takeEvery(getData.request, fetchData),
  ])
}
