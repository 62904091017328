import {Archive} from './types';
import {ActionType, getType} from 'typesafe-actions';
import * as actions from './archive.actions';

export interface ArchiveState {
  data?: Archive[],
  isLoading?: boolean;
  error?: boolean;
}

const initialState: ArchiveState = {}

type Action = ActionType<typeof actions>

export const archiveReducer = (state = initialState, action: Action): ArchiveState => {
  switch (action.type) {
    case getType(actions.getArchives.request) :
      return {...initialState, isLoading: true};
    case getType(actions.getArchives.failure):
      return {...state, data: [], error: true};
    case getType(actions.getArchives.success):
      return {...initialState, data: action.payload}
    case getType(actions.onCheck): {
      if (!state.data) return state;
      const {index, value} = action.payload;
      const data = state.data.map((archive, i) => {
        return i === index ? {...archive, isSelected: value} : archive;
      })
      return {...state, data}
    }
    default :
      return state
  }
}
