import {getType, ActionType} from 'typesafe-actions';
import {SubUserLoginForm, Errors} from './types';
import * as actions from './subUser.actions';

type Action = ActionType<typeof actions>

export interface SubUserLoginState {
  form: SubUserLoginForm;
  loading: boolean;
  errors: Errors;
  success?: boolean;
  recaptchaToken: string;
}

const form:SubUserLoginForm = {
  password: '',
  repeatPassword: '',
  validationValue: '',
  agreeToPlatformTerms: false,
  agreeToGdpr: false,
  agreeToDataProcessing: false,
  agreeToMarketingTerms: false,
};

const initialState: SubUserLoginState = {
  form,
  recaptchaToken: '',
  loading: false,
  errors: {},
};

export const subUserReducer = (state = initialState, action: Action): SubUserLoginState => {
  switch (action.type) {
    case getType(actions.onInputChange): {
      const {name, value} = action.payload;
      return {
        ...state,
        form: {...state.form, [name]: value},
        errors: {...state.errors, [name]: ''},
        success: false,
      }
    }
    case getType(actions.verifyHuman) :
      return {...state, recaptchaToken: action.payload}
    case getType(actions.register.request):
      return {...state, loading: true, errors: {}, success: false}
    case getType(actions.register.success):
      return {...state, loading: false, errors: {}, success: true, form}
    case  getType(actions.register.failure) :
      return {...state, loading: false, errors: action.payload}
    default:
      return state;
  }
};
