import React from 'react';
import {Link} from 'react-router-dom';
import {NavigationPaths} from '../../../constants/navigationPaths';
import {Text} from '../../ui/Text';
import {selectSuperAdminState, switchBackToAdmin} from '../../../store/superAdmin';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {useDispatch, useSelector} from 'react-redux';
import {logout, selectUserIsAdmin} from '../../../store/auth/user';

const cx = classNames.bind(styles);

interface Props {
  className: string;
}
export const ExpandableMenuItems: React.FC<Props> = ({className}) => {
  const isAdmin = useSelector(selectUserIsAdmin);
  const {adminToken} = useSelector(selectSuperAdminState);
  const dispatch = useDispatch();
  const onLogout = () => {
    dispatch(logout({redirect: true}));
  };

  return (
    <>
      <Link to={NavigationPaths.profile} className={cx(className)}>
        <span className={cx('item-text')}>
          <Text msg="top-menu.profile" />
        </span>
      </Link>
      <Link to={NavigationPaths.report} className={cx(className)}>
        <span className={cx('item-text')}>
          <Text msg="top-menu.export-settings" />
        </span>
      </Link>
      {isAdmin && (
        <Link to={NavigationPaths.superAdmin} className={cx(className)}>
          <span className={cx('item-text')}>
            <Text msg="top-menu.super-admin" />
          </span>
        </Link>
      )}
      {adminToken && (
        <div className={cx(className)} onClick={() => dispatch(switchBackToAdmin.request())}>
          <span className={cx('item-text')}>
            <Text msg="top-menu.switch-to-admin" />
          </span>
        </div>
      )}
      <div className={cx(className)} onClick={onLogout}>
        <span className={cx('item-text')}>
          <Text msg="top-menu.logout2" />
        </span>
      </div>
    </>
  );
};
