import React from 'react';
import {images} from '../../../assets/img';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {HoverPopup} from "../HoverPopup";

const cx = classNames.bind(styles);

interface Props {
  className?: string;
  onChange: (name: string, value: boolean) => void;
  checked: boolean;
  name: string;
  disabled?: boolean;
}

export const CheckBox = (props: Props) => {
  const {name, checked, onChange, disabled, className} = props;
  const classNames = cx('checkbox', checked && 'checkbox--active', className, disabled && 'checkbox--disabled')

  const onCheck = () => {
    if (disabled) return;
    onChange(name, checked)
  }
  return (
    <div
      className={classNames}
      onClick={onCheck}>
      {props.checked && <img src={images.check} className={cx('icon')} alt=""/>}
      <HoverPopup visible={disabled} className={cx('not-available')}/>
    </div>
  );
};
