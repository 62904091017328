import {createAction, createAsyncAction} from 'typesafe-actions';
import {InputPayload} from '../../../types/events';
import {Errors} from './types';

export const onInputChange = createAction('auth/SUB-USER_INPUT_CHANGE')<InputPayload>()

export const verifyHuman = createAction('auth/SUB-USER_VERIFY_HUMAN')<string>()

export const register = createAsyncAction(
  'auth/SUB-USER_REGISTER_STARTED',
  'auth/SUB-USER_REGISTER_DONE',
  'auth/SUB-USER_REGISTER_FAILED',
)<string, void, Errors>()
