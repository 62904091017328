import isinValidator from 'js-isin-validator';

export class Validator<T> {
  errors: any = {};
  // errors: {[key in keyof T]?: string} = {};
  private data:any;
  // private data:T; type length does not exist on type T[keyof T]
  constructor(data: T) {
    this.data = data;
  }

  hasValue(prop: keyof T, msg: string): void {
    if (!this.data[prop] || this.data[prop].length < 1) this.errors[prop] = msg;
  }

  validateEmail(prop: keyof T, msg?: string): void {
    if (this.data[prop] && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(this.data[prop])) {
      this.errors[prop] = msg ? msg : 'error-invalid-email';
    }
  }

  validateISIN(prop: keyof T):void{
    if(!isinValidator.isIsinValid(this.data[prop])){
      this.errors[prop] = 'error-invalid-isin'
    }
  }

  validateSUM(prop: keyof T, value1:number, value2: number):void{
    if(value1!==value2){
      this.errors[prop] = 'Invalid value'
    }
  }

  validateUrl(prop: keyof T, msg:string):void{
    const value = this.data[prop];
    if(!value) return ;
    if(value.includes('http')){
      if(this.data[prop] && !/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=,\w]+@)[A-Za-z0-9.-]+)((?:\/[~%.\w-_]*)?\??(?:[-=&;%@.\w_]*)#?(?:[\w]*))?)/.test(this.data[prop])){
        this.errors[prop] = msg;
      }
    }else{
      if(!value.includes('.')) this.errors[prop] = msg;
    }
  }

  match(prop: keyof T, match: keyof T, msg: string) {
    if (!this.data[prop] || !this.data[match]) return;
    if (this.data[prop] !== this.data[match]) {
      this.errors[prop] = msg;
    }
  }

  validatePassword(prop: keyof T, msg: string): void {
    if (this.data[prop] && !/^(?=.*[A-Z])(?=.*[!@#$%^&])(?=.*[0-9]).{6,}$/.test(this.data[prop])) {
      this.errors[prop] = msg ? msg : 'error-invalid-password';
    }
  }

  validatePhoneNumber(prop: keyof T, msg?: string): void {
    if (this.data[prop] && !/^\+(?:[0-9] ?){6,14}[0-9]$/g.test(this.data[prop])) {
      this.errors[prop] = msg ? msg : 'error-invalid-phone';
    }
  }

  isChecked(prop: keyof T, msg:string): void {
    if (typeof this.data[prop] !== 'boolean') this.errors[prop] = msg;
  }

  get hasErrors(): boolean {
    return Object.keys(this.errors).length > 0;
  }
}
