import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Interval, TableMenu} from '../../components/TableMenu';
import {changeInterval, getData, selectSeo} from '../../store/seo';
import {useDispatch, useSelector} from 'react-redux';
import {TableFooter} from '../../components/TableFooter';
import {Graph} from '../../components/LinearGraph/Graph';
import {apiEndpoints} from '../../constants/api';
import {Loader} from '../../components/ui/Loader';
import {Types} from '../../store/seo/types';
import {ErrorMessage} from '../../components/ErrorMessage';
import {useHasLimitedAccess, useHasNoAccess} from '../../utils/useCheckPermissions';
import {AccessSections} from '../../store/app/types';
import {NoAccessMessage} from '../../components/ui/NoAccessMessage';
import {selectUser} from '../../store/auth';
const cx = classNames.bind(styles);

export const SeoRankOutboundLinksChart = () => {
  const dispatch = useDispatch();
  const {seoRankOutboundLinksChart} = useSelector(selectSeo);
  const {interval, data, error, isLoading} = seoRankOutboundLinksChart;

  useEffect(() => {
    dispatch(getData.request({type: Types.seoRankOutboundLinksChart}));
  }, [dispatch, interval]);

  const user = useSelector(selectUser);
  const limitedAccess = useHasLimitedAccess(AccessSections['seo-rank-outbound-links-chart'], user);
  const noAccess = useHasNoAccess(AccessSections['seo-rank-outbound-links-chart'], user);

  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeInterval({interval: quarter, type: Types.seoRankOutboundLinksChart}));
  };

  const renderGraph = () => {
    if (noAccess) return <NoAccessMessage />;
    if (error) return <ErrorMessage />;
    if (isLoading || !data) return <Loader className={cx('loader')} />;
    return <Graph legend="Punktzahl" data={data} max={104} />;
  };
  return (
    <div className={cx('graph-container')}>
      <TableMenu
        limitedAccess={limitedAccess}
        active={interval}
        downloadApiUrl={apiEndpoints.getSeoRankOutboundLinksChart(interval || 'Y', true)}
        title="seo.rankings-outbound-links.chart.title"
        questionMark="seo.rankings-outbound-links.chart.question-mark"
        buttonsTitles={['table-menu.button1', 'table-menu.button2', 'table-menu.button3']}
        onIntervalChange={onIntervalChange}
      />
      <div className={cx('graph')}>{renderGraph()}</div>
      <TableFooter withChartLabels={data && data.length > 3} />
    </div>
  );
};
