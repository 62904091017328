import {ActionType, getType} from 'typesafe-actions';
import * as actions from './benchmarking.actions';
import {RankingsRegional, RankingsTop, SEO, WritingStyle} from "./types";

type Action = ActionType<typeof actions>

export interface BenchmarkingState {
  rankingsTop: {
    isLoading?: boolean;
    interval: string;
    data?: RankingsTop[];
    error?: boolean;
  },
  rankingsRegional: {
    isLoading?: boolean;
    interval: string;
    data?: RankingsRegional[];
    error?: boolean;
  },
  writingStyle: {
    isLoading?: boolean;
    interval: string;
    data?: WritingStyle;
    error?: boolean;
  },
  overviewSeo: {
    isLoading?: boolean;
    interval: string;
    data?: SEO;
    error?: boolean;
  },
}

const initialState: BenchmarkingState = {
  rankingsTop: {
    interval: '1',
  },
  rankingsRegional: {
    interval: '1',
  },
  writingStyle: {
    interval: '1',
  },
  overviewSeo: {
    interval: '1',
  }
}

export const benchmarkingReducer = (state = initialState, action: Action): BenchmarkingState => {
  switch (action.type) {
    case getType(actions.getRankingsRegional.request): {
      return {
        ...state,
        rankingsRegional: {isLoading: true, interval: state.rankingsRegional.interval}
      }
    }
    case getType(actions.getRankingsRegional.success): {
      return {...state, rankingsRegional: {data: action.payload, interval: state.rankingsRegional.interval}}
    }
    case getType(actions.getRankingsRegional.failure): {
      return {...state, rankingsRegional: {error: true, interval: state.rankingsRegional.interval}}
    }
    case getType(actions.getRankingsTop.request): {
      return {
        ...state,
        rankingsTop: {isLoading: true, interval: state.rankingsTop.interval}
      }
    }
    case getType(actions.getRankingsTop.success): {
      return {...state, rankingsTop: {data: action.payload, interval: state.rankingsTop.interval}}
    }
    case getType(actions.getRankingsTop.failure): {
      return {...state, rankingsTop: {error: true, interval: state.rankingsTop.interval}}
    }
    case getType(actions.getWritingStyle.request): {
      return {
        ...state,
        writingStyle: {isLoading: true, interval: state.writingStyle.interval}
      }
    }
    case getType(actions.getWritingStyle.success): {
      return {...state, writingStyle: {data: action.payload, interval: state.writingStyle.interval}}
    }
    case getType(actions.getWritingStyle.failure): {
      return {...state, writingStyle: {error: true, interval: state.writingStyle.interval}}
    }
    case getType(actions.getOverviewSeo.request): {
      return {
        ...state,
        overviewSeo: {isLoading: true, interval: state.overviewSeo.interval}
      }
    }
    case getType(actions.getOverviewSeo.success): {
      return {...state, overviewSeo: {data: action.payload, interval: state.overviewSeo.interval}}
    }
    case getType(actions.getOverviewSeo.failure): {
      return {...state, overviewSeo: {error: true, interval: state.overviewSeo.interval}}
    }
    case getType(actions.changeRankingsTopInterval):
      return {...state, rankingsTop: {interval: action.payload}}
    case getType(actions.changeRankingsRegionalInterval):
      return {...state, rankingsRegional: {interval: action.payload}}
    case getType(actions.changeWritingStyleInterval):
      return {...state, writingStyle: {interval: action.payload}}
    case getType(actions.changeOverviewSeoInterval):
      return {...state, overviewSeo: {interval: action.payload}}
    default :
      return state
  }
}
