import React, {useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {homeMenu, settingsMenu, Menu} from '../SideMenu/data';
import {Link, NavLink} from 'react-router-dom';
import {Text} from '../../ui/Text';
import {useSelector} from 'react-redux';
import {selectUser} from '../../../store/auth';
import {ExpandableMenuItems} from './ExpandableMenuItems';
import {NavigationPaths} from '../../../constants/navigationPaths';
import {images} from '../../../assets/img';

const cx = classNames.bind(styles);

export const MobileMenu = () => {
  const {firstName, lastName, isAuthenticated} = useSelector(selectUser);
  const [mobileMenu, toggleMenu] = useState<boolean>(false);

  const disableScroll = () => {
    const body = document.querySelector('body');
    if (body && !mobileMenu) {
      body.classList.add('stop-scroll');
    } else if (body && mobileMenu) {
      body.classList.remove('stop-scroll');
    }
  };

  const toggleMobileMenu = () => {
    toggleMenu(!mobileMenu);
    disableScroll();
  };

  const renderMenuItems = (menu: Menu[]) => {
    return menu.map((item) => {
      return (
        <NavLink
          activeClassName={cx('content__link--active')}
          className={cx('content__link')}
          key={item.path}
          to={item.path}>
          <Text msg={item.title} />
        </NavLink>
      );
    });
  };

  const renderUserMenu = () => (
    <>
      <div className={cx('content__user', !firstName && 'content__user--hidden')}>
        <h3 className={cx('content__user-name')}>
          {firstName} {lastName}
        </h3>
      </div>
      <div className={cx('content__section')}>
        <h4 className={cx('content__section-title')}>
          <Text msg="mobile-menu.title.home" />
        </h4>
        {renderMenuItems(homeMenu)}
      </div>
      <div className={cx('content__section--flex')}>
        <h4 className={cx('content__section-title')}>
          <Text msg="mobile-menu.title.settings" />
        </h4>
        <ExpandableMenuItems className={cx('content__user-menu-item')} />
      </div>
      <div className={cx('content__section', 'content__section--profile')}>
        <h4 className={cx('content__section-title')}>
          <Text msg="mobile-menu.title.profile" />
        </h4>
        {renderMenuItems(settingsMenu)}
      </div>
    </>
  );
  const renderLoginMenu = () => (
    <div className={cx('content__section')}>
      <a className={cx('content__link')} href={'mailto:' + NavigationPaths.contact}>
        <Text msg="top-menu.kontakt" />
      </a>
      <NavLink className={cx('content__link')} to={NavigationPaths.authentication}>
        <Text msg="top-menu.login" />
      </NavLink>
    </div>
  );
  return (
    <div className={cx('mobile-menu')}>
      <div className={cx('top-row')}>
        <Link to={NavigationPaths.homePage}>
          <img src={images.logo} className={cx('top-row__logo')} alt="" />
        </Link>
        <h1 className={cx('top-row__title')}>
          <Text msg="top-menu.title" />
        </h1>
        <div className={cx('top-row__bars')} onClick={toggleMobileMenu}>
          <img src={images.bars} alt="" />
        </div>
      </div>
      <div className={cx('content', mobileMenu && 'content--active')} onClick={toggleMobileMenu}>
        {isAuthenticated ? renderUserMenu() : renderLoginMenu()}
        <div className={cx('beta')}>BETA</div>
      </div>
    </div>
  );
};
