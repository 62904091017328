import {all, select, takeEvery, put, call} from 'redux-saga/effects';
import axios, {AxiosResponse} from 'axios';
import {apiEndpoints} from "../../constants/api";
import {getData} from "./visuals.actions";
import {SubtitlesCount, ImagesCount, Types, Chart} from "./types";
import {selectVisuals} from "./index";
import {mapChartData} from './dataMapper';
import {handleResponseError} from "../../utils/responseErrors";
import {mapGraphData} from "../../utils/mapResponseData";

function* fetchSubtitlesCount() {
  const {subtitlesCount} = selectVisuals(yield select());
  try {
    const {data}: AxiosResponse<{ data: SubtitlesCount[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getVisualsSubtitlesCount(subtitlesCount.interval || '1')
    )
    const mappedData = mapChartData(data.data)
    yield put(getData.success({data: mappedData, type: Types.subtitlesCount}))
  } catch (e) {
    yield handleResponseError(e, ()=>getData.failure({type: Types.subtitlesCount}))
  }
}

function* fetchImagesCount() {
  const {imagesCount} = selectVisuals(yield select());
  
  try {
    const {data}: AxiosResponse<{ data: ImagesCount[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getVisualsImagesCount(imagesCount.interval || '1')
    )
    const mappedData = mapChartData(data.data)
    yield put(getData.success({data: mappedData, type: Types.imagesCount}))
  } catch (e) {
    yield handleResponseError(e, ()=>getData.failure({type: Types.imagesCount}))
  }
}

function* fetchChart() {
  const {chart} = selectVisuals(yield select());
  try {
    const {data}: AxiosResponse<{ data: Chart[], requestJson: string }> = yield call(
      axios.get,
      apiEndpoints.getVisualsChart(chart.interval || '1')
    )
    const graphData = mapGraphData(data.data)
    yield put(getData.success({data: graphData, type: Types.chart}))
  } catch (e) {
    yield handleResponseError(e, ()=>getData.failure({type: Types.chart}))
  }
}

function* fetchData(action: ReturnType<typeof getData.request>) {
  const {type} = action.payload;
  switch (type) {
    case "subtitlesCount":
      return yield fetchSubtitlesCount()
    case "imagesCount":
      return yield fetchImagesCount();
    case "chart":
      return yield fetchChart();
    default :
      return;
  }
}

export function* visualsSagas() {
  yield all([
    yield takeEvery(getData.request, fetchData),
  ])
}
