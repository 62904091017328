import React, {useEffect} from 'react';
import {Text, Button, TextInput} from "../../components/ui";
import Container from "../../components/layout/Container";
import {FormEvent} from "../../types/events";
import {useDispatch, useSelector} from "react-redux";
import {selectConfirmEmail} from '../../store/auth';
import {useLocation} from 'react-router-dom';
import {
  onInputChange,
  confirm,
  clearState, saveDataFromUrl, resendEmail
} from '../../store/auth/confirm-email/confirm-email.actions';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const {loading, success, error, code, isResending} = useSelector(selectConfirmEmail);
  const location = useLocation();
  let params = new URLSearchParams(location.search);
  const queryEmail = params.get('email')?.replace(/ /g, '+') || '';
  const queryCode = params.get('code') || '';

  useEffect(() => {
    dispatch(saveDataFromUrl({email: queryEmail, code: queryCode}))
    return () => {
      dispatch(clearState())
    }
  }, [dispatch])

  const onSubmit = (e: FormEvent) => {
    e.preventDefault()
    dispatch(confirm.request())
  }
  const onChange = (name: string, value: string) => {
    dispatch(onInputChange(parseFloat(value)))
  }

  return (
    <Container className={cx('container')}>
      <h1 className={cx('heading')}><Text msg='confirm-email.desc'/></h1>
      <form onSubmit={onSubmit}>
        <TextInput
          onChange={onChange}
          value={code}
          name='text'
          error={error}
          label='confirm-email.confirmation-code'
          className={cx('input')}
        />
        <Button
          loading={loading}
          msg='confirm-email.confirm'
          className={cx('button')}
        />
        {success && <p className={cx('success')}><Text msg='confirm-email.success'/></p>}
      </form>
      {error &&
      <Button
        loading={isResending}
        onClick={() => dispatch(resendEmail.request())}
        className={cx('send-code')}
        msg='confirm-email.code-sent'/>
      }
    </Container>
  );
};
