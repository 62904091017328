import React, {useState} from 'react';
import {InputEvent} from '../../../types/events';
import {Text, useTranslation} from '../Text';
import {images} from '../../../assets/img';
import styles from './index.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

export type InputType = 'password' | 'number' | 'text';

interface InputProps {
  type?: InputType;
  autoFocus?: boolean;
  lowercase?: boolean;
  capitalize?: boolean;
  autocomplete?: string;
  value: string | number | undefined;
  className?: string | null;
  name: string;
  error?: string;
  placeholder?: string;
  notTranslatedPlaceholder?: string;
  onChange: (name: string, value: string) => void;
  label: string;
  labelPosition?: 'top' | 'left' | 'bottom';
  labelClassName?: string;
  inputClassName?: string;
  onInputBlur?: () => void;
  disabled?: boolean;
}

export const TextInput = (props: InputProps) => {
  const {
    name,
    value,
    label,
    error,
    notTranslatedPlaceholder,
    labelClassName,
    placeholder,
    autoFocus,
    labelPosition,
    inputClassName,
    autocomplete,
    disabled,
    type,
    onInputBlur,
  } = props;
  const placeholderTranslation = useTranslation(placeholder);
  const [inputType, changeInputType] = useState(type);

  const textStyle: {[key: string]: string} = {};
  if (props.lowercase) textStyle.textTransform = 'lowercase';
  if (props.capitalize) textStyle.textTransform = 'capitalize';
  const className = cx(
    'container',
    disabled && 'disabled',
    error && 'container--error',
    props.className,
    labelPosition === 'left' && 'container__label-left'
  );

  const node = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (autoFocus) node?.current?.focus();
  }, [autoFocus]);

  return (
    <div className={className}>
      <div className={cx('label', error && 'label__error', labelClassName)}>
        <Text msg={error ? error : label} />
      </div>
      <input
        disabled={disabled}
        ref={node}
        placeholder={notTranslatedPlaceholder || placeholderTranslation}
        style={textStyle ? textStyle : {}}
        className={cx('input', inputClassName, error && 'input--error')}
        autoComplete={autocomplete}
        onChange={(e: InputEvent) => props.onChange(e.target.name, e.target.value)}
        name={name}
        onBlur={onInputBlur}
        value={value || ''}
        type={inputType}
      />
      {type === 'password' && (
        <img
          src={inputType === 'password' ? images.eyeSlash : images.eyeRegular}
          alt="show-password"
          onClick={() => changeInputType(inputType === 'text' ? 'password' : 'text')}
          className={cx('show-password-icon')}
        />
      )}
    </div>
  );
};
TextInput.defaultProps = {
  inputType: 'text',
};
