import classNames from 'classnames/bind';
import React, {FC, useRef} from 'react';
import styles from './index.module.scss';
interface PaginationProps {
  dataTotal: number;
  pageSize: number;
  tabNumber: number;
  setTabNumber: (v: number) => void;
}
const cx = classNames.bind(styles);

export const Pagination: FC<PaginationProps> = (props) => {
  const {dataTotal, pageSize, tabNumber, setTabNumber} = props;

  const input = useRef<HTMLInputElement | null>(null);
  if (dataTotal === 0) return null;
  const pageCount = Math.ceil(dataTotal / pageSize) - 1;
  return (
    <div className={cx('pagination')}>
      <div className={cx('pagination-button')} onClick={() => setTabNumber(1)}>
        1
      </div>
      <div
        className={cx('pagination-button')}
        onClick={() => {
          if (tabNumber === 1) return;
          setTabNumber(tabNumber - 1);
        }}>
        &#60;
      </div>
      <input
        ref={input}
        onClick={() => {
          if (input.current) input.current.select();
        }}
        onChange={(e) => {
          if (!e.target.value) setTabNumber(1);
          setTabNumber(parseFloat(e.target.value));
        }}
        value={tabNumber || 1}
        className={cx('current')}
        name=""
      />
      <div
        className={cx('pagination-button')}
        onClick={() => {
          if (tabNumber === pageCount + 1) return;
          setTabNumber(tabNumber + 1);
        }}>
        &#62;
      </div>
      <div className={cx('pagination-button')} onClick={() => setTabNumber(pageCount + 1)}>
        {pageCount + 1}
      </div>
    </div>
  );
};
