import React, {useEffect} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import {Interval, TableMenu} from '../../components/TableMenu';
import {changeTrendsOverallInterval} from '../../store/trend/trend.actions';
import {useDispatch, useSelector} from 'react-redux';
import {selectTrend} from '../../store/trend';
import {TableFooter} from '../../components/TableFooter';
import {Graph} from '../../components/LinearGraph/Graph';
import {getTrendsOverall} from '../../store/trend/trend.actions';
import {apiEndpoints} from '../../constants/api';
import {Loader} from '../../components/ui/Loader';
import {ErrorMessage} from '../../components/ErrorMessage';
const cx = classNames.bind(styles);

export const Overall = () => {
  const dispatch = useDispatch();
  const {overall} = useSelector(selectTrend);
  const {interval, data, isLoading, error} = overall;
  const onIntervalChange = (quarter: Interval) => {
    dispatch(changeTrendsOverallInterval(quarter));
  };

  useEffect(() => {
    dispatch(getTrendsOverall.request());
  }, [interval, dispatch]);

  const renderGraph = () => {
    if (error) return <ErrorMessage />;
    if (isLoading || !data) return <Loader className={cx('loader')} />;
    return <Graph data={data} max={108} />;
  };
  return (
    <div className={cx('table')}>
      <TableMenu
        title="trend.title.overall"
        questionMark="trend.title.overall.question-mark"
        downloadApiUrl={apiEndpoints.getTrendOverall(interval, true)}
        active={interval}
        onIntervalChange={onIntervalChange}
      />
      <div className={cx('graph')}>{renderGraph()}</div>
      <TableFooter withChartLabels={data && data.length > 3} />
    </div>
  );
};
