import React from 'react';
import {pdfTextStyle, textStyle} from './config';
import {CustomLayerProps} from '@nivo/line';

export const Labels = {
  A: 'Unser URL A',
  B: 'Vergleich B',
  C: 'Vergleich C',
  D: 'Vergleich D',
  E: 'Vergleich E',
  F: 'Vergleich F',
};

export const YAxisTick = (tick: any, pdf?: boolean) => {
  return (
    <g transform={`translate(${pdf ? '-30' : '-50'} ${tick.y * 1})`}>
      <text textAnchor="start" dominantBaseline="middle" style={pdf ? pdfTextStyle : textStyle}>
        {tick.value}
      </text>
      <line stroke="#e3e3e3" strokeWidth={pdf ? 1 : 1} x1={pdf ? 20 : 37} x2={500} />
    </g>
  );
};

export const XAxisTick = (tick: any, pdf?: boolean) => {
  const fontSize = window.innerWidth < 600 ? 8 : 12;
  return (
    <g transform={`translate(${tick.x},${tick.y + pdf ? 26 : 32})`}>
      <line stroke="#e3e3e3" strokeWidth={pdf ? 1 : 1} y1={-32} y2={-20} />
      <text
        y={pdf ? -5 : 0}
        textAnchor="middle"
        dominantBaseline="hanging"
        style={pdf ? {...pdfTextStyle, fontSize: 7} : {...textStyle, fontSize}}>
        {tick.value}
      </text>
    </g>
  );
};

export const TradeMark = (layer: any, pdf?: boolean) => {
  return null; //temp. disable
  return (
    <g transform={`translate(${layer.innerWidth - 35}, ${layer.innerHeight + 10})`}>
      <text
        textAnchor="middle"
        dominantBaseline="hanging"
        style={pdf ? {...pdfTextStyle, fill: '#d0d0d0'} : textStyle}>
        © PageTracker.drkpi.com
      </text>
    </g>
  );
};

export const TopOverlay = (layer: any, pdf?: boolean) => {
  // if(pdf) return null
  return (
    <g>
      <rect x={-2} width={layer.innerWidth + 4} y={0} height={pdf ? 4 : 8} fill="white" />
    </g>
  );
};

const lineStyle: {[key: string]: any} = {
  [Labels.A]: {
    strokeWidth: 1,
  },
  [Labels.B]: {
    strokeWidth: 1,
  },
  [Labels.C]: {
    strokeWidth: 1,
  },
  [Labels.D]: {
    strokeDasharray: '2, 2',
    strokeWidth: 1,
  },
  [Labels.E]: {
    strokeDasharray: '1, 6',
    strokeWidth: 6,
  },
  [Labels.F]: {
    strokeDasharray: '6, 6',
    strokeWidth: 1,
  },
};
export const CustomLine = ({series, lineGenerator, xScale, yScale}: CustomLayerProps) => {
  return series.map(({id, data, color}) => {
    return (
      <path
        key={id}
        d={lineGenerator(
          data.map((d) => ({
            x: xScale(d.data.x as any),
            y: yScale(d.data.y as any),
          }))
        )}
        fill="none"
        stroke={color}
        style={lineStyle[id] || lineStyle.default}
      />
    );
  });
};
