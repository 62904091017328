import React, {useState} from 'react';
import styles from './index.module.scss';
import classNames from 'classnames/bind';
import Select, {Option} from "../../components/ui/Select";
import {Text} from "../../components/ui/Text";
import {TextInput} from "../../components/ui/TextInput";
import {QuestionMark} from "../../components/ui/QuestionMark";
import {CountriesSelection} from "../../components/ui/CountriesSelection";
import {FormErrorMessage} from "../../components/ui/FormErrorMessage";
import {FormSuccessMessage} from "../../components/ui/FormSuccessMessage";
import {Button} from "../../components/ui/Button";
import {FormEvent} from "../../types/events";
import {showConfirmModal} from "../../store/app/app.actions";
import {useDispatch, useSelector} from "react-redux";
import {selectAppState} from "../../store/app";
import {ComparePage, ComparePageErrors} from "../../store/compare/types";
import {Heading} from './Heading';
import {Textarea} from "../../components/ui/Textarea";
import {syncFailCodes} from "../../constants/codes";
import {placeholders} from './placeholders';
import {checkSubUserStatus} from "../../store/auth/user";

const cx = classNames.bind(styles);

interface Props {
  page: ComparePage;
  errors: ComparePageErrors;
  index: number;
  className?: string;
  disableDelete?: boolean;
  deletePage: () => void;
  withUsersEmail?: boolean;
  updateComparePage: () => void
  disableSubheading?: boolean;
  disableCustomOptionsWidth?: boolean;
  disableCreateButton?: boolean;
  onInputChange: (name: string, value: string | boolean) => void
}

export const Page = (props: Props) => {
  const {page, index: i, errors, disableSubheading, withUsersEmail, onInputChange, deletePage, disableDelete,disableCreateButton, disableCustomOptionsWidth, updateComparePage, className} = props;
  const {parameters} = useSelector(selectAppState);
  const isSubUser = useSelector(checkSubUserStatus);
  const [fakeButtonState, setFakeButtonState] = useState<'loading' | 'hidden' | ''>('');

  const setFakeButtonLoading = () => {
    setFakeButtonState('loading');
    setTimeout(() => {
      setFakeButtonState('hidden')
    }, 1000)
  }

  const dispatch = useDispatch();

  const onFormSubmit = (e: FormEvent) => {
    e.preventDefault();
  }

  const placeholder = placeholders[i];

  const failedMessage = syncFailCodes[page.rawPageStatus || ''];

  const showModal = () => {
    dispatch(showConfirmModal({
      title: 'compare.confirm-modal.title',
      onConfirm: () => deletePage()
    }))
  }

  const renderCommentRatings = () => {
    if (!parameters) return;
    return parameters.commentRatings.map((comment, i) => {
      return <Option translate={false} key={i} value={comment.id} label={comment.name}/>
    })
  }

  const renderCategories = () => {
    if (!parameters) return;
    return parameters.categories.map((category, i) => {
      return <Option translate={false} key={i} value={category.sub_category} label={category.name}/>
    })
  }

  const renderGroups = () => {
    if (!parameters) return;
    return parameters.groups.map((group, i) => {
      return <Option translate={false} key={i} value={group.id} label={group.name}/>
    })
  }

  const renderCompanySizes = () => {
    if (!parameters) return;
    return parameters.companySizes.map((company, i) => {
      return <Option translate={false} key={i} value={company.id} label={company.name}/>
    })
  }

  const renderAdvertisings = () => {
    if (!parameters) return;
    return parameters.advertisings.map((add, i) => {
      return <Option translate={false} key={i} value={add.id} label={add.name}/>
    })
  }

  const renderSubcategories = () => {
    if (!parameters || !page.categoryLetter) return;
    const [subcategories] = parameters.categories.filter((category) => {
      return category.sub_category === page.categoryLetter;
    })
    return subcategories.sub_categories.map((subcategory, j) => {
      return <Option translate={false} key={j} value={subcategory.id} label={subcategory.name}/>
    })
  }

  return (
    <form onSubmit={onFormSubmit} className={cx('page', className)}>
      <Heading
        letter={page.comparisonLetter}
        disableSubheading={disableSubheading}
        index={i}
      />
      <div className={cx('row', 'row__select')}>
        <TextInput
          onChange={(name, value) => onInputChange(name, value)}
          value={page.pageUrl}
          name="pageUrl"
          placeholder={`compare.website.placeholder.${i + 1}`}
          error={errors.pageUrl}
          disabled={!page.isEditable}
          className={cx('input', 'input__url')}
          label={i === 0 ? 'compare.url' : 'compare.url.other'}
        />
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.url'
              : 'compare.question-mark.url.other'
          }
        />
      </div>
      {withUsersEmail && (
        <TextInput
          onChange={(name, value) => onInputChange(name, value)}
          value={page.email}
          name="email"
          placeholder="compare.placeholder.email"
          error={errors.email}
          disabled={!page.isEditable}
          className={cx('input', 'input__url')}
          label="compare.email"
        />
      )}
      <div className={cx('row', 'row__select')}>
        <Select
          disabled={!page.isEditable}
          name="categoryLetter"
          value={page.categoryLetter}
          optionsClassName={cx(
            !disableCustomOptionsWidth && 'category-options-list'
          )}
          error={errors.categoryLetter}
          label="compare.category"
          onChange={(name, value) => onInputChange(name, value)}
          className={cx('select')}
          valueClassName={cx('select-value')}
        >
          {renderCategories()}
        </Select>
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.category'
              : 'compare.question-mark.category.other'
          }
        />
      </div>
      {page.categoryLetter && (
        <div className={cx('row', 'row__select')}>
          <Select
            disabled={!page.isEditable}
            name="categoryId"
            optionsClassName={cx(
              !disableCustomOptionsWidth && 'subcategory-options-list'
            )}
            value={page.categoryId}
            error={errors.categoryId}
            label="compare.subcategory"
            onChange={(name, value) => onInputChange(name, value)}
            className={cx('select')}
            valueClassName={cx('select-value')}
          >
            {renderSubcategories()}
          </Select>
          <QuestionMark
            tooltipPosition="left"
            className={cx('question-mark')}
            content={
              i === 0
                ? 'compare.question-mark.subcategory'
                : 'compare.question-mark.subcategory.other'
            }
          />
        </div>
      )}
      <div className={cx('row', 'row__select')}>
        <Select
          disabled={!page.isEditable}
          name="groupId"
          error={errors.groupId}
          label="compare.list"
          onChange={(name, value) => onInputChange(name, value)}
          optionsClassName={cx(
            !disableCustomOptionsWidth && 'category-options-list'
          )}
          value={page.groupId}
          className={cx('select')}
          valueClassName={cx('select-value')}
        >
          {renderGroups()}
        </Select>
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.list'
              : 'compare.question-mark.list.other'
          }
        />
      </div>
      <div className={cx('row', 'row__select')}>
        <CountriesSelection
          disabled={!page.isEditable}
          name="countryId"
          placeholder={page.new && page.isEditable ? placeholder?.land : ''}
          label="compare.country"
          value={page.countryId}
          labelPosition="top"
          error={errors.countryId}
          onChange={(name, value) => onInputChange(name, value)}
          className={cx('select')}
          valueClassName={cx('select-value')}
        />
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.country'
              : 'compare.question-mark.country.other'
          }
        />
      </div>
      <div className={cx('row', 'row__select')}>
        <Select
          disabled={!page.isEditable}
          name="commentRating"
          label="compare.comment"
          error={errors.commentRating}
          onChange={(name, value) => onInputChange(name, value)}
          value={page.commentRating}
          className={cx('select')}
          valueClassName={cx('select-value')}
        >
          {renderCommentRatings()}
        </Select>
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.comment'
              : 'compare.question-mark.comment.other'
          }
        />
      </div>
      <div className={cx('row', 'row__select')}>
        <Select
          disabled={!page.isEditable}
          name="advertising"
          label="compare.advertising"
          onChange={(name, value) => onInputChange(name, value)}
          error={errors.advertising}
          value={page.advertising}
          className={cx('select')}
          valueClassName={cx('select-value')}
        >
          {renderAdvertisings()}
        </Select>
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.advertising'
              : 'compare.question-mark.advertising.other'
          }
        />
      </div>
      <div className={cx('row', 'row__select')}>
        <Select
          disabled={!page.isEditable}
          name="companySize"
          error={errors.companySize}
          label="compare.employees-number"
          onChange={(name, value) => onInputChange(name, value)}
          value={page.companySize}
          className={cx('select')}
          valueClassName={cx('select-value')}
        >
          {renderCompanySizes()}
        </Select>
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.employees'
              : 'compare.question-mark.employees.other'
          }
        />
      </div>

      <div className={cx('row')}>
        <TextInput
          error={errors.isin}
          value={page.isin}
          onChange={(name, value) => onInputChange(name, value)}
          label="compare.sisin-number"
          name="isin"
          disabled={!page.isEditable}
          inputClassName={cx('text-input')}
          labelClassName={cx('input-label')}
          className={cx('input')}
        />
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.isin'
              : 'compare.question-mark.isin.other'
          }
        />
      </div>
      <div className={cx('row')}>
        <TextInput
          value={page.companyName}
          onChange={(name, value) => onInputChange(name, value)}
          error={errors.companyName}
          label="compare.company-name"
          name="companyName"
          notTranslatedPlaceholder={
            page.isEditable && page.new ? placeholder?.company : ''
          }
          disabled={!page.isEditable}
          inputClassName={cx('text-input')}
          labelClassName={cx('input-label')}
          className={cx('input')}
        />
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.company-name'
              : 'compare.question-mark.company-name.other'
          }
        />
      </div>
      <div className={cx('row')}>
        <TextInput
          value={page.brands}
          onChange={(name, value) => onInputChange(name, value)}
          label="compare.brands"
          name="brands"
          disabled={!page.isEditable}
          inputClassName={cx('text-input')}
          error={errors.brands}
          labelClassName={cx('input-label')}
          className={cx('input')}
          notTranslatedPlaceholder={
            page.isEditable && page.new ? placeholder?.brands : ''
          }
        />
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.brands'
              : 'compare.question-mark.brands.other'
          }
        />
      </div>
      <div className={cx('row')}>
        <Textarea
          notTranslatedPlaceholder={
            page.isEditable && page.new ? placeholder?.hashtags : ''
          }
          label="compare.hashtags"
          value={page.hashtags}
          name="hashtags"
          error={errors.hashtags}
          disabled={!page.isEditable}
          className={cx('textarea', 'textarea--light')}
          onChange={(name, value) => onInputChange(name, value)}
        />
        <QuestionMark
          tooltipPosition="left"
          className={cx('question-mark')}
          content={
            i === 0
              ? 'compare.question-mark.hashtags'
              : 'compare.question-mark.hashtags.other'
          }
        />
      </div>
      {page.status && (
        <div className={cx('row', 'row__status')}>
          <div>
            <Text msg="report.status" />
          </div>
          {page.status === 'FAILED' && (
            <div className={cx('status-text', 'status-text--failed')}>
              <Text msg={failedMessage} />
              <Text msg={'compare.failed.contact-support'} />
            </div>
          )}
          {page.status !== 'FAILED' && (
            <div className={cx('status')}>
              <Text msg={page.status} />
            </div>
          )}
        </div>
      )}
      <div className={cx('buttons')}>
        <FormErrorMessage
          message={
            Object.values(errors).filter((v) => v).length > 0
              ? 'compare.error'
              : ''
          }
        />
        <FormSuccessMessage
          className={cx('failed')}
          visible={
            !page.isEditable &&
            page.status !== 'FAILED' &&
            page.status !== 'DOES NOT EXIST'
          }
          message={'compare.title.not-editable'}
        />
        <FormErrorMessage
          className={cx('error')}
          message={errors.server ? errors.server : ''}
        />
        <FormErrorMessage
          className={cx('error')}
          message={
            page.status === 'DOES NOT EXIST'
              ? 'compare.error-page-not-found'
              : ''
          }
        />
        <FormSuccessMessage
          className={cx('success')}
          visible={
            (!!page.success || page.status === 'PENDING') && page.isEditable
          }
          message="compare.success-create"
        />
        {console.log(page.isEditable, isSubUser, page.id)}
        {!disableCreateButton && page.isEditable && !isSubUser && !page.id && (
          <Button
            msg="compare.button-create"
            loading={page.isLoading}
            onClick={updateComparePage}
            className={cx('button')}
          />
        )}
        {page.id &&
          page.isEditable &&
          fakeButtonState !== 'hidden' &&
          !isSubUser && (
            <Button
              msg="compare.button-update"
              loading={fakeButtonState === 'loading'}
              onClick={updateComparePage}
              className={cx('button')}
            />
          )}
        {!disableDelete && !isSubUser && (
          <Button
            onClick={showModal}
            msg="compare.button-delete"
            className={cx('button', 'button__delete')}
          />
        )}
      </div>
    </form>
  );
};
