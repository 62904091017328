import {ActionType, getType} from 'typesafe-actions';
import * as actions from './editSectionText.actions';
import {DiagramTextValues} from './types';
import update from 'immutability-helper';
type Action = ActionType<typeof actions>
export interface EditSectionTextState {
  diagramsPages:{[key:string]:Array<Array<DiagramTextValues>>};
  savedDiagramsPages:{[key:string]:Array<Array<DiagramTextValues>>},
  loading:boolean;
  loadingUpdate?:boolean;
  success?:boolean;
  currentPage:Array<Array<DiagramTextValues>>
}
const initialState: EditSectionTextState = {
  diagramsPages: {
    benchmarking:[[],[],[],[]],
    trendanalyse:[[],[]],
    branding:[[],[],[],[],[],[]],
    seo:[[],[],[],[]],
    engagement:[[],[],[]],
    visuals:[[],[],[]],
  },
  savedDiagramsPages: {},
  currentPage:[],
  loading:true
};

export const editSectionTextReducer = (state = initialState, action: Action): EditSectionTextState => {
  switch (action.type) {
    case getType(actions.onEditorTextChange): {
      const {index,value,side,diagramId,textId}=action.payload;
       const diagramsPages = update(state.diagramsPages, {
      [index]: {[diagramId]: {[textId]: {[side]: {$set: value}}}},
    });
      return {...state, diagramsPages}
    }
    case getType(actions.onEditorAddTextBox): {
      const {index,diagramId}=action.payload;
      const pushData = {title: '', description: ''};
       const diagramsPages = update(state.diagramsPages, {
      [index]: {[diagramId]:  {$push: [pushData]}},
    });
      return {...state, diagramsPages}
    }
    case getType(actions.onEditorRemoveTextBox): {
      const {index,diagramId,textId}=action.payload;
       const diagramsPages = update(state.diagramsPages, {
       [index]: {[diagramId]: {$splice: [[textId, 1]]}},
    });
      return {...state, diagramsPages}
    }
    case getType(actions.getSectionTextData.request): {
      return {...state, loading:true}
    }
    case getType(actions.getSectionTextData.success): {
      const {diagramsPages}=action.payload;
      return {...state, diagramsPages, loading:false,savedDiagramsPages:diagramsPages}
    }
    case getType(actions.getSectionTextData.failure): {
      return {...state, loading:false}
    }
    case getType(actions.getCurrentSectionTextData.request): {
      return {...state, loading:true}
    }
    case getType(actions.getCurrentSectionTextData.success): {
      const data=action.payload;
      return {...state, currentPage:data, loading:false,}
    }
    case getType(actions.getCurrentSectionTextData.failure): {
      return {...state, loading:false}
    }
    case getType(actions.updateSectionTextData.request): {
      return {...state, loadingUpdate:true}
    }
    case getType(actions.updateSectionTextData.success): {
      const {diagramsPages}=action.payload;
      return {...state, loadingUpdate:false,savedDiagramsPages:diagramsPages}
    }
    case getType(actions.updateSectionTextData.failure): {
      return {...state, loadingUpdate:false}
    }
    
    default :
      return state
  }
}
